<template>
  <el-col>
    <div class="pt-4">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-vertical-demo"
        active-text-color="#FFFFFF"
      >
        <el-menu-item
          v-for="(item, i) in memuList"
          :key="i"
          :index="item.index"
          @click="goPath(item.route)"
        >
          <div class="aside-icon row-center">
            <exp-svg-icon class="icon" :name="item.svgName" size="xs" />
          </div>
          <span class="ml-3.5">{{ item.label }} <span v-if="item.showAsterisk" class="asterisk">*</span>
          </span>
        </el-menu-item>
      </el-menu>
    </div>
  </el-col>
</template>

<script>
import { mapState } from "vuex";
import bus from "@/utils/eventbus";

export default {
  computed: {
    ...mapState({
      device: state => state.device,
      walletAccount: state => state.walletAccount,
      userInfo: state => state.userInfo
    }),
    memuList() {
      const { userInfo } = this;
      const list = [
        {
          label: "Dashboard",
          index: "1",
          svg: true,
          svgName: "menu-dashbord",
          route: "/dashboard",
          isShow: true,
          showAsterisk: false,
        },
        {
          label: "Payment Links",
          index: "2",
          svg: true,
          svgName: "menu-link",
          route: "/payment-link",
          isShow: true,
          showAsterisk: false,
        },
        {
          label: "Billing",
          index: "3",
          svg: true,
          svgName: "menu-billing",
          route: "/billing",
          isShow: true,
          showAsterisk: false,
        },
        {
          label: "L2 Wallet",
          index: "4",
          svg: true,
          svgName: "pay-wallet",
          route: "/wallet",
          isShow: !["mixin", "mvm", "robot", "multisig"].includes(userInfo.type)
        },
        {
          label: "Payment History",
          index: "6",
          svg: true,
          svgName: "payment-history",
          route: "/history",
          isShow: true,
          showAsterisk: false,
        },
        {
          label: "Settings",
          index: "7",
          svg: true,
          svgName: "settings",
          route: "/settings",
          isShow: true,
          showAsterisk: !userInfo.isInWhiteList,
        },
        {
          label: "API Key",
          index: "8",
          svg: true,
          svgName: "api-key",
          route: "/api-key",
          isShow: false,
          showAsterisk: false,
        }
      ];
      return list.filter(item => item.isShow);
    }
  },

  data() {
    return {
      activeIndex: "1"
    };
  },

  watch: {
    $route(to) {
      this.setActiveIndex(to.path);
    }
  },

  created() {
    this.setActiveIndex("");
  },

  methods: {
    goPath(path) {
      if (
        window.localStorage.getItem("walletChainId") &&
        window.localStorage.getItem("walletChainId") != "0x120c7"
      ) {
        bus.emit("openMvmTips");
      }
      this.$emit("menu-collapse");
      this.$router.push({
        path: path
      });
    },
    setActiveIndex(path) {
      var result = "";
      if (path) {
        result = path;
      } else {
        result = this.$route.path;
      }

      const current = this.memuList.find(
        item => result.indexOf(item.route) > -1
      );
      if (current) {
        this.activeIndex = current.index;
      } else {
        this.activeIndex = this.memuList[0].index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.asterisk {
  color: #ff0000;
  font-size: 12px;
}
</style>