<template>
  <el-dialog
    title="Notification Receivers"
    top="10vh"
    class="w-max">
    <template #default>
      <div class="scroller">
        <el-scrollbar max-height="65vh">
          <div class="list-contacts">
            <div
              v-for="(item, index) in remembrancerList"
              class="item-list-contacts row-justify-center"
              :class="[index % 2 != 0 ? 'item-list-contacts-right' : '']"
              :key="index"
            >
              <div class="row-align-center">
                <el-avatar
                  :size="40"
                  class="item-list-contacts-avatar"
                  :src="item.avatar"
                />
                <div class="col ml-2">
                    <div class="row-align-center">
                      <div class="item-list-contacts-nickname"> {{ stringName(item.nickname) }} </div>
                      <span class="status" :class="item.status.icon" v-if="item.status">{{ item.status.text }}</span>
                    </div>
                    <div class="item-list-contacts-mixinId mt-1">
                      {{ item.mixinId }}
                    </div>
                </div>
                
              </div>
              <el-icon
                color="#F56C6C"
                class="cursor-pointer"
                v-if="index != 0"
                @click="deleteContact(item.id)"
                ><DeleteFilled
              /></el-icon>
            </div>
            <div
              class="item-list-contacts add-contact row-center cursor-pointer"
              :class="[
                remembrancerList.length % 2 != 0 ? 'item-list-contacts-right' : '',
              ]"
              @click="showAddContact"
            >
              <el-icon class="mr-2" size="18px"><CirclePlus /></el-icon>
              {{ $t("actions.addReceiver") }}
            </div>
          </div>
        </el-scrollbar>
      </div>

      <add-notification-dialog 
        v-model="isShowAddDialog"
        @addReceiver="addReceiver"/>

    </template>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
import AddNotificationDialog from './add-notification-dialog.vue';
import { ElMessageBox, ElNotification } from "element-plus";
import { deleteRemembrancer } from "@/api/index.js";

export default {
  components: {
    AddNotificationDialog,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
    remembrancerList() {
      const list = [];
      if(this.userInfo.avatar) {
        list.push({
          mixinId: this.userInfo.mixinId,
          nickname: this.userInfo.nickname,
          avatar: this.userInfo.avatar,
        });
      }
      if(Array.isArray(this.userInfo.remembrancers)) {
        this.userInfo.remembrancers.forEach(item => {
          list.push({
            id: item.id,
            mixinId: item.mixinId,
            nickname: item.nickname,
            avatar:
              item.avatarUrl ||
              require("@/assets/images/avatar-default.svg"),
            status: this.getStatus(item.isAgree),
          });
        });
      }
      return list;
    },
  },

  data() {
    return {
      isShowAddDialog: false,
    }
  },

  mounted() {

  },

  methods: {
    stringName(name) {
      if(name.length < 12) {
          return name;
      }   
      return name.substring(0, 12) + '...';
    },
    showAddContact() {
      this.isShowAddDialog = true;
    },
    addReceiver() {
      this.$store.dispatch("updateUserInfo");
      this.isShowAddDialog = false;
    },
    getStatus(status = 0) {
      if (status == -1) {
        return {
          icon: "Rejected",
          text: this.$t("status.reject"),
        };
      }
      if (status == 0) {
        return {
          icon: "Pending",
          text: this.$t("status.wait"),
        };
      }
      if (status == 1) {
        return {
          icon: "Approved",
          text: this.$t("status.approved"),
        };
      }
      return {};
    },
    deleteContact(id) {
      ElMessageBox.confirm(this.$t('notification.addConfirm'), "Tips", {
        confirmButtonText: this.$t('actions.confirm'),
        cancelButtonText: this.$t('actions.cancel'),
      }).then(() => {
        deleteRemembrancer(id).then(() => {
          this.$store.dispatch("updateUserInfo");
          ElNotification({
            title: 'Success',
            message: 'Delete Receiver Success.',
            type: 'success',
          })
        })
        .catch((err) => {
            this.$errHandler(err, "dialog");
        });
      })
      .catch(() => {
      
      });
    },
  }

}
</script>

<style lang="scss" scoped>
.scroller {
  // width: 824px;
  width: 546px;
}

.item-list-contacts {
  height: 70px;
}

.list-contacts {
  display: flex;
  flex-wrap: wrap;

  .item-list-contacts {
    position: relative;
    box-sizing: border-box;
    width: 260px;
    border-radius: 8px;
    border: 1px solid border-default-color;
    margin-bottom: 18px;
    padding: 0 12px;

    &-nickname {
      color: color-text-base;
    }

    &-mixinId {
      font-size: 12px;  
    }

    &-right {
      margin-left: 18px;
    }
  }

  .add-contact {
    color: color-primary;
    font-weight: 500;
    border: 1px solid color-primary;
  }

  .add-contact:hover {
    background: #F8FAFF;
    font-weight: 600;
  }

  .status {
    margin-left: 6px;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 8px;
    color: #fff;

    &.Pending {
        background: #ffa527;
    }

    &.Approved {
        background: #0ec9b1;
    }

    &.Rejected {
        background: #ff4466;
    }
    }
}
</style>