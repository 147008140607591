<template>
  <div :class="device === 'mobile' ? 'w-full' : 'w-4/5'">
    <h1>Where are the settled assets?</h1>
    <p class="mt-4 text-gray-600">Your assets are settled to the <el-link type="primary" href="https://scan.mvm.dev/">MVM Network</el-link>.</p>
    <h1 class="mt-6">What is MVM Network？</h1>
    <p class="mt-4 text-gray-600"><el-link type="primary" href="https://mixin.one/">Mixin Network</el-link> is an open-source, lightning-fast and decentralized W3 platform to bring speed and scalability to the blockchain. The vision of Mixin is to provide users with a decentralized blockchain infrastructure that always puts security, privacy and decentralization first.</p>
    <p class="mt-4 text-gray-600">Since Mixin uses the same degree of privacy but a more flexible TIP scheme to store private keys, developers of other blockchains (such as Ethereum) cannot easily use their existing technical solutions in Mixin ecosystem. The purpose of MVM is to facilitate smart contract developers to develop based on Mixin.</p>
    <p class="mt-4 text-gray-600">MVM allows developers on other networks to migrate their applications directly to Mixin almost without any modification.</p>
    <h1 class="mt-6">How to withdraw from MVM Network?</h1>
    <p class="mt-4 text-gray-600">Withdrawal from the <el-link type="primary" href="https://scan.mvm.dev/">MVM Network</el-link> requires the use of <el-link type="primary" href="https://bridge.mvm.app/">Assets Bridge</el-link>.</p>
    <el-steps :active="5" align-center class="mt-10" :space="device === 'mobile' ? 50 : ''" :direction="device === 'mobile' ? 'vertical' : 'horizontal' ">
      <el-step title="Open Assets Bridge" />
      <el-step title="Connect Wallet" />
      <el-step title="Select Withdrawal" />
      <el-step title="Select Asset" />
      <el-step title="Confirm withdrawal" />
    </el-steps>
    <h1 class="mt-10">Join MVM</h1>
    <p class="mt-6">Mainnet RPC</p>
    <p class="mt-4"><span class="text-gray-600">Network name:</span> Mixin Virtual Machine</p>
    <p class="mt-4"><span class="text-gray-600">RPC link:</span> <el-link type="primary" href="https://geth.mvm.dev">https://geth.mvm.dev</el-link></p>
    <p class="mt-4"><span class="text-gray-600">ChainID:</span> 73927</p>
    <p class="mt-4"><span class="text-gray-600">Symbol:</span> ETH</p>
    <p class="mt-4"><span class="text-gray-600">Blockchain browser:</span> <el-link type="primary" href="https://scan.mvm.dev">https://scan.mvm.dev</el-link></p>
    <p class="mt-6"><span class="text-gray-600">You can also add to MetaMask via the link:</span> <el-link type="primary" href="https://chainlist.info/?search=mvm">https://chainlist.info/?search=mvm</el-link></p>
    <el-link class="mt-6 mb-20" type="primary" href="http://help.mixpay.me">to use MVM in MetaMask?</el-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'AssetHelpPage',
  
  computed: {
    ...mapState({
      device: state => state.device,
      walletAccount: state => state.walletAccount,
    }),
  },

  mounted() {
    if(!this.walletAccount) {
      this.$router.push('/')
    }
  }

};
</script>

<style lang="scss" scoped>

</style>