<template>
  <el-dialog title="Switch Accounts" class="w-max" @open="dialogOpen">
    <el-scrollbar max-height="400px" v-loading="isLoading">
      <div
        class="item-list row-justify-center cursor-pointer"
        :class="{ active: selectMultisigId === '' }"
        @click="selectAccount('')"
      >
        <div class="row-align-center">
          <el-avatar :src="info.avatar || userInfo.avatar" class="avatar" />
          <div class="name ml-2">{{ info.nickname || userInfo.nickname }}</div>
        </div>
        <el-icon color="#409EFF" size="large" v-if="selectMultisigId === ''"
          ><CircleCheckFilled
        /></el-icon>
      </div>
      <div
        class="item-list row-justify-center cursor-pointer"
        :class="{ active: selectMultisigId === item.multisigId }"
        v-for="(item, index) in userSubAccount.list"
        :key="index"
      >
        <div class="row-align-center flex-1" @click="selectAccount(item)">
          <el-avatar :src="item.avatarUrl" class="avatar" />
          <div class="name ml-2">{{ item.name }}</div>
          <div class="item-type row-center" :class="item.type">
            {{ item.type === "robot" ? "Robot" : "Multisig" }}
          </div>
        </div>
        <el-icon
          color="#409EFF"
          size="large"
          v-if="selectMultisigId === item.multisigId"
          ><CircleCheckFilled
        /></el-icon>
        <el-icon
          color="#F56C6C"
          size="large"
          v-if="selectMultisigId != item.multisigId"
          @click="deleteAccount(item.multisigId, index)"
          ><DeleteFilled
        /></el-icon>
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { ElMessageBox } from "element-plus";
import { relieveAccount } from "@/api/index";

export default {
  computed: {
    ...mapState({
      userSubAccount: state => state.userSubAccount,
      device: state => state.device,
      userInfo: state => state.userInfo
    })
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectMultisigId: "",
      isLoading: false
    };
  },

  methods: {
    dialogOpen() {
      if (window.localStorage.getItem("select-multisigId")) {
        this.selectMultisigId = window.localStorage.getItem(
          "select-multisigId"
        );
      }
    },
    selectAccount(account) {
      if (account) {
        this.selectMultisigId = account.multisigId;
        window.localStorage.setItem("select-multisigId", this.selectMultisigId);
        window.localStorage.setItem("select-multisigId-type", account.type);
      } else {
        this.selectMultisigId = "";
        window.localStorage.removeItem("select-multisigId");
        window.localStorage.removeItem("select-multisigId-type");
      }
      this.$emit("reload");
    },
    deleteAccount(id, index) {
      ElMessageBox.confirm(this.$t("account.deleteTips"), "Tips", {
        confirmButtonText: this.$t("actions.confirm"),
        cancelButtonText: this.$t("actions.cancel"),
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          relieveAccount(id)
            .then(() => {
              this.userSubAccount.list.splice(index, 1);
              this.isLoading = false;
            })
            .catch(err => {
              this.isLoading = false;
              this.$errHandler(err, "dialog");
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
:deep {
  .el-dialog__body {
    padding: 24px 0;
  }
}

.el-dialog__body {
  padding: 24px 0;
}

.item-list {
  height: 70px;
  width: 500px;

  &.active {
    background: #f8faff;
  }

  .name {
    display: inline-block;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 470px) {
      max-width: 150px;
    }
  }

  .item-type {
    margin-left: 6px;
    height: 20px;
    padding: 0 4px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 600;
  }

  .robot {
    background: #e8f1fa;
    color: color-primary;
  }

  .multisig {
    background: #e8faf2;
    color: #62d37e;
  }
}
</style>