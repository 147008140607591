<template>
  <el-dialog
    :title="$t('history.withdrawTitle')"
    :width="device === 'mobile' ? '90%' : '30%'"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <el-scrollbar
      v-loading="isLoading"
      element-loading-text="Loading..."
      element-loading-background="#FFFFFF"
      max-height="480px"
    >
      <div class="result-wrapper">
        <div class="status col-center">
          <exp-svg-icon :name="statusIcon" size="xl" />
          <h4 :class="['status-title', status]">
            {{ $t(`status.${status}`) }}
          </h4>
          <p class="strong-num">{{ amount }} {{ symbol }}</p>
        </div>
        <div class="division"></div>
        <div class="detail">
          <div class="detail-item row-justify-center">
            <div class="detail-item-title">
              {{ $t("history.withdrawalMethod") }}
            </div>
            <div class="detail-item-content">
              {{ $t(`history.${withdrawType}`) }}
            </div>
          </div>
          <div class="detail-item row-justify-center" v-if="feeAmount > 0">
            <div class="detail-item-title">{{ $t("history.fee") }}</div>
            <div class="detail-item-content">{{ feeAmount }} {{ symbol }}</div>
          </div>
          <div class="detail-item row-justify-center">
            <div class="detail-item-title">{{ $t("history.date") }}</div>
            <div class="detail-item-content">{{ createdAt }}</div>
          </div>
          <div class="detail-item row-justify-center" v-if="isAsset != 0">
            <div class="detail-item-title">
              {{ $t("history.transactionID") }}
            </div>
            <div
              class="row-center"
              v-if="snapshotId"
              @click="openExplorer(snapshotId)"
            >
              <div class="detail-item-content link">{{ snapshotId }}</div>
            </div>
            <div class="row-center" v-else>
              <div class="detail-item-content">-</div>
            </div>
          </div>
          <div class="detail-item" v-if="withdrawWay.length > 0">
            <div class="detail-item-title">
              {{
                withdrawType === "cash"
                  ? $t("withdraw.receiptAddress")
                  : $t("withdraw.receiptAccount")
              }}
            </div>
            <div
              class="detail-item-way"
              v-for="(item, index) in withdrawWay"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { getWithdrawDetail, overWithdrawRecord } from "@/api/index";
import { mapState } from "vuex";
import { formatTime } from "@/utils/date-format.js";
export default {
  components: {},

  filters: {
    formatTime,
  },

  props: {
    traceId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isLoading: false,
      status: "pending",
      pollKey: null,
      amount: "",
      symbol: "",
      withdrawType: "",
      feeAmount: "",
      createdAt: "",
      isAsset: 0,
      snapshotId: "",
      withdrawWay: [],
      isBtnClick: false,
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      device: (state) => state.device,
      statusIcon() {
        switch (this.status) {
          case "success":
          case "done":
            return "status-success";
          case "checking":
            return "status-checking";
          case "pending":
          case "processing":
            return "status-waiting";
          default:
            return "status-failed";
        }
      },
    }),
  },

  methods: {
    dialogOpen() {
      this.pollKey = setInterval(() => {
        this.loadData()
          .then(() => {
            if (this.status === "success") {
              clearInterval(this.pollKey);
            }
          })
          .catch(() => {});
      }, 2000);
      this.isLoading = true;
      this.loadData()
        .then(() => {
          this.isLoading = false;
          if (this.status === "success") {
            clearInterval(this.pollKey);
          }
        })
        .catch((err) => {
          this.$errHandler(err);
        });
    },
    dialogClose() {
      clearInterval(this.pollKey);
    },
    loadData() {
      return getWithdrawDetail(this.traceId).then(({ data: d }) => {
        this.amount = d.amount;
        this.status = d.status;
        this.withdrawWay = Array.isArray(d.withdrawWay) ? d.withdrawWay : [];
        this.withdrawType = d.type;
        this.feeAmount = d.feeAmount;
        this.isAsset = d.isAsset;
        this.createdAt = formatTime(d.createdAt * 1000);
        this.snapshotId = d.snapshotId;
        this.symbol = d.symbol;
      });
    },
    confirmWithdraw() {
      this.isBtnClick = true;
      overWithdrawRecord(this.traceId)
        .then(() => {
          this.isBtnClick = false;
        })
        .catch((err) => {
            this.isBtnClick = false;
          this.$errHandler(err);
        });
    },
    modifyAddress() {
    //   this.$router.push({
    //     path:
    //       this.withdrawType === "cash"
    //         ? "/withdraw/manage-address"
    //         : "/withdraw/manage-account",
    //     query: {
    //       withdrawWay: this.withdrawType,
    //       traceId: this.traceId,
    //       symbol: this.symbol,
    //     },
    //   });
    },
    openExplorer(snapshotId) {
      window.open(
        `https://mixin.one/snapshots/${snapshotId}`,
        "_blank",
        "noopener"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  .status-title {
    margin: 10px;
    font-size: 20px;
    color: color-text-caption;

    &.pending, &.checking, &.processing {
      color: #F7A349;
    }

    &.done, &.success {
      color: #0EC9B1;
    }

    &.failed {
      color: #FF4466;
    }
  }

  .strong-num {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  .num {
    color: color-text-caption;
  }
}

.division {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-position: left center;
  background-size: 20px 2px;
  background-image: linear-gradient(90deg, rgba(#5162ad, 0.16), rgba(#5162ad, 0.16) 12px, transparent 12px, transparent 20px);
}

.detail-item {
  font-size: 16px;
  margin-top: 20px;

  @media only screen and (max-width: 470px) {
    font-size: 12px;
  }

  &-title {
    max-width: 300px;
    color: color-text-caption;
  }

  &-content {
    max-width: 200px;
    font-weight: 600;
    word-break: break-all;
    text-align: right;

    &.link {
      color: color-primary;
    }
  }

  &-way {
    font-weight: 600;
    word-break: break-all;
    margin-top: 10px;
  }
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  left: 40px;
  right: 40px;
  margin-bottom: 40px;
  z-index: 100;

  :deep .md-button {
    height: 100px;
    font-size: 32px;
  }

  :deep .btn-modify {
    border: 1px solid color-primary;
    color: color-primary;
    margin-top: 32px;
  }
}
</style>
