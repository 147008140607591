<template>
  <div>
    <body-header>
      <template #header-right>
        <div class="alert-warning flex">
          <exp-svg-icon name="alert" size="sm" class="flex-shrink-0" />
          <div class="text-sm font-normal row-align-center mx-2">
            These links will be automatically deactivated once payment is
            received for the payment link.
          </div>
          <el-link
            class="font-normal flex-shrink-0"
            type="primary"
            @click="goPaymentLink"
          >
            Set permanent link
          </el-link>
        </div>
      </template>
    </body-header>
    <div class="py-7 px-10">
      <div v-loading="isLoading">
        <div class="filter flex flex-wrap">
          <el-select
            class="selector"
            v-model="filterValue"
            placeholder="Select"
            @change="selectStatus"
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>

          <el-date-picker
            v-model="dateValue"
            type="daterange"
            range-separator="To"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :clearable="false"
            @change="selectDate"
          />
          <el-button type="warning" @click="clearFilter">Reset</el-button>
          <el-button
            type="success"
            icon="Refresh"
            :loading="isRefreshing"
            @click="refrshData"
          >
            Refresh
          </el-button>
          <el-button type="primary" icon="plus" @click="addBilling">
            Add Billing
          </el-button>
        </div>

        <div
          class="empty-view mt-7"
          v-if="
            page === 1 && billingList.length <= 0 && !filterValue && !startTime
          "
        >
          <div v-if="!isLoading">
            <div class="mb-16 flex flex-col gap-y-1">
              <div class="empty-view-title text-xl leading-8">
                Welcome to Billing
              </div>
              <div class="empty-view-tips text-sm leading-6">
                Create billing without all the heavy lifting.
              </div>
            </div>
            <div class="mb-10">
              <div class="flex flex-col gap-y-12 max-w-xxs">
                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="plus" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="
                        empty-item-title
                        text-base
                        leading-5
                        text-dark-dark
                      "
                    >
                      Create billing quickly
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Start accepting payments in just a few clicks.
                    </div>
                  </div>
                </div>

                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="share" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="
                        empty-item-title
                        text-base
                        leading-5
                        text-dark-dark
                      "
                    >
                      Share with customers on any channel
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Be flexible by sharing the link on any channel or device.
                    </div>
                  </div>
                </div>

                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="bitcoin" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="
                        empty-item-title
                        text-base
                        leading-5
                        text-dark-dark
                      "
                    >
                      Easy and convenient
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Support any currency quote any currency settlement.
                    </div>
                  </div>
                </div>

                <el-button
                  class="w-min"
                  type="primary"
                  @click="addBilling"
                  icon="plus"
                >
                  Add Billing
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="billingList.length <= 0">
          <empty-view class="mt-4 h-[28rem]" />
        </div>

        <div class="mt-4" v-else>
          <el-table
            :data="billingList"
            size="large"
            max-height="560"
            table-layout="auto"
            class="text-[16px] shadow-sm"
          >
            <el-table-column label="Billing Link" min-width="160">
              <template #default="scope">
                <div class="row-align-center">
                  <span class="mx-1">{{ showUrl }}</span>
                  <el-icon class="cursor-pointer" @click="copy(scope.row.url)">
                    <CopyDocument />
                  </el-icon>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Quote" min-width="120">
              <template #default="scope">
                <div class="row-align-center">
                  <exp-symbol
                    class="coin-icon flex-shrink-0"
                    :uuid="scope.row.quoteAssetId"
                    :iconUrl="scope.row.quoteIcon"
                  />
                  <span class="ml-1" v-if="scope.row.quoteAmount">
                    {{ noSci(scope.row.quoteAmount) }}
                  </span>
                  <span class="ml-1">{{ scope.row.quoteSymbol }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Receiving" min-width="130">
              <template #default="scope">
                <div
                  class="row-align-center"
                  v-if="scope.row.settlementAssetId"
                >
                  <exp-symbol
                    class="coin-icon mr-1 flex-shrink-0"
                    :uuid="scope.row.settlementAssetId"
                    :iconUrl="scope.row.settlementIcon"
                  />
                  <span>{{ scope.row.settlementSymbol }}</span>
                </div>
                <div class="row-align-center" v-else>
                  <img
                    class="coin-icon mr-1 w-[18px] flex-shrink-0"
                    src="@/assets/all.svg"
                  />
                  <span>{{ $t("selector.all") }} </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Status" width="100">
              <template #default="scope">
                <span
                  :class="
                    scope.row.orderStatus === 'pending'
                      ? 'text-[#fda527]'
                      : scope.row.orderStatus === 'received'
                      ? 'text-[#62d37e]'
                      : ''
                  "
                >
                  {{ upperFirstConcat(scope.row.orderStatus) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Time Created"
              prop="createdAt"
              width="180"
            />
            <el-table-column
              label="Time Expired"
              prop="expiredAt"
              width="180"
            />
            <el-table-column
              label="Actions"
              :min-width="
                actionsLength === 4
                  ? '220'
                  : actionsLength === 3
                  ? '180'
                  : actionsLength === 2
                  ? '140'
                  : '100'
              "
            >
              <template #default="scope">
                <div class="row-align-center space-x-3">
                  <el-link
                    type="success"
                    @click="sendEmail(scope.row)"
                    v-if="
                      scope.row.orderStatus === 'pending' &&
                        !scope.row.hasSendEmail &&
                        !scope.row.email
                    "
                  >
                    Send
                  </el-link>
                  <el-link type="primary" @click="goDetail(scope.row)">
                    Detail
                  </el-link>
                  <el-link
                    type="danger"
                    @click="goExpire(scope.row.id)"
                    v-if="scope.row.orderStatus === 'pending'"
                  >
                    Expire
                  </el-link>
                  <el-popover placement="left" :width="300">
                    <template #reference>
                      <el-link
                        type="primary"
                        v-if="scope.row.orderStatus === 'pending'"
                      >
                        QR
                      </el-link>
                    </template>
                    <div
                      :id="`image-${scope.row.id}`"
                      class="col-center bg-white"
                    >
                      <img
                        class="share-img-header"
                        src="@/assets/images/share-img-header-temporary.png"
                      />
                      <div class="my-2 row-center">
                        <span
                          class="ml-1 text-lg font-bold text-font-color"
                          v-if="scope.row.quoteAmount"
                        >
                          {{ noSci(scope.row.quoteAmount) }}
                        </span>
                        <span class="ml-1 text-lg font-bold text-font-color">
                          {{ scope.row.quoteSymbol }}
                        </span>
                      </div>
                      <span class="text-xs text-gray-gray5">
                        {{ scope.row.remark }}
                      </span>
                      <div class="my-6 qrcode" ref="qrcode">
                        <qrcode-vue
                          :value="scope.row.url"
                          :size="200"
                          level="H"
                        />
                        <img
                          v-if="userInfo.avatar"
                          class="qrcode-avatar w-20 h-20"
                          crossorigin="anonymous"
                          :src="`${userInfo.avatar}?id=${scope.row.id}`"
                        />
                        <!-- <img
                          :src="userAvatar"
                          class="qrcode-avatar w-20 h-20"
                          crossorigin="anonymous"
                          v-if="userAvatar"
                        />
                        <el-avatar
                          :src="userInfo.avatar"
                          class="qrcode-avatar w-20 h-20"
                          v-else
                        /> -->
                      </div>
                      <span
                        class="text-lg font-bold text-font-color"
                        v-if="userInfo.nickname == userInfo.mixinId"
                      >
                        {{ userInfo.nickname }}
                      </span>
                      <span
                        class="text-lg font-bold text-font-color"
                        v-else-if="userInfo.mixinId"
                      >
                        {{ userInfo.nickname }}({{ userInfo.mixinId }})
                      </span>
                      <span class="text-lg font-bold text-font-color" v-else>
                        {{ userNickname }}
                      </span>
                      <span class="text-sm text-font-color mt-6">
                        Pay any Crypto with any Wallet
                      </span>
                      <img
                        class="share-img-cryptos"
                        src="@/assets/images/share-img-cryptos.png"
                      />
                      <img
                        class="share-img-bottom"
                        src="@/assets/images/share-img-bottom.png"
                      />
                    </div>
                    <div class="mb-4 row-center">
                      <el-button
                        class="btn-download"
                        type="primary"
                        @click="handleClick(scope.row.id)"
                        :loading="downloading"
                      >
                        Download
                      </el-button>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt-4"
            layout="prev, pager, next"
            background
            :current-page="page"
            :page-count="pageCount"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <add-billing-dialog
      v-model="isShowAddBillingDialog"
      v-model:selectValuationUuid="valuationUuid"
      v-model:selectAssetUuid="assetUuid"
      v-model:name="name"
      v-model:email="email"
      v-model:amount="amount"
      v-model:note="note"
      @addDialogClose="addDialogClose"
      @handleValuationDialog="handleValuationDialog"
      @handleSettleDialog="handleSettleDialog"
      @successAdd="successAdd"
    />

    <quote-picker-dialog
      v-model="isShowQuotePicker"
      v-model:defaultValue="valuationUuid"
      @closeDialog="handleValuationDialog"
    />

    <settle-picker-dialog
      v-model="isShowSettlePicker"
      :options="settlementAssets"
      has-filter
      v-model:defaultValue="assetUuid"
      @closeDialog="handleSettleDialog"
    />

    <billing-detail-dailog
      v-model="isShowReceiveDetail"
      :billing="selectBilling"
      @createBilling="createBilling"
    />

    <customer-info-dialog
      v-model="isShowSendEmailDialog"
      :billing="selectBilling"
      @successSend="successSend"
    />

    <billing-confirm-dialog
      v-model="isShowBillingConfirmDialog"
      :billing="selectBilling"
      @getIt="getIt"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AddBillingDialog from "./components/add-billing-dialog.vue";
import QuotePickerDialog from "../qrcode/components/quote-picker-dialog.vue";
import SettlePickerDialog from "../qrcode/components/settle-picker-dialog.vue";
import CustomerInfoDialog from "./components/customer-info-dialog.vue";
import BillingConfirmDialog from "./components/billing-confirm-dialog.vue";
import bus from "@/utils/eventbus";
import {
  getBillingList,
  expiredBilling,
  getSettlementAssets
} from "@/api/index";
import { noSci } from "@/utils/num-format.js";
import copyText from "@/utils/copy-text.js";
import { formatTime } from "@/utils/date-format.js";
import { getPageCount } from "@/utils/num-format";
import BillingDetailDailog from "./components/billing-detail-dialog.vue";
import { upperFirstConcat } from "@/utils/string";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import html2Canvas from "html2canvas";
import QrcodeVue from "qrcode.vue";
import axios from "axios";

export default {
  name: "BillingList",
  components: {
    AddBillingDialog,
    QuotePickerDialog,
    SettlePickerDialog,
    BillingDetailDailog,
    CustomerInfoDialog,
    BillingConfirmDialog,
    QrcodeVue
  },

  computed: {
    ...mapGetters(["quoteMap", "assetMap"]),
    ...mapState({
      appURL: state => state.appURL,
      device: state => state.device,
      settings: state => state.settings,
      userInfo: state => state.userInfo,
      billingFirstPageList: state => state.billingFirstPageList
    }),
    showUrl() {
      return `${this.appURL}/code...`;
    },
    isContainAll() {
      let check = false;
      this.billingList.forEach(item => {
        if (!item.settlementAssetId) {
          check = true;
          return;
        }
      });
      return check;
    },
    actionsLength() {
      let count = 1;
      for (let i = 0; i < this.billingList.length; i++) {
        if (this.billingList[i].orderStatus === "pending") {
          count += 2;
          break;
        }
      }
      for (let i = 0; i < this.billingList.length; i++) {
        if (
          this.billingList[i].orderStatus === "pending" &&
          !this.billingList[i].hasSendEmail &&
          !this.billingList[i].email
        ) {
          count += 1;
          break;
        }
      }
      return count;
    },
    userNickname() {
      return this.userInfo.nickname.length > 20
        ? this.userInfo.nickname.slice(0, 6)
        : this.userInfo.nickname;
    }
  },

  data() {
    return {
      filter: "all",
      filterValue: "",
      isShowAddBillingDialog: false,
      valuationUuid: "",
      assetUuid: "",
      settlementAssets: [],
      isShowQuotePicker: false,
      isShowSettlePicker: false,
      page: 1,
      perPage: 10,
      billingList: [],
      pageCount: 1,
      selectBilling: null,
      name: "",
      email: "",
      amount: "",
      note: "",
      isShowReceiveDetail: false,
      isLoading: false,
      isRefreshing: false,
      stateList: [
        {
          value: "",
          label: "All Billings"
        },
        {
          value: "pending",
          label: "Pending"
        },
        {
          value: "received",
          label: "Received"
        },
        {
          value: "expired",
          label: "Expired"
        }
      ],
      dateValue: null,
      startTime: null,
      endTime: null,
      isShowSendEmailDialog: false,
      isShowBillingConfirmDialog: false,
      downloading: false,
      userAvatar: ""
    };
  },

  created() {
    if (this.billingFirstPageList && this.billingFirstPageList.list) {
      this.billingList = this.billingFirstPageList.list;
      this.pageCount = this.billingFirstPageList.pageCount;
    }
    this.loadData();

    bus.on("sendSettlementAssets", data => {
      this.settlementAssets = data;
    });
  },

  // mounted() {
  //   this.getUserAvatar();
  // },

  methods: {
    noSci,
    upperFirstConcat,
    loadData() {
      if (this.billingFirstPageList.status === "pending") {
        this.isLoading = true;
      }
      this.$store
        .dispatch("updateBillingFirstPageList", {
          filter: this.filterValue,
          startTime: this.startTime,
          endTime: this.endTime
        })
        .then(() => {
          this.isLoading = false;
          this.isRefreshing = false;
          this.billingList = this.billingFirstPageList.list;
          this.pageCount = this.billingFirstPageList.pageCount;
        })
        .catch(() => {
          this.isLoading = false;
          this.isRefreshing = false;
        });
    },
    loadMoreData() {
      this.isLoading = true;
      getBillingList(
        this.page,
        this.perPage,
        this.filterValue,
        this.startTime,
        this.endTime
      )
        .then(data => {
          const list = [];
          if (data && data.data && Array.isArray(data.data)) {
            data.data.forEach(item => {
              list.push({
                id: item.id,
                url: `${this.appURL}/code/${item.traceId}`,
                orderStatus: item.orderStatus,
                quoteAmount: item.quoteAmount,
                quoteAssetId: item.quoteAssetId,
                quoteIcon: item.quoteIcon,
                quoteSymbol: item.quoteSymbol,
                remark: item.remark,
                settlementAssetId: item.settlementAssetId,
                settlementIcon: item.settlementIcon,
                settlementSymbol: item.settlementSymbol,
                traceId: item.traceId,
                expiredAt: formatTime(
                  item.expiredTimestamp * 1000,
                  "YYYY-MM-DD HH:mm"
                ),
                createdAt: formatTime(
                  item.createdAt * 1000,
                  "YYYY-MM-DD HH:mm"
                ),
                name: item.name,
                email: item.email,
                hasSendEmail: item.hasSendEmail
              });
            });
          }
          this.isLoading = false;
          this.isRefreshing = false;
          this.billingList = list;
          if (data.meta && data.meta.total) {
            this.pageCount = getPageCount(data.meta.total, this.perPage);
          }
        })
        .catch(err => {
          this.isLoading = false;
          this.isRefreshing = false;
          this.$errHandler(err, "dialog");
        });
    },
    goPaymentLink() {
      this.$store.commit("setShowAddPaymentLink", true);
      this.$router.push("/payment-link");
    },
    onSelectState(state) {
      if (this.filter === state.type) {
        return;
      }
      this.filter = state.type;
      this.filterValue = state.value;
      this.pageChange(1);
    },
    addBilling() {
      this.isShowAddBillingDialog = true;
    },
    addDialogClose() {
      this.valuationUuid = "";
      this.assetUuid = "";
      this.name = "";
      this.email = "";
      this.amount = "";
      this.note = "";
    },
    handleValuationDialog() {
      this.isShowQuotePicker = !this.isShowQuotePicker;
    },
    handleSettleDialog(valuationUuid, quoteAmount) {
      this.isShowSettlePicker = !this.isShowSettlePicker;
      if (this.isShowSettlePicker) {
        getSettlementAssets(this.userInfo.userUuid, valuationUuid, quoteAmount)
          .then(({ data: d }) => {
            if (Array.isArray(d)) {
              this.settlementAssets = d.map(item => ({
                uuid: item.assetId,
                isAsset: !!+item.isAsset,
                available: !!+item.isAvailable,
                symbol: item.symbol,
                icon: item.iconUrl,
                minQuoteAmount: item.minQuoteAmount,
                maxQuoteAmount: item.maxQuoteAmount,
                quoteSymbol: item.quoteSymbol
              }));
            }
            const asset = this.settlementAssets.find(
              item => item.uuid === this.assetUuid
            );
            if (this.assetUuid !== "all" && (!asset || !asset.available)) {
              this.assetUuid = "";
            }
          })
          .catch(err => {
            this.$errHandler(err);
          });
      }
    },
    successAdd() {
      this.isShowAddBillingDialog = false;
      this.page = 1;
      this.loadData();
    },
    copy(url) {
      if (copyText(url)) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    },
    pageChange(val) {
      this.page = val;
      this.loadMoreData();
    },
    goDetail(billing) {
      this.selectBilling = billing;
      this.isShowReceiveDetail = true;
    },
    successSend() {
      this.isShowSendEmailDialog = false;
      ElMessageBox.confirm(
        "The billing is on its way.</br>You can check the status of all payments on your dashboard,and we'll make sure to notify you once the payment is completed.",
        "Billing Sent",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          showCancelButton: false
        }
      );
      if (this.page === 1) {
        this.loadData();
      } else {
        this.loadMoreData();
      }
    },
    goExpire(id) {
      ElMessageBox.confirm(
        "Your payer won't be able to complete the payment, and you will have to create a new payment link.",
        "Manually expire payment link?",
        {
          confirmButtonText: this.$t("actions.confirm"),
          cancelButtonText: this.$t("actions.cancel")
        }
      )
        .then(() => {
          expiredBilling(id)
            .then(() => {
              ElNotification({
                title: "Success",
                message: "Set Expire Success.",
                type: "success"
              });
              if (this.page === 1) {
                this.loadData();
              } else {
                this.loadMoreData();
              }
            })
            .catch(err => {
              this.$errHandler(err, "dialog");
            });
        })
        .catch(() => {});
    },
    refrshData() {
      this.isRefreshing = true;
      if (
        this.page === 1 &&
        this.billingList &&
        this.billingList.length > 0 &&
        !this.filter
      ) {
        this.loadData();
      } else {
        this.loadMoreData();
      }
    },
    selectStatus(val) {
      this.filterValue = val;
      this.pageChange(1);
    },
    selectDate() {
      this.startTime = this.dateValue[0].getTime() / 1000;
      this.dateValue[1].setHours(23);
      this.dateValue[1].setMinutes(59);
      this.dateValue[1].setSeconds(59);
      this.endTime = this.dateValue[1].getTime() / 1000;
      this.pageChange(1);
    },
    clearFilter() {
      this.filterValue = "";
      this.dateValue = null;
      this.startTime = "";
      this.endTime = "";
      this.pageChange(1);
    },
    createBilling() {
      this.valuationUuid = this.selectBilling.quoteAssetId;
      this.assetUuid = this.selectBilling.settlementAssetId || "all";
      this.name = this.selectBilling.name;
      this.email = this.selectBilling.email;
      this.amount = noSci(this.selectBilling.quoteAmount);
      this.note = this.selectBilling.remark;
      this.isShowReceiveDetail = false;
      this.isShowAddBillingDialog = true;
    },
    sendEmail(billing) {
      this.selectBilling = billing;
      this.isShowSendEmailDialog = true;
    },
    issued(billing) {
      this.selectBilling = billing;
      this.isShowBillingConfirmDialog = true;
    },
    getIt() {
      this.isShowBillingConfirmDialog = false;
    },
    handleClick(id) {
      this.downloading = true;
      var imgName = "MixPay-" + id + "-" + new Date().getTime();
      let myCanvas = document.getElementById(`image-${id}`);
      const options = {
        useCORS: true,
        backgroundColor: null,
        dpi: 192
      };
      html2Canvas(myCanvas, options)
        .then(canvas => {
          let a = document.createElement("a");
          let blob = this.base64ToBlob(canvas.toDataURL("image/png"));
          let evt = document.createEvent("HTMLEvents");
          evt.initEvent("click", true, true);
          a.download = imgName;
          a.href = URL.createObjectURL(blob);
          a.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
          ElMessage.error("Download Failed");
        });
    },
    base64ToBlob(base64) {
      const parts = base64.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    getUserAvatar() {
      var that = this;
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = this.userInfo.avatar;
      img.onload = function() {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        that.userAvatar = canvas.toDataURL("image/jpeg", 1);
        canvas = null;
        img = null;
        console.log(that.userAvatar);
        console.log("load");
      };
      img.onerror = function() {
        console.log("error");
        axios
          .get(that.userInfo.avatar, { responseType: "arraybuffer" })
          .then(res => {
            const bufferUrl = btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            that.userAvatar = "data:image/png;base64," + bufferUrl;
          });
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.alert-warning {
  padding-left: 10px;
}

.filter {
  :deep {
    .el-select {
      margin-right: 10px;
      margin-bottom: 20px;
      max-width: 300px;
      width: 100%;
    }

    .el-range-editor.el-input__wrapper {
      margin-right: 10px;
      margin-bottom: 20px;
      max-width: 300px;
      box-sizing: border-box;
      width: 100%;
    }

    .el-input__wrapper {
      font-size: 14px;
      font-family: "Inter";
    }

    .el-input {
      --el-input-border-radius: 4px;
    }

    .el-button {
      margin-left: 0;
      margin-right: 10px;
      margin-bottom: 20px;
      height: 32px;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

:deep {
  .filter-button {
    background-color: rgba(#f2f2f2, 0.5);
    border: rgba(#f2f2f2, 0.5);
    color: #828282;
    font-size: 14px;
    font-weight: 500;
  }

  .filter-button:hover {
    background-color: rgba(#e5e7eb, 0.5);
    border: rgba(#e5e7eb, 0.5);
    transform: scale(1.05);
    color: #828282;
  }

  .el-button--default:focus {
    background-color: rgba(#f2f2f2, 0.5);
    border: rgba(#f2f2f2, 0.5);
    color: #828282;
    font-size: 14px;
    font-weight: 500;
  }

  .filter-button-active {
    background-color: bg-blue-charlieLight;
    border: bg-blue-charlieLight;
    color: text-blue-medium;
    font-size: 14px;
    font-weight: 500;
  }

  .filter-button-active:hover {
    background-color: bg-blue-charlieLight;
    border: bg-blue-charlieLight;
    color: text-blue-medium;
    transform: scale(1.05);
  }

  .el-button--info:focus {
    background-color: bg-blue-charlieLight;
    border: bg-blue-charlieLight;
    color: text-blue-medium;
    font-size: 14px;
    font-weight: 500;
  }

  .el-alert .el-alert__icon.is-big {
    width: 20px;
    height: 20px;
  }

  .el-table .cell {
    padding: 0px;
  }

  .el-table .cell:first-child {
    padding-left: 12px;
  }

  .el-table .cell:last-child {
    padding-right: 12px;
  }
}

.selector {
  border-radius: 4px;
}

.empty-view {
  &-title {
    font-weight: 600;
  }

  &-tips {
    color: #999999;
  }
}

.empty-item {
  &-title {
    font-weight: 500;
  }
}

.step-icon {
  color: #2a7dfb;
}

.qrcode {
  position: relative;

  :deep canvas {
    width: 100%;
    height: 100%;
  }
}

.qrcode-avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
}

.share-img-header {
  width: 100%;
  margin-top: -12px;
}

.share-img-cryptos {
  width: 80%;
  margin-top: 20px;
}

.share-img-bottom {
  width: 80%;
  margin: 20px 0;
}

.btn-download {
  height: 36px;
  width: 140px;
  margin-top: 20px;
}
</style>