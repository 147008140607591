<template>
  <el-dialog
    title="New Billing"
    top="10vh"
    style="max-width:720px"
    width="90%"
    @open="dialogOpen"
    @close="dialogClose"
    destroy-on-close
  >
    <template #default>
      <el-scrollbar max-height="80vh">
        <el-form
          class="el-form"
          ref="billingForm"
          :rules="billingRules"
          :model="billingForm"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12">
              <el-form-item label="Amount" prop="quoteAmount">
                <el-input
                  v-model="billingForm.quoteAmount"
                  size="large"
                  type="number"
                  @input="quoteAmountChange"
                  :placeholder="$t('actions.typeHere')"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="Quote Currency" prop="valuationUuid">
                <template #default>
                  <div
                    class="input-symbol input-symbol-wrapper flex items-center corsor-pointer w-full row-justify-center"
                    :class="{ errorInput: valuationUuidError != '' }"
                    @click="handleValuationDialog"
                  >
                    <div
                      class="row-align-center"
                      v-if="currentValuation && currentValuation.uuid"
                    >
                      <exp-symbol
                        :uuid="currentValuation && currentValuation.uuid"
                        class="mr-2"
                        v-if="currentValuation && currentValuation.uuid"
                      />
                      <span class="coin-name text-slate-700">
                        {{ currentValuation && currentValuation.symbol }}
                      </span>
                      <exp-fiat-icon
                        v-if="currentValuation && !currentValuation.isAsset"
                      />
                    </div>
                    <span
                      class="input-symbol-placeholder text-placeholder-color"
                      v-else
                    >
                      {{ $t("actions.chooseHere") }}
                    </span>
                    <el-icon class="ml-2"><ArrowDownBold /></el-icon>
                  </div>
                </template>
                <template #error>
                  <div class="error-tips">{{ valuationUuidError }}</div>
                </template>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="Validity Period">
                <el-date-picker
                  v-model="selectDate"
                  type="date"
                  placeholder="Pick a day"
                  :disabled-date="disabledDate"
                  class="cursor-pointer"
                />
                <div class="input-hint">
                  The link is valid until 23:59 of the selected end data.
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="Settlement Currency" prop="assetUuid">
                <template #default>
                  <div
                    class="input-symbol input-symbol-wrapper flex items-center corsor-pointer w-full row-justify-center"
                    :class="{ errorInput: assetUuidError != '' }"
                    @click="handleSettleDialog"
                  >
                    <div class="row-align-center" v-if="billingForm.assetUuid">
                      <exp-symbol :uuid="billingForm.assetUuid" class="mr-2" />
                      <exp-coin-name
                        :uuid="billingForm.assetUuid"
                        :symbol="currentAsset && currentAsset.symbol"
                        class="coin-name text-slate-700"
                      />
                      <exp-fiat-icon
                        v-if="currentAsset && !currentAsset.isAsset"
                      />
                    </div>
                    <span
                      class="input-symbol-placeholder text-placeholder-color"
                      v-else
                    >
                      {{ $t("actions.chooseHere") }}
                    </span>
                    <el-icon class="ml-2"><ArrowDownBold /></el-icon>
                  </div>
                </template>
                <template #error>
                  <div class="error-tips">{{ assetUuidError }}</div>
                </template>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item
                class="note-input"
                :label="$t('receiveSettings.noteOptional')"
                :class="device === 'mobile' ? 'mt-3' : 'mt-10'"
              >
                <el-input
                  v-model="remark"
                  size="large"
                  :placeholder="$t('actions.typeHere')"
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="row-center">
          <el-button
            class="w-1/2"
            type="primary"
            @click="addBilling"
            :loading="isSubmitting"
          >
            Confirm
          </el-button>
        </div>
      </el-scrollbar>
    </template>
  </el-dialog>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import { createBills, getSettlementAssets } from "@/api/index.js";
import { fixDecimal } from "@/utils/num-format.js";
import bus from "@/utils/eventbus";
import { ElNotification } from "element-plus";
import moment from "moment";
import { formatTime } from "@/utils/date-format.js";

export default {
  components: {},

  props: {
    selectValuationUuid: {
      type: String,
      default: ""
    },
    selectAssetUuid: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    amount: {
      type: String,
      default: ""
    },
    note: {
      type: String,
      default: ""
    }
  },

  watch: {
    selectValuationUuid(val) {
      this.billingForm.valuationUuid = val;
      this.valuationUuidError = "";
    },
    selectAssetUuid: {
      handler(newVal) {
        this.billingForm.assetUuid = newVal;
        this.assetUuidError = "";
      }
    }
  },

  computed: {
    ...mapGetters(["assetMap", "quoteMap"]),
    ...mapState({
      quoteAssets: state => state.settings.quoteAssets,
      userInfo: state => state.userInfo,
      device: state => state.device,
      settings: state => state.settings
    }),

    computedAssets() {
      return [
        {
          icon: require("@/assets/all.svg"),
          symbol: this.$t("selector.all"),
          uuid: "all",
          isAsset: 1,
          size: "lg",
          available: true
        },
        ...this.settlementAssets
      ];
    },
    currentAsset() {
      return this.computedAssets.find(
        item => item.uuid == this.billingForm.assetUuid
      );
    },
    currentValuation() {
      return this.quoteAssets.find(
        item => item.uuid == this.billingForm.valuationUuid
      );
    }
  },

  data() {
    // const validateName = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("Please enter the Name"));
    //   } else {
    //     callback();
    //   }
    // };
    // const validateEmail = (rule, value, callback) => {
    //   var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
    //   if (!value) {
    //     callback(new Error("Please enter the Email"));
    //   } else if (!verify.test(value)) {
    //     callback(new Error("Please enter the true Email"));
    //   }else {
    //     callback();
    //   }
    // };
    const validateQuoteAmount = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please enter your amount"));
      } else {
        callback();
      }
    };
    const validateValuationUuid = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("receiveSettings.selectQuote")));
        this.valuationUuidError = this.$t("receiveSettings.selectQuote");
      } else {
        callback();
        this.valuationUuidError = "";
      }
    };
    const validateAssetUuid = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("receiveSettings.selectReceive")));
        this.assetUuidError = this.$t("receiveSettings.selectReceive");
      } else if (value === "error") {
        callback(
          new Error(
            "The limit has been exceeded, please select a new settlement currency."
          )
        );
        this.assetUuidError =
          "The limit has been exceeded, please select a new settlement currency.";
        this.billingForm.assetUuid = "";
      } else {
        callback();
        this.assetUuidError = "";
      }
    };
    return {
      billingForm: {
        quoteAmount: "",
        valuationUuid: "",
        assetUuid: ""
        // name: '',
        // email: '',
      },
      billingRules: {
        // name: [{required: true, trigger: "change", validator: validateName }],
        // email: [{required: true, trigger: "change", validator: validateEmail }],
        quoteAmount: [
          { required: true, trigger: "change", validator: validateQuoteAmount }
        ],
        valuationUuid: [
          { required: true, trigger: "blur", validator: validateValuationUuid }
        ],
        assetUuid: [
          { required: true, trigger: "blur", validator: validateAssetUuid }
        ]
      },
      remark: "",
      // valuationUuid: "",
      // assetUuid: "",
      selectTime: 0,
      settlementAssets: [],
      isSubmitting: false,
      selectDate: null,
      nowDate: new Date(),
      valuationUuidError: "",
      dateError: "",
      assetUuidError: "",
      isEmailSend: false,

      isShowConfirmDialog: false
    };
  },

  methods: {
    formatTime,
    dialogOpen() {
      this.selectDate = new Date(moment().add(30, "days"));

      let [p1, p2, p3] = [];
      if (this.settings.loadStatus !== "success") {
        p1 = this.$store.dispatch("updateSettings");
      }
      if (this.settings.quoteStatus !== "success") {
        p2 = this.$store.dispatch("updateQuoteAssets");
      }
      if (!this.userInfo.name) {
        p3 = this.$store.dispatch("updateUserInfo");
      }

      Promise.all([p1, p2, p3]).then(() => {
        if (!this.billingForm.valuationUuid) {
          this.billingForm.valuationUuid = this.userInfo.valuationUuid || "";
          this.$emit(
            "update:selectValuationUuid",
            this.billingForm.valuationUuid
          );
        }
        if (!this.billingForm.assetUuid) {
          this.billingForm.assetUuid = this.userInfo.assetUuid || "";
          this.$emit("update:selectAssetUuid", this.billingForm.assetUuid);
        }
        this.avatarUrl =
          this.userInfo.avatar || require("@/assets/images/avatar-default.svg");

        return this.loadSettlement();
      });

      if (this.name) {
        this.billingForm.name = this.name;
      }
      if (this.email) {
        this.billingForm.email = this.email;
      }
      if (this.amount) {
        this.billingForm.quoteAmount = this.amount;
      }
      if (this.note) {
        this.remark = this.note;
      }
    },

    dialogClose() {
      this.billingForm.quoteAmount = "";
      this.remark = "";
      this.billingForm.valuationUuid = "";
      this.billingForm.assetUuid = "";
      this.billingForm.name = "";
      this.billingForm.email = "";
      this.isEmailSend = false;
      this.valuationUuidError = "";
      this.assetUuidError = "";
      this.dateError = "";
      this.$emit("addDialogClose");
    },

    loadSettlement() {
      this.isAssetLoading = true;
      return getSettlementAssets(
        this.userInfo.userUuid,
        this.billingForm.valuationUuid,
        this.billingForm.quoteAmount
      )
        .then(({ data: d }) => {
          this.isAssetLoading = false;
          if (Array.isArray(d)) {
            this.settlementAssets = d.map(item => ({
              uuid: item.assetId,
              symbol: item.symbol,
              isAsset: !!+item.isAsset,
              available: !!+item.isAvailable,
              maxQuoteAmount: item.maxQuoteAmount,
              minQuoteAmount: item.minQuoteAmount,
              quoteSymbol: item.quoteSymbol
            }));
            bus.emit("sendSettlementAssets", this.settlementAssets);
          }
        })
        .catch(err => {
          this.isAssetLoading = false;
          return Promise.reject(err);
        });
    },

    handleValuationDialog() {
      this.$emit("handleValuationDialog");
    },

    handleSettleDialog() {
      this.$emit(
        "handleSettleDialog",
        this.billingForm.valuationUuid,
        this.billingForm.quoteAmount
      );
    },

    quoteAmountChange() {
      this.$nextTick(() => {
        if (this.billingForm.quoteAmount < 0) {
          this.billingForm.quoteAmount = "0";
        } else {
          const precision =
            this.currentValuation && this.currentValuation.precision;
          let output = fixDecimal(this.billingForm.quoteAmount, precision);
          this.billingForm.quoteAmount = output;
        }
      });
    },
    disabledDate(time) {
      time.setHours(23);
      time.setMinutes(59);
      time.setSeconds(59);
      return (
        time.getTime() < this.nowDate.getTime() ||
        (time.getTime() - this.nowDate.getTime()) / (24 * 60 * 60 * 1000) > 365
      );
    },

    addBilling() {
      this.selectDate.setHours(23);
      this.selectDate.setMinutes(59);
      this.selectDate.setSeconds(59);
      this.valuationUuidError = "";
      this.assetUuidError = "";
      this.dateError = "";
      this.$refs.billingForm.validate(valid => {
        if (valid) {
          if (this.billingForm.assetUuid === "all") {
            this.goNext();
          } else {
            this.isSubmitting = true;
            getSettlementAssets(
              this.userInfo.userUuid,
              this.billingForm.valuationUuid,
              this.billingForm.quoteAmount
            )
              .then(({ data: d }) => {
                this.isSubmitting = false;
                if (Array.isArray(d)) {
                  const asset = d.find(
                    item => item.assetId === this.billingForm.assetUuid
                  );
                  if (asset && asset.isAvailable) {
                    this.goNext();
                  } else {
                    this.billingForm.assetUuid = "error";
                    this.$refs.billingForm.validate();
                  }
                }
              })
              .catch(err => {
                this.isSubmitting = false;
                return Promise.reject(err);
              });
          }
        }
      });
    },
    goNext() {
      this.isSubmitting = true;
      this.selectTime = parseInt(this.selectDate.getTime() / 1000);
      createBills(
        this.billingForm.assetUuid === "all" ? "" : this.billingForm.assetUuid,
        this.billingForm.quoteAmount,
        this.billingForm.valuationUuid,
        this.remark,
        this.selectTime
      )
        .then(() => {
          this.isShowConfirmDialog = false;
          this.isSubmitting = false;
          this.$emit("successAdd");
          ElNotification({
            title: "Success",
            message: "Add Billing succeeded.",
            type: "success"
          });
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin: 0 0 30px;
}

.el-button {
  height: 50px;
}

.input-hint {
  margin-top: 10px;
  color: #828282;
  font-size: 12px;
  line-height: 1;
}

:deep {
  .el-date-editor.el-input,
  .el-date-editor.el-input__wrapper {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
}

.el-input {
  height: 50px;
  font-size: 16px;
}

.input-symbol {
  width: 100%;
  height: 50px;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  padding: 0 12px;

  &-label {
    font-size: 16px;
    color: #1e1e22;
    font-weight: 500;
  }

  &-wrapper {
    font-size: 16px;
    // margin-top: 8px;
    &.errorInput {
      border: 1px solid var(--el-color-danger);
    }
  }

  &-placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  &.errorInput {
    border: 1px solid var(--el-color-danger);
  }
}

.error-tips {
  color: var(--el-color-danger);
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.el-fade-in-enter-active {
  transition: all 3s ease-out;
}

.el-fade-in-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.confirm-body {
  width: 500px;

  .info-title {
    margin-bottom: 30px;
  }

  .info-item {
    font-size: 16px;
    margin-bottom: 24px;

    &-title {
      max-width: 300px;
      color: color-text-caption;
    }

    &-content {
      max-width: 350px;
      font-weight: 600;
      word-break: break-all;
      text-align: right;
    }
  }

  // .el-button {
  //   width: 240px;
  // }
}
</style>