<template>
  <el-dialog title="Billing Confirmation" class="w-max" append-to-body>
    <div class="confirm-body">
      <h3 class="info-title">Customer Information</h3>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Name</div>
        <div class="info-item-content">{{ billing.name }}</div>
      </div>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Email</div>
        <div class="info-item-content">{{ billing.email }}</div>
      </div>
      <h3 class="info-title">Payment Information</h3>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Amount</div>
        <div class="info-item-content row-align-center">
          <exp-symbol
            :uuid="billing.quoteAssetId"
            class="mr-1"
            v-if="billing.quoteAssetId"
          />
          <span class="coin-name text-slate-700">
            {{ noSci(billing.quoteAmount) }} {{ billing.quoteSymbol }}
          </span>
        </div>
      </div>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Settlement Currency</div>
        <div
          class="info-item-content row-align-center"
          v-if="billing.settlementAssetId"
        >
          <exp-symbol :uuid="billing.settlementAssetId" class="mr-1" />
          <exp-coin-name
            :uuid="billing.settlementAssetId"
            :symbol="billing.settlementSymbol"
            class="coin-name text-slate-700"
          />
        </div>
        <div class="info-item-content row-align-center" v-else>
          <img class="coin-icon mr-1 w-[18px]" src="@/assets/all.svg" />
          <span>{{ $t("selector.all") }} </span>
        </div>
      </div>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Validity Period</div>
        <div class="info-item-content">{{ billing.expiredAt }}</div>
      </div>
      <div class="info-item row-justify-center">
        <div class="info-item-title">Note</div>
        <div class="info-item-content">{{ billing.remark || "--" }}</div>
      </div>

      <div class="row-center mt-2">
        <el-button type="primary" class="w-1/2" @click="getIt">
          I Get It
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { noSci } from "@/utils/num-format.js";
export default {
  props: {
    billing: {
      type: Object
    }
  },

  methods: {
    noSci,
    getIt() {
      this.$emit("getIt");
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-body {
  width: 500px;

  .info-title {
    margin-bottom: 30px;
  }

  .info-item {
    font-size: 16px;
    margin-bottom: 24px;

    &-title {
      max-width: 300px;
      color: color-text-caption;
    }

    &-content {
      max-width: 350px;
      font-weight: 600;
      word-break: break-all;
      text-align: right;
      color: color-text-base;
    }
  }
}
</style>