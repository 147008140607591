<template>
    <div class="row-align-center">
        <span>Contact Us：</span>
        <el-link href="mailto:support@mixpay.me" type="primary"><span class="text-lg">support@mixpay.me</span></el-link>
    </div>
</template>

<script>
export default {
    

    created() {
       
    },



    methods: {
        // onClick() {
        //      const centerRotationAd = document.getElementById('ad');
        //     console.log(centerRotationAd);

        //     const script = document.createElement("script");
        //     // script.type = "text/javascript";
        //     script.textContent = 'window.intercomSettings = { api_base: "https://api-iam.intercom.io", app_id: "t43vuwyn" };'

        //     const script1 = document.createElement('script');
        //     // script1.type = 'text/javascript';
        //     script1.textContent = '(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic("reattach_activator");ic("update",w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement("script");s.type="text/javascript";s.async=true;s.src="https://widget.intercom.io/widget/t43vuwyn";var x=d.getElementsByTagName("script")[0];x.parentNode.insertBefore(s,x);};if(document.readyState==="complete"){l();}else if(w.attachEvent){w.attachEvent("onload",l);}else{w.addEventListener("load",l,false);}}})();';

        //     centerRotationAd.appendChild(script);
        //     centerRotationAd.appendChild(script1);
        // }
    },

}
</script>