<template>
  <el-dialog
    top="10vh"
    width="90%"
    style="max-width:720px"
    :title="$t('link.addPaymentLink')"
    @open="dialogOpen"
    @close="dialogClose"
    destroy-on-close
  >
    <el-form class="el-form" ref="loginForm" label-position="top" :model="form">
      <div class="upload-avatar row-center">
        <el-upload
          class="avatar-uploader"
          name="avatar"
          :show-file-list="false"
          :on-change="handelAvatarChange"
          :auto-upload="false"
        >
          <img :src="avatarUrl" class="avatar" />
          <el-icon class="upload-icon" size="20px"><UploadFilled /></el-icon>
        </el-upload>
      </div>

      <el-row :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('receiveSettings.qrcodeName')">
            <el-input
              v-model="qrname"
              maxlength="20"
              show-word-limit
              size="large"
              :placeholder="$t('actions.typeHere')"
            />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12">
          <el-form-item label="Quote Amount(Optional)">
            <el-input
              v-model="quoteAmount"
              size="large"
              type="number"
              @input="quoteAmountChange"
              :placeholder="$t('actions.typeHere')"
            >
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12">
          <div
            class="input-symbol cursor-pointer"
            @click="handleValuationDialog"
          >
            <span class="input-symbol-label">Quote Currency</span>
            <div class="input-symbol-wrapper flex items-center corsor-pointer">
              <div class="w-full row-justify-center">
                <div
                  class="row-align-center"
                  v-if="currentValuation && currentValuation.uuid"
                >
                  <exp-symbol
                    :uuid="currentValuation && currentValuation.uuid"
                    class="mr-2"
                    v-if="currentValuation && currentValuation.uuid"
                  />
                  <span class="coin-name text-slate-700">
                    {{ currentValuation && currentValuation.symbol }}
                  </span>
                  <exp-fiat-icon
                    v-if="currentValuation && !currentValuation.isAsset"
                  />
                </div>
                <span
                  class="input-symbol-placeholder text-placeholder-color"
                  v-else
                >
                  {{ $t("actions.chooseHere") }}
                </span>
                <el-icon class="ml-2"><ArrowDownBold /></el-icon>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :xs="24" :sm="12">
          <div class="input-symbol cursor-pointer" @click="handleSettleDialog">
            <span class="input-symbol-label">Settlement Currency</span>
            <div class="input-symbol-wrapper flex items-center corsor-pointer">
              <div class="w-full row-justify-center">
                <div class="row-align-center" v-if="assetUuid">
                  <exp-symbol :uuid="assetUuid" class="mr-2" />
                  <exp-coin-name
                    :uuid="assetUuid"
                    :symbol="currentAsset && currentAsset.symbol"
                    class="coin-name text-slate-700"
                  />
                  <exp-fiat-icon v-if="currentAsset && !currentAsset.isAsset" />
                </div>
                <span
                  class="input-symbol-placeholder text-placeholder-color"
                  v-else
                >
                  {{ $t("actions.chooseHere") }}
                </span>
                <el-icon class="ml-2"><ArrowDownBold /></el-icon>
              </div>
            </div>
          </div>
        </el-col>

        <el-col>
          <el-form-item
            class="note-input"
            :label="$t('receiveSettings.noteOptional')"
            :class="device === 'mobile' ? 'mt-3' : 'mt-10'"
          >
            <el-input
              v-model="remark"
              size="large"
              :placeholder="$t('actions.typeHere')"
              maxlength="50"
            />
          </el-form-item>
        </el-col>

        <el-col>
          <div class="link-header row-justify-center">
            <span class="link-header-label">Payment Link</span>
            <div
              class="cursor-pointer text-primary-font-color"
              @click="setName"
              v-if="nameAvailable"
            >
              Set Name
            </div>
          </div>
          <el-form-item
            class="paylink-input"
            :class="device === 'mobile' ? 'mt-3' : 'mt-10'"
          >
            <el-input
              v-model="paymentLink"
              size="large"
              :placeholder="$t('actions.typeHere')"
              maxlength="20"
              show-word-limit
            >
              <template #prepend>
                <div class="payment-input-prepend">
                  <span>{{ paymentLinkPrefix }}</span>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="row-center">
      <el-button
        class="w-1/2"
        type="primary"
        @click="addQRCode"
        :loading="isSubmitting"
      >
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import { addUserReceive, getSettlementAssets } from "@/api/index.js";
import { fixDecimal } from "@/utils/num-format.js";
import bus from "@/utils/eventbus";
import { compressAccurately } from "image-conversion";
import { ElMessage, ElNotification } from "element-plus";
import { getBase64 } from "@/utils/string.js";

export default {
  components: {},

  props: {
    selectValuationUuid: {
      type: String,
      default: ""
    },
    selectAssetUuid: {
      type: String,
      default: ""
    }
  },

  watch: {
    selectValuationUuid(val) {
      this.valuationUuid = val;
    },
    // selectAssetUuid(val) {
    //   this.assetUuid = val;
    // },
    selectAssetUuid: {
      handler(newVal) {
        this.assetUuid = newVal;
      }
    }
  },

  computed: {
    ...mapGetters(["assetMap", "quoteMap"]),
    ...mapState({
      quoteAssets: state => state.settings.quoteAssets,
      userInfo: state => state.userInfo,
      device: state => state.device,
      settings: state => state.settings,
      appURL: state => state.appURL
    }),

    computedAssets() {
      return [
        {
          icon: require("@/assets/all.svg"),
          symbol: this.$t("selector.all"),
          uuid: "all",
          isAsset: 1,
          size: "lg",
          available: true
        },
        ...this.settlementAssets
      ];
    },
    currentAsset() {
      return this.computedAssets.find(item => item.uuid == this.assetUuid);
    },
    currentValuation() {
      return this.quoteAssets.find(item => item.uuid == this.valuationUuid);
    },
    paymentLinkPrefix() {
      if (this.prefix) {
        return this.prefix;
      }
      return `${this.appURL}/${this.userInfo.name}/`;
    },
    nameAvailable() {
      // if (!this.userInfo.name) {
      //   return true;
      // }
      const now = new Date().getTime();
      return now > this.userInfo.nextChangeNameAt * 1000;
    }
  },

  data() {
    return {
      qrname: "",
      quoteAmount: "",
      remark: "",
      paymentLink: "",
      prefix: "",
      avatarUrl: "",
      valuationUuid: "",
      assetUuid: "",
      settlementAssets: [],
      isSubmitting: false,
      avatarLoading: false
    };
  },

  methods: {
    dialogOpen() {
      this.avatarUrl = this.userInfo.avatar;
      let [p1, p2, p3] = [];
      if (this.settings.loadStatus !== "success") {
        p1 = this.$store.dispatch("updateSettings");
      }
      if (this.settings.quoteStatus !== "success") {
        p2 = this.$store.dispatch("updateQuoteAssets");
      }
      if (!this.userInfo.name) {
        p3 = this.$store.dispatch("updateUserInfo");
      }

      Promise.all([p1, p2, p3]).then(() => {
        this.valuationUuid = this.userInfo.valuationUuid || "";
        this.$emit("update:selectValuationUuid", this.valuationUuid);
        this.assetUuid = this.userInfo.assetUuid || "";
        this.$emit("update:selectAssetUuid", this.assetUuid);
        this.avatarUrl =
          this.userInfo.avatar || require("@/assets/images/avatar-default.svg");

        return this.loadSettlement();
      });
    },

    dialogClose() {
      this.qrname = "";
      this.quoteAmount = "";
      this.remark = "";
      this.paymentLink = "";
      this.valuationUuid = "";
      this.assetUuid = "";
      this.$emit("addDialogClose");
    },

    loadSettlement() {
      this.isAssetLoading = true;
      return getSettlementAssets(
        this.userInfo.userUuid,
        this.valuationUuid,
        this.amount
      )
        .then(({ data: d }) => {
          this.isAssetLoading = false;
          if (Array.isArray(d)) {
            this.settlementAssets = d.map(item => ({
              uuid: item.assetId,
              symbol: item.symbol,
              isAsset: !!+item.isAsset,
              available: !!+item.isAvailable,
              maxQuoteAmount: item.maxQuoteAmount,
              minQuoteAmount: item.minQuoteAmount,
              quoteSymbol: item.quoteSymbol
            }));
            bus.emit("sendSettlementAssets", this.settlementAssets);
          }
        })
        .catch(err => {
          this.isAssetLoading = false;
          return Promise.reject(err);
        });
    },

    handleValuationDialog() {
      this.$emit("handleValuationDialog");
    },

    handleSettleDialog() {
      this.$emit("handleSettleDialog", this.valuationUuid, this.quoteAmount);
    },

    quoteAmountChange() {
      this.$nextTick(() => {
        if (this.quoteAmount < 0) {
          this.quoteAmount = "0";
        } else {
          const precision =
            this.currentValuation && this.currentValuation.precision;
          let output = fixDecimal(this.quoteAmount, precision);
          this.quoteAmount = output;
          this.assetUuid = "";
          this.$emit("update:selectAssetUuid", this.assetUuid);
        }
      });
    },

    setName() {
      this.$emit("setName", this.nameAvailable);
    },

    addQRCode() {
      if (!this.qrname) {
        ElMessage.error(this.$t("receiveSettings.qrcodeNameTip"));
        return;
      }
      if (!this.valuationUuid) {
        ElMessage.error(this.$t("receiveSettings.selectQuote"));
        this.isValuationShow = true;
        return;
      }
      if (!this.assetUuid) {
        ElMessage.error(this.$t("receiveSettings.selectReceive"));
        this.isAssetShow = true;
        return;
      }
      if (!this.paymentLink) {
        ElMessage.error(this.$t("receiveSettings.enterUniqueName"));
        return;
      }
      this.isSubmitting = true;
      addUserReceive(
        this.avatarUrl != this.userInfo.avatar ? this.avatarUrl : "",
        this.qrname,
        this.quoteAmount,
        this.assetUuid === "all" ? "" : this.assetUuid,
        this.valuationUuid,
        this.remark,
        this.paymentLink
      )
        .then(() => {
          this.isSubmitting = false;
          this.$emit("successAdd");
          ElNotification({
            title: "Success",
            message: "Add Payment Link succeeded.",
            type: "success"
          });
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    },
    handelAvatarChange(file) {
      if (file.size / 1024 > 200) {
        compressAccurately(file.raw, 200).then(res => {
          getBase64(res).then(data => {
            this.avatarUrl = data;
          });
        });
      } else {
        getBase64(file.raw).then(data => {
          this.avatarUrl = data;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-avatar {
  height: 100px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
}

.upload-icon {
  position: relative;
  bottom: -40px;
  right: 10px;
  color: color-primary;
}

.el-form-item {
  margin: 0 0 30px;
}

.el-button {
  height: 50px;
}

.el-input {
  height: 50px;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .payment-input-prepend {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.input-symbol {
  margin-bottom: 30px;

  &-label {
    font-size: 16px;
    color: #1e1e22;
    font-weight: 500;
  }

  &-wrapper {
    height: 50px;
    font-size: 16px;
    border: 1px solid #dcdfe6;
    border-radius: 10px;
    margin-top: 8px;
    padding: 0 12px;
  }

  &-placeholder {
    font-size: 16px;
    font-weight: 400;
  }
}

.link-header {
  margin-bottom: 8px;

  &-label {
    font-size: 16px;
    color: #1e1e22;
    font-weight: 500;
  }
}
</style>