<template>
  <el-dialog
    :title="$t('account.nickname')"
    width="90%"
    style="max-width:480px"
    @open="dialogOpen"
  >
    <el-input
      class="mt-6"
      v-model="name"
      maxlength="20"
      minlength="1"
      show-word-limit
      :placeholder="$t('name.enterName')"
      clearable
    />

    <div class="mt-6 space-y-2" v-if="NameAvailable">
      <p>{{ $t("account.setNicknameTips") }}</p>
    </div>

    <div class="row-center">
      <el-button
        type="primary"
        class="mt-10 w-1/2"
        :loading="isSubmitting"
        @click="confirm"
      >
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
import { setUserInfo } from "@/api/index.js";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device
    })
  },

  data() {
    return {
      name: "",
      isSubmitting: false
    };
  },

  methods: {
    dialogOpen() {
      this.name = this.userInfo.nickname;
    },
    confirm() {
      if (!this.name) {
        ElMessage.error(this.$t("name.enterName"));
        return;
      }
      if (this.name === this.userInfo.nickname) {
        this.$emit("closeDialog");
        return;
      }
      if (this.name.length > 20) {
        ElMessage.error("The nickname must not be greater than 20 characters.");
        return;
      }
      this.isSubmitting = true;
      setUserInfo(this.name)
        .then(() => {
          return this.$store.dispatch("updateUserInfo");
        })
        .then(() => {
          this.isSubmitting = false;
          this.$emit("closeDialog");
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    }
  }
};
</script>
