const lang = {
  actions : {
    confirm: 'Confirm',
    cancel: 'Cancel',
    receive: 'Receive',
    withdraw: 'Withdraw',
    viewAll: 'View All',
    contactService: 'Contact',
    contactCustomerService: 'Contact Customer Service',
    setup: 'Set up',
    share: 'Share',
    setAmount: 'Set Amount',
    clearAmount: 'Clear Amount',
    copyLink: 'Copy Link',
    shareCard: 'Share Card',
    saveImage: 'Share Image',
  
    payTo: 'Pay to {name}',
    paySymbolTo: 'Pay {amount} {symbol} to {name}',
    goPaymentSetting: 'Set up your permanent payment link',
    copiedToClipboard: 'Copied to clipboard',
    delete: 'Delete',
    addQrcode: 'Add QR Code',
    addReceiver: 'Add Receiver',
  
    revise: 'Revise',
  
    agree: 'Agree',
    reject: 'Reject',
  
    finish: 'Finish',
  
    continue: 'Continue',
    save: 'Save',
    search: 'Search',
    copy: 'Copy',
  
    refresh: 'Refresh',
    changePicture: 'Select a photo',
  
    pay: 'Pay',
    goBack: 'Back',
  
    confirmed: 'I have transferred',

    typeHere: 'Type here...',
    chooseHere: 'Choose here...',
    saveChange : 'Save Change',
    saveChanges : 'Save Changes',
  },
  
  toast : {
    processing: 'Processing...',
    loading: 'Loading...',
    checking: 'Checking...',
    beyondTheMaximumLimit: 'It is beyond the maximum limit and cannot be added more.',
  },
  
  selector : {
    all: 'Any Currency',
  },
  
  tips : {
    unauthorizedFiat: 'If you need to open the fiat collection, please contact customer service first.',
    setDefaultFirst: 'Pls set up your preferred currency,<br/> or you will receive just the currency that the other party chooses to pay you.',
    setDefaultFirstTitle: 'Settlement currency is unset, please set it first.',
    temporaryLink: 'This QR code can only be <strong>paid once and cannot be reused.</strong>',
  
    quoteTip: 'Use this currency to calculate exchange rate.',
    receiveTip: 'You will be settled in this currency. If you choose "Receive any currency", You will receive any currency paid by the other party.',
    assetSelectorTip:
      '<strong>You will be settled in this currency.</strong><br/> If you choose <strong>"Receive any currency"</strong>, You will receive any currency paid by the other party.',
  
    selectQuote: 'Select a currency as quotation currency',
    selectReceive: 'Select a currency as settlement currency',
  
    noteBoard: 'Add reason for collection',
    enterAmount: 'Please enter an amount',
  
    qrcodeFaq: {
      title: 'How to use My QR Code?',
      content: [
        'Collect Payment via QR Code',
        'If you have an offline physical store, you can set up the currency you want to receive, save the payment QR code, display the payment QR code in the store, and then allow customers to pay with any currency.',
        'Collect Payment via Link',
        'MixPay payment link supports customization. You can post the payment link which you have set up on your website, blog, or social media platform, or send it to each other via SMS or email to receive cryptocurrency payment.',
        'Collect Payment via Sharing Card',
        'This payment method is only available within Mixin Messenger. You can share the payment card directly with Mixin Messenger contact. Once clicked on the card, the contact can pay in any currency. Less than 1s to the account, Zero fee.',
        'Set up Collection Notification',
        'Each collection QR code can be set separately to receive the notification of the collection to the account, up to 10 people.',
      ],
    },
  
    withdraw: [
      'Why do I need to pay EPC?',
      'There is no password required for MixPay withdrawal, for the security of your assets, when you withdraw money to the Mixin wallet, you need to pay EPC from the Mixin wallet to confirm that the Mixin wallet belongs to you. ',
      'How do I get EPC?',
      'MixPay will transfer 1 EPC to you after your first successful collection with MixPay.',
    ],
  
    mixinWallet: {
      title: 'Why Mixin Wallet?',
      content: [
        'Mixin Network',
        'A free, lightning fast, peer-to-peer and decentralized network for transferring digital assets.',
        'Mixin Messenger',
        'An open source end-to-end encrypted Messenger ,with multi-chain cryptocurrency wallet based on Mixin Network.',
        'Features',
        'Login with mobile phone number,never lose your account.',
        'Secured by 6 digit TIP.',
        'All assets are stored in PoS-BFT-DAG distributed network.',
        'Enormous bots to extend the chat and wallet capabilities.',
        'Send cryptocurrencies directly to phone contacts.',
        'Send encrypted messages with Signal protocol.',
        'Download Mixin Messenger',
        'iOS: Download on the <a href="https://apps.apple.com/app/mixin/id1322324266" target="_blank">App Store</a>, search for <a href="https://apps.apple.com/app/mixin/id1322324266" target="_blank">Mixin Messenger</a>.',
        'Android: <a href="https://play.google.com/store/apps/details?id=one.mixin.messenger" class="button" target="_blank">Download</a>',
      ],
    },
  
    service: {
      title: 'How to contact customer service?',
      content: [
        'Download MixinMessenger',
        'iOS: search for <a href="https://apps.apple.com/app/mixin/id1322324266" target="_blank">Mixin Messenger</a> in the <a href="https://apps.apple.com/app/mixin/id1322324266" target="_blank">App Store</a> to download.',
        'Android: <a href="https://play.google.com/store/apps/details?id=one.mixin.messenger" class="button" target="_blank">Download</a>',
        'Sign in to Mixin Messenger',
        'Enter your phone number + verification code to log in to Mixin Messenger.',
        'Add customer service',
        'Search ID: 7000104220, send message.',
      ],
    },
  
    share: {
      payTip: 'Pay any Crypto with any Wallet',
      saveTip: 'Long press the image to save',
    },
  
    autoSettlement: 'The corresponding amount of crypto will be automatically transferred to your Mixin Wallet after the successful receipt of payment.',
  
    selectCurrency: 'We hope you will first select the currency you want to use for payment.',
    mixinPayTips: ['Open the Mixin Messenger', 'Scan the QR code', 'Enter your wallet PIN'],
    commonPayTips: ['Open your {symbol} wallet', 'Scan the QR code', 'Enter your wallet PIN'],
    viewAddressTips:
      '<p style="color:#FF4466">1. Do not transfer any non-{symbol} assets to the above address.</p><p>2. Please confirm your transfer network is <span style="color:#FF4466">“{network}”</span>, or you may lose your assets.<p>3. When using the wallet to transfer, pay attention to the loss of handling fees, and please ensure that the amount received is sufficient.</p>',
  },
  
  popup : {
    note: 'Note Board',
    byDate: 'By date',
    start: 'Start date',
    end: 'End date',
    to: 'To',
    withdrawCurrency: 'Currency',
    selectAreaCode: 'Select Area Code',
  },
  
  scroll : {
    loading: 'Loading more...',
    loaded: 'All loaded',
  },
  
  status : {
    receive: 'Receive',
    receiveWithPayer: 'Receive-{payer}',
    withdraw: 'Withdraw',
    withdrawFailed: 'Withdraw failed',
    refund: 'Refund',
  
    pending: 'Pending',
    checking: 'Waiting for review',
    processing: 'Waiting for payment',
    success: 'Success',
    done: 'Success',
    failed: 'Failed',
  
    reject: 'Rejected',
    wait: 'Pending',
    approved: 'Approved',
  
    transferred: 'Transferred',
  },
  
  home : {
    totalBalance: 'Total Balance',
    recentTransactions: 'Recent Transactions',
    assetList: 'Asset List',
    myAccount: 'My Account',
    robotAccount: 'Add Robot Account',
    multisigAccount: 'Add Multisig Account',
    addANewAccount: 'Add a new account',
    assets: 'Assets',
    paymentHistory: 'Payment History',
    paymentReport: 'Payment Report',
    receiveMoney: 'Receive Money',
  },
  
   receive : {
    name: 'Receive',
    receive: 'Receive',
    autoSettlement: 'Automatic Settlement',
    autoSettlementTip: 'Once enabled, each successful payment will be automatically transferred to your Mixin Wallet.',
    autoSettlementTips: [
      '<span class="strong">Once enabled</span>, each successful payment will be automatically transferred to your <span class="strong">Mixin Wallet</span>.',
      '<span class="strong">Once closed</span>, each successful payment will be automatically transferred to your <span class="strong">MixPay Account</span>.',
    ],
  },
  
  receiveSettings : {
    default: 'Receive Settings',
  
    quote: 'Quote currency',
    selectQuote: 'Select a currency as quotation currency',
  
    receive: 'Settlement currency',
    selectReceive: 'Select a currency as settlement currency',
  
    addNote: 'Add Note',
    changeNote: 'Change',
    noteOptional: 'Note (optional)',
    addNoteTip: 'Add payment request description',
  
    addQrcode: 'Add QR Code',
    qrcodeName: 'Name',
    qrcodeNameTip: 'Please enter name',
  
    amount: 'Amount',
    setAmount: 'Set Amount',
    clearAmount: 'Clear Amount',
    enterAmount: 'Please enter an amount',
  
    suffixRemark: 'remark',
    remark: 'Remark',
    enterRemark: 'Please enter a remark',

  
    setName: 'Set Name',
    viewName: 'View Name',
  
    paymentLink: 'Payment Link',
  
    setting: 'Settings',
    qrcodeProfile: 'QR Avatar',
    more: 'More',
  
    uniqueName: 'Unique Name',
    enterUniqueName: 'Please enter a unique name',
  },
  
  notification : {
    name: 'Notification',
    receivers: 'Receivers',
    tips: 'Tips',
    tipsContent: [
      '1. You could add Mixin Messenger contacts to receive notifications together.',
      '2. After you add a receiver, you need to wait for confirmation from your receiver.',
      '3. Up to 10 receivers can be added.',
      '4. You can’t add your receivers any more if your receivers refuse to receive notifications.',
      '5. Please confirm that your receivers have added MixPay bot.',
    ],
    addBot: ['How to follow the MixPay bot?', '1. Open Mixin Messenger Search for ID: ', '2. Open the robot card and click "Add Bot"'],
    addConfirm: 'Whether to remove the receiver from the list?',
  
    addBotTip: [
      'The receivers you add must follow the MixPay bot',
      'How to add receivers?',
      '1. First, please confirm your receivers have added MixPay bot. Then, enter Mixin ID of your receiver, confirm it.',
      '2. The receiver will get a message from MixPay bot, click "View" - "Agree" to accept.',
      '3. After your receiver accepted request, your list will show "Approved" status.',
      'Please enter the Mixin ID',
    ],
  },
  
  myQrcode : {
    name: 'My QR Code',
    brief: 'Customized permanent payment link',
    deleteConfirm: 'This payment link will be invalid after deletion, confirm deletion?',
    tips: 'The payment QR code has been sent to you by MixPay bot. You can view it by clicking "X" at the top-right corner of a window to close it.',
    getIt: 'I Get it',
  },
  
  name : {
    name: 'Name',
    modifyTip: 'Username can only be modified once.',
    nameTip: '*This name will be displayed in Payment Link',
    editName: 'Edit Name',
    enterName: 'Please enter the name',
    nameRequired: ['The name must be 5-20 characters.', 'Name cannot be all numbers.'],
    modifyConfirm: 'The name cannot be modified repeatedly. Do you want to confirm the claimed?',
    setNameFirst: 'Please set the name first',
  },
  
  withdraw : {
    receivingCurrency: 'Currency',
    crypto: 'Cryptos',
    cash: 'Fiats',
    addAddress: 'Add Address',
    addAccount: 'Add Account',
    regionOnly: '{area} area only',
    receiptAddress: 'Receipt Address',
    arrivalAmount: 'Arrival Amount',
    receiptAccount: 'Receipt Account',
    atLeast: 'Minimum withdrawal {amount} {symbol}',
    upTo: 'Maximum withdrawal {amount} {symbol}',
    insufficientBalance: 'Insufficient balance',
    address: {
      addressManagement: 'Address Management',
      youHaveNoAddress: "You don't have an address yet",
      contact: 'Full Name',
      inputContacts: 'Please enter the contact',
      contactsNumber: 'Phone Number',
      inputContactsNumber: 'Please enter the phone number',
      collectionAddress: 'Receipt Address',
      inputCollectionAddress: 'Please enter the full address',
      modifyAddress: 'Modify Address',
      addAddress: 'Add Address',
    },
    account: {
      accountManagement: 'Account Management',
      youHaveNoAccount: "You don't have an account yet",
      name: 'Name',
      inputName: 'Please enter the name',
      bankName: 'Name of Bank',
      inputBankName: 'Please enter the name of your bank',
      bankAccount: 'Bank Account',
      inputBankAccount: 'Please enter the bank account',
      swiftCode: 'Swift Code',
      inputSwiftCode: 'Please enter the swift code',
      modifyPayee: 'Modify Account',
      addPayee: 'Add Account',
    },
  
    from: 'From',
    to: 'To',
    inputPlaceholder: 'Please input an amount',
  
    amount: 'Amount',
    enterAmount: 'Please input an amount',
    max: 'MAX',
    loadBalanceFailed: 'Load failed, ',
    refresh: 'click here to refresh',
    balance: 'Balance',
    locked: 'Locked',
    fee: 'Fee',
  
    withdrawConfirm: 'Confirm withdrawal?',
    withdrawTip: ['You need to pay 0.00000001 EPC', 'Why do I need to pay EPC?'],
  },
  
  invitation : {
    invitationConfirm: 'Do you agree to accept the friend invitation?',
    rejectConfirm: 'If you reject the invitation, you will never receive it from your friend. Do you confirm?',
  },
  
  history : {
    history: 'History',
    receive: 'Received',
    withdraw: 'Withdrawals',
    otherAccounts: 'From',
    date: 'Date',
    transactionID: 'Transaction ID',
    note: 'Note',
    payeeNote: 'Payee Note',
    payerNote: 'Payer Note',
    remark: 'Remark',
    toBeReviewed: 'To be reviewed',
    byDate: 'By date',
    confirmReceipt: 'Confirm Receipt',
    paymentMethod: 'Payment Method',
    withdrawalMethod: 'Withdrawal Method',
    cash: 'Cash',
    telegraph: 'Wire Transfer',
    mixin: 'Mixin Wallet',
    mixpay: 'MixPay',
    mvm: 'MVM',
  
    depositTitle: 'Transaction Details',
    refundTitle: 'Refund',
    withdrawTitle: 'Withdrawals',
  
    settlementTo: 'Settle to',
    qrcode: 'QR Name',
    fee: 'Fee',
  
    all: 'All',
  
    settlementSuccess: 'Settled',
    settlementProcessing: 'Settle in progress',
  },
  
  pay : {
    payTo: 'Pay to {name}',
    valuationMethod: 'Valuation method',
    quoteCurrency: 'Quote Currency',
    paymentCurrency: 'Payment Currency',
    paymentMethod: 'Payment Method',
    estimatePayment: 'Estimate Payment:',
    payTips: 'When you confirm, please complete the payment within',
    payAgain: 'Pay again',
    detail: {
      paymentFailed: 'Payment Failed',
      payTimeOut: 'Payment timeout, the payment will be refunded.',
    },
    note: 'Note: {note}',
    merchantNote: "Recipient's note: {note}",
    yourNote: 'Your note: {note}',
  
    payCrypto: 'Payment Crypto',
    payCryptoPlaceholder: 'Select a crypto to pay',
    payCryptoTip: 'Please select a crypto to pay.',
  
    payWallet: 'Payment Wallet',
    estAmount: 'Estimate Amount:',
  
    changeNote: 'Change',
    addNote: 'Add a note',
    noteBoard: 'Note Board',
    noteTip: 'Add reason for payment',
  
    enterValuationPlaceholder: 'Select a currency as quotation currency',
    enterValuationTip: 'Please select a currency as quotation currency',
  
    amount: 'Amount',
    enterAmount: 'Please enter an amount',
    enterAmountTip: 'Please enter the amount of quotation currency.',
  
    mixinWallet: 'Mixin Wallet',
    onChainTransfer: 'On-chain Wallet',
  
    recommend: 'Recommended',
  
    transferCountdown: 'Payment amount will be refreshed after <span class="strong">{count}s</span>',
    network: 'Network',
    address: 'Address',
    memo: 'Memo',
    payAmount: 'Pay Amount',
    refresh: 'Please Refresh First',
    overtime: 'Payment overtime',
  
    tips: [
      'Important Tips',
      '1. Do not transfer any non-{symbol} assets to the above address.',
      '2. When using the wallet to transfer, pay attention to the loss of handling fees, and please ensure that the amount received is sufficient.',
      '1. Your order has expired. An order needs to be transferred within {seconds} seconds. If you want to pay again, click the "Refresh" button.',
      '2. If you have paid, but the payment has not been confirmed by the network. This means that we have not yet received your payment. If we receive the payment later, we will make a refund arrangement for you. Please {0} to register the information.',
      '"contact customer service"',
      '2. Please confirm your transfer network is "{network}", or you may lose your assets.',
      '3. When using the wallet to transfer, pay attention to the loss of handling fees, and please ensure that the amount received is sufficient.',
    ],
  
    scanByMixin: 'Scan the QR code and pay via Mixin Messenger',
  
    transferred: 'Block confirmation takes time, please wait patiently.',
    success: 'Your payment has been completed.',
    failed: 'Payment failed, please contact customer <br/> service for refund',
    state: 'State',
    transactionID: 'Transaction ID',
    payee: 'Payee',
    date: 'Date',
    actual: 'Actual Payment',
    payables: 'Payables',
    transferTime: 'Transfer time:',
    transferFee: 'Fee:',
    transferNetworkFee: 'Network Fee:',
    walletConnect: 'Wallet Connect',
  
    pendingTips: ['Do not close current page.', 'Block confirmation takes time, please wait patiently.'],
    failedTips: [
      'Payment failed, please contact customer service.',
      'The payable amount is {payable}, but you paid {pay}. Please contact customer service for a refund.',
      'The payable amount is {payable}, but you paid {pay}.',
    ],
    help: '<span>Any questions, feel free to </span><strong>contact customer service.</strong>',
    balanceTip:
      'Your order is priced at {quoteAmount} {quoteSymbol} and {payAmount} {paySymbol} is required to pay using {paySymbol}. The actual amount you have paid is {totalAmount} {paySymbol}. You can contact the Customer Service for the remaining <span>{restAmount} {paySymbol}</span>.',
    checking: 'Checking...',
    checkTip: 'Payment information is being checked, please wait patlently',
    payMoreTips: 'Your order required {quoteAmount} {paySymbol}, but you actually paid {payAmount} {paySymbol}, you can contact customer service for a refund.',
  },
  
  error : {
    maxLimit: 'Exceed the maximum limit of {max} {symbol}',
    minLimit: 'Exceed the minimum limit of {min} {symbol}',
    invalidMixinId: 'Please enter a correct Mixin ID.',
    noRecord: 'No Record',
    noFound: 'Not Found',
    params: 'Parmaters Error',
    invalidPayment: 'Sorry, the payment information is invalid and cannot continue to pay.',
    duplicatePayment: 'Someone else has already paid for this link, double payments are not available.',
    unsupportPayment: 'This crypto does not support on-chain wallet payment.',
    unsupportWalletConnect: 'This crypto does not support wallet connect payment.',
  },
  
  account : {
    personnalAccount: 'Personnal Account',
    robotAccount: 'Robot Account',
    robotAccountTips: 'Receive payments using your bots account',
    multisigAccount: 'Multisig Account',
    multisigAccountTips: 'Multi-person management, more secure assets',
    next: 'Next',
    deleteTips: 'After unbinding, you will not be able to manage the account. Are you sure you want to unbind?',
    robot: {
      title: 'Add Robot Account',
      tips: [
        'You can only add developers as your own bots',
        'What can I do with a bot account?',
        '1. Any cryptocurrency payment can be accepted using a robot account.',
        '2. With the simple and easy to use API,you can receive and withdraw cryptocurrency quickly.',
      ],
      gotIt: 'Got It',
    },
    multisig: {
      title: 'Add Multisig Account',
      addContact: 'Add Contact',
      setingMultisigThreshold: 'Seting Multisig Threshold',
      multisigThreshold: 'Multisig Threshold',
      aboutMultisigThreshold: 'About Multisig Threshold',
      tips: [
        'What can I do with multi-signature accounts?',
        '1. Payments in any cryptocurrency can be accepted using multi-signature accounts.',
        '2. Assets are safer when multiple people co-manage the collection of multi-signature accounts.',
        'Learn more about multisig account',
        'When transferring funds from multi-signature  accounts, a valid signature of multiple people is required. To ensure the proper use of your assets, please add contacts correctly.',
        'Mixin mainnet supports multi-signature based on ed25519, and t/M can be arbitrarily set to participate in multi-signature, where t is threshold, "M" is members, and "M" supports up to 255 people to participate in multi-signature. ',
        'Enter Number',
        'For example, 2/3 multi-signature means that there are 3 people who jointly manage the assets, and any two people need to sign the transaction to use the assets.'
      ],
    },
    setReasonableValue: 'Please set a reasonable value.',
    nickname: 'Nickname',
    setNicknameTips: 'The name must be 1-20 characters',
    avatar: 'Avatar',
    multisigGroup: 'Multisig Group',
  },

  link: {
    title: 'Payment Link',
    addPaymentLink: 'New Payment Link',
    editPaymentLink: 'Edit Payment Link'
  }
  
}

export default lang