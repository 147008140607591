<template>
  <el-dialog
    :title="$t('notification.name')"
    top="10vh"
    width="90%"
    style="max-width:480px"
    @open="dialogOpen"
  >
    <template #default>
      <h3 class="title">{{ $t("notification.receivers") }}</h3>
      <div class="scroller mt-2">
        <el-scrollbar max-height="65vh">
          <div class="h-20" v-loading="isLoading" v-if="isLoading"></div>
          <div class="list-contacts" v-else>
            <el-row :gutter="20">
              <el-col
                :xs="24"
                :sm="12"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-list-contacts row-justify-center">
                  <div class="row-align-center">
                    <el-avatar
                      :size="40"
                      class="item-list-contacts-avatar"
                      :src="item.avatar"
                    />
                    <div class="col ml-2">
                      <div class="row-align-center">
                        <div class="item-list-contacts-nickname">
                          {{ stringName(item.nickname) }}
                        </div>
                        <span
                          class="status"
                          :class="item.status.icon"
                          v-if="item.status"
                          >{{ item.status.text }}</span
                        >
                      </div>
                      <div class="item-list-contacts-mixinId mt-1">
                        {{ item.mixinId }}
                      </div>
                    </div>
                  </div>
                  <el-icon
                    color="#F56C6C"
                    class="cursor-pointer"
                    v-if="index != 0"
                    @click="deleteContact(item.id)"
                  >
                    <DeleteFilled />
                  </el-icon>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12">
                <div
                  class="item-list-contacts add-contact row-center cursor-pointer"
                  @click="showAddContact"
                >
                  <el-icon class="mr-2" size="18px"><CirclePlus /></el-icon>
                  {{ $t("actions.addReceiver") }}
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="mt-10 space-y-4">
            <h3 class="title">{{ $t("notification.tips") }}</h3>
            <p class="tips-content">{{ $t("notification.tipsContent[0]") }}</p>
            <p class="tips-content">{{ $t("notification.tipsContent[1]") }}</p>
            <p class="tips-content">{{ $t("notification.tipsContent[2]") }}</p>
            <p class="tips-content">{{ $t("notification.tipsContent[3]") }}</p>
            <!-- <p><strong>{{ $t('notification.tipsContent[4]') }}</strong></p> -->
          </div>

          <div class="mt-10 space-y-4">
            <h3 class="title">{{ $t("notification.addBot[0]") }}</h3>
            <p class="tips-content">
              {{ $t("notification.addBot[1]") }}
              <span class="span-title" @click="copyRobot">7000104220</span>
            </p>
            <img class="w-1/2" src="../images/step-0.png" alt="" />
            <p class="tips-content">{{ $t("notification.addBot[2]") }}</p>
            <img class="w-1/2" src="../images/step-1.png" alt="" />
          </div>
        </el-scrollbar>
      </div>

      <add-receiver-dialog
        v-model="isShowAddDialog"
        :linkId="id"
        @addReceiver="addReceiver"
      />
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  getNotificationList,
  deleteNotificationReceiver
} from "@/api/index.js";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import AddReceiverDialog from "./add-receiver-dialog.vue";
import copyText from "@/utils/copy-text.js";

export default {
  components: {
    AddReceiverDialog
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },

  computed: mapState({
    userInfo: state => state.userInfo
  }),

  data() {
    return {
      list: [],
      isLoading: true,
      isShowAddDialog: false,
      isShowDeleteDialog: false,
      deleteId: "",
      idDeleting: false
    };
  },

  methods: {
    dialogOpen() {
      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      getNotificationList(this.id)
        .then(({ data: d }) => {
          this.list = [];
          if (Array.isArray(d)) {
            this.list = d.map(item => {
              return {
                id: item.id,
                mixinId: item.mixinId,
                nickname: item.nickname,
                avatar:
                  item.avatarUrl ||
                  require("@/assets/images/avatar-default.svg"),
                status: this.getStatus(item.isAgree)
              };
            });
          }
          this.list.unshift({
            mixinId: this.userInfo.mixinId,
            nickname: this.userInfo.nickname,
            avatar: this.userInfo.avatar
          });
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.$errHandler(err);
        });
    },
    showAddContact() {
      this.isShowAddDialog = true;
    },
    addReceiver() {
      this.isShowAddDialog = false;
      this.loadData();
    },
    getStatus(status = 0) {
      if (status == -1) {
        return {
          icon: "Rejected",
          text: this.$t("status.reject")
        };
      }
      if (status == 0) {
        return {
          icon: "Pending",
          text: this.$t("status.wait")
        };
      }
      if (status == 1) {
        return {
          icon: "Approved",
          text: this.$t("status.approved")
        };
      }
      return {};
    },
    deleteContact(id) {
      ElMessageBox.confirm(this.$t("notification.addConfirm"), "Tips", {
        confirmButtonText: this.$t("actions.confirm"),
        cancelButtonText: this.$t("actions.cancel")
      })
        .then(() => {
          deleteNotificationReceiver(id)
            .then(() => {
              const index = this.list.findIndex(item => item.id == id);
              if (index > -1) {
                this.list.splice(index, 1);
              }
              ElNotification({
                title: "Success",
                message: "Delete Receiver Success.",
                type: "success"
              });
            })
            .catch(err => {
              this.$errHandler(err, "dialog");
            });
        })
        .catch(() => {});
    },
    copyRobot() {
      if (copyText("7000104220")) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    },
    stringName(name) {
      if (name.length < 12) {
        return name;
      }
      return name.substring(0, 12) + "...";
    }
  }
};
</script>

<style lang="scss" scoped>
.item-list-contacts {
  height: 70px;
}

.el-input {
  height: 50px;
}

.list-contacts {
  .item-list-contacts {
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--el-border-color);
    margin-bottom: 18px;
    padding: 0 12px;

    &-nickname {
      color: color-text-base;
    }

    &-mixinId {
      font-size: 12px;
    }

    &-right {
      margin-left: 18px;
    }
  }

  .add-contact {
    color: color-primary;
    font-weight: 500;
    border: 1px solid color-primary;
  }

  .add-contact:hover {
    background: #f8faff;
    font-weight: 600;
  }

  .status {
    margin-left: 6px;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 8px;
    color: #fff;

    &.Pending {
      background: #ffa527;
    }

    &.Approved {
      background: #0ec9b1;
    }

    &.Rejected {
      background: #ff4466;
    }
  }
}

.title {
  color: color-text-base;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sub-title {
  color: color-text-base;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.tips-content {
  color: color-text-base;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.span-title {
  color: var(--el-color-primary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.span {
  color: color-primary;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
</style>