import Big from "big.js";

Big.NE = -17;

export const toFixed = (num, precision) => {
  return Big(num).toFixed(precision);
};

export const noSci = (num, precision = 8) => {
  return Big(num).round(precision, 1).toString();
};

export function fixDecimal(value, precision) {
  // 仅允许输入正数
  let output = String(value)
    .replace(/[^0-9.,-]/g, "")
    .replace(/,/g, ".");
  let outputArr = output.split(".");
  if (output === "") return "";
  if (output === ".") return "0.";
  if (output.startsWith("-")) return "0";
  if (outputArr[0] == 0) {
    outputArr[0] = "0";
  }
  if (outputArr[1] !== undefined) {
    if (precision == 0) {
      output = outputArr[0];
    } else {
      output = `${outputArr[0]}.${outputArr[1].slice(0, precision)}`;
    }
  } else {
    output = outputArr[0];
  }
  return output;
}

export function num2percent(num, precision = 2) {
  if (num > 0) {
    return Big(num).times("100").round(precision, 1).toString() + "%";
  }
  return 0;
}

export function getPageCount(count, pageSize) {
  if (typeof count == "number") {
    if (count > 0) {
      try {
        var _pagerCount =
          count % pageSize == 0 ? count / pageSize : count / pageSize + 1;
        var c = _pagerCount.toFixed(0); //小数取整
        _pagerCount = c > _pagerCount ? c - 1 : c; //过滤四舍五入
        return Number(_pagerCount);
      } catch (error) {
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}