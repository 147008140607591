<template>
  <el-dialog
    class="email-login-dialog"
    top="10vh"
    width="90%"
    style="max-width:380px"
    title="Login with Email"
    :close-on-click-modal="false"
    @close="onDialogClose"
  >
    <div>
      <div class="input-wrapper">
        <div class="input-title">Email</div>
        <div class="input-content">
          <el-input
            :class="{ 'is-error': emailErr }"
            v-model="email"
            type="text"
            clearable
            placeholder="Enter your email..."
            @input="onEmailChange"
            @blur="onEmailInputBlur"
          ></el-input>
        </div>
        <div class="input-error" v-if="emailErr">{{ emailErr }}</div>
      </div>
      <div class="input-wrapper">
        <div class="input-title">Verification Code</div>
        <div class="input-content">
          <el-input
            v-model.trim="verifyCode"
            type="number"
            placeholder="Enter verification code"
            :maxlength="6"
            :class="{ 'is-error': verifyCodeErr }"
            @input="onVerifyCodeChange"
          >
            <template v-slot:append>
              <el-button
                class="btn-send cursor-pointer"
                :class="{ disabled: btnDisabled }"
                :loading="isSending"
                link
                @click="onVerifyCodeSend"
              >
                <span v-if="countdownNum >= 0">{{ countdownNum }}s</span>
                <span v-else-if="!isSending">Send</span>
              </el-button>
            </template>
          </el-input>
        </div>
        <div class="input-error" v-if="verifyCodeErr">{{ verifyCodeErr }}</div>
      </div>
      <div class="mt-6">
        <el-button type="primary" :loading="isSubmitting" @click="onSubmit">
          Login
        </el-button>
      </div>

      <p class="email-login-tip text-center">
        <el-link
          type="primary"
          target="_blank"
          href="https://help.mixpay.me/en/articles/7189844-i-can-t-receive-the-login-verification-code-what-should-i-do"
        >
          Can't receive the verification code?
        </el-link>
      </p>
    </div>
  </el-dialog>
</template>

<script>
import { sendEmailVerifyCode, submitEmailVerifyCode } from "@/api/index";
import { validateEmail } from "@/utils/email-format";
export default {
  data() {
    return {
      email: "",
      emailErr: "",
      verifyCode: "",
      verifyCodeErr: "",
      isSending: false,
      isSubmitting: false,
      countdownNum: -1
    };
  },

  computed: {
    btnDisabled() {
      return (
        this.isSending ||
        this.isSubmitting ||
        this.countdownNum >= 0 ||
        !this.email ||
        this.emailErr
      );
    }
  },

  methods: {
    onVerifyCodeSend() {
      if (this.btnDisabled) return;
      this.isSending = true;
      sendEmailVerifyCode(this.email)
        .then(() => {
          this.isSending = false;
          this.startCountdown(60);
        })
        .catch(err => {
          this.isSending = false;
          this.emailErr = err.message;
        });
    },
    onSubmit() {
      if (!this.email) {
        this.emailErr = "The email must be a valid email address.";
        return;
      }
      if (!this.verifyCode) {
        this.verifyCodeErr = "Please enter the verification code.";
        return;
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      submitEmailVerifyCode(this.email, this.verifyCode)
        .then(data => {
          this.isSubmitting = false;
          this.$store.commit("setUserInfo", { token: data.data.token });
          this.$emit("jump");
        })
        .catch(err => {
          this.isSubmitting = false;
          this.verifyCodeErr = err.message;
        });
    },
    startCountdown(num) {
      this.countdownNum = num;
      if (num >= 0) {
        this.timeout = setTimeout(() => {
          this.startCountdown(--num);
        }, 1000);
      }
    },
    onEmailChange() {
      this.emailErr = "";
    },
    onEmailInputBlur() {
      if (this.email == "" || validateEmail(this.email)) {
        this.emailErr = "";
      } else {
        this.emailErr = "The email must be a valid email address.";
      }
    },
    onVerifyCodeChange() {
      this.verifyCodeErr = "";
    },
    onDialogClose() {
      clearTimeout(this.timeout);
      this.countdownNum = -1;
      this.email = "";
      this.emailErr = "";
      this.verifyCode = "";
      this.verifyCodeErr = "";
    }
  }
};
</script>

<style lang="scss">
.email-login-dialog {
  border-radius: 8px;

  .el-dialog__header {
    padding: 14px 12px 14px 16px;
  }

  .el-dialog__title {
    line-height: 1;
    font-weight: 500;
  }

  .el-dialog__headerbtn {
    top: 1px;
    right: 0;
    width: 48px;
    height: 48px;
    font-size: 20px;
  }

  .el-dialog__body {
    padding: 32px 24px;
  }
}
</style>

<style lang="scss" scoped>
.input-wrapper {
  margin-bottom: 16px;

  .input-title {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
  }

  .el-input {
    --el-input-border-radius: 6px;
    --el-input-height: 36px;

    &.is-error :deep .el-input__wrapper {
      box-shadow: 0 0 0 1px #ff4466 inset;
    }
  }

  .input-error {
    margin-top: 8px;
    color: #ff4466;
  }
}

.el-button {
  width: 100%;
  height: 36px;
}

.btn-send {
  min-width: 72px;
  text-align: center;

  &:not(.disabled) {
    color: #2a7dfb;
  }
}

.email-login-tip {
  margin-top: 16px;
}
</style>