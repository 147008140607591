<template>
  <span class="exp-coin-name">
    <span class="exp-coin-name-main">{{ symbol }}</span>
    <span class="exp-coin-name-chain" v-if="chain">{{ chain }}</span>
  </span>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'exp-coin-name',
  props: {
    uuid: {
      type: String,
      default: '',
    },
    symbol: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      networkMap: state => state.settings.networkMap,
    }),
    chain() {
      const { uuid, networkMap } = this;
      return networkMap[uuid] ? `(${networkMap[uuid]})` : '';
    },
  },
};
</script>

<style lang="scss">
.exp-coin-name {
  .exp-coin-name-main {
    font-size: 100%;
  }

  .exp-coin-name-chain {
    margin-left: 4px;
    font-size: 75%;
    color: #adb5be;
  }
}
</style>
