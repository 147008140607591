<template>
  <div>
    <el-container v-if="step === 0">
      <div class="box">
        <h2 class="title text-center">Please set your PIN</h2>
        <ul class="mt-8 text-base box-tips">
          <li>PIN verification is required when you make a withdrawal.</li>
          <li class="mt-4">Decentralized Wallet, MixPay does not store PIN.</li>
          <li class="mt-4 color-danger">
            PIN loss is not retrievable. Please keep it safe.
          </li>
        </ul>
        <el-checkbox
          v-model="isChecked"
          class="mt-4"
          size="large"
          label="I have learned that PIN loss is not retrievable, and I will keep it safe."
        />
        <p class="mt-8 row-center">
          <el-button class="btn" type="primary" @click="goNext">
            Set PIN
          </el-button>
        </p>

        <p class="mt-8 row-center">
          <el-link
            type="primary"
            target="_blank"
            href="https://help.mixpay.me/en/collections/3921995-wallet-faqs"
          >
            Wallet FAQ
          </el-link>
        </p>
      </div>
    </el-container>
    <el-container v-else>
      <div class="box">
        <h3 class="title text-center">{{ title }}</h3>
        <p class="mt-8 text-center" v-if="brief">{{ brief }}</p>
        <p class="mt-8 text-center">
          Please keep the 6-digit PIN safe, If lost, it will not be recovered.
        </p>

        <code-box class="mt-12" v-model:value="code" mask autoFocus ref="codebox" />

        <p class="mt-16 row-center">
          <el-button
            class="btn"
            type="primary"
            @click="goNext"
            :loading="isSubmitting"
          >
            Confirm
          </el-button>
        </p>
      </div>
    </el-container>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import CodeBox from "@/components/codebox";
import { setPincode } from "@/api/index.js";
import { mapState } from "vuex";
export default {
  name: "SetPincode",

  components: {
    CodeBox
  },

  data() {
    return {
      isChecked: false,
      prevCode: "",
      code: "",
      step: 0,
      isSubmitting: false
    };
  },

  computed: {
    ...mapState(["userInfo"]),
    title() {
      const { step } = this;
      return step > 1 ? "Confirm 6-digit PIN" : "Set 6-digit PIN";
    },
    brief() {
      const { step } = this;
      if (step === 3) {
        return "It's rare to see a third confirmation, right?";
      }
      if (step === 4) {
        return "This is the last confimation.";
      }
      return "";
    }
  },

  created() {
    if (this.userInfo.hasPin) {
      this.jump();
    }
  },

  methods: {
    goNext() {
      if (this.step === 0) {
        if (!this.isChecked) {
          ElMessage({
            message: "Please read the tips carefully and check the box.",
            type: "warning"
          });
          return;
        } else {
          this.step++;
          return;
        }
      }

      if (this.code.length < 6) {
        ElMessageBox.alert("Please make sure your password contains at least 6 characters.", "Reminder", {
          confirmButtonText: "Confirm",
          showClose: false,
          callback: () => {
            this.$refs.codebox && this.$refs.codebox.focus();
          }
        });
        return;
      }

      if (this.step === 1) {
        this.prevCode = this.code;
        this.code = "";
        this.$refs.codebox && this.$refs.codebox.focus();
        this.step++;
        return;
      }

      if (this.step === 2 || this.step === 3) {
        if (this.prevCode === this.code) {
          this.code = "";
          this.$refs.codebox && this.$refs.codebox.focus();
          this.step++;
          return;
        } else {
          this.verifyPrevCodeDialog();
          return;
        }
      }

      if (this.step === 4) {
        if (this.prevCode === this.code) {
          if (this.isSubmitting) return;
          this.isSubmitting = true;
          setPincode(this.prevCode)
            .then(() => {
              this.isSubmitting = false;
              this.$store.commit("setUserInfo", {
                hasPin: true,
                inputPincodeTimestamp: new Date().getTime()
              });
              this.jump();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$errHandler(err, "dialog");
            });
        } else {
          this.verifyPrevCodeDialog();
          return;
        }
      }
    },
    verifyPrevCodeDialog() {
      ElMessageBox.confirm("PIN do not match.", "Reminder", {
        confirmButtonText: "Re-enter PIN",
        cancelButtonText: "Reset PIN",
        type: "warning",
        showClose: false,
        closeOnClickModal: false,
        callback: action => {
          if (action === "confirm") {
            this.code = "";
            this.$refs.codebox && this.$refs.codebox.focus();
          }
          if (action === "cancel") {
            this.prevCode = "";
            this.code = "";
            this.step = 1;
            this.$refs.codebox && this.$refs.codebox.focus();
          }
        }
      });
    },
    jump() {
      if (this.$route.query.from) {
        this.$router.replace(this.$route.query.from);
      } else {
        this.$router.replace("/wallet");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 80px auto 0;
  width: 600px;

  .box-tips {
    padding: 30px 40px;
    background: #f1f1f1;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: currentColor;
      }
    }

    .color-danger {
      color: #ff4466;
    }
  }

  .el-checkbox :deep .el-checkbox__label {
    color: var(--el-check-text-color) !important;
  }

  .btn {
    width: 240px;
    height: 50px;
    font-size: 16px;
  }
}
</style>