<template>
  <div class="main-header w-full h-full">
    <div class="row-justify-center h-full">
      <div class="row-align-center">
        <logo class="header-logo" v-if="device === 'desktop'" />
        <div class="row-align-center" v-else>
          <div @click="onMenuClick" class="menu mr-2 pl-10">
            <el-icon :size="24"><Menu /></el-icon>
          </div>
        </div>
      </div>

      <div class="row-align-center">
        <div class="avatar-wrapper row-justify-center">
          <el-tooltip
            placement="bottom"
            effect="light"
            v-if="userInfo.walletAddress"
          >
            <template #content>
              <div class="guides-tip-list space-y-6 py-3">
                <div class="guides-tip-list-item row-center cursor-pointer">
                  <el-icon size="18"><DocumentCopy /></el-icon>
                  <p class="text-base ml-2" :underline="false" @click="copy">
                    Copy Address
                  </p>
                </div>
                <div
                  class="guides-tip-list-item row-center cursor-pointer"
                  v-if="['mixin', 'robot', 'c'].includes(userInfo.type)"
                >
                  <exp-svg-icon
                    name="account-tips"
                    size="xs"
                    class="account-tips-icon"
                  />
                  <p class="text-base ml-2" @click="showMoreAccountDialog">
                    More Account
                  </p>
                </div>
              </div>
            </template>
            <div class="row-align-center">
              <img class="avatar" :src="userInfo.avatar" />
              <div
                class="user-name ml-4 text-font-color cursor-pointer"
                v-if="!isMobile"
                :class="isMobile ? 'ml-2 text-base' : 'ml-4 text-lg'"
              >
                {{ stringName(userInfo.walletAddress, 4, 4) }}
              </div>
              <el-icon class="ml-4"><ArrowDownBold /></el-icon>
            </div>
          </el-tooltip>
          <el-popover
            ref="popover"
            trigger="hover"
            width="420"
            placement="bottom"
            @show="popShow"
            v-else
          >
            <template #reference>
              <div class="row-align-center cursor-pointer" @click="copy">
                <img class="avatar" :src="userInfo.avatar" />
                <div
                  class="user-name ml-4 text-font-color"
                  v-if="!isMobile"
                  :class="isMobile ? 'ml-2 text-base' : 'ml-4 text-lg'"
                >
                  {{ userInfo.nickname }}
                </div>
                <el-icon class="ml-4"><ArrowDownBold /></el-icon>
              </div>
            </template>
            <template #default>
              <el-scrollbar max-height="400px">
                <h4 class="accounts-title pl-4 mb-4">My Accounts</h4>
                <div v-loading="userSubAccount.loadStatus === 'pending'">
                  <div
                    class="item-list px-4 row-align-center cursor-pointer"
                    :class="{ active: selectMultisigId === '' }"
                    @click="selectAccount('personal')"
                  >
                    <div class="w-8 row-align-center">
                      <el-icon
                        color="#409EFF"
                        size="large"
                        v-if="selectMultisigId === ''"
                        ><CircleCheckFilled
                      /></el-icon>
                    </div>
                    <div class="row-align-center">
                      <el-avatar
                        :src="info.avatar || userInfo.avatar"
                        class="item-list-avatar"
                      />
                      <div
                        class="name ml-2"
                        :class="{ nameActive: selectMultisigId === '' }"
                      >
                        {{ info.nickname || userInfo.nickname }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="item-list px-4 row-justify-center cursor-pointer"
                    :class="{ active: selectMultisigId === item.multisigId }"
                    v-for="(item, index) in userSubAccount.list"
                    :key="index"
                  >
                    <div
                      class="row-align-center flex-1"
                      @click="selectAccount(item)"
                    >
                      <div class="w-8 row-ailgn-center">
                        <el-icon
                          color="#409EFF"
                          size="large"
                          v-if="selectMultisigId === item.multisigId"
                          ><CircleCheckFilled
                        /></el-icon>
                      </div>
                      <el-avatar
                        :src="item.avatarUrl"
                        class="item-list-avatar"
                      />
                      <div
                        class="name ml-2"
                        :class="{
                          nameActive: selectMultisigId === item.multisigId
                        }"
                      >
                        {{ item.name }}
                      </div>
                      <div
                        class="item-type row-center"
                        :class="item.type"
                        v-if="['robot', 'multisig'].includes(item.type)"
                      >
                        {{ item.type === "robot" ? "Robot" : "Multisig" }}
                      </div>
                    </div>

                    <el-icon
                      color="#F56C6C"
                      size="large"
                      v-if="selectMultisigId != item.multisigId"
                      @click="deleteAccount(item.multisigId, index)"
                    >
                      <DeleteFilled />
                    </el-icon>
                  </div>
                </div>
              </el-scrollbar>
              <div
                class="account-action h-16 pl-12 pr-4 cursor-pointer row-justify-center"
                @click="showMoreAccountDialog"
                v-if="['mixin', 'robot', 'multisig'].includes(userInfo.type)"
              >
                <div class="row-align-center cursor-pointer ">
                  <exp-svg-icon
                    name="account-tips"
                    size="xs"
                    class="account-tips-icon"
                  />
                  <p class="text-base ml-4">More Account</p>
                </div>
                <el-icon><ArrowRightBold /></el-icon>
              </div>
            </template>
          </el-popover>
          <div
            class="shutdown row-align-center cursor-pointer"
            @click="disconnect"
          >
            <exp-svg-icon name="shutdown" size="sm" />
          </div>
        </div>

        <el-tooltip placement="bottom" effect="light">
          <template #content>
            <div class="guides-tip-list space-y-6 py-3 pl-4">
              <div class="guides-tip-list-item row-align-center">
                <exp-svg-icon name="faq" size="sm" class="icon-faq" />
                <el-link
                  class="text-base ml-2"
                  href="https://help.mixpay.me/en/collections/3897841-mixpay-faq"
                  :underline="false"
                  target="_blank"
                  >FAQ</el-link
                >
              </div>
              <div class="guides-tip-list-item row-align-center">
                <el-icon size="18"><User /></el-icon>
                <el-link
                  class="text-base ml-2"
                  href="https://mixpay.me/developers"
                  :underline="false"
                  target="_blank"
                  >Developers</el-link
                >
              </div>
              <div class="guides-tip-list-item row-align-center">
                <el-icon size="18"><ChatRound /></el-icon>
                <el-link
                  class="text-base ml-2"
                  href="https://mixpay.me/contact-us"
                  :underline="false"
                  target="_blank"
                  >Contact us</el-link
                >
              </div>
            </div>
          </template>
          <div class="guide-tips row-center cursor-pointer ml-4">
            <exp-svg-icon name="ellipsis" size="md" class="account-tips-icon" />
          </div>
        </el-tooltip>
      </div>
    </div>

    <switch-account-dialog
      v-model="isShowSwitchAccountDialog"
      @reload="reloadUser"
      :info="info"
    />

    <add-robot-dialog
      v-model="isShowAddRobotDialog"
      @dialogClose="showAddDialog(1)"
    />

    <add-multi-dialog
      v-model="isShowAddMultiDialog"
      @dialogClose="showAddDialog(2)"
      :info="info"
    />

    <el-dialog
      v-model="isShowMoreAccountDialog"
      title="More Account"
      class="w-max"
    >
      <div class="more-dialog-body">
        <div
          class="mb-6 mx-9 w-auto alert-warning"
          v-if="userInfo.walletAddress"
        >
          <div>
            Multiple account modes are supported when you connect using Mixin
            Wallet.
          </div>
          <div class="mt-4 row-center">
            <el-link
              type="primary"
              href="https://mixin.one/messenger"
              target="_blank"
              >Download Mixin Wallet</el-link
            >
          </div>
        </div>
        <div class="modes-tip mb-6">
          <h3 class="text-lg modes-tip-title">
            Mixin Wallet connect supports multiple account modes
          </h3>
          <h4 class="modes-tip-personal mt-5 text-base">Personal:</h4>
          <p class="text-sm">Accept crypto payments anywhere.</p>
          <h4 class="modes-tip-developer mt-5 text-base">Developer Account:</h4>
          <p class="text-sm">The world's most powerful and easy-to-use APIs.</p>
          <div
            class="modes-tip-developer-btn mt-2 cursor-pointer w-fit"
            @click="readDocs"
          >
            Read the docs
          </div>
          <h4 class="modes-tip-multi-signer mt-5 text-base">
            Multisig Account:
          </h4>
          <p class="text-sm">
            Assets are safer when multiple people co-manage the collection of
            multi-signature accounts.
          </p>
          <div
            class="modes-tip-multi-signer-btn mt-2 cursor-pointer w-fit"
            @click="aboutMultisig"
          >
            About Multisig Account
          </div>
        </div>
        <div
          class="account-action h-16 flex cursor-pointer"
          v-if="!userInfo.walletAddress"
        >
          <div
            class="account-action-item flex-1 row-center"
            @click="showAddDialog(1)"
          >
            <img
              src="@/assets/images/icon-robot-account.svg"
              class="icon-account"
            />
            <div class="ml-2">{{ $t("home.robotAccount") }}</div>
          </div>
          <div
            class="account-action-item right flex-1 row-center"
            @click="showAddDialog(2)"
          >
            <img
              src="@/assets/images/icon-multisig-account.svg"
              class="icon-account"
            />
            <div class="ml-2">{{ $t("home.multisigAccount") }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <about-multi-dialog v-model="isShowAboutDialog" append-to-body />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Logo from "./Sidebar/Logo";
import pathToRegexp from "path-to-regexp";
import SwitchAccountDialog from "./switch-account-dialog.vue";
import AddRobotDialog from "./add-robot-dialog.vue";
import AddMultiDialog from "./add-multi-dialog.vue";
import { getUserInfo } from "@/api/index.js";
import { stringName } from "@/utils/string.js";
import copyText from "@/utils/copy-text.js";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { relieveAccount } from "@/api/index";
import AboutMultiDialog from "@/layout/components/about-multi-dialog.vue";

export default {
  inject: ["reload"],

  components: {
    Logo,
    SwitchAccountDialog,
    AddRobotDialog,
    AddMultiDialog,
    AboutMultiDialog
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device,
      walletChainId: state => state.walletChainId,
      walletAccount: state => state.walletAccount,
      userSubAccount: state => state.userSubAccount,
      multisigAccount: state => state.multisigAccount,
      isAsideMenuOpen: state => state.isAsideMenuOpen
    }),

    isMobile() {
      return this.device === "mobile";
    },

    isShow() {
      return this.userInfo && this.userInfo.token;
    }
  },

  data() {
    return {
      isShowdrawer: false,
      levelList: null,
      isShowSwitchAccountDialog: false,
      isShowAddRobotDialog: false,
      isShowAddMultiDialog: false,
      info: {
        avatar: "",
        nickname: "",
        mixinId: "",
        uuid: ""
      },
      isAccountPopShow: false,
      selectMultisigId: "",
      isShowMoreAccountDialog: false,
      isShowAboutDialog: false
    };
  },

  watch: {
    $route(route) {
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    }
  },

  created() {
    this.getBreadcrumb();
    if (this.userInfo.token) {
      this.$store.dispatch("updateUserInfo").catch(() => {});
    }
    if (this.multisigAccount && this.multisigAccount.id) {
      this.selectMultisigId = this.multisigAccount.id;
      getUserInfo(true)
        .then(({ data: d }) => {
          this.info.avatar = d.avatarUrl;
          this.info.nickname = d.nickname;
          this.info.mixinId = d.mixinNumber;
          this.info.uuid = d.userId;
        })
        .catch(err => {
          this.$errHandler(err);
        });
    }
  },

  methods: {
    stringName,

    getBreadcrumb() {
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: "/dashboard", meta: { title: "" } }].concat(matched);
      }

      this.levelList = matched.filter(
        item => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase()
      );
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
    onMenuClick() {
      this.$store.commit("toggleAsideMenu", !this.isAsideMenuOpen);
    },
    switchAccounts() {
      this.isShowSwitchAccountDialog = !this.isShowSwitchAccountDialog;
    },
    disconnect() {
      this.$store.commit("clearLocal");
      this.$router.push("/login");
    },
    reloadUser() {
      location.reload();
    },
    showAddDialog(type) {
      this.isShowMoreAccountDialog = false;
      if (type === 1) {
        this.isShowAddRobotDialog = !this.isShowAddRobotDialog;
      } else {
        this.isShowAddMultiDialog = !this.isShowAddMultiDialog;
      }
    },
    copy() {
      if (
        this.userInfo.walletAddress &&
        copyText(this.userInfo.walletAddress)
      ) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      } else {
        this.isAccountPopShow = true;
      }
    },
    popShow() {
      if (this.multisigAccount && this.multisigAccount.id) {
        this.selectMultisigId = this.multisigAccount.id;
      }
      this.$store.dispatch("updateUserSubAccounts").catch(() => {});
    },
    selectAccount(account) {
      if (
        (account === "personal" && this.multisigAccount.id === "") ||
        account.multisigId === this.multisigAccount.id
      ) {
        this.$refs.popover.hide();
        return;
      }
      if (account) {
        this.selectMultisigId = account.multisigId;
        this.$store.commit("setMultisigAccount", {
          id: this.selectMultisigId,
          type: account.type
        });
      } else {
        this.selectMultisigId = "";
        this.$store.commit("setMultisigAccount", {});
      }
      this.reloadUser();
    },
    deleteAccount(id, index) {
      ElMessageBox.confirm(this.$t("account.deleteTips"), "Tips", {
        confirmButtonText: this.$t("actions.confirm"),
        cancelButtonText: this.$t("actions.cancel"),
        type: "warning"
      })
        .then(() => {
          relieveAccount(id)
            .then(() => {
              this.userSubAccount.list.splice(index, 1);
            })
            .catch(err => {
              this.$errHandler(err, "dialog");
            });
          ElNotification({
            title: "Success",
            message: "Delete Account Success.",
            type: "success"
          });
        })
        .catch(() => {});
    },
    showMoreAccountDialog() {
      this.isShowMoreAccountDialog = !this.isShowMoreAccountDialog;
    },
    aboutMultisig() {
      this.isShowMoreAccountDialog = false;
      this.isShowAboutDialog = !this.isShowAboutDialog;
    },
    readDocs() {
      window.open("https://mixpay.me/developers");
    }
  }
};
</script>

<style lang="scss">
.avatar-wrapper {
  border: 1px solid #ececec;
  border-radius: 12px;
  padding-left: 14px;
}

.avatar {
  border: 1px solid #dcdfe6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.shutdown {
  background-color: #fff2f5;
  padding: 8px 14px;
  margin-left: 14px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  color: #eb5757;
}

.user-name {
  @media only screen and (max-width: 470px) {
    display: inline-block;
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-account {
  height: 70px;

  @media only screen and (max-width: 470px) {
    height: 70px;
  }
}

.drawer {
  padding: 0px;
  display: none;
  @media only screen and (max-width: 960px) {
    display: block;
  }
}

.item-menu {
  height: 50px;
  padding-left: 10px;
  color: #333333;

  .title {
    margin-left: 10px;
    font-weight: 500;
  }
}

.item-menu:hover {
  color: #409eff;
}

.el-popover.el-popper {
  --el-popover-padding: 12px 0px 0px;
}

.accounts-title {
  font-weight: 600;
}

.item-list:hover {
  font-weight: 600;
}
.item-list {
  height: 70px;

  &.active {
    background: #f8faff;
  }

  .name {
    display: inline-block;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 470px) {
      max-width: 150px;
    }

    // &.nameActive {
    //   font-weight: 600;
    // }
  }

  .item-type {
    margin-left: 6px;
    height: 20px;
    padding: 0 4px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 600;
  }

  .robot {
    background: #e8f1fa;
    color: #409eff;
  }

  .multisig {
    background: #e8faf2;
    color: #62d37e;
  }
}

.account-action {
  border-top: 1px solid #ececec;
  overflow: hidden;

  .right {
    border-left: 1px solid #ececec;
  }

  &-item:hover {
    background: #f8faff;
    font-weight: 500;
  }
}

.account-tips {
  padding: 8px;
  border: 2px solid #ececec;
  border-radius: 8px;
  color: #6b7280;
  margin: 0 14px;

  &-icon {
    width: 20px;
    height: 20px;
  }
}

.account-tips:hover {
  transform: scale(1.05);
  color: #4b5563;
}

.modes-tip {
  padding: 0 36px;

  &-title {
    color: #1e1e22;
  }

  &-personal {
    color: #ffa527;
  }

  &-developer {
    color: #409eff;
  }

  &-developer-btn {
    color: #409eff;
    border: 1px solid #409eff;
    border-radius: 12px;
    padding: 8px 12px;
  }

  &-multi-signer {
    color: #62d37e;
  }

  &-multi-signer-btn {
    color: #62d37e;
    border: 1px solid #62d37e;
    border-radius: 12px;
    padding: 8px 12px;
  }

  .btn-add-robot {
    height: 36px;
    font-size: 12px;
  }

  .btn-add-multi {
    height: 36px;
    font-size: 12px;
  }

  .url {
    text-decoration: underline;
    cursor: pointer;
  }
}

.guide-tips {
  padding: 2px;
  border: 2px solid #ececec;
  border-radius: 8px;
  color: #6b7280;
  margin-right: 20px;
}

.guide-tips:hover {
  transform: scale(1.05);
  color: #4b5563;
}

.guides-tip-list {
  width: 160px;

  &-item {
    color: #333333;
    font-weight: normal;
  }

  &-item:hover {
    color: #409eff;
  }

  .icon-faq {
    width: 18px !important;
    height: 18px !important;
  }
}

.more-dialog-body {
  margin: 0 -20px -30px;
  border-radius: 0 0 16px 16px;
  overflow: hidden;

  .alert-warning {
    background: rgba(255, 242, 114, 0.2);
    border-radius: 12px;
    border: 1px solid #ffecac;
    padding: 16px;
  }
}
</style>