<template>
    <el-dialog :title="$t('account.robot.title')" class="w-min">
        
        <el-input v-model="mixinNumber" type="number" :placeholder="$t('notification.addBotTip[5]')" label="Mixin ID" clearable/>

        <div class="input-tips mt-4">{{ $t('account.robot.tips[0]') }}</div>
        <div class="row-center mt-10">
            <el-button class="w-1/2" type="primary" @click="onSubmit" :loading="isSubmitting">{{ $t('actions.confirm') }}</el-button>
        </div>
        <div class="space-y-4 mt-10">
            <h3 class="tips-title">{{ $t('account.robot.tips[1]') }}</h3>
            <div class="tips-content">{{ $t('account.robot.tips[2]') }}</div>
            <div class="tips-content">{{ $t('account.robot.tips[3]') }}</div>
        </div>

    </el-dialog>
</template>

<script>

import { mapState } from 'vuex';
import { ElMessage, ElNotification } from 'element-plus';
import { bindRobot } from '@/api/index';

export default {

    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            device: state => state.device,
        }),
    },
    
    data() {
        return {
            mixinNumber: '',
            isSubmitting: false,
        }
    },

    methods: {
        onSubmit() {
            if(!this.mixinNumber) {
                ElMessage.error(this.$t('notification.addBotTip[5]'));
            }else {
                this.isSubmitting = true;
                bindRobot(this.mixinNumber)
                    .then(() => {
                        this.mixinNumber = '';
                        this.isSubmitting = false;
                        ElNotification({
                            title: 'Success',
                            message: 'Add Account Success.',
                            type: 'success',
                        })
                        this.$store.dispatch('updateUserSubAccounts').catch(() => {});
                        this.$emit('dialogClose')
                    })
                    .catch(err => {
                        this.isSubmitting = false;
                        this.$errHandler(err, 'dialog');
                    });
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.el-input {
    width: 500px;
    height: 50px;    
}
</style>