import axios from "@/utils/request.js";
import pinEncrypt from "@/utils/pin-encrypt";

/**
 * 获取登录授权
 * @returns url
 */
export const getAuth = (returnTo) => {
  return axios.get(`/mixin_oauth_path?returnTo=${returnTo}`);
};

/**
 * 获取用户自己的基本信息
 * @returns
 */
export const getUserInfo = (isPerson) => {
  return axios.post("/user_info", { isPerson });
};

/**
 * 用户的最新的记录
 * @returns
 */
export const getUserFlows = () => {
  return axios.post("/payments_history");
};

/**
 * 获取用户保存的收款方式
 * @returns
 */
export const updateUserReceiveList = (page, perPage, isSinglePage = 0) => {
  return axios.post("/user/receipt_links", {
    page,
    perPage,
    isSinglePage,
  });
};

/**
 * 获取计价币种
 * @param {*} payeeId
 * @returns
 */
export const getQuoteAssets = (payeeId) => {
  return axios.get(
    "/setting/quote_assets" + (payeeId ? "?payeeId=" + payeeId : "")
  );
};

/**
 * 获取结算币种
 * @param {*} payeeId
 * @param {*} quoteAssetId
 * @param {*} quoteAmount
 * @returns
 */
export const getSettlementAssets = (payeeId, quoteAssetId, quoteAmount) => {
  let url = "/setting/settlement_assets?";
  if (payeeId) {
    url += `payeeId=${payeeId}&`;
  }
  if (quoteAssetId) {
    url += `quoteAssetId=${quoteAssetId}&`;
  }
  if (quoteAmount) {
    url += `quoteAmount=${quoteAmount}`;
  }
  return axios.get(url);
};

/**
 * 获取默认的配置
 * @returns
 */
export const getDefaultSettings = () => {
  return axios.get("/setting");
};

/**
 * 添加收款方式
 * @param {*} quoteAmount
 * @param {*} settlementAssetId
 * @param {*} quoteAssetId
 * @param {*} note
 * @param {*} remark
 * @returns
 */
export const addUserReceive = (
  avatarUrl,
  linkName,
  quoteAmount,
  settlementAssetId,
  quoteAssetId,
  note,
  remark
) => {
  const obj = {
    quoteAssetId,
    linkName,
    avatarUrl,
  };
  if (quoteAmount) {
    obj.quoteAmount = quoteAmount;
  }
  if (settlementAssetId) {
    obj.settlementAssetId = settlementAssetId;
  }
  if (note) {
    obj.note = note;
  }
  if (remark) {
    obj.remark = remark;
  }
  return axios.post("/user/add_receipt_link", obj);
};

// 更改用户唯一 Name
export const updateUserName = (name) => {
  return axios.post("/user/change_name", { name });
};

export const getUserReceive = (id) => {
  return axios.post(`/user/link_show/${id}`);
};

export const deleteUserReceive = (id) => {
  return axios.post(`/user/delete_link/${id}`);
};

export const postReceiveProfile = (linkId, avatarUrl, linkName, note) => {
  const data = {};
  if (avatarUrl) {
    data.avatarUrl = avatarUrl;
  }
  if (linkName) {
    data.linkName = linkName;
  }
  data.note = note;
  return axios.post(`/user/edit_link/${linkId}`, data);
};

/**
 * 获取某个收款的通知成员
 * @param {*} receiveId
 * @returns
 */
export const getNotificationList = (receiveId) => {
  return axios.post(`/user/link_members/${receiveId}`);
};

/**
 * 移除通知接收者
 * @param {*} mixinId
 * @returns
 */
export const deleteNotificationReceiver = (id) => {
  return axios.post(`/user/del_link_member/${id}`);
};

/**
 * 为链接添加通知成员
 * @param {*} receiveId
 * @param {*} mixinId
 * @returns
 */
export const addNotificationReceiver = (receiveId, mixinNumber) => {
  return axios.post(`/user/link_member/${receiveId}`, {
    mixinNumber,
  });
};

/**
 * 获取收款记录列表
 * @param {*} start_time 开始时间
 * @param {*} end_time 结束时间
 * @param {*} page 页码
 * @param {*} per_page 每页长度
 * @returns
 */
export const getReceiveRecords = (
  startTime = null,
  endTime = null,
  page = 1,
  perPage = 5,
  linkId = null,
  orderId = null
) => {
  const obj = {
    startTime,
    endTime,
    page,
    perPage,
  };
  if (linkId && linkId !== "all") {
    obj.linkId = linkId;
  }
  if (orderId) {
    obj.orderId = orderId;
  }
  return axios.post("/payments_history", obj);
};

/**
 * 获取提现记录列表
 * @param {*} start_time
 * @param {*} end_time
 * @param {*} page
 * @param {*} per_page
 * @returns
 */
export const getWithdrawRecords = (
  startTime = null,
  endTime = null,
  page = 1,
  perPage = 10
) => {
  return axios.post("/withdraw_records", {
    startTime,
    endTime,
    page,
    perPage,
  });
};

/**
 * 获取收款记录详情
 * @param {*} traceId 记录的唯一凭证
 * @returns
 */
export const getReceiveDetail = (traceId) => {
  return axios.post("/payments_detail", {
    traceId,
  });
};

/**
 * 获取提现记录详情
 * @param {*} traceId 支付epc的唯一凭证
 * @returns
 */
export const getWithdrawDetail = (traceId) => {
  return axios.post("/withdraw_record", {
    traceId,
  });
};

/**
 * 提现确认收款
 * @param {*} trace_id
 * @returns
 */
export const overWithdrawRecord = (traceId) => {
  return axios.post("/over_withdraw_record", { traceId });
};

/**
 * 设置个人信息
 * @param {*} nickname
 * @param {*} avatarUrl
 * @returns
 */
export const setUserInfo = (nickname, avatarUrl) => {
  return axios.post("user/set_info", { nickname, avatarUrl });
};

// 设置默认的收款方式
export const updateUserDefaultReceive = (
  settlementAssetId = "",
  quoteAssetId = ""
) => {
  const obj = {};
  if (quoteAssetId) {
    obj.quoteAssetId = quoteAssetId;
  }
  if (settlementAssetId) {
    obj.settlementAssetId = settlementAssetId;
  }
  return axios.post("/user/set_default_link", obj);
};

/**
 * 获取用户子账户
 * @param {*} hasAvatar
 * @returns
 */
export const getUserSubAccount = (hasAvatar) => {
  return axios.post("/user/sub_accounts", { hasAvatar });
};

/**
 * 绑定机器人
 * @param {*} mixinNumber
 * @returns
 */
export const bindRobot = (mixinNumber) => {
  return axios.post("/bind/robot", { mixinNumber });
};

/**
 * 获取Mixin用户信息
 * @param {*} mixinNumber
 * @returns
 */
export const getMixiUserInfo = (mixinNumber) => {
  return axios.post("mixin_user_info", { mixinNumber });
};

/**
 * 获取多签组id
 * @param {*} receivers 用户uuid集合
 * @param {*} threshold
 * @returns
 */
export const getMultisigId = (receivers, threshold) => {
  return axios.post("multisig", { receivers, threshold });
};

/**
 * 绑定多签组
 * @param {*} multisigId 多签组id
 * @returns
 */
export const bindMultisig = (multisigId) => {
  return axios.post("bind/multisig", { multisigId });
};

/**
 * 解绑子账户
 * @param {*} multisigId
 * @returns
 */
export const relieveAccount = (multisigId) => {
  return axios.post("user/relieve_account", { multisigId });
};

/**
 * 获取多签成员组信息
 * @returns
 */
export const getMultiMembers = (userId) => {
  return axios.post(`/user/multisig_members/${userId}`);
};

/**
 * 获取用户钱包资产列表
 * @returns
 */
export const getUserAssets = (token) => {
  return axios.post("/wallet_balance_list", { token });
};

/**
 * Mvm授权登录
 * @param {*} address
 * @param {*} sig
 * @returns
 */
export const getMvmLogin = (address, sig) => {
  return axios.post("/auth/metamask/login", { address, sig });
};

/**
 * MetaMask 获取 nonce
 * @param {*} address
 * @returns
 */
export const getNonce = (address) => {
  return axios.post(`/auth/metamask/${address}/nonce`);
};

export const fetchMvmNonce = (address) => {
  return axios.post(`/auth/mvm/${address}/nonce`);
};

export const postMvmLogin = (address, sig) => {
  return axios.post(`/auth/mvm/mvm_login`, { address, sig });
};

export const fetchMetaMaskNonce = (address) => {
  return axios.post(`/auth/metamask/${address}/nonce`);
};

export const postMetaMaskLogin = (address, sig) => {
  return axios.post(`/auth/metamask/login`, { address, sig });
};

/**
 * 获取币种价格
 * @returns
 */
export const getPrice = () => {
  return axios.get("/setting/mixin_asset");
};

/**
 * 获取用户今日统计数据
 * @returns
 */
export const getTodayStatistic = () => {
  return axios.get("/user/today_statistic");
};

/**
 * 创建账单
 * @param {*} settlementAssetId
 * @param {*} quoteAmount
 * @param {*} quoteAssetId
 * @param {*} remark
 * @param {*} expiredTimestamp
 * @returns
 */
export const createBills = (
  settlementAssetId,
  quoteAmount,
  quoteAssetId,
  remark,
  expiredTimestamp,
  name,
  email,
  isSend
) => {
  const obj = {};
  if (settlementAssetId) {
    obj.settlementAssetId = settlementAssetId;
  }
  if (quoteAmount) {
    obj.quoteAmount = quoteAmount;
  }
  if (quoteAssetId) {
    obj.quoteAssetId = quoteAssetId;
  }
  if (remark) {
    obj.remark = remark;
  }
  if (expiredTimestamp) {
    obj.expiredTimestamp = expiredTimestamp;
  }
  if (name) {
    obj.name = name;
  }
  if (email) {
    obj.email = email;
  }
  obj.isSend = isSend;
  return axios.post("/user/bill/create", obj);
};

/**
 * 获取账单列表
 * @param {*} page
 * @param {*} perPage
 * @param {*} filter
 * @returns
 */
export const getBillingList = (page, perPage, filter, startTime, endTime) => {
  let url = "/user/bills?";
  if (page) {
    url += `page=${page}&`;
  }
  if (perPage) {
    url += `perPage=${perPage}&`;
  }
  if (filter) {
    url += `filter=${filter}&`;
  }
  if (startTime) {
    url += `startTime=${startTime}&`;
  }
  if (endTime) {
    url += `endTime=${endTime}`;
  }

  return axios.get(url);
};

/**
 * 账单过期
 * @param {*} id
 * @returns
 */
export const expiredBilling = (id) => {
  return axios.post(`/user/bill/expired/${id}`);
};

/**
 * 发送邮件
 * @param {*} id
 * @returns
 */
export const sendMail = (id, name, email) => {
  let url = `/user/bill/send_mail/${id}?`;
  if (name) {
    url += `name=${name}&`;
  }
  if (email) {
    url += `email=${email}`;
  }
  return axios.post(url);
};

/**
 * 用户订单通知 添加邀请用户
 * @param {*} mixinNumber
 * @returns
 */
export const addRemembrancer = (mixinNumber) => {
  return axios.post("/user/remembrancer", { mixinNumber });
};

/**
 * 用户订单通知 删除邀请用户
 * @param {*} id
 * @returns
 */
export const deleteRemembrancer = (id) => {
  return axios.post(`/user/del_remembrancer/${id}`);
};

/**
 * 获取Api Key
 * @returns
 */
export const getApiKey = () => {
  return axios.post("/user/api_key");
};

export const exportBillings = (startTime, endTime, linkId, orderId = null) => {
  return axios({
    method: "get",
    url: "/payments_export",
    params: {
      startTime,
      endTime,
      linkId,
      orderId,
    },
    responseType: "blob",
  }).then((data) => {
    const blob = data;
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const ele = document.createElement("a");
    const filename =
      `payment_history_${startTime}_${endTime}` +
      (linkId ? `_${linkId}` : "") +
      ".xlsx";
    ele.style.display = "none";
    ele.href = url;
    ele.setAttribute("download", filename);
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
    (window.URL || window.webkitURL).revokeObjectURL(url);
    return Promise.resolve();
  });
};

/**
 * 给邮件发送验证码
 * @param {*} email
 * @returns
 */
export const sendEmailVerifyCode = (email) => {
  return axios.post("/auth/email/code", { email });
};

/**
 * 邮件+验证码登录
 * @param {*} email
 * @param {*} code
 * @returns
 */
export const submitEmailVerifyCode = (email, code) => {
  return axios.post("/auth/email/login", { email, code });
};

export const setPincode = (pin) => {
  return axios.post("/account/set_pin", { pin: pinEncrypt(pin) });
};

export const upgradeToSafe = (
  pin
) => {
  return axios.post("/account/upgrade_to_safe", {
    pin: pinEncrypt(pin),
  });
};

export const getEmailUserAssets = () => {
  return axios.get("/account/wallet");
};

export const addAddress = (assetId, label, pin, destination, tag = "") => {
  return axios.post("/account/add_address", {
    assetId,
    label,
    pin: pinEncrypt(pin),
    destination,
    tag,
  });
};

export const deleteAddress = (addressId, pin) => {
  return axios.post("/account/delete_address", {
    addressId,
    pin: pinEncrypt(pin),
  });
};

export const getAddressList = (assetId) => {
  return axios.get(`/account/address?assetId=${assetId}`);
};

export const getAddressDetail = (addressId) => {
  return axios.put(`/account/address/${addressId}`);
};

export const getEmailAssetDetail = (assetId) => {
  return axios.get(`/account/wallet_asset?assetId=${assetId}`);
};

export const withdrawToAddress = (addressId, amount, pin) => {
  return axios.post("/account/asset_withdrawal", {
    addressId,
    amount,
    pin: pinEncrypt(pin),
  });
};

export const getEmailSnapshots = (
  startTime,
  endTime,
  page = 1,
  perPage = 30
) => {
  return axios.get(
    `/account/snapshots?startTime=${startTime}&endTime=${endTime}&page=${page}&perPage=${perPage}`
  );
};

export const getWithdraws = (
  page = 1,
  perPage = 30
) => {
  return axios.get(
    `/account/withdraws?page=${page}&perPage=${perPage}`
  );
};

export const getSwapAssetsList = () => {
  return axios.get("/exinone/convert/assets");
};

export const fetchConvertEstimate = (
  payAssetUuid,
  receiveAssetUuid,
  payAssetAmount
) => {
  return axios.get(
    `/exinone/convert/estimate/amount?payAssetUuid=${payAssetUuid}&receiveAssetUuid=${receiveAssetUuid}&payAssetAmount=${payAssetAmount}&withRange=1`
  );
};

export const postConvert = (
  payAssetUuid,
  receiveAssetUuid,
  payAssetAmount,
  pin
) => {
  return axios.post("/exinone/convert", {
    payAssetUuid,
    receiveAssetUuid,
    payAssetAmount,
    pin: pinEncrypt(pin),
  });
};

export const getRoleInvitaionList = () => {
  return axios.get("/permission/invite_user");
};

export const sendRoleInvitation = (type, account) => {
  return axios.post("/permission/invite_user", { type, account });
};

export const deleteRoleInvitation = (id) => {
  return axios.post(`/permission/delete_invite_user/${id}`);
};

export const getRoleInvitationStatus = (recordId) => {
  return axios.get(`/permission/invitation/${recordId}`);
};

export const doActionToRoleInvitation = (id, action) => {
  return axios.post("/permission/invitation_action", { id, action });
};
