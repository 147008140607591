<template>
  <el-dialog
    title="Login With"
    top="10vh"
    width="80%"
    style="max-width:480px"
    :close-on-click-modal="false"
    @close="onDialogClose"
  >
    <div class="px-6 pb-6">
      <div class="btn-group flex flex-wrap">
        <div class="btn">
          <el-button type="default" plain @click="onChoose('mvm')">
            <img
              class="btn-icon"
              src="@/assets/images/login/icon-mvm.svg"
              alt="MVM"
            />
            <span>MVM</span>
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { MetaMaskProvider, signature } from "@/plugins/web3/index";
import { fetchMvmNonce, postMvmLogin } from "@/api/index";
import { ElLoading, ElMessageBox } from "element-plus";
import { ethers } from "ethers";
export default {
  methods: {
    onDialogClose() {},
    async onChoose(val) {
      if (val === "mvm") {
        if (!MetaMaskProvider.hasMetaMask()) {
          if (
            navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
          ) {
            return ElMessageBox.confirm(
              "Please login with a desktop browser.",
              "Tips",
              {
                cancelButtonText: this.$t("actions.confirm"),
                type: "warning"
              }
            ).catch(() => {});
          } else {
            return ElMessageBox.confirm(
              "Please install MetaMask extension first",
              "Tips",
              {
                confirmButtonText: "Install MetaMask",
                cancelButtonText: this.$t("actions.cancel"),
                type: "warning"
              }
            )
              .then(() => {
                window.open("https://metamask.io", "_blank");
              })
              .catch(() => {});
          }
        }
        let loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        try {
          await MetaMaskProvider.switchToMvmNetwork();
          const accounts = await MetaMaskProvider.getAccounts();
          const address = ethers.utils.getAddress(accounts[0]);
          const nonceData = await fetchMvmNonce(address);
          const signatureData = await signature(
            MetaMaskProvider.provider,
            nonceData.data.nonce
          );
          const tokenData = await postMvmLogin(address, signatureData);
          if (tokenData.data && tokenData.data.token) {
            this.$store.commit(
              "setWalletAccount",
              ethers.utils.getAddress(accounts[0])
            );
            this.$store.commit("setUserInfo", {
              walletAddress: ethers.utils.getAddress(accounts[0]),
              token: tokenData.data.token,
              type: "mvm"
            });
            this.$emit("jump");
          }
        } catch (e) {
          var msg = e.reason || e.message;
          if (msg === "user rejected signing") {
            msg = "Account verification failed. Please try again later.";
          }
          ElMessageBox.confirm(msg, "", {
            confirmButtonText: "OK",
            type: "warning",
            showCancelButton: false
          }).catch(() => {});
        } finally {
          loading && loading.close();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-group {
  .btn {
    margin-bottom: 16px;
    box-sizing: border-box;
    height: 44px;
    font-size: 16px;
    border-radius: 6px;
    width: calc(50% - 8px);

    &:nth-child(odd) {
      margin-right: 16px;
    }

    .el-button {
      width: 100%;
      height: 44px;
      font-size: 16px;
      border-radius: 6px;
    }

    .el-button--default {
      background-color: #fff;
      border-color: #f2f3f5;
      box-shadow: 0px 2px 4px rgba(#000, 0.05);
      color: inherit;

      &:hover {
        border-color: #e5e6eb;
        background-color: #f2f3f5;
        transform: scale(1);
      }

      &:active {
        border-color: #e5e6eb;
        background-color: #e5e6eb;
      }
    }

    .btn-icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>