<template>
  <el-dialog
    :title="title"
    @open="onDialogOpen"
    top="10vh"
    width="90%"
    style="max-width:480px"
  >
    <div>
      <div
        class="loading"
        v-loading="true"
        v-if="loadStatus === 'loading'"
      ></div>
      <div class="failed row-center" v-else-if="loadStatus === 'failed'">
        <div class="text-center">
          <p>Something is wrong, please try again.</p>
          <p class="mt-4">
            <el-button type="primary" @click="loadData">try again</el-button>
          </p>
        </div>
      </div>
      <div class="success" v-else>
        <div class="infos">
          <div class="item row-justify">
            <div class="item-left mr-4">
              <div class="item-title text-lg">Address</div>
              <div class="item-content row-justify text-sm mt-2">
                <span class="item-content-text">{{ destination }}</span>
                <exp-svg-icon
                  class="ml-1 cursor-pointer"
                  name="copy"
                  size="sm"
                  @click="copy(destination)"
                />
              </div>
            </div>
            <div class="item-right">
              <qrcode-vue
                class="qrcode"
                :value="destination"
                :size="200"
                level="H"
              />
            </div>
          </div>
          <div class="item row-justify mt-4" v-if="tag">
            <div class="item-left">
              <div class="item-title text-lg">Memo</div>
              <div class="item-content row-justify text-sm mt-2">
                <span class="item-content-text">{{ tag }}</span>
                <exp-svg-icon
                  class="ml-1 cursor-pointer"
                  name="copy"
                  size="sm"
                  @click="copy(tag)"
                />
              </div>
            </div>
            <div class="item-right">
              <qrcode-vue class="qrcode" :value="tag" :size="200" level="H" />
            </div>
          </div>
        </div>

        <div class="deposit-tips mt-8">
          <ul class="text-sm">
            <li class="tip-item text-color-danger" v-if="tag">
              Both Memo and Address are required to deposit your {{ symbol }} to
              your account, or you may lose your assets.
            </li>
            <li class="tip-item">
              Do not transfer any
              <span class="text-color-danger">non-{{ symbol }}</span> assets to
              the above address.
            </li>
            <li class="tip-item">
              Please confirm your transfer network is
              <span class="text-color-danger">"{{ network }}"</span>, or you may
              lose your assets.
            </li>
            <li class="tip-item" v-if="blockConfirmations > 0">
              The deposit will be credited after
              <strong>{{ blockConfirmations }}</strong> block
              {{ `confirmation${blockConfirmations > 1 ? "s" : ""}` }}.
            </li>
            <!-- <li class="tip-item">
              Min deposit: <strong>0.00000001</strong> {{ symbol }}.
            </li> -->
          </ul>
        </div>

        <div class="deposit-fqa mt-8">
          <p class="text-center">
            <el-link
              type="primary"
              href="https://help.mixpay.me/en/articles/7191757-how-to-deposit-into-the-mixpay-dashboard-wallet"
              target="_blank"
            >
              How to deposit?
            </el-link>
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getEmailAssetDetail } from "@/api/index.js";
import QrcodeVue from "qrcode.vue";
import copyText from "@/utils/copy-text.js";
import { ElMessage } from "element-plus";
export default {
  name: "DepositDialog",

  components: {
    QrcodeVue
  },

  props: {
    uuid: String
  },

  data() {
    return {
      loadStatus: "loading",
      loading: false,
      destination: "",
      tag: "",
      blockConfirmations: 0,
      network: "",
      symbol: "",
      reserve: ""
    };
  },

  computed: {
    ...mapState({
      assets: state => state.emailAssets.list
    }),
    title() {
      const { assets, uuid } = this;
      const select = assets.find(item => item.uuid === uuid);
      return `Deposit ${(select && select.symbol) || ""}`;
    }
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      getEmailAssetDetail(this.uuid)
        .then(data => {
          const d = data.data;
          this.destination = d.destination;
          this.tag = d.tag;
          this.symbol = d.symbol;
          this.network = d.network;
          this.blockConfirmations = d.confirmations;
          this.reserve = d.reserve;
          this.loadStatus = "success";
        })
        .catch(err => {
          this.loadStatus = "failed";
          console.error(err);
        });
    },
    copy(url) {
      if (copyText(url)) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    },

    onDialogOpen() {
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.loading,
.failed {
  height: 300px;
}

.success {
  min-height: 300px;
}

.infos {
  .item-title {
    font-weight: bold;
    color: #1e1e1e;
  }

  .item-content-text {
    word-break: break-all;
  }

  .qrcode {
    width: 120px !important;
    height: 120px !important;
  }
}

.deposit-tips {
  padding: 15px;
  background: #fffdee;

  ul {
    padding-left: 20px;
  }

  .tip-item {
    list-style: disc;

    & + .tip-item {
      margin-top: 0.5rem;
    }
  }

  .text-color-danger {
    font-weight: bold;
    color: #ff4466;
  }
}
</style>