<template>
  <span class="exp-fiat-icon">Fiat</span>
</template>

<script>
export default {
  name: 'exp-fiat-icon',
};
</script>

<style lang="scss">
.exp-fiat-icon {
  height: 20px;
  line-height: 20px;
  margin: 0 10px;
  padding: 0 4px;
  border-radius: 8px;
  font-family: 'custom din', sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFA527;
  background: rgba(255, 165, 39, 0.07);
  border-radius: 2px;
  border: 1px solid rgba(255, 165, 39, 0.06);
}
</style>
