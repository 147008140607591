<template>
  <el-dialog
    :title="$t('history.depositTitle')"
    class="w-max"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <el-scrollbar v-loading="isLoading" element-loading-text="Loading..." max-height="480px">
      <div class="status col-center">
        <exp-svg-icon :name="statusIcon" :size="device === 'mobile' ? 'lg' : 'xl' " />
        <h4 class="status-title">{{ statusText }}</h4>
        <p class="col-center">
          <span class="strong-num" :class="transferStatus">
            {{ price }} <span class="unit">{{ priceSymbol }}</span>
          </span>
          <span class="num" v-if="symbol !== priceSymbol && amount > 0"
            >{{ amount }} {{ symbol }}</span
          >
        </p>
      </div>
      <div class="division"></div>
      <div class="detail">
        <div class="detail-item row-justify-center" v-if="orderId">
          <div class="detail-item-title">Merchant Order ID</div>
          <div class="detail-item-content">{{ orderId }}</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.otherAccounts") }}</div>
          <div class="detail-item-content">
            {{ payer }} <br />
            <span v-if="payerId">(ID: {{ payerId }})</span>
          </div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.date") }}</div>
          <div class="detail-item-content">{{ createdAt }}</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.settlementTo") }}</div>
          <div class="detail-item-content">
            {{ walletChainId ? 'MVM' : $t(`history.${settlementMethod}`) }}
          </div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.transactionID") }}</div>
          <div
            class="row-center"
            @click="openExplorer(transferSnapshotId)"
          >
            <div class="detail-item-content link">
              {{ transferSnapshotId || "-" }}
            </div>
          </div>
        </div>
        <div class="detail-item row-justify-center" v-if="qrName">
          <div class="detail-item-title">{{ $t("history.qrcode") }}</div>
          <div class="detail-item-content">{{ qrName || "-" }}</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.payeeNote") }}</div>
          <div class="detail-item-content">{{ remark || "-" }}</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.payerNote") }}</div>
          <div class="detail-item-content">{{ note || "-" }}</div>
        </div>
      </div>
      <!-- TODO 后端暂时不上线 -->
      <!-- <div v-if="isChangeSettle === 1">
        <h3 class="mt-6 mb-4">Why Settlement {{ symbol }} ?</h3>
        <p class="leading-6">1. MixPay will be traded in real-time through the exchange at the time of settlement. However, due to the different depths of each currency, MixPay has set limit protection to not go to the exchange when the settlement amount is exceeded to prevent the loss of enormous slippage caused by trading too large amounts at the time of settlement.</p>
        <p class="leading-6">2. If your cryptos need to be converted, you can use the Convert feature of the <span class="span-title" @click="copyRobot">ExinOne (7000101276)</span> bot.</p>
      </div> -->
     
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { formatTime } from "@/utils/date-format.js";
import { noSci } from "@/utils/num-format.js";
import copyText from "@/utils/copy-text.js";
import { getReceiveDetail } from "@/api/index.js";
import { ElMessage } from "element-plus";

export default {
  filters: {
    formatTime,
  },

  props: {
    traceId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isLoading: false,
      type: "deposit",
      amount: "0",
      symbol: "",
      price: "",
      priceSymbol: "",
      snapshotId: "",
      status: "",
      createdAt: "0",
      payer: "",
      payerId: "",
      note: "",
      remark: "",
      transferSnapshotId: "",
      transferStatus: "none",
      settlementMethod: "",
      transactionHash: "",
      qrName: "",
      pollKey: null,
      isChangeSettle: 0,
      orderId: "",
    };
  },

  computed: mapState({
    userInfo: (state) => state.userInfo,
    device: (state) => state.device,
    walletChainId: state => state.walletChainId,
    statusIcon() {
      switch (this.transferStatus) {
        case "unpaid":
        case "pending":
          return "status-processing";
        case "done":
        case "none":
          return "status-settled";
        default:
          return "status-failed";
      }
    },
    statusText() {
      switch (this.transferStatus) {
        case "unpaid":
        case "pending":
          return this.$t("history.settlementProcessing");
        case "done":
        case "none":
          return this.$t("history.settlementSuccess");
        default:
          return this.$t("status.failed");
      }
    },
  }),

  methods: {
    dialogOpen() {
      this.pollKey = setInterval(() => {
        this.loadData()
          .then(() => {
            if (["done", "none"].includes(this.transferStatus)) {
              clearInterval(this.pollKey);
            }
          })
          .catch(() => {});
      }, 2000);
      this.isLoading = true;
      this.loadData()
        .then(() => {
          this.isLoading = false;
          if (["done", "none"].includes(this.transferStatus)) {
            clearInterval(this.pollKey);
          }
        })
        .catch((err) => {
          this.$errHandler(err);
        });
    },

    dialogClose() {
      clearInterval(this.pollKey);
    },
    loadData() {
      return getReceiveDetail(this.traceId).then(({ data: d }) => {
        this.amount = d.settlementAmount;
        this.symbol = d.settlementSymbol;
        this.price = d.quoteAmount;
        this.priceSymbol = d.quoteSymbol;
        this.snapshotId = d.snapshotId;
        this.status = d.status;
        this.createdAt = formatTime(d.createdAt * 1000);
        this.payer =
          String(d.payerName).length > 12
            ? d.payerName.substring(0, 12) + "..."
            : d.payerName;
        this.payerId = d.payerMixinNumber;
        this.note = d.note;
        this.remark = d.remark;
        this.transferSnapshotId = d.transferSnapshotId;
        this.settlementMethod = d.settlementMethod;
        this.transactionHash = d.transactionHash;
        this.qrName = d.qrName;
        this.transferStatus = d.transferStatus;
        this.isChangeSettle = d.isChangeSettle;
        this.orderId = d.orderId;
      });
    },
    copy(snapshotId) {
      if (snapshotId && copyText(snapshotId)) {
        ElMessage.success(this.$t("actions.copiedToClipboard"));
      }
    },
    openExplorer(snapshotId) {
      if (!snapshotId) return;
      window.open(
        `https://mixin.one/snapshots/${snapshotId}`,
        "_blank",
        "noopener"
      );
    },
    dataNoSci(data) {
        return noSci(data);
    },
    copyRobot() {
      if (copyText('7000104220')) {
        ElMessage({
            message: 'Copied to clipboard',
            type: 'success',
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.el-scrollbar {
  width: 600px;
}
.status {
  .status-title {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
    color: color-text-base;
  }

  .strong-num {
    display: flex;
    align-items: flex-start;
    font-size: 32px;
    font-weight: bold;

    &.pending, &.checking, &.processing, &.unpaid {
      color: #F7A349;
    }

    &.done, &.none {
      color: #0EC9B1;
    }

    &.failed {
      color: #FF4466;
    }

    .unit {
      margin-top: 12px;
      margin-left: 2px;
      font-size: 18px;
    }
  }

  .num {
    margin-top: 10px;
  }
}

.division {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-position: left center;
  background-size: 20px 2px;
  background-image: linear-gradient(90deg, rgba(#5162ad, 0.16), rgba(#5162ad, 0.16) 12px, transparent 12px, transparent 20px);
}

.detail-item {
  font-size: 16px;

  @media only screen and (max-width: 470px) {
   font-size: 12px;
  }

  & + .detail-item {
    margin-top: 16px;
  }

  &-title {
    max-width: 300px;
    color: color-text-caption;
  }

  &-content {
    max-width: 350px;
    font-weight: 600;
    word-break: break-all;
    text-align: right;

    &.link {
      color: color-primary;
    }
  }
}
.span-title {
  color: color-primary;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
</style>