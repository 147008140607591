<template>
  <el-dialog
    title="Address Book"
    top="10vh"
    width="90%"
    style="max-width:480px"
    :close-on-click-modal="false"
    append-to-body
    @open="onDialogOpen"
  >
    <div>
      <div class="popup-header">
        <div class="row-justify">
          <div v-permission="['own']">
            <el-button type="primary" @click="onAddClick">
              <div class="row-center">
                <el-icon class="el-icon--add"><Plus /></el-icon>
                <span class="ml-2 text-base">Add Address</span>
              </div>
            </el-button>

            <!-- <el-button type="danger" @click="onDeleteClick">
              <div class="row-center">
                <el-icon class="el-icon--delete"><Delete /></el-icon>
                <span class="ml-2 text-base">Delete Address</span>
              </div>
            </el-button> -->
          </div>
        </div>
      </div>
      <div class="popup-body pb-6">
        <div class="address-list-wrapper">
          <div
            class="py-20 mt-4"
            v-loading="true"
            v-if="!addressMap || addressMap.loadStatus === 'loading'"
          ></div>
          <empty-view class="mt-4" v-else-if="!addressMap.list.length" />
          <div class="address-list mt-4 px-4 pb-4" v-else>
            <div
              class="address-item cursor-pointer row"
              v-for="item in addressMap.list"
              :key="item.id"
              @click="onChoose(item)"
            >
              <div class="row-center address-item-prefix">
                <el-icon
                  class="text-main-color"
                  size="24"
                  v-if="addressId === item.id"
                >
                  <CircleCheckFilled />
                </el-icon>
                <exp-svg-icon v-else size="sm" name="circle" />
              </div>
              <div class="address-item-content ml-4 py-4">
                <div class="label text-lg">{{ item.label }}</div>
                <div class="address mt-2">
                  {{ item.destination }} {{ item.tag }}
                </div>
              </div>
              <div
                class="address-item-addon row-center"
                @click.stop="onDeleteClick(item.addressUuid)"
              >
                <div class="row-center">
                  <el-icon class="el-icon--delete" size="large"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    uuid: String,
    addressId: [String, Number]
  },
  computed: mapState({
    emailAssetAddressMap: state => state.emailAssetAddressMap,
    addressMap() {
      const { uuid, emailAssetAddressMap } = this;
      return emailAssetAddressMap[uuid] || null;
    }
  }),

  methods: {
    onDialogOpen() {
      // this.$store.dispatch("updateEmailAddressMap", this.uuid).catch(() => {});
    },
    onChoose(item) {
      this.$emit("choose", item.id);
    },
    onAddClick() {
      this.$emit("add-address");
    },
    onDeleteClick(addressUuid) {
      this.$emit("delete-address", addressUuid);
    }
  }
};
</script>

<style lang="scss" scoped>
.address-list-wrapper {
  min-height: 200px;
}

.address-list {
  border: 1px solid #ececec;
  border-radius: 12px;
  max-height: 600px;
  overflow: hidden auto;

  .address-item-prefix {
    min-width: 24px;
  }

  .address-item-content {
    flex: 1 0 0%;
    border-bottom: 1px solid #ececec;

    .label {
      font-weight: bold;
    }

    .address {
      max-width: 300px;
      word-break: break-all;
    }
  }

  .address-item-addon {
    flex-shrink: 0;
    color: rgb(var(--el-color-danger-rgb));
  }
}
</style>