<template>
  <div>
    <div class="page-header px-10 py-6 row-justify-center">
      <h3 class="page-header-title row-align-center">
        <el-link type="primary" @click="goWallet" :underline="false">
          L2 Wallet
        </el-link>
        <el-icon class="mx-2" size="22"><ArrowRight /></el-icon>
        <span>Withdraw History</span>
      </h3>
    </div>
    <div class="page-content px-10 py-6">
      <div v-loading="loading">
        <div class="loading-wrapper" v-if="!isLoaded"></div>
        <empty-view v-else-if="!list.length" />
        <div class="table-wrapper" v-else>
          <el-scrollbar>
            <div class="table-container">
              <el-row class="table-header px-4">
                <el-col :span="8"><strong>Withdraw Amount</strong></el-col>
                <el-col :span="4"><strong>Status</strong></el-col>
                <el-col :span="6"><strong>Transaction ID(Hash)</strong></el-col>
                <el-col :span="6"><strong>Time</strong></el-col>
              </el-row>
              <el-row
                class="table-data text-sm px-4 py-4"
                v-for="(item, i) in list"
                :key="i"
              >
                <el-col :span="8">
                  <div class="row-align-center">
                    <exp-symbol
                      :uuid="item.uuid"
                      size="lg"
                      class="flex-shrink-0"
                    />
                    &nbsp; {{  item.amount }} &nbsp;
                    <span>{{ item.symbol }}</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="row-align-center">
                    <span class="ml-2">

                      <span
                        class="mr-2"
                        :class="item.status == 'done' ? 'text-green' : 'text-red'"
                      >
                        {{ item.status }}
                      </span>
                    </span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="row-align-center" v-if="item.txid">
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.explorer"
                      >{{ formatTxid(item.txid) }}</el-link
                    >
                    <exp-svg-icon
                      class="ml-2 cursor-pointer"
                      name="copy"
                      size="xs"
                      @click="copy(item.txid)"
                    />
                  </div>
                  <div class="row-align-center" v-else>
                    <span>-</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="row-align-center">{{ item.createdAt }}</div>
                </el-col>
              </el-row>
            </div>
          </el-scrollbar>
          <el-pagination
            class="mt-4 cursor-pointer"
            layout="prev, pager, next"
            background
            :current-page="page"
            :page-count="pageCount"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWithdraws } from "@/api";
import { formatTime } from "@/utils/date-format";
import { getPageCount } from "../../utils/num-format";
import copyText from "@/utils/copy-text.js";
import { ElMessage } from "element-plus";
export default {
  name: "WithdrawsView",

  data() {
    return {
      page: 1,
      pageCount: 1,
      perPage: 100,
      list: [],
      isLoaded: false,
      loading: false
    };
  },

  mounted() {
    this.isLoaded = false;
    this.loadData().finally(() => {
      this.isLoaded = true;
    });
  },

  methods: {
    loadData() {
      this.loading = true;
      return getWithdraws(
        this.page,
        this.perPage
      )
        .then(data => {
          const d = data.data;
          if (Array.isArray(d)) {
            this.list = d.map(item => {
              return {
                id: item.id,
                type: item.type,
                status: item.status,
                amount: item.amount,
                uuid: item.asset_uuid,
                symbol: item.asset_symbol,
                txid: item.txid,
                explorer: item.explorer,
                createdAt: formatTime(
                  item.createdAt * 1000,
                  "YYYY-MM-DD HH:mm:ss UTCZ"
                )
              };
            });
          }
          if (data.meta && data.meta.total) {
            this.pageCount = getPageCount(data.meta.total, this.perPage);
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          return Promise.reject(err);
        });
    },
    goWallet() {
      this.$router.replace("/wallet");
    },
    onPageChange(page) {
      this.page = page;
      this.loadData().catch(err => {
        this.$errHandler(err, "dialog");
      });
    },
    formatTxid(str) {
      if (str.length < 12) {
        return str;
      }
      return str.slice(0, 6) + "..." + str.slice(-6);
    },
    copy(url) {
      if (copyText(url)) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  border-bottom: 1px solid #ececec;

  .page-header-title {
    font-size: 22px;
    line-height: 36px;
    font-weight: 600;

    .el-link {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}

.loading-wrapper {
  min-height: 300px;
}

.table-wrapper {
  .table-container {
    min-width: 720px;
    border-radius: 12px;
    border: 1px solid #ececec;
  }

  .table-header {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ececec;
  }

  .table-data {
    &:not(:last-child) {
      border-bottom: 1px solid #ececec;
    }
  }

  .text-green {
    color: #0ec9b1;
  }

  .text-red {
    color: #ff4466;
  }
}
</style>