<template>
  <div class="balance px-10 py-6">
    <div class="row-justify">
      <div class="balance-left">
        <h3 class="title">Balance</h3>
        <div class="row-align-center mt-4 amount">
          <span>≈ $</span>
          <span>&nbsp;</span>
          <span>{{ totalUSD }}</span>
        </div>
      </div>
      <div class="balance-right actions text-right">

        <el-link
          class="action"
          type="primary"
          @click="goWithdraws"
        >
          <span class="mr-2">Withdraw History</span>
        </el-link>

        <el-link
          class="action"
          type="primary"
          @click="goTransactions"
        >
          <span class="ml-1">Transaction History</span>
        </el-link>
        
        <!-- <el-button
          type="primary"
          size="large"
          round
          class="mt-4"
          @click="onSwapClick()"
        >
          <div class="row-center">
            <exp-svg-icon name="swap" size="xs" />
            <span class="ml-1">Swap</span>
          </div>
        </el-button> -->
      </div>
    </div>
  </div>
  <div class="list px-10 py-6">
    <div class="list-header row-justify-center">
      <div class="list-header-title">Assets List</div>
      <div class="list-header-action"></div>
    </div>
    <div class="list-body mt-4">
      <div
        class="py-20"
        v-loading="true"
        v-if="emailAssets.loadStatus === 'pending'"
      ></div>
      <template v-else>
        <el-row
          class="asset-item py-4"
          v-for="item in emailAssets.list"
          :key="item.uuid"
        >
          <template v-if="device === 'mobile'">
            <el-col :xs="12">
              <div class="row-align-center">
                <exp-symbol :uuid="item.uuid" :iconUrl="item.icon" size="xl" />
                <div class="ml-2">
                  <p>{{ item.amount }}</p>
                  <p class="mt-1 text-sm">{{ item.symbol }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="btn-group">
                 <el-space :size="10">
                  <!-- <el-link type="primary" @click="onDepositClick(item)" v-if="item.isOn">
                    <span>Deposit</span>
                  </el-link> -->
                  <el-link type="primary" @click="onWithdrawClick(item)">
                    <span>Withdraw</span>
                  </el-link>
                </el-space> 
              </div>
            </el-col>
          </template>
          <template v-else>
            <el-col :span="8" class="row-align-center">
              <exp-symbol :uuid="item.uuid" :iconUrl="item.icon" size="xl" />
              <span class="ml-2">{{ item.symbol }}</span>
            </el-col>
            <el-col :span="12">
              <div class="main-amount">{{ item.amount }}</div>
              <div class="sub-amount mt-1">
                $ {{ usdFormat(item.amountUSD) }}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="btn-group">
                <!-- <el-button
                  type="primary"
                  plain
                  @click="onDepositClick(item)"
                  v-if="item.isOn"
                >
                  <span>Deposit</span>
                </el-button> -->
                <el-button type="primary" plain @click="onWithdrawClick(item)">
                  <span>Withdraw</span>
                </el-button> 
                <!-- <el-button type="primary" plain @click="onSwapClick(item)">
                  <span>Swap</span>
                </el-button> -->
              </div>
            </el-col>
          </template>
          <!-- <div class="danger-tips row-align-center mt-4" v-if="!item.isOn">
            <el-icon size="16"><WarnTriangleFilled /></el-icon>
            <p>
              This currency is no longer supported by MixPay, please withdraw as
              soon as possible.
            </p>
          </div> -->
        </el-row>
      </template>
    </div>

    <withdraw-dialog
      v-model:value="isWithdrawDialogShow"
      v-model:uuid="withdrawAssetUuid"
    />

    <deposit-dialog v-model="isDepositDialogShow" :uuid="depositAssetUuid" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import WithdrawDialog from "@/components/wallet/withdraw-dialog.vue";
import DepositDialog from "@/components/wallet/deposit-dialiog.vue";
import Big from "big.js";
import { toFixed } from "@/utils/num-format";
export default {
  name: "WalletIndex",

  components: { WithdrawDialog, DepositDialog },

  computed: {
    ...mapState({
      emailAssets: state => state.emailAssets,
      device: state => state.device
    }),
    totalUSD() {
      const total = this.emailAssets.list.reduce((sum, item) => {
        if (item.amountUSD > 0) {
          return sum.plus(Big(item.amountUSD));
        }
        return sum;
      }, Big("0"));
      return toFixed(total.round(2, 1).toString(), 2);
    }
  },

  data() {
    return {
      isWithdrawDialogShow: false,
      withdrawAssetUuid: "",
      isDepositDialogShow: false,
      depositAssetUuid: "",
      pollKey: null
    };
  },

  mounted() {
    this.$store.dispatch("updateEmailUserAssets").catch(err => {
      console.log(err);
    });

    this.pollKey = setInterval(() => {
      this.$store.dispatch("updateEmailUserAssets").catch(() => {});
    }, 30000);
  },

  beforeUnmount() {
    clearInterval(this.pollKey);
  },

  methods: {
    onWithdrawClick(item) {
      this.isWithdrawDialogShow = true;
      this.withdrawAssetUuid = item.uuid;
    },
    onDepositClick(item) {
      this.isDepositDialogShow = true;
      this.depositAssetUuid = item.uuid;
    },
    usdFormat(num) {
      return toFixed(num, 2);
    },
    goTransactions() {
      this.$router.push("/wallet/transactions");
    },
    goWithdraws () {
      this.$router.push("/wallet/withdraws");
    },
    onSwapClick(item) {
      let pay = "4d8c508b-91c5-375b-92b0-ee702ed2dac5";
      let receive = "43d61dcd-e413-450d-80b8-101d5e903357";
      if (item) {
        if (item.uuid === "43d61dcd-e413-450d-80b8-101d5e903357") {
          [pay, receive] = [receive, pay];
        } else {
          pay = item.uuid;
        }
      }
      localStorage.setItem("swap_assets", `${pay},${receive}`);
      this.$router.push("/wallet/swap");
    }
  }
};
</script>

<style lang="scss" scoped>
.balance {
  border-bottom: 1px solid #ececec;

  .title {
    font-size: 22px;
    font-weight: 600;
  }

  .action {
    text-decoration: none;
  }

  .amount {
    font-size: 24px;
  }
}

.list {
  &-header-title {
    font-size: 20px;
  }

  .asset-item {
    border-bottom: 1px solid #ececec;

    .main-amount {
      font-size: 18px;
    }

    .sub-amount {
      font-size: 14px;
      color: #828282;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.danger-tips {
  font-size: 12px;
  color: var(--el-color-danger);
}
</style> 