<template>
  <el-dialog
    :title="$t('account.multisig.title')"
    top="5vh"
    class="w-max"
    @open="dialogOpen"
  >
    <template #default>
      <h3 class="title">{{ $t("actions.contactService") }}</h3>
      <div class="scroller mt-2">
        <el-scrollbar max-height="60vh">
        <div class="list-contacts">
          <div
            v-for="(item, index) in contactList"
            class="item-list-contacts row-justify-center"
            :class="[index % 3 != 0 ? 'item-list-contacts-right': '']"
            :key="index"
          >
            <div class="row-align-center">
              <el-avatar
                :size="40"
                class="item-list-contacts-avatar"
                :src="item.avatar"
              />
              <div class="col ml-2">
                <div class="item-list-contacts-nickname">{{ item.nickname }}</div>
                <div class="item-list-contacts-mixinId mt-1">
                  {{ item.mixinId }}
                </div>
              </div>
            </div>
            <el-icon color="#F56C6C" class="cursor-pointer" v-if="index != 0" @click="deleteContact(index)"><DeleteFilled /></el-icon>
          </div>
          <div class="item-list-contacts add-contact row-center cursor-pointer" :class="[contactList.length % 3 != 0 ? 'item-list-contacts-right': '']" @click="showAddContact">
            <el-icon class="mr-2" size="18px"><CirclePlus /></el-icon>
            {{ $t("account.multisig.addContact") }}
          </div>
        </div>
        <!-- <div class="row-center mt-6">
          <el-button
            type="primary"
            class="w-1/2"
            @click="showAddContact"
            >{{ $t("account.multisig.addContact") }}</el-button
          >
        </div> -->

        <div class="mt-10 space-y-2">
          <h3 class="title">How to creat a multi-signature account?</h3>
          <h4 class="sub-title">Step1: Add co-signers</h4>
          <div class="tips-content">
            When transferring funds from multi-signature accounts, a valid
            signature of multiple people is required. To ensure the proper use
            of your assets, please add contacts correctly.
          </div>
          <div class="row-center my-2">
            <img
              class="w-2/3"
              src="@/assets/images/account/creat-multi-account-step1.png"
            />
          </div>
          <h4 class="sub-title">Step2: Set up threshold values</h4>
          <div class="tips-content">
            For example, 2/3 multi-signature means that there are 3 people
            who jointly manage the assets, and any two people need to sign the
            transaction to use the assets.
          </div>
          <div class="row-center my-2">
            <img
              class="w-2/3"
              src="@/assets/images/account/creat-multi-account-step2.png"
            />
          </div>
          <h4 class="sub-title">Step3: Your multi-signature account has been created</h4>
          <div class="tips-content">
            MixPay does not provide the asset management function for
            multi-signature accounts. You need to
            <span class="span" @click="goManageAssets"
              >use the multi-signature wallet to manage assets</span
            >
            after the multi-signature accounts are settled.
          </div>
          <div class="row-center my-2">
            <img
              class="w-2/3"
            src="@/assets/images/account/creat-multi-account-step3.png"
            />
          </div>
          <h3 class="title">{{ $t("account.multisig.tips[0]") }}</h3>
          <div class="tips-content">{{ $t("account.multisig.tips[1]") }}</div>
          <div class="tips-content">{{ $t("account.multisig.tips[2]") }}</div>
          <div
            class="span"
            @click="showAboutDialog"
          >
            {{ $t("account.multisig.tips[3]") }}
          </div>
        </div>
      </el-scrollbar>
      <div class="row-center mt-6">
        <el-button type="primary" class="w-1/2" @click="showSetDialog">{{
          $t("account.next")
        }}</el-button>
      </div>
      </div>

      <el-dialog
        v-model="isShowContactDialog"
        :title="$t('account.multisig.addContact')"
        class="w-min"
        append-to-body
      >
        <el-input
          v-model="mixinNumber"
          type="number"
          :placeholder="$t('notification.addBotTip[5]')"
          label="Mixin ID"
          clearable
        />

        <div class="row-center mt-10">
          <el-button
            class="w-1/2"
            type="primary"
            @click="addContact"
            :loading="isSubmitting"
            >{{ $t("actions.confirm") }}</el-button
          >
        </div>
        <h3 class="mt-10 title">{{ $t("notification.tips") }}</h3>
        <div class="tips-content">{{ $t("account.multisig.tips[4]") }}</div>
      </el-dialog>

      <el-dialog
        v-model="isShowSetDialog"
        :title="$t('account.multisig.setingMultisigThreshold')"
        class="w-min"
        append-to-body
        @close="setDialogClose"
      >
        <el-input
          v-model="threshold"
          type="number"
          :placeholder="$t('account.multisig.tips[6]')"
          clearable
        >
          <template #append>/{{ this.contactList.length }}</template>
        </el-input>

        <div class="row-center mt-10">
          <el-button
            class="w-1/2"
            type="primary"
            @click="setMulti"
            :loading="isSubmitting"
            >{{ $t("actions.confirm") }}</el-button
          >
        </div>

        <h3 class="mt-10 title" >
          {{ $t("account.multisig.aboutMultisigThreshold") }}
        </h3>
        <div class="tips-content">{{ $t("account.multisig.tips[5]") }}</div>
        <div class="tips-content">{{ $t("account.multisig.tips[7]") }}</div>
      </el-dialog>

      <about-multi-dialog v-model="isShowAboutDialog"  append-to-body/>

      <manage-assets-dialog v-model="isShowManageDialog" append-to-body />

    </template>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getMixiUserInfo, getMultisigId, bindMultisig } from "@/api/index";
import { ElMessage, ElNotification } from "element-plus";
import AboutMultiDialog from './about-multi-dialog.vue';
import ManageAssetsDialog from './manage-assets-dialog.vue';

export default {
  components: {
    AboutMultiDialog,
    ManageAssetsDialog,
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      device: (state) => state.device,
    }),
  },

  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      contactList: [],
      isShowContactDialog: false,
      isSubmitting: false,
      mixinNumber: "",
      isShowSetDialog: false,
      threshold: '',
      isShowAboutDialog: false,
      isShowManageDialog: false,
    };
  },

  methods: {
    dialogOpen() {
      this.contactList = [];
      this.contactList.push({
        avatar: this.info.avatar || this.userInfo.avatar,
        nickname: this.info.nickname || this.userInfo.nickname,
        mixinId: this.info.mixinId || this.userInfo.mixinId,
        uuid: this.info.uuid || this.userInfo.userUuid,
      });
    },
    showAddContact() {
      this.isShowContactDialog = !this.isShowContactDialog;
    },
    addContact() {
      if (
        this.mixinNumber === (this.info.mixinId || this.userInfo.mixinId) ||
        this.contactList.find((item) => item.mixinId == this.mixinNumber)
      ) {
        this.showAddContact();
        return;
      }
      this.isSubmitting = true;
      getMixiUserInfo(this.mixinNumber)
        .then(({ data: d }) => {
          this.contactList.push({
            avatar:
              d.avatarUrl || require("@/assets/images/avatar-default.svg"),
            nickname: d.name,
            mixinId: d.mixinNumber,
            uuid: d.mixinUuid,
          });
          this.isSubmitting = false;
          this.mixinNumber = "";
          this.showAddContact();
        })
        .catch((err) => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    },
    showSetDialog() {
      if (this.contactList.length <= 1) {
        ElMessage.error("Please add a contact at first.");
        return;
      }
      this.isShowSetDialog = !this.isShowSetDialog;
    },
    deleteContact(index) {
     if (this.contactList.length > 1) {
        this.contactList.splice(index, 1);
      } 
    },
    setMulti() {
      const receivers = [];
      this.contactList.forEach((item) => {
        receivers.push(item.uuid);
      });
      this.isSubmitting = true;    
      getMultisigId(receivers, this.threshold)
        .then(({ data: d }) => {
          bindMultisig(d.multisigId)
            .then(() => {
              this.isSubmitting = false;
              this.isShowSetDialog = false;
              this.threshold = '';
              this.$store.dispatch('updateUserSubAccounts').catch(() => {});
              ElNotification({
                  title: 'Success',
                  message: 'Add Account Success.',
                  type: 'success',
              });
              this.$emit('dialogClose');
            })
            .catch((err) => {
              this.threshold = '';
              this.isSubmitting = false;
              this.isShowSetDialog = false;
              if (err.code == 10047) {
                err.message =
                  "You have successfully bound this multi-signed account.";
              } 
              this.$errHandler(err, 'dialog');
            });
        })
        .catch((err) => {
          this.isSubmitting = false;
          return this.$errHandler(err, 'dialog');
        });
    },
    showAboutDialog() {
      this.isShowAboutDialog = true;
    },
    goManageAssets() {
      this.isShowManageDialog = true;
    },
    setDialogClose() {
      this.threshold = '';
    }
  },
};
</script>

<style lang="scss" scoped>
.scroller {
  // width: 696px;   
  width: 706px;
}
.item-list-contacts {
  height: 70px;
}
.el-input {
  width: 500px;
  height: 50px;    
}
.list-contacts {
  display: flex;
  flex-wrap: wrap;

  .item-list-contacts {
    position: relative;
    box-sizing: border-box;
    width: 220px;
    border-radius: 8px;
    border: 1px solid border-default-color;
    margin-bottom: 18px;
    padding: 0 12px;

    &-nickname {
      color: color-text-base;
    }

    &-mixinId {
      font-size: 12px;  
    }

    &-right {
      margin-left: 18px;
    }
  }

  .add-contact {
    color: color-primary;
    font-weight: 500;
    border: 1px solid color-primary;
  }

  .add-contact:hover {
    background: #F8FAFF;
    font-weight: 600;
  }
}



.title {
  color: color-text-base;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sub-title {
  color: color-text-base;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.tips-content {
  color: color-text-base;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.span {
  color: color-primary;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

</style>