<template>
  <div>
    <h2 class="text-center mt-4">Security Verification</h2>
    <div class="codebox-wrapper mt-8 mx-8">
      <div class="codebox-title">Enter the 6-digit PIN</div>
      <div class="codebox-control mt-4">
        <code-box
          ref="codebox"
          mask
          :value="pin"
          @update:value="onInput"
          auto-focus
        ></code-box>
      </div>
    </div>
    <div class="btn-group mt-12 mx-8">
      <el-button type="primary" @click="onSubmit" :loading="loading">
        Confirm
      </el-button>
    </div>
    <div class="tips-wrapper">
      <p class="mt-8 mx-8">
        <a
          href="https://help.mixpay.me/en/articles/7185569-i-forgot-the-pin-what-should-i-do"
          target="_blank"
          class="text-main-color"
        >
          Lost your 6-digit PIN?
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import CodeBox from "./index.vue";
export default {
  components: {
    CodeBox
  },

  props: {
    pin: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInput(value) {
      this.$emit("update:pin", value);
    },
    onSubmit() {
      this.$emit("submit");
    },
    focus() {
      this.$refs.codebox && this.$refs.codebox.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.codebox-wrapper {
  :deep .codebox-box {
    flex: 0 1 40px;
    width: 40px;
  }
}

.btn-group {
  .el-button {
    width: 100%;
    height: 50px;
  }
}
</style>