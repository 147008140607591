<template>
  <div>
    <el-contaienr>
      <el-header class="header items-center bg-white">
        <app-header />
      </el-header>
      <div class="row-center">
        <div class="wrapper">
          <el-card class="mt-4" v-if="loadStatus === 'success'">
            <div class="px-8 py-8">
              <h3 class="title text-2xl">Invitations</h3>
              <p class="mt-2 text-lg">
                [{{ fullName }}] invites you to register as an administrator.
                <br />Do you accept the invitation?
              </p>
              <p class="mt-2">
                <img
                  src="@/assets/images/permission-invitation.png"
                  style="width:100%"
                />
              </p>
              <div class="btn-group text-right mt-8">
                <el-button
                  type="danger"
                  size="large"
                  :loading="isRejecting"
                  @click="onReject"
                >
                  Reject
                </el-button>
                <el-button
                  type="primary"
                  size="large"
                  :loading="isAccepting"
                  @click="onAccept"
                >
                  Accept
                </el-button>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </el-contaienr>
  </div>
</template>

<script>
import { getRoleInvitationStatus, doActionToRoleInvitation } from "@/api";
import AppHeader from "@/layout/components/AppHeader.vue";
export default {
  name: "InvitationView",

  components: {
    AppHeader
  },

  created() {
    const paramsStr = this.$route.query.params;
    if (paramsStr) {
      try {
        this.params = JSON.parse(window.atob(paramsStr));
        if (this.params.type === "permission") {
          this.fetchInvitationStatus(this.params.recordId)
            .then(data => {
              if (data.isAgree == 0) {
                this.loadStatus = "success";
              } else {
                this.$router.replace({
                  name: "home"
                });
              }
            })
            .catch(err => {
              this.loadStatus = "failed";
              if (err.code == "404") {
                this.$router.replace("/404");
              } else {
                this.$errHandler(err, "dialog");
              }
            });
        }
      } catch (e) {
        //
        this.$router.replace("/");
      }
    } else {
      this.$router.replace("/");
    }
  },

  data() {
    return {
      loadStatus: "pending",
      isRejecting: false,
      isAccepting: false,
      params: {},
      fullName: ""
    };
  },

  methods: {
    fetchInvitationStatus(recordId) {
      return getRoleInvitationStatus(recordId).then(({ data: d }) => {
        this.fullName = d.fullName;
        return Promise.resolve(d);
      });
    },
    onReject() {
      if (this.isRejecting) return;
      this.isRejecting = true;
      doActionToRoleInvitation(this.params.recordId, 0)
        .then(() => {
          this.isRejecting = false;
          this.jump();
        })
        .catch(err => {
          this.isRejecting = false;
          this.$errHandler(err, "dialog");
        });
    },
    onAccept() {
      if (this.isAccepting) return;
      this.isAccepting = true;
      doActionToRoleInvitation(this.params.recordId, 1)
        .then(() => {
          this.isAccepting = false;
          this.jump();
        })
        .catch(err => {
          this.isAccepting = false;
          this.$errHandler(err, "dialog");
        });
    },
    jump() {
      this.$router.replace("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 600px;
  padding-top: 40px;
}

.btn-group {
  .el-button {
    width: 160px;
    height: 50px;
  }

  .el-button+.el-button {
    margin-left: 40px;
  }
}
</style>