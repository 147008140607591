<template>
  <div>
    <body-header />
    <div class="my-7 px-10">
      <div class="filter">
        <div class="filter-row flex flex-wrap">
          <el-select
            v-model="selectLinkId"
            placeholder="Select"
            @change="selectLink"
            v-if="activeIndex === '1'"
          >
            <el-option
              v-for="item in radioList"
              :key="item.value"
              :label="item.lable"
              :value="item.value"
            />
          </el-select>

          <el-date-picker
            v-model="dateValue"
            type="daterange"
            range-separator="To"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            @change="dateSelect"
          />

          <el-button type="warning" @click="clearFilter">Reset</el-button>
          <el-button type="primary" @click="exportBillings" v-if="list.length">
            Export
          </el-button>
        </div>
        <div class="filter-row flex flex-wrap">
          <el-input
            placeholder="Merchant Order ID"
            type="text"
            v-model.trim="orderId"
            clearable
            @keydown.enter="onSearchClick"
          ></el-input>

          <el-button type="warning" @click="onSearchClear">Reset</el-button>
          <el-button type="primary" @click="onSearchClick">Search</el-button>
        </div>
      </div>

      <div v-loading="loading" element-loading-text="Loading...">
        <empty-view
          :class="device === 'mobile' ? 'mt-6' : 'mt-4 h-[28rem]'"
          v-if="page === 1 && list.length <= 0"
        />

        <div v-else>
          <el-table
            :data="list"
            max-height="600"
            size="large"
            class="mt-4 text-[15px] shadow-sm"
            table-layout="auto"
            v-if="activeIndex === '1'"
          >
            <el-table-column label="Payer" min-width="100">
              <template #default="scope">
                <span>{{ subName(scope.row.title) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Quote" min-width="160">
              <template #default="scope">
                <div class="row-align-center">
                  <exp-symbol
                    class="coin-icon flex-shrink-0"
                    :uuid="scope.row.quoteAssetId"
                    :iconUrl="scope.row.quoteIcon"
                  />
                  <span class="mx-2">{{ scope.row.quoteAmount }}</span>
                  <span>{{ scope.row.quoteSymbol }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Receiving" min-width="160">
              <template #default="scope">
                <div class="row-align-center" v-if="scope.row.settlementAmount">
                  <exp-symbol
                    class="coin-icon flex-shrink-0"
                    :uuid="scope.row.settlementAssetId"
                    :iconUrl="scope.row.settlementIcon"
                  />
                  <span class="mx-2">{{ scope.row.settlementAmount }}</span>
                  <span>{{ scope.row.settlementSymbol }}</span>
                </div>
                <div class="row-align-center" v-else>
                  --
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Status" min-width="80">
              <template #default="scope">
                <div class="row-align-center">
                  <span
                    :class="
                      getSettlementStatus(scope.row.transferStatus) &&
                      getSettlementStatus(scope.row.transferStatus).status ===
                        'success'
                        ? 'text-[#62d37e]'
                        : 'text-[#fda527]'
                    "
                  >
                    {{
                      getSettlementStatus(scope.row.transferStatus) &&
                        getSettlementStatus(scope.row.transferStatus).text
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="date" label="Date" width="180" />
            <el-table-column label="Operations" min-width="200">
              <template #default="scope">
                <el-link
                  type="primary"
                  @click="goDetail('deposit', scope.row.traceId)"
                >
                  Details
                </el-link>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            :data="list"
            stripe
            border
            max-height="600"
            size="large"
            class="mt-10 w-min"
            v-else
          >
            <el-table-column label="Amount" width="200">
              <template #default="scope">
                <div class="row-align-center">
                  <span class="mr-2">{{ scope.row.amount }}</span>
                  <span>{{ scope.row.symbol }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Status" width="180">
              <template #default="scope">
                <div class="row-align-center">
                  <span
                    :class="
                      ['pending', 'checking', 'processing'].includes(
                        scope.row.status
                      )
                        ? 'text-[#F7A349]'
                        : ['done', 'success'].includes(scope.row.status)
                        ? 'text-[#0EC9B1]'
                        : 'text-[#FF4466]'
                    "
                    >{{ $t(`status.${scope.row.status}`) }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="Date" width="180" />
            <el-table-column label="Operations" width="160">
              <template #default="scope">
                <el-link
                  type="primary"
                  @click="goDetail('withdraw', scope.row.traceId)"
                  >Details</el-link
                >
                <!-- <el-button
                link
                type="primary"
                size="small"
                @click="goDetail('withdraw', scope.row.traceId)"
                >Details</el-button
              > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt-4"
            layout="prev, pager, next"
            background
            :current-page="page"
            :page-count="pageCount"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <receive-detail-dailog
      v-model="isShowReceiveDetail"
      :traceId="receiveTraceId"
    />

    <withdraw-detail-dialog
      v-model="isShowWithdrawDetail"
      :traceId="withdrawTraceId"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getReceiveRecords,
  getWithdrawRecords,
  updateUserReceiveList,
  exportBillings
} from "@/api/index.js";
import { formatTime } from "@/utils/date-format.js";
import { getPageCount } from "@/utils/num-format";
import moment from "moment";
import ReceiveDetailDailog from "./components/receive-detail-dialog.vue";
import WithdrawDetailDialog from "./components/withdraw-detail-dialog.vue";

export default {
  components: {
    ReceiveDetailDailog,
    WithdrawDetailDialog
  },
  computed: mapState({
    userInfo: state => state.userInfo,
    device: state => state.device,
    receiveRecordsFirstPageList: state => state.receiveRecordsFirstPageList
  }),

  data() {
    return {
      activeIndex: "1",
      page: 1,
      startTime: "",
      endTime: "",
      startTempTime: "",
      dateValue: [
        new Date(
          moment()
            .startOf("day")
            .subtract("1", "year")
        ),
        new Date(moment().endOf("day") - 999)
      ],
      list: [],
      loading: false,
      selectDate: [],
      radioList: [],
      selectLinkId: "",
      receiveTraceId: "",
      isShowReceiveDetail: false,
      withdrawTraceId: "",
      isShowWithdrawDetail: false,
      pageCount: 1,

      isExporting: false,

      orderId: ""
    };
  },

  mounted() {
    this.init();
    this.loadReceiveList();
    this.loadRadioList();
  },

  methods: {
    handleSelect(index) {
      this.activeIndex = index;
      this.pageChange(1);
    },

    init() {
      this.startTime = +this.dateValue[0];
      this.endTime = +this.dateValue[1];
      this.startTempTime = this.startTime;
      this.endTempTime = this.endTime;
    },

    loadReceiveList() {
      if (
        this.receiveRecordsFirstPageList &&
        this.receiveRecordsFirstPageList.list
      ) {
        this.list = this.receiveRecordsFirstPageList.list;
        this.pageCount = this.receiveRecordsFirstPageList.pageCount;
      }
      if (this.receiveRecordsFirstPageList.status === "pending") {
        this.loading = true;
      }

      let startTime = this.startTime / 1000;
      let endTime = this.endTime / 1000 + 24 * 60 * 60 - 1;

      this.$store
        .dispatch("updateReceiveRecordsFirstPageList", {
          startTime: startTime,
          endTime: endTime
        })
        .then(() => {
          this.loading = false;
          this.pageCount = this.receiveRecordsFirstPageList.pageCount;
          this.list = this.receiveRecordsFirstPageList.list;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadMoreReceiveList() {
      let startTime = this.startTime / 1000;
      let endTime = this.endTime / 1000 + 24 * 60 * 60 - 1;
      this.loading = true;
      getReceiveRecords(
        startTime,
        endTime,
        this.page,
        10,
        this.selectLinkId,
        this.orderId
      )
        .then(data => {
          const d = data.data;
          const arr = [];
          if (Array.isArray(d)) {
            d.forEach(item => {
              const obj = {
                type: "deposit",
                quoteAmount: item.quoteAmount,
                quoteSymbol: item.quoteSymbol,
                quoteAssetId: item.quoteAssetId,
                quoteIcon: item.quoteIcon,
                settlementAmount: item.settlementAmount,
                settlementSymbol: item.settlementSymbol,
                settlementAssetId: item.settlementAssetId,
                settlementIcon: item.settlementIcon,
                date: formatTime(item.createdAt * 1000),
                traceId: item.traceId,
                status: item.status,
                transferStatus: item.transferStatus
                // settlementStatus: this.getSettlementStatus(item.transferStatus),
              };
              obj.title = this.getTitle(obj.type, item.payerName);
              Object.freeze(obj);
              arr.push(obj);
            });
          }
          this.list = arr;
          if (data.meta && data.meta.total) {
            this.pageCount = getPageCount(data.meta.total, 10);
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          return Promise.reject(err);
        });
    },

    loadWithdrawalList() {
      let startTime = this.startTime / 1000;
      let endTime = Math.floor(this.endTime / 1000) + 24 * 60 * 60 - 1;
      this.loading = true;
      getWithdrawRecords(startTime, endTime, this.page, 20)
        .then(data => {
          const d = data.data;
          const arr = [];
          if (Array.isArray(d)) {
            d.forEach(item => {
              const obj = {
                type: "withdraw",
                amount: item.amount,
                symbol: item.symbol,
                date: formatTime(item.createdAt * 1000),
                traceId: item.traceId,
                status: item.status,
                settlementStatus: null
              };
              obj.title = this.getTitle(obj.type, item.payerName);
              Object.freeze(obj);
              arr.push(obj);
            });
          }
          this.list = arr;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          return Promise.reject(err);
        });
    },

    getTitle(type = "withdraw", payerName = "") {
      let title = "";
      switch (type) {
        case "deposit":
          title = payerName;
          break;
        case "snapshot_refund":
          title = this.$t("status.refund");
          break;
        default:
          title = this.$t("status.withdraw");
          break;
      }
      return title;
    },
    getSettlementStatus(status) {
      let obj;
      switch (status) {
        case "done":
        case "none":
          obj = {
            status: "success",
            text: this.$t("history.settlementSuccess")
          };
          break;
        case "pending":
        case "unpaid":
          obj = {
            status: "pending",
            text: this.$t("history.settlementProcessing")
          };
          break;
        default:
          obj = {
            status: "",
            text: ""
          };
      }
      return obj;
    },

    loadRadioList() {
      return updateUserReceiveList(1, 100, 1)
        .then(data => {
          if (Array.isArray(data.data)) {
            this.radioList = data.data.map(item => {
              return {
                value: "" + item.id,
                lable: item.linkName
              };
            });

            this.radioList.unshift({
              value: "all",
              lable: this.$t("history.all")
            });
          } else {
            this.radioList = [
              {
                value: "all",
                lable: this.$t("history.all")
              }
            ];
          }
        })
        .catch(err => Promise.reject(err));
    },

    pageChange(val) {
      this.page = val;
      if (this.activeIndex === "1") {
        this.loadMoreReceiveList();
      } else {
        this.loadWithdrawalList();
      }
    },

    dateSelect() {
      let startTime = moment(this.dateValue[0]);
      let endTime = moment(this.dateValue[1]).add(1, "day") - 1000;
      let maxEndTime = moment(this.dateValue[0]).add(1, "year") - 1000;
      if (endTime > maxEndTime) {
        endTime = maxEndTime;
      }
      this.dateValue[1] = new Date(endTime);
      this.startTime = +startTime;
      this.endTime = +endTime;
      this.pageChange(1);
    },

    selectLink(val) {
      if (val === "all") {
        val = "";
      }
      this.selectLinkId = val;
      this.pageChange(1);
    },

    clearFilter() {
      this.selectLinkId = "";
      this.dateValue = [
        new Date(
          moment()
            .startOf("day")
            .subtract("1", "year")
        ),
        new Date(moment().endOf("day") - 999)
      ];
      this.dateValue[1].setHours(23);
      this.dateValue[1].setMinutes(59);
      this.dateValue[1].setSeconds(59);
      this.startTime = this.dateValue[0].getTime();
      this.endTime = Date.parse(this.dateValue[1]);
      this.orderId = "";
      this.pageChange(1);
    },

    exportBillings() {
      if (this.isExporting) return;
      this.isExporting = true;
      exportBillings(
        this.startTime / 1000,
        this.endTime / 1000,
        this.selectLinkId,
        this.orderId
      )
        .then(() => {
          this.isExporting = false;
        })
        .catch(err => {
          this.isExporting = false;
          return Promise.reject(err);
        });
    },

    goDetail(type, traceId) {
      if (type === "deposit") {
        this.receiveTraceId = traceId;
        this.isShowReceiveDetail = true;
      } else {
        this.withdrawTraceId = traceId;
        this.isShowWithdrawDetail = true;
      }
    },
    subName(name) {
      if (name && name.length > 12) {
        return name.substring(0, 12) + "...";
      }
      return name;
    },

    onSearchClear() {
      this.orderId = "";
      this.pageChange(1);
    },
    onSearchClick() {
      this.pageChange(1);
    }
  }
};
</script>


<style lang="scss" scoped>
.filter-row {
  .el-select {
    margin-right: 10px;
    margin-bottom: 20px;
    max-width: 300px;
    width: 100%;
  }

  .el-input {
    max-width: 300px;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  :deep {
    .el-input {
      max-width: 300px;
      width: 100%;
      --el-input-border-radius: 4px;
    }

    .el-input__wrapper {
      font-size: 14px;
      font-family: "Inter";
    }

    .el-date-editor.el-input__wrapper {
      margin-right: 10px;
      margin-bottom: 20px;
      max-width: 300px;
      box-sizing: border-box;
      --el-date-editor-width: 100%;
    }

    .el-button {
      margin-left: 0;
      margin-right: 10px;
      margin-bottom: 20px;
      height: 32px;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>