import store from "@/store";

const { body } = document;
// const WIDTH = 1024; // refer to Bootstrap's responsive design
const WIDTH = 960;

export default {
  mounted() {
    window.addEventListener("resize", this.$_resizeHandler);
    this.$_resizeHandler();
  },

  unmounted() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },

  methods: {
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width < WIDTH;
    },

    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();
        store.dispatch("toggleDevice", isMobile ? "mobile" : "desktop");
      }
    },
  },
};
