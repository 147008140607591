import { createApp } from "vue";
import Vuex from "vuex";
import { formatTime } from "@/utils/date-format.js";
import { ethers } from "ethers";
import Big from "big.js";
import {
  getUserInfo,
  getQuoteAssets,
  getDefaultSettings,
  getSettlementAssets,
  getUserSubAccount,
  getUserAssets,
  getUserFlows,
  getPrice,
  getTodayStatistic,
  getBillingList,
  updateUserReceiveList,
  getReceiveRecords,
  getEmailUserAssets,
  getAddressList,
  getRoleInvitaionList,
  getSwapAssetsList,
} from "@/api/index.js";

import { getMvmAssets, getContractAssetId } from "@/api/mixin";
import { getPageCount, noSci, toFixed } from "@/utils/num-format";
import config from "@/config";

const promiseMap = {};

createApp().use(Vuex);

const store = new Vuex.Store({
  state: Object.assign(
    {
      navLang: "en",
      clientId: "",
      serviceUuid: "",
      apiURL: "",
      appURL: "",
      device: "desktop",
      isAsideMenuOpen: false,
      settings: {
        loadStatus: "pending",

        quoteStatus: "pending",
        quoteAssets: [],
        paymentStatus: "pending",
        paymentAssets: [],
        settlementStatus: "pending",
        settlementAssets: [],

        assets: [],
        quotes: [],
        networkMap: {},
      },
      dashbordFlows: {
        list: [],
        loadStatus: "pending",
      },
      showAddPaymentLink: false,
      billingFirstPageList: {
        list: [],
        pageCount: 1,
        status: "pending",
      },
      qrcodeFirstPageList: {
        list: [],
        pageCount: 1,
        status: "pending",
      },
      receiveRecordsFirstPageList: {
        list: [],
        pageCount: 1,
        status: "pending",
      },
      swapAssets: [],
    },
    getDefault()
  ),
  mutations: {
    loadFromLocal(state) {
      const userInfoStr = window.localStorage.getItem("userInfo");
      if (userInfoStr) {
        try {
          const userInfo = JSON.parse(userInfoStr);
          if (userInfo.token) {
            Object.assign(state.userInfo, userInfo);
          }
        } catch (e) {
          //
        }
      }
      if (window.localStorage.getItem("walletChainId")) {
        Object.assign(
          state.walletChainId,
          window.localStorage.getItem("walletChainId")
        );
      }
      if (window.localStorage.getItem("walletAccount")) {
        Object.assign(
          state.walletAccount,
          window.localStorage.getItem("walletAccount")
        );
      }
      if (
        window.localStorage.getItem("select-multisigId") &&
        window.localStorage.getItem("select-multisigId-type")
      ) {
        Object.assign(state.multisigAccount, {
          id: window.localStorage.getItem("select-multisigId"),
          type: window.localStorage.getItem("select-multisigId-type"),
        });
      }
    },
    clearLocal(state) {
      Object.assign(state, getDefault());
      window.localStorage.clear();
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    toggleAsideMenu: (state, isAsideMenuOpen) => {
      state.isAsideMenuOpen = isAsideMenuOpen;
    },
    setUserInfo(state, obj) {
      Object.assign(state.userInfo, obj);
      window.localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    setQuoteAssets(state, quoteAssets) {
      state.settings.quoteAssets = quoteAssets;
    },
    setSettings(state, settings = {}) {
      Object.assign(state.settings, settings);
    },
    setSettingsAssets(state, payload) {
      switch (payload.type) {
        case "quote":
          state.settings.quoteAssets = payload.data;
          break;
        case "payment":
          state.settings.paymentAssets = payload.data;
          break;
        case "settlement":
          state.settings.settlementAssets = payload.data;
          break;
        default:
      }
    },
    setSettingsStatus(state, payload) {
      switch (payload.type) {
        case "quote":
          state.settings.quoteStatus = payload.status;
          break;
        case "payment":
          state.settings.paymentStatus = payload.status;
          break;
        case "settlement":
          state.settings.settlementStatus = payload.status;
          break;
      }
    },
    setUserSubAccount(state, userSubAccount) {
      Object.assign(state.userSubAccount, userSubAccount);
    },
    setMultisigAccount(state, multisigAccount) {
      state.multisigAccount = multisigAccount;
      if (multisigAccount.id && multisigAccount.type) {
        window.localStorage.setItem("select-multisigId", multisigAccount.id);
        window.localStorage.setItem(
          "select-multisigId-type",
          multisigAccount.type
        );
      } else {
        window.localStorage.removeItem("select-multisigId");
        window.localStorage.removeItem("select-multisigId-type");
      }
    },
    setUserAssets(state, userAssets) {
      Object.assign(state.userAssets, userAssets);
    },
    setEmailUserAssets(state, assets) {
      Object.assign(state.emailAssets, assets);
    },
    setEmailAddressMap(state, addressObj) {
      if (!state.emailAssetAddressMap[addressObj.id]) {
        state.emailAssetAddressMap[addressObj.id] = {};
      }
      if (addressObj.loadStatus) {
        state.emailAssetAddressMap[addressObj.id].loadStatus =
          addressObj.loadStatus;
      }
      if (addressObj.list) {
        state.emailAssetAddressMap[addressObj.id].list = addressObj.list;
      }
    },
    setWalletAccount(state, account) {
      state.walletAccount = account;
      window.localStorage.setItem("walletAccount", account || "");
    },
    setWalletLabel(state, label) {
      state.walletLabel = label;
    },
    setWalletChainId(state, chainId) {
      state.walletChainId = chainId;
      window.localStorage.setItem("walletChainId", chainId || "");
    },
    setDashbordFlows(state, flows) {
      state.dashbordFlows = flows;
    },
    setMvmAssets(state, assets) {
      state.mvmAssets = assets;
    },
    setAssetsPrices(state, assetsPrices) {
      state.assetsPrices = assetsPrices;
    },
    setUserTodayStatistic(state, userTodayStatistic) {
      state.userTodayStatistic = userTodayStatistic;
    },
    setShowAddPaymentLink(state, showAddPaymentLink) {
      state.showAddPaymentLink = showAddPaymentLink;
    },
    setBillingFirstPageList(state, billingFirstPageList) {
      state.billingFirstPageList = billingFirstPageList;
    },
    setQrcodeFirstPageList(state, qrcodeFirstPageList) {
      state.qrcodeFirstPageList = qrcodeFirstPageList;
    },
    setReceiveRecordsFirstPageList(state, receiveRecordsFirstPageList) {
      state.receiveRecordsFirstPageList = receiveRecordsFirstPageList;
    },
    setConfig(state, config = {}) {
      Object.assign(state, config);
    },
    setRoleInvitation(state, obj) {
      Object.assign(state.roleInvitation, obj);
    },

    setSwapAssets(state, assets) {
      state.swapAssets = assets;
    },
  },
  actions: {
    toggleDevice({ commit }, device) {
      commit("TOGGLE_DEVICE", device);
    },
    updateUserInfo({ commit }) {
      if (!promiseMap["updateUserInfo"]) {
        promiseMap["updateUserInfo"] = getUserInfo(false)
          .then(({ data: d }) => {
            commit("setUserInfo", {
              userUuid: d.userId,
              mixinId: d.mixinNumber,
              nickname: d.nickname,
              avatar:
                d.avatarUrl || require("@/assets/images/avatar-default.svg"),
              name: d.name,
              nextChangeNameAt: d.nextChangeNameAt,
              receiveCurrency: Array.isArray(d.receiveFiatCurrency)
                ? d.receiveFiatCurrency
                : [],
              assetUuid: (d.setting && d.setting.settlementAssetId) || "",
              valuationUuid: (d.setting && d.setting.quoteAssetId) || "",
              settlementMethod:
                (d.setting && d.setting.settlementMethod) || "mixin",
              remembrancers: d.remembrancers,
              hasPrivateKey: d.hasPrivateKey,
              isInWhiteList: d.isInWhiteList,
              isReadNewlyAgreement: d.isReadNewlyAgreement,
              isSafeRegistered: d.isSafeRegistered,
              type: d.type,
              hasPin: d.hasPin,
              role: d.role,
              collaborators: Array.isArray(d.collaborators)
                ? d.collaborators
                : [],
            });
            promiseMap["updateUserInfo"] = null;
          })
          .catch((err) => {
            promiseMap["updateUserInfo"] = null;
            return Promise.reject(err);
          });
      }
      return promiseMap["updateUserInfo"];
    },
    updateQuoteAssets({ commit, state }, payeeId) {
      return getQuoteAssets(payeeId || state.userInfo.userUuid).then(
        ({ data: d }) => {
          let assets = [];
          if (Array.isArray(d)) {
            assets = d.map((item) => ({
              uuid: item.assetId,
              symbol: item.symbol,
              icon: item.iconUrl || require("@/assets/images/fail.png"),
              isAsset: !!+item.isAsset,
              precision: item.decimalDigit,
            }));
          }
          commit("setQuoteAssets", assets);
        }
      );
    },
    updateSettings({ commit }) {
      if (promiseMap["updateSettings"]) {
        return promiseMap["updateSettings"];
      }
      promiseMap["updateSettings"] = getDefaultSettings()
        .then(({ data: d }) => {
          const obj = {
            loadStatus: "success",
            assets: [],
            quotes: [],
            networkMap: {},
          };
          if (Array.isArray(d.assets)) {
            obj.assets = d.assets.map((item) => {
              return {
                // id: item.id,
                uuid: item.assetId,
                name: item.name,
                symbol: item.symbol,
                icon: item.iconUrl,
                contractAddress: item.contractAddress,
                isAsset: !!+item.isAsset,

                chainUuid: (item.chainAsset && item.chainAsset.id) || null,
                chainIcon: (item.chainAsset && item.chainAsset.iconUrl) || null,
                chainSymbol:
                  (item.chainAsset && item.chainAsset.symbol) || null,
                chainFee: (item.chainAsset && item.chainAsset.minerFees) || 0,
                minThresholdAmount:
                  (item.chainAsset && item.chainAsset.minThresholdAmount) || 0,
                maxThreshold:
                  (item.chainAsset && item.chainAsset.maxConfirmations) || 0,
                minThreshold:
                  (item.chainAsset && item.chainAsset.minConfirmations) || 0,
                blockSecond:
                  (item.chainAsset && item.chainAsset.confirmationSeconds) || 0,

                withdrawMin: item.withdrawMinAmount,
                withdrawMax: item.withdrawMaxAmount,
                withdrawWay: item.withdrawWay,
                fee: item.withdrawFeeRate,

                supportChain: !!+item.isChain, //支持公链支付
                network: item.network, // 公链名称
                scheme: item.scheme, // 通用协议
                supportWalletConnect: !!+item.supportedWalletConnect,
              };
            });
          }
          if (Array.isArray(d.quotes)) {
            obj.quotes = d.quotes.map((item) => {
              return {
                uuid: item.assetId,
                symbol: item.symbol,
                icon: item.iconUrl || require("@/assets/images/fail.png"),
                isAsset: !!+item.isAsset,
                precision: item.decimalDigit,
                chainUuid: (item.chainAsset && item.chainAsset.id) || null,
                chainIcon: (item.chainAsset && item.chainAsset.iconUrl) || null,
                chainSymbol:
                  (item.chainAsset && item.chainAsset.symbol) || null,
              };
            });
          }
          Object.assign(obj.networkMap, d.assetVersion);
          commit("setSettings", obj);
          promiseMap["updateSettings"] = null;
        })
        .catch((err) => {
          commit("setSettings", { loadStatus: "fail" });
          promiseMap["updateSettings"] = null;
          return Promise.reject(err);
        });
      return promiseMap["updateSettings"];
    },
    updateSettlementAssets(
      { commit, state },
      { payeeId, quoteAssetId, quoteAmount }
    ) {
      if (promiseMap["updateSettlementAssets"]) {
        return promiseMap["updateSettlementAssets"];
      }
      promiseMap["updateSettlementAssets"] = getSettlementAssets(
        payeeId || state.userInfo.userUuid,
        quoteAssetId,
        quoteAmount
      )
        .then(({ data: d }) => {
          promiseMap["updateSettlementAssets"] = null;
          commit("setSettingsStatus", {
            type: "settlement",
            status: "success", 
          });
          let assets = [];
          if (Array.isArray(d)) {
            assets = d.map((item) => ({
              uuid: item.assetId,
              name: item.name,
              symbol: item.symbol,
              icon: item.iconUrl,
              chainIcon: (item.chainAsset && item.chainAsset.iconUrl) || null,
              chainSymbol: (item.chainAsset && item.chainAsset.symbol) || null,
              isAsset: !!+item.isAsset,
              available: !!+item.isAvailable,
              maxQuoteAmount: item.maxQuoteAmount,
              minQuoteAmount: item.minQuoteAmount,
              quoteSymbol: item.quoteSymbol,
            }));
          }
          commit("setSettingsAssets", { type: "settlement", data: assets });
        })
        .catch((err) => {
          promiseMap["updateSettlementAssets"] = null;
          commit("setSettingsStatus", { type: "settlement", status: "failed" });
          return Promise.reject(err);
        });
      return promiseMap["updateSettlementAssets"];
    },
    updateUserSubAccounts({ commit }) {
      return getUserSubAccount(true).then(({ data: d }) => {
        const listRobot = [];
        const listMultisig = [];
        const obj = {
          list: [],
          robotSize: 0,
          multisigSize: 0,
          loadStatus: "pending",
        };
        if (Array.isArray(d)) {
          d.forEach((item) => {
            listMultisig.push({
              multisigId: item.multisigId,
              type: item.type,
              avatarUrl:
                item.avatarUrl || require("@/assets/images/avatar-default.svg"),
              name: item.name,
              groupCount: item.groupCount >= 5 ? item.groupCount - 5 : 0,
            });
          });
        }
        obj.list = listRobot.concat(listMultisig);
        obj.robotSize = listRobot.length;
        obj.multisigSize = listMultisig.length;
        obj.loadStatus = "success";
        commit("setUserSubAccount", obj);
      });
    },
    updateUserAssets({ commit, state }) {
      return getUserAssets(state.userInfo.token)
        .then(({ data: d }) => {
          const obj = {
            loadStatus: "success",
            list: [],
          };
          if (Array.isArray(d)) {
            obj.list = d.map((item) => {
              return {
                uuid: item.assetId,
                amount: item.amount || 0,
                amountUSD: item.amountUsd || 0,
                icon: item.icon,
                symbol: item.symbol,
                name: item.name,
                isAsset: item.isAsset,
                isOn: item.isOn, // 是否可收款
              };
            });
          }
          obj.list.sort(
            (itemA, itemB) => Number(itemB.amountUSD) - Number(itemA.amountUSD)
          );
          commit("setUserAssets", obj);
        })
        .catch((err) => {
          commit("setUserAssets", {
            loadStatus: "failed",
          });
          return Promise.reject(err);
        });
    },
    updateDashbordFlows({ commit }) {
      return getUserFlows()
        .then((data) => {
          const d = data.data;
          const arr = [];
          if (Array.isArray(d)) {
            d.forEach((item) => {
              const obj = {
                type: "deposit",
                quoteAmount: item.quoteAmount,
                quoteSymbol: item.quoteSymbol,
                quoteAssetId: item.quoteAssetId,
                quoteIcon: item.quoteIcon,
                settlementAmount: item.settlementAmount,
                settlementSymbol: item.settlementSymbol,
                settlementAssetId: item.settlementAssetId,
                settlementIcon: item.settlementIcon,
                date: formatTime(item.createdAt * 1000),
                traceId: item.traceId,
                status: item.status,
                transferStatus: item.transferStatus,
                payerName: item.payerName,
              };
              Object.freeze(obj);
              arr.push(obj);
            });
          }
          commit("setDashbordFlows", { list: arr, loadStatus: "success" });
        })
        .catch((err) => {
          commit("setDashbordFlows", {
            loadStatus: "failed",
          });
          return Promise.reject(err);
        });
    },

    updateMvmAssets({ commit }, assdress) {
      const obj = {
        list: [],
        loadStatus: "pending",
      };
      getMvmAssets("eth_get_balance", assdress)
        .then((data) => {
          if (data.data && data.data.result) {
            obj.list.push({
              uuid: "43d61dcd-e413-450d-80b8-101d5e903357",
              symbol: "ETH",
              name: "Ether",
              balance: ethers.utils.formatEther(data.data.result),
              isCanAdd: false,
            });
          }
          getMvmAssets("tokenlist", assdress)
            .then((data) => {
              if (
                data.data &&
                data.data.result &&
                Array.isArray(data.data.result)
              ) {
                var count = data.data.result.length;
                var sum = 1;
                data.data.result.forEach((item) => {
                  getContractAssetId(item.contractAddress).then((data) => {
                    if (data.data && data.data.asset_id) {
                      obj.list.push({
                        // uuid: formatAssetId(data.data.asset_id.substring(2, data.data.asset_id.length)),
                        uuid: data.data.asset_id,
                        symbol: item.symbol,
                        name: item.name,
                        balance: Big(item.balance)
                          .div(Big(10).pow(Number(item.decimals)))
                          .round(8, 1)
                          .toString(),
                        isCanAdd: true,
                      });
                      if (sum < count) {
                        sum++;
                      } else {
                        obj.loadStatus = "success";
                        commit("setMvmAssets", obj);
                      }
                    }
                  });
                });
              }
            })
            .catch((err) => {
              commit("setMvmAssets", {
                loadStatus: "failed",
              });
              return Promise.reject(err);
            });
        })
        .catch((err) => {
          commit("setMvmAssets", {
            loadStatus: "failed",
          });
          return Promise.reject(err);
        });
    },
    getAssetPrice({ commit }) {
      return getPrice()
        .then((data) => {
          if (data && data.data && Array.isArray(data.data)) {
            const list = [];
            data.data.forEach((item) => {
              list.push({
                uuid: item.assetId,
                name: item.name,
                priceBtc: item.priceBtc,
                priceUsd: item.priceUsd,
                symbol: item.symbol,
              });
            });
            commit("setAssetsPrices", list);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateTodayStatistic({ commit }) {
      return getTodayStatistic()
        .then((data) => {
          if (data && data.data) {
            const obj = {
              activeUserCount: data.data.activeUserCount || 0,
              transactionCount: data.data.transactionCount || 0,
              transactionValue: toFixed(
                Math.floor((data.data.transactionValue || 0) * 100) / 100,
                2
              ),
            };
            commit("setUserTodayStatistic", obj);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateBillingFirstPageList(
      { commit, state },
      { filter, startTime, endTime }
    ) {
      return getBillingList(1, 10, filter || "", startTime, endTime)
        .then((data) => {
          const obj = {
            list: [],
            pageCount: 1,
            status: "pending",
          };
          if (data && data.data && Array.isArray(data.data)) {
            data.data.forEach((item) => {
              obj.list.push({
                id: item.id,
                url: `${state.appURL}/code/${item.traceId}`,
                orderStatus: item.orderStatus,
                quoteAmount: item.quoteAmount,
                quoteAssetId: item.quoteAssetId,
                quoteIcon: item.quoteIcon,
                quoteSymbol: item.quoteSymbol,
                remark: item.remark,
                settlementAssetId: item.settlementAssetId,
                settlementIcon: item.settlementIcon,
                settlementSymbol: item.settlementSymbol,
                traceId: item.traceId,
                expiredAt: formatTime(
                  item.expiredTimestamp * 1000,
                  "YYYY-MM-DD HH:mm"
                ),
                createdAt: formatTime(
                  item.createdAt * 1000,
                  "YYYY-MM-DD HH:mm"
                ),
                name: item.name,
                email: item.email,
                hasSendEmail: item.hasSendEmail,
              });
            });
          }
          if (data && data.meta && data.meta.total) {
            obj.pageCount = getPageCount(data.meta.total, 10);
          }
          obj.status = "success";
          commit("setBillingFirstPageList", obj);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateQrcodeFirstPageList({ commit }) {
      return updateUserReceiveList(1, 10)
        .then((data) => {
          const obj = {
            list: [],
            pageCount: 1,
            status: "pending",
          };
          if (data && data.data && Array.isArray(data.data)) {
            data.data.forEach((item) => {
              obj.list.push({
                id: item.id,
                name: item.linkName || "",
                amount: item.quoteAmount || "",
                quoteAsset: item.quoteSymbol,
                quoteIcon: item.quoteIcon || "",
                receiveAsset: item.settlementSymbol,
                receiveAssetUuid: item.settlementAssetId,
                valuationUuid: item.quoteAssetId,
                settlementIcon: item.settlementIcon || "",
                url: item.url || "",
                avatar: item.avatarUrl,
                note: item.note || "",
                createdAt: formatTime(item.createdAt * 1000),
              });
            });
          }
          if (data && data.meta && data.meta.total) {
            obj.pageCount = getPageCount(data.meta.total, 10);
          }
          obj.status = "success";
          commit("setQrcodeFirstPageList", obj);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateReceiveRecordsFirstPageList({ commit }, { startTime, endTime }) {
      return getReceiveRecords(startTime, endTime, 1, 10)
        .then((data) => {
          const obj = {
            list: [],
            pageCount: 1,
            status: "pending",
          };
          const d = data.data;
          if (Array.isArray(d)) {
            d.forEach((item) => {
              obj.list.push({
                type: "deposit",
                quoteAmount: item.quoteAmount,
                quoteSymbol: item.quoteSymbol,
                quoteAssetId: item.quoteAssetId,
                quoteIcon: item.quoteIcon,
                settlementAmount: item.settlementAmount,
                settlementSymbol: item.settlementSymbol,
                settlementAssetId: item.settlementAssetId,
                settlementIcon: item.settlementIcon,
                date: formatTime(item.createdAt * 1000),
                traceId: item.traceId,
                status: item.status,
                transferStatus: item.transferStatus,
                title: item.payerName,
              });
            });
          }
          if (data.meta && data.meta.total) {
            obj.pageCount = getPageCount(data.meta.total, 10);
          }
          obj.status = "success";
          commit("setReceiveRecordsFirstPageList", obj);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    updateEmailUserAssets({ commit }) {
      return getEmailUserAssets()
        .then((data) => {
          const d = data.data;
          const obj = {
            loadStatus: "success",
            list: [],
          };
          if (Array.isArray(d)) {
            obj.list = d.map((item) => {
              return {
                uuid: item.assetId,
                amount: item.balance > 0 ? noSci(item.balance) : "0.0",
                amountUSD: item.amountUsd > 0 ? noSci(item.amountUsd) : "0.0",
                icon: item.iconUrl,
                symbol: item.symbol,
                sort: item.sort,
                isOn: item.isOn, // 是否可收款
              };
            });
          }
          obj.list.sort(
            (itemA, itemB) => Number(itemB.sort) - Number(itemA.sort)
          );
          obj.list.sort(
            (itemA, itemB) => Number(itemB.amountUSD) - Number(itemA.amountUSD)
          );
          commit("setEmailUserAssets", obj);
        })
        .catch((err) => {
          commit("setEmailUserAssets", { loadStatus: "failed" });
          return Promise.reject(err);
        });
    },
    updateEmailAddressMap({ commit, state }, assetId) {
      if (
        !state.emailAssetAddressMap[assetId] ||
        !state.emailAssetAddressMap[assetId].list.length
      ) {
        commit("setEmailAddressMap", {
          id: assetId,
          loadStatus: "loading",
        });
      }
      return getAddressList(assetId).then((data) => {
        const d = data.data;
        commit("setEmailAddressMap", {
          id: assetId,
          list: Array.isArray(d) ? d : [],
          loadStatus: "success",
        });
      });
    },

    updateRoleInvitation({ commit, state }) {
      if (state.roleInvitation.loadStatus !== "success") {
        commit("setRoleInvitation", { loadStatus: "loading" });
      }
      return getRoleInvitaionList()
        .then(({ data: d }) => {
          const obj = {
            loadStatus: "success",
            list: [],
          };
          if (Array.isArray(d)) {
            d.forEach((item) => {
              obj.list.push({
                id: item.id,
                nickname: item.nickname,
                isAgree: item.isAgree,
                avatarUrl:
                  item.avatarUrl ||
                  require("@/assets/images/avatar-default.svg"),
              });
            });
          }
          commit("setRoleInvitation", obj);
        })
        .catch((err) => {
          commit("setRoleInvitation", { loadStatus: "failed", list: [] });
          return Promise.reject(err);
        });
    },

    updateSwapAssets({ commit }) {
      return getSwapAssetsList().then(({ data: d }) => {
        const assets = [];
        if (Array.isArray(d)) {
          d.forEach((item) => {
            assets.push({
              uuid: item.mixinId,
              symbol: item.symbol,
              name: item.name,
              icon: item.icon || "",
              isMirrorAsset: !!+item.isMirrorAsset,
              isTradeToFundPoolOnly: !!+item.isTradeToFundPoolOnly,
            });
          });
        }
        commit("setSwapAssets", assets);
      });
    },
  },
  getters: {
    device: (state) => state.device,
    assetMap: (state) => {
      const map = {};
      state.settings.assets.forEach((item) => {
        map[item.uuid] = item;
      });
      return map;
    },
    userAssetsMap: state => {
      const map = {};
      state.emailAssets.list.forEach(item => {
        map[item.uuid] = item;
      });
      return map;
    },
    paymentAssetMap: (state) => {
      const map = {};
      state.settings.paymentAssets.forEach((item) => {
        map[item.uuid] = item;
      });
      return map;
    },
    quoteMap: (state) => {
      const map = {};
      state.settings.quotes.forEach((item) => {
        map[item.uuid] = item;
      });
      return map;
    },
    mvmAssetsMap: (state) => {
      const map = {};
      state.mvmAssets.list.forEach((item) => {
        map[item.uuid] = item;
      });
      return map;
    },
    assetsPricesMap: (state) => {
      const map = {};
      state.assetsPrices.forEach((item) => {
        map[item.uuid] = item;
      });
      return map;
    },
  },
});

store.commit("setConfig", {
  clientId: config.CLIENT_ID,
  apiURL: config.API_URL,
  appURL: config.APP_URL,
});
store.commit("loadFromLocal");

export default store;

function getDefault() {
  return {
    userInfo: {
      token: "",
      userUuid: "",
      mixinId: "",
      nickname: "",
      name: "",
      // avatar: require('@/assets/images/avatar-default.svg'),
      nextChangeNameAt: 0,
      assetUuid: "",
      valuationUuid: "",
      settlementMethod: "",
      walletAddress: "",
      type: "",
      role: "",
      collaborators: [],
      hasPin: "",
      inputPincodeTimestamp: "",
    },
    roleInvitation: {
      list: [],
      loadStatus: "pending",
    },
    emailAssets: {
      list: [],
      loadStatus: "pending",
    },
    emailAssetAddressMap: {},
    userAssets: {
      list: [],
      loadStatus: "pending",
    },
    userFlows: {
      list: [],
      loadStatus: "pending",
    },
    userAddress: {
      list: [],
      loadStatus: "pending",
    },
    userBank: {
      list: [],
      loadStatus: "pending",
    },
    // 收款方式暂存
    userTempReceive: {
      amount: "",
      assetUuid: "",
      valuationUuid: "",
      note: "",
    },
    // 默认的收款方式
    userDefaultReceive: {
      amount: "",
      assetUuid: "",
      valuationUuid: "",
      note: "",
    },
    userSubAccount: {
      list: [],
      robotSize: 0,
      multisigSize: 0,
      loadStatus: "pending",
    },
    multiMembers: {
      number: 0,
      threshold: "",
      members: [],
    },
    walletAccount: "",
    walletLabel: "",
    walletChainId: "",
    mvmAssets: {
      list: [],
      loadStatus: "pending",
    },
    assetsPrices: [],
    userTodayStatistic: {
      activeUserCount: 0,
      transactionCount: 0,
      transactionValue: 0,
    },
    multisigAccount: {
      id: "",
      type: "",
    },
  };
}
