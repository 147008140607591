<template>
  <div class="codebox">
    <div class="codebox-container">
      <span
        v-for="i in maxlength"
        :key="i"
        :class="[
          'codebox-box',
          i === value.length + 1 && focused && 'is-active',
          value.charAt(i - 1) !== '' && 'is-filled'
        ]"
      >
        <template v-if="value.charAt(i - 1)">
          <template v-if="mask">
            <span class="codebox-box-dot"></span>
          </template>
          <template v-else>
            {{ value.charAt(i - 1) }}
          </template>
        </template>
        <template v-if="i === value.length + 1 && focused">
          <span class="codebox-box-blink"></span>
        </template>
      </span>
    </div>
    <input
      ref="codeboxInput"
      class="codebox-input"
      type="tel"
      :value="value"
      :maxlength="maxlength"
      @input="onInput"
      @focus="onInputFocus"
      @blur="onInputBlur"
      @keydown="onKeydown"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  name: "CodeboxComponent",
  props: {
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      default: 6
    },
    mask: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const focused = ref(false);
    const codeboxInput = ref(null);
    const onInputFocus = () => (focused.value = true);
    const onInputBlur = () => (focused.value = false);
    const onInput = $event => context.emit("update:value", $event.target.value);
    const onKeydown = $event => {
      if (
        ![8, 13, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105].includes(
          $event.keyCode
        )
      ) {
        $event.preventDefault();
      }
    };
    const focus = () => {
      codeboxInput.value && codeboxInput.value.focus();
    }

    onMounted(() => {
      if (props.autoFocus) {
        focus();
      }
    });

    return {
      focused,
      codeboxInput,
      onInputFocus,
      onInputBlur,
      onInput,
      onKeydown,
      // public methods
      focus,
    };
  }
});
</script>

<style lang="scss" scoped>
.codebox {
  position: relative;

  .codebox-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .codebox-box {
    position: relative;
    flex: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 66px;
    height: 70px;
    color: #01080f;
    font-size: 50px;
    font-weight: 600;
    line-height: 1.2;
    border-bottom: 1px solid #c5cad5;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-active, &.is-filled {
      border-color: #01080f;
    }

    .codebox-box-dot {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #01080f;
    }

    .codebox-box-blink {
      display: block;
      width: 2px;
      height: 56px;
      background: #01080f;
      animation: blink-flash steps(2) 1s infinite;
    }
  }

  .codebox-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    opacity: 0;
    -webkit-appearance: none;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes blink-flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>