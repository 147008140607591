import { createApp } from "vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import "./assets/css/main.css";
import service from "./utils/request";
import App from "./App.vue";
import { Buffer } from "buffer/";

import errHandler from "@/plugins/err-handler/index";
import { createI18n } from "vue-i18n";
import i18nEn from "./lang/en";
import permissionDirective from './directives/permission';

window.Buffer = window.Buffer || Buffer;

service.interceptors.request.use(
  (req) => {
    if (store.state.userInfo.token) {
      req.headers["Authorization"] = `Bearer ${store.state.userInfo.token}`;
    }

    const multisigId =
      store.state.multisigAccount.id ||
      window.localStorage.getItem("select-multisigId");

    if (multisigId) {
      req.headers["multisigId"] = multisigId;
    }
    return Promise.resolve(req);
  },
  (err) => {
    return Promise.reject(err);
  }
);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(errHandler);

const moduleFiles = require.context("./components/base", false, /\.vue$/);

moduleFiles.keys().forEach((key) => {
  const conponentObj = moduleFiles(key).default;
  app.component(conponentObj.name, conponentObj);
});

const i18n = createI18n({
  locale: store.state.navLang,
  fallbackLocale: "en",
  messages: {
    en: i18nEn,
  },
});

app.use(i18n);

app.mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.directive('permission', permissionDirective);

if (window.top != window.self) {
  top.location.replace(document.location);
  alert('For security reasons, framing is not allowed; click OK to remove the frames');
}
