<template>
  <svg :class="`exp-svg-icon ${size}`">
    <use :xlink:href="`#icon-${name}`" />
  </svg>
</template>

<script>
export default {
  name: "exp-svg-icon",
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "lg",
      validator: val => ["xl", "lg", "md", "sm", "xs", "xxs"].indexOf(val) > -1
    }
  }
};
</script>

<style lang="scss">
.exp-svg-icon {
  &.xxs {
    width: 12px;
    height: 12px;
  }

  &.xs {
    width: 18px;
    height: 18px;
  }

  &.sm {
    width: 24px;
    height: 24px;
  }

  &.md {
    width: 32px;
    height: 32px;
  }

  &.lg {
    width: 48px;
    height: 48px;
  }

  &.xl {
    width: 64px;
    height: 64px;
  }
}
</style>
