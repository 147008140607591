<template>
  <div class="body-header row-justify-center py-4 px-10 gap-3.5">
    <div class="row-align-center h-full gap-x-4">
      <el-icon size="24" class="cursor-pointer" v-if="isBack" @click="back">
        <Back />
      </el-icon>
      <h3 class="title">{{ $route.meta.title }}</h3>
    </div>
    <div class="header-right">
      <slot name="header-right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "body-header",
  props: {
    isBack: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.body-header {
  border-bottom: 1px solid #ECECEC;
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
}
</style>