<template>
  <div>
    <div class="swap-header row-justify px-10 py-6">
      <div>
        <h3 class="swap-header-title row-align-center">
          <el-link type="primary" @click="goWallet" :underline="false">
            L2 Wallet
          </el-link>
          <el-icon class="mx-2" size="22"><ArrowRight /></el-icon>
          <span>Swap</span>
        </h3>
        <p class="swap-header-brief mt-4">
          Instant trading of tokens with 0 fees
        </p>
      </div>
      <div class="swap-header-action">
        <el-link
          class="action row-center"
          type="primary"
          @click="goTransactions"
        >
          <exp-svg-icon name="payment-history" size="xs" />
          <span class="ml-1">Transaction History</span>
        </el-link>
      </div>
    </div>
    <div class="swap-container mt-12 px-10">
      <div class="swap-pane">
        <div class="control">
          <div class="control-header row-justify-center">
            <div class="control-header-left text-base">From</div>
            <div class="control-header-right text-sm" v-if="payAsset">
              <span class="balance">
                Balance: {{ payAsset.amount }} {{ payAsset.symbol }}
              </span>
              <span
                class="btn-max cursor-pointer ml-2 text-main-color"
                @click="onMaxClick"
              >
                Max
              </span>
            </div>
          </div>
          <div class="control-content mt-2">
            <el-input
              v-model="payAmount"
              type="number"
              @input="onPayAmountChange"
              placeholder="0.0"
              title=""
              autocomplete="off"
            >
              <template #prepend>
                <div
                  class="row-align-center control-content-left cursor-pointer"
                  :class="isMobile ? 'is-mobile' : ''"
                  @click="onAssetSelect('pay')"
                >
                  <exp-symbol
                    :uuid="payAssetUuid"
                    :iconUrl="payAsset && payAsset.icon"
                    class="flex-shrink-0"
                    :size="isMobile ? 'md' : 'lg'"
                  />
                  <span class="mx-2 symbol">
                    {{ payAsset && payAsset.symbol }}
                  </span>
                  <el-icon><CaretBottom /></el-icon>
                </div>
              </template>
            </el-input>
          </div>
          <div class="control-footer">
            <p class="input-error text-sm mt-1" v-if="errMsg">{{ errMsg }}</p>
          </div>
        </div>
        <div class="division mt-8 mb-2">
          <div
            class="division-box row-center cursor-pointer text-main-color"
            @click="onRouteExchange"
          >
            <el-icon size="20"><Switch /></el-icon>
          </div>
        </div>
        <div class="control">
          <div class="control-header row-justify-center">
            <div class="control-header-left text-base">To</div>
            <div class="control-header-right text-sm" v-if="receiveAsset">
              <span class="balance">
                Balance: {{ receiveAsset.amount }}
                {{ receiveAsset.symbol }}
              </span>
            </div>
          </div>
          <div class="control-content mt-2 no-focus">
            <el-input
              v-model="receiveAmount"
              type="number"
              readonly
              placeholder="0.0"
              :tabindex="-1"
            >
              <template #prepend>
                <div
                  class="row-align-center control-content-left cursor-pointer"
                  :class="isMobile ? 'is-mobile' : ''"
                  @click="onAssetSelect('receive')"
                >
                  <exp-symbol
                    :uuid="receiveAssetUuid"
                    :iconUrl="receiveAsset && receiveAsset.icon"
                    :size="isMobile ? 'md' : 'lg'"
                    class="flex-shrink-0"
                  />
                  <span class="mx-2 symbol">
                    {{ receiveAsset && receiveAsset.symbol }}
                  </span>
                  <el-icon><CaretBottom /></el-icon>
                </div>
              </template>
            </el-input>
          </div>

          <div class="control-footer">
            <div class="row-justify text-sm mt-4" v-if="receiveAmount > 0">
              <div>Price</div>
              <div class="text-right">
                <div>
                  1 {{ receiveAsset && receiveAsset.symbol }} ≈
                  {{ forwardPrice }}
                  {{ payAsset && payAsset.symbol }}
                </div>
                <div class="mt-1">
                  1 {{ payAsset && payAsset.symbol }} ≈ {{ price }}
                  {{ receiveAsset && receiveAsset.symbol }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-group mt-12 row-center">
          <el-button
            class="btn-swap"
            type="primary"
            :disabled="swapBtnDisabled"
            @click="onSwapClick"
            round
          >
            <span>Swap</span>
          </el-button>
        </div>

        <div class="mt-12">
          <div class="row-center">
            <el-link
              type="primary"
              target="_blank"
              href="https://help.mixpay.me/en/articles/7202842-how-to-use-swap-transactions"
            >
              How to use Swap?
            </el-link>
          </div>
        </div>
      </div>
    </div>

    <asset-picker
      v-model="isAssetPickerShow"
      :options="assetList"
      @choose="onAssetChoose"
    />

    <order-confirm-dialog
      v-model="isOrderConfirmDialogShow"
      :payAmount="payAmount"
      :receiveAmount="receiveAmount"
      :payAsset="payAsset"
      :receiveAsset="receiveAsset"
      :price="price"
      :forwardPrice="forwardPrice"
      @success="onTransferSuccess"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchConvertEstimate } from "@/api/index.js";
import { fixDecimal } from "@/utils/num-format.js";
import AssetPicker from "@/components/swap/asset-picker.vue";
import orderConfirmDialog from "@/components/swap/order-confirm-dialog.vue";
import Big from "big.js";
export default {
  name: "SwapView",

  components: {
    AssetPicker,
    orderConfirmDialog
  },

  data() {
    return {
      payAmount: "",
      receiveAmount: "",
      payAssetUuid: "",
      receiveAssetUuid: "",

      price: "",
      forwardPrice: "",

      isAssetPickerShow: false,
      type: "pay",

      isOrderConfirmDialogShow: false,
      timeout: null,

      errMsg: "",

      pollKey: null
    };
  },

  computed: {
    ...mapState({
      device: state => state.device,
      emailAssets: state => state.emailAssets,
      swapAssets: state => state.swapAssets
    }),

    swapAssetsMap() {
      const map = {};
      this.swapAssets.map(item => {
        map[item.uuid] = item;
      });
      return map;
    },

    assetList() {
      const { swapAssetsMap, emailAssets } = this;
      return emailAssets.list.filter(item => {
        return (
          swapAssetsMap[item.uuid] &&
          !swapAssetsMap[item.uuid].isMirrorAsset &&
          !swapAssetsMap[item.uuid].isTradeToFundPoolOnly
        );
      });
    },

    isMobile() {
      return this.device === "mobile";
    },
    payAsset() {
      return this.emailAssets.list.find(
        item => item.uuid === this.payAssetUuid
      );
    },
    receiveAsset() {
      return this.emailAssets.list.find(
        item => item.uuid === this.receiveAssetUuid
      );
    },
    swapBtnDisabled() {
      const { errMsg, payAmount, payAsset, receiveAmount } = this;
      return !!(
        errMsg ||
        payAmount <= 0 ||
        receiveAmount <= 0 ||
        (payAsset && payAsset.amount < payAmount)
      );
    }
  },

  mounted() {
    let selectAssetStr = localStorage.getItem("swap_assets");
    let pay = "";
    let receive = "";
    if (selectAssetStr) {
      [pay, receive] = selectAssetStr.split(",");
    }
    this.loadData()
      .then(() => {
        if (
          !this.payAssetUuid &&
          this.assetList.find(item => item.uuid === pay)
        ) {
          this.payAssetUuid = pay;
        }
        if (
          !this.receiveAssetUuid &&
          this.assetList.find(item => item.uuid === receive)
        ) {
          this.receiveAssetUuid = receive;
        }
        if (!this.payAssetUuid || !this.receiveAssetUuid) {
          // 默认选择 USDT(ERC-20) - ETH
          this.payAssetUuid = "4d8c508b-91c5-375b-92b0-ee702ed2dac5";
          this.receiveAssetUuid = "43d61dcd-e413-450d-80b8-101d5e903357";
        }
      })
      .catch(err => {
        console.log(err);
      });

    this.pollKey = setInterval(() => {
      this.$store.dispatch("updateEmailUserAssets").catch(() => {});
    }, 30000);
  },

  beforeUnmount() {
    clearInterval(this.pollKey);
  },

  methods: {
    loadData() {
      const promises = [];
      if (!this.swapAssets.length) {
        promises.push(this.$store.dispatch("updateSwapAssets"));
      }
      if (!this.emailAssets.length) {
        promises.push(this.$store.dispatch("updateEmailUserAssets"));
      }
      return Promise.all(promises);
    },
    onAssetChoose(uuid) {
      this.isAssetPickerShow = false;
      if (this.type === "pay") {
        if (uuid === this.payAssetUuid) {
          return;
        }
        if (uuid === this.receiveAssetUuid) {
          this.receiveAssetUuid = this.payAssetUuid;
        }
        this.payAssetUuid = uuid;
      }

      if (this.type === "receive") {
        if (uuid === this.receiveAssetUuid) {
          return;
        }
        if (uuid === this.payAssetUuid) {
          this.payAssetUuid = this.receiveAssetUuid;
        }
        this.receiveAssetUuid = uuid;
      }
      localStorage.setItem(
        "swap_assets",
        `${this.payAssetUuid},${this.receiveAssetUuid}`
      );
      this.payAmount = "";
      this.onPayAmountChange();
    },
    onMaxClick() {
      if (this.payAsset) {
        this.payAmount = this.payAsset.amount;
        this.onPayAmountChange();
      }
    },
    onAssetSelect(type) {
      this.type = type;
      this.isAssetPickerShow = true;
    },
    onPayAmountChange() {
      this.$nextTick(() => {
        this.payAmount = fixDecimal(this.payAmount, 8);
        this.errMsg = "";
        clearTimeout(this.timeout);
        if (this.payAmount <= 0) {
          this.receiveAmount = "";
          return;
        }
        this.timeout = setTimeout(() => {
          fetchConvertEstimate(
            this.payAssetUuid,
            this.receiveAssetUuid,
            this.payAmount
          )
            .then(({ data: d }) => {
              this.receiveAmount = this.formatNum(d.calcSum);
              this.price = this.formatNum(d.calcPrice);
              this.forwardPrice = this.formatNum(
                d.calcPrice > 0 ? Big(1).div(d.calcPrice) : "0"
              );
              const max = d.range.max;
              const min = d.range.min;
              if (+this.payAmount < +min) {
                this.errMsg = `The amount must be less than ${min} ${this
                  .payAsset && this.payAsset.symbol}`;
              } else if (+this.payAmount > +max) {
                this.errMsg = `The amount must be greater than ${max} ${this
                  .payAsset && this.payAsset.symbol}`;
              } else if (
                this.payAsset &&
                +this.payAmount > +this.payAsset.amount
              ) {
                this.errMsg = "Balance insufficient.";
              }
            })
            .catch(err => {
              if (["1243", "1242"].includes(String(err.code))) {
                this.errMsg = "No trade path in current direction";
              } else {
                this.errMsg =
                  err.message || "Network Error, please try it again.";
              }
            });
        }, 500);
      });
    },
    onTransferSuccess() {
      this.payAmount = "";
      this.onPayAmountChange();
      this.isOrderConfirmDialogShow = false;
      this.$store.dispatch("updateEmailUserAssets").catch(() => {});
    },
    onSwapClick() {
      this.isOrderConfirmDialogShow = true;
    },
    onRouteExchange() {
      const uuid = this.receiveAssetUuid;
      this.receiveAssetUuid = this.payAssetUuid;
      this.payAssetUuid = uuid;
      this.payAmount = "";
      this.onPayAmountChange();
    },
    goTransactions() {
      this.$router.push("/wallet/transactions");
    },
    formatNum(num) {
      if (num <= 0) {
        return "0";
      }
      const input = Big(num);
      if (input.gte("0.00000001")) {
        return input.round(8, 1).toString();
      }
      // 小于 1e-8 的数 保留两位有效数字
      const str = input.round(16, 1).toString();
      let i = 10;
      for (i; i < str.length; i++) {
        if (str[i] !== "0") {
          break;
        }
      }
      return str.slice(0, i + 2);
    },
    goWallet() {
      this.$router.replace("/wallet");
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-header {
  border-bottom: 1px solid #ececec;

  .swap-header-title {
    font-size: 22px;
    font-weight: 600;

    .el-link {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .swap-header-brief {
    color: #828282;
  }
}

.swap-pane {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 480px;
}

.control {
  .balance {
    color: #828282;
  }

  .control-content-left {
    min-width: 100px;

    .symbol {
      min-width: 100px;
      color: #1e1e1e;
    }

    &.is-mobile {
      .symbol {
        min-width: 70px;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  .no-focus {
    :deep {
      .el-input__wrapper.is-focus {
        box-shadow: 0 0 0 1px
          var(--el-input-border-color, var(--el-border-color)) inset !important;
      }

      .el-input__wrapper {
        background: rgba(#f5f7fa, 0.5);
      }

      .el-input__inner {
        cursor: not-allowed;
      }
    }
  }

  :deep {
    .el-input-group__prepend {
      padding: 0 15px;
    }
  }

  .control-footer {
    position: relative;

    .input-error {
      position: absolute;
    }
  }

  .input-error {
    color: #f46;
  }
}

.division {
  margin-left: 170px;

  &-box {
    width: 30px;
    height: 30px;
    border: 1px solid currentColor;
    border-radius: 50%;
    transform: rotate(90deg);
  }
}

.btn-swap {
  width: 160px;
  height: 50px;
  border-radius: 25px !important;
}
</style>