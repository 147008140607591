import axios from "axios";


/**
 * 获取MVM余额资产
 */
 export const getMvmAssets = (action, address) => {
    return axios.get(`https://scan.mvm.dev/api?module=account&action=${action}&address=${address}`);
  }

/**
 * 根据合约地址获取asset_id
 */
export const getContractAssetId = contract => {
    return axios.get(`https://api.mvm.dev/contract_asset?contract=${contract}`);
}

/**
 * 根据 asset_id 获取 资产合约地址
 * @param {*} asset 
 * @returns 
 */
export const getAssetContract = asset => {
  return axios.get(`https://api.mvm.dev/asset_contract?asset=${asset}`);
}