import { ElNotification, ElMessageBox } from 'element-plus';
import store from '@/store/index';
import router from '@/router/index';

export default  {
  install: (app) => {
    app.config.globalProperties.$errHandler = (err, level = 'toast', callback = null) => {
      
      if (err.code == '10000' || err.code == '30015') {
        // mixin token 失效 需重新登录
        store.commit('clearLocal');
        router.replace('/login');
        return;
      }
      if (level === 'toast') {
        ElNotification({
          title: 'Tips',
          message: err.message,
          type: 'error',
        })
      }
      if (level === 'dialog') {
        ElMessageBox.alert(err.message, 'Tips', {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: 'Confirm',
          dangerouslyUseHTMLString: true,
          showClose: false,
          callback: () => {
            callback && callback();
          },
        })
      }
      if (['ECONNABORTED'].includes(String(err.code))) {
        return;
      }
      if (['Network Error'].includes(String(err.message))) {
        return;
      }
    };
  }

}

