<template>
  <div>
    <body-header>
      <template #header-right>
        <el-button
          type="primary"
          @click="addQRCode"
          icon="plus"
          v-if="list.length > 0"
        >
          {{ $t("link.addPaymentLink") }}
        </el-button>
      </template>
    </body-header>
    <div class="my-7 px-10">
      <div class="mt-4" v-loading="isLoading">
        <!-- <el-skeleton :rows="15" animated v-if="isLoading" /> -->
        <div class="empty-view mt-10" v-if="list.length <= 0">
          <div v-if="!isLoading">
            <div class="mb-16 flex flex-col gap-y-1">
              <div class="empty-view-title text-xl leading-8">
                Welcome to Payment Links
              </div>
              <div class="empty-view-tips text-sm leading-6">
                Accept payments without all the heavy lifting.
              </div>
            </div>

            <div class="mb-10">
              <div class="flex flex-col gap-y-12 max-w-xxs">
                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="plus" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="empty-item-title text-base leading-5 text-dark-dark"
                    >
                      Create links quickly
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Start accepting payments in just a few clicks.
                    </div>
                  </div>
                </div>

                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="share" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="empty-item-title text-base leading-5 text-dark-dark"
                    >
                      Share with customers on any channel
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Be flexible by sharing the link on any channel or device.
                    </div>
                  </div>
                </div>

                <div class="empty-item flex items-start gap-x-4">
                  <div class="w-7 h-7 rounded-full bg-[#ddeafe] row-center">
                    <exp-svg-icon name="bitcoin" size="xs" class="step-icon" />
                  </div>
                  <div class="flex flex-col gap-y-4">
                    <div
                      class="empty-item-title text-base leading-5 text-dark-dark"
                    >
                      Easy and convenient
                    </div>
                    <div
                      class="empty-item-content text-sm leading-5 text-gray-500"
                    >
                      Support any currency quote any currency settlement.
                    </div>
                  </div>
                </div>

                <el-button
                  class="w-min"
                  type="primary"
                  @click="addQRCode"
                  icon="plus"
                >
                  {{ $t("link.addPaymentLink") }}
                </el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <el-table
            :data="list"
            size="large"
            max-height="580"
            table-layout="auto"
            class="text-[16px] shadow-sm"
          >
            <el-table-column label="Name" min-width="80">
              <template #default="scope">
                <div class="row-align-center">
                  <el-avatar :src="scope.row.avatar" class="link-avatar" />
                  <span class="ml-1 flex-shrink-0">{{
                    subName(scope.row.name)
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Payment Link"
              :min-width="
                urlLongLength > 48 && !isAmountLong
                  ? '210'
                  : urlLongLength > 48
                  ? '180'
                  : '165'
              "
            >
              <template #default="scope">
                <div class="row-align-center">
                  <span class="m-1">{{ scope.row.url }}</span>
                  <el-icon class="cursor-pointer" @click="copy(scope.row.url)">
                    <CopyDocument />
                  </el-icon>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Quote" min-width="120">
              <template #default="scope">
                <div class="row-align-center">
                  <div class="flex-shrink-0">
                    <exp-symbol
                      :uuid="scope.row.valuationUuid"
                      :iconUrl="scope.row.quoteIcon"
                    />
                  </div>
                  <span class="ml-1">
                    {{ scope.row.amount }} {{ scope.row.quoteAsset }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Receiving" min-width="160">
              <template #default="scope">
                <div class="row-align-center" v-if="scope.row.receiveAssetUuid">
                  <exp-symbol
                    class="coin-icon mr-1 flex-shrink-0"
                    :uuid="scope.row.receiveAssetUuid"
                    :iconUrl="scope.row.settlementIcon"
                  />
                  <span>{{ scope.row.receiveAsset }}</span>
                </div>
                <div class="row-align-center" v-else>
                  <img
                    class="coin-icon mr-1 flex-shrink-0"
                    src="@/assets/all.svg"
                  />
                  <span>{{ $t("selector.all") }} </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Time Created" width="180" prop="createdAt">
            </el-table-column>
            <el-table-column
              label="Action"
              width="120"
              align="center"
            >
              <template #default="scope">
                <div class="row-center space-x-5">
                  <div
                    class="cursor-pointer"
                    @click="editQRCode(scope.row, scope)"
                  >
                    <el-icon size="16px"><Edit /></el-icon>
                  </div>
                  <div
                    class="cursor-pointer"
                    @click="goNotification(scope.row.id)"
                    v-if="userInfo.type === 'mixin'"
                  >
                    <el-icon size="16px"><Bell /></el-icon>
                  </div>

                  <el-popover
                    placement="left"
                    :width="300"
                    @before-enter="beforeEnter(scope.row.id, scope.row.avatar)"
                  >
                    <template #reference>
                      <div class="cursor-pointer">
                        <svg-icon name="qrcode" size="xs" />
                      </div>
                    </template>
                    <div
                      :id="`image-${scope.row.id}`"
                      class="col-center bg-white"
                    >
                      <img
                        class="share-img-header"
                        src="@/assets/images/share-img-header.png"
                      />
                      <div class="my-6 qrcode" ref="qrcode">
                        <qrcode-vue
                          :value="scope.row.url"
                          :size="200"
                          level="H"
                        />
                        <img
                          v-if="scope.row.avatar"
                          class="qrcode-avatar w-20 h-20"
                          :src="`${scope.row.avatar}?id=${scope.row.id}`"
                          crossorigin="anonymous"
                        />
                        <!-- <img
                          :src="avatarMap[scope.row.id]"
                          class="qrcode-avatar w-20 h-20"
                          crossorigin="anonymous"
                          v-if="avatarMap[scope.row.id]"
                        />
                        <el-avatar
                          :src="scope.row.avatar"
                          class="qrcode-avatar w-20 h-20"
                          v-else
                        /> -->
                      </div>
                      <span class="text-lg font-bold text-font-color">{{
                        scope.row.name
                      }}</span>
                      <span class="text-sm text-font-color mt-6"
                        >Pay any Crypto with any Wallet</span
                      >
                      <img
                        class="share-img-cryptos"
                        src="@/assets/images/share-img-cryptos.png"
                      />
                      <img
                        class="share-img-bottom"
                        src="@/assets/images/share-img-bottom.png"
                      />
                    </div>
                    <div class="mb-4 row-center">
                      <el-button
                        class="btn-download"
                        type="primary"
                        @click="handleClick(scope.row.id, scope.row.name)"
                        :loading="downloading"
                        >Download</el-button
                      >
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt-4"
            layout="prev, pager, next"
            background
            :current-page="page"
            :page-count="pageCount"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <add-qrcode-dialog
      v-model="isShowQrCodeDialog"
      v-model:selectValuationUuid="valuationUuid"
      v-model:selectAssetUuid="assetUuid"
      @addDialogClose="addDialogClose"
      @handleValuationDialog="handleValuationDialog"
      @handleSettleDialog="handleSettleDialog"
      @setName="setName"
      @successAdd="successAdd"
    />

    <quote-picker-dialog
      v-model="isShowQuotePicker"
      v-model:defaultValue="valuationUuid"
      @closeDialog="handleValuationDialog"
    />

    <settle-picker-dialog
      v-model="isShowSettlePicker"
      :options="settlementAssets"
      has-filter
      v-model:defaultValue="assetUuid"
      @closeDialog="handleSettleDialog"
    />

    <set-name-dialog
      v-model="isShowSetNameDialog"
      :nameAvailable="nameAvailable"
      @closeDialog="setName"
      @setNameSuccess="setNameSuccess"
      @dialogClose="dialogClose"
    />

    <edit-qrcode-dialog
      v-model="isShowEditDialog"
      :linkData="editLink"
      @editDialogClose="editDialogClose"
      @successEdit="successEdit"
    />

    <notification-dialog
      v-model="isShowNotificationDialog"
      :id="showNotificationId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { updateUserReceiveList, getSettlementAssets } from "@/api/index.js";
import copyText from "@/utils/copy-text.js";
import { ElMessage } from "element-plus";
import { formatTime } from "@/utils/date-format.js";
import { getPageCount } from "@/utils/num-format";
import bus from "@/utils/eventbus";
import addQrcodeDialog from "./components/add-qrcode-dialog.vue";
import QuotePickerDialog from "./components/quote-picker-dialog.vue";
import SettlePickerDialog from "./components/settle-picker-dialog.vue";
import SetNameDialog from "./components/set-name-dialog.vue";
import EditQrcodeDialog from "./components/edit-qrcode-dialog.vue";
import NotificationDialog from "./components/notification-dialog.vue";
import SvgIcon from "@/components/base/svg-icon.vue";
import html2Canvas from "html2canvas";
import QrcodeVue from "qrcode.vue";
// import axios from "axios";

export default {
  name: "QRCode",
  components: {
    addQrcodeDialog,
    QuotePickerDialog,
    SettlePickerDialog,
    SetNameDialog,
    EditQrcodeDialog,
    NotificationDialog,
    SvgIcon,
    QrcodeVue
  },

  computed: {
    ...mapGetters(["quoteMap", "assetMap"]),
    ...mapState({
      device: state => state.device,
      settings: state => state.settings,
      userInfo: state => state.userInfo,
      showAddPaymentLink: state => state.showAddPaymentLink,
      qrcodeFirstPageList: state => state.qrcodeFirstPageList
    }),
    isAmountLong() {
      let check = false;
      if (this.list.length > 0) {
        this.list.forEach(item => {
          if (item.amount && item.amount.length > 15) {
            check = true;
            return;
          }
        });
      }
      return check;
    },
    urlLongLength() {
      let length = 0;
      if (this.list.length > 0) {
        this.list.forEach(item => {
          if (item.url && item.url.length > length) {
            length = item.url.length;
            return;
          }
        });
      }
      return length;
    }
  },

  data() {
    return {
      isLoading: false,
      page: 1,
      list: [],
      qrcode: "",
      pageCount: 1,
      isShowQrCodeDialog: false,
      isShowQuotePicker: false,
      isShowSettlePicker: false,
      valuationUuid: "",
      assetUuid: "",
      settlementAssets: [],
      isShowSetNameDialog: false,
      nameAvailable: false,
      isShowEditDialog: false,
      editLink: null,
      isShowNotificationDialog: false,
      showNotificationId: "",
      downloading: false,
      avatarMap: {}
    };
  },

  mounted() {
    if (this.showAddPaymentLink) {
      this.addQRCode();
      this.$store.commit("setShowAddPaymentLink", false);
    }
  },

  created() {
    if (this.qrcodeFirstPageList && this.qrcodeFirstPageList.list) {
      this.list = this.qrcodeFirstPageList.list;
      this.pageCount = this.qrcodeFirstPageList.pageCount;
    }

    if (this.quoteMap) {
      if (this.qrcodeFirstPageList.status === "pending") {
        this.isLoading = true;
      }
      Promise.all([
        this.$store.dispatch("updateSettings"),
        this.$store.dispatch("updateQuoteAssets")
      ])
        .then(() => {
          this.loadData();
        })
        .catch(() => {
          this.loadData();
        });
    } else {
      this.loadData();
    }

    if (
      window.localStorage.getItem("walletChainId") &&
      window.localStorage.getItem("walletChainId") != "0x120c7" &&
      this.userInfo.token
    ) {
      bus.emit("openMvmTips");
    }

    bus.on("sendSettlementAssets", data => {
      this.settlementAssets = data;
    });
  },

  methods: {
    loadData() {
      this.$store
        .dispatch("updateQrcodeFirstPageList")
        .then(() => {
          this.isLoading = false;
          this.list = this.qrcodeFirstPageList.list;
          this.pageCount = this.qrcodeFirstPageList.pageCount;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    loadMoreData() {
      this.isLoading = true;
      updateUserReceiveList(this.page, 10)
        .then(data => {
          if (Array.isArray(data.data)) {
            const result = [];
            data.data.forEach(item => {
              result.push({
                id: item.id,
                name: item.linkName || "",
                amount: item.quoteAmount || "",
                quoteAsset: this.quoteMap[item.quoteAssetId]
                  ? this.quoteMap[item.quoteAssetId]["symbol"]
                  : item.quoteSymbol,
                quoteIcon: item.quoteIcon || "",
                receiveAsset: this.assetMap[item.settlementAssetId]
                  ? this.assetMap[item.settlementAssetId]["symbol"]
                  : item.settlementSymbol,
                receiveAssetUuid: item.settlementAssetId,
                valuationUuid: item.quoteAssetId,
                settlementIcon: item.settlementIcon || "",
                url: item.url || "",
                avatar: item.avatarUrl || this.userInfo.avatar,
                note: item.note || "",
                createdAt: formatTime(item.createdAt * 1000)
              });
            });
            this.list = result;
          }
          if (data.meta && data.meta.total) {
            this.pageCount = getPageCount(data.meta.total, 10);
          }
          this.isLoading = false;
          return Promise.resolve(data);
        })
        .catch(err => {
          this.isLoading = false;
          return Promise.reject(err);
        });
    },
    copy(url) {
      if (copyText(url)) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    },
    addQRCode() {
      if (
        window.localStorage.getItem("walletChainId") &&
        window.localStorage.getItem("walletChainId") != "0x120c7"
      ) {
        bus.emit("openMvmTips");
        return;
      }
      if (!this.userInfo.name) {
        this.setName(true);
      } else {
        this.isShowQrCodeDialog = true;
        // this.$router.push('/payment-link/add');
      }
    },
    editQRCode(data) {
      this.editLink = data;
      this.isShowEditDialog = true;
    },
    goNotification(id) {
      this.showNotificationId = id;
      this.isShowNotificationDialog = true;
    },
    handleClick(id, name) {
      this.downloading = true;
      this.downloadImage(id, name);
    },
    downloadImage(id, name) {
      var imgName = name + "-" + new Date().getTime();
      let myCanvas = document.getElementById(`image-${id}`);
      const options = {
        useCORS: true,
        backgroundColor: null,
        dpi: 192
      };
      html2Canvas(myCanvas, options)
        .then(canvas => {
          let a = document.createElement("a");
          let blob = this.base64ToBlob(canvas.toDataURL("image/png"));
          let evt = document.createEvent("HTMLEvents");
          evt.initEvent("click", true, true);
          a.download = imgName;
          a.href = URL.createObjectURL(blob);
          a.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
          ElMessage.error("Download Failed");
        });
    },
    base64ToBlob(base64) {
      const parts = base64.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    subUrl(url) {
      if (url && url.length > 30) {
        return url.substring(0, 30) + "...";
      }
      return url;
    },
    subName(name) {
      if (name && name.length > 10) {
        return name.substring(0, 10) + "...";
      }
      return name;
    },

    pageChange(val) {
      this.page = val;
      this.loadMoreData();
    },

    handleValuationDialog() {
      this.isShowQuotePicker = !this.isShowQuotePicker;
    },

    handleSettleDialog(valuationUuid, quoteAmount) {
      this.isShowSettlePicker = !this.isShowSettlePicker;
      if (this.isShowSettlePicker) {
        getSettlementAssets(this.userInfo.userUuid, valuationUuid, quoteAmount)
          .then(({ data: d }) => {
            if (Array.isArray(d)) {
              this.settlementAssets = d.map(item => ({
                uuid: item.assetId,
                isAsset: !!+item.isAsset,
                available: !!+item.isAvailable,
                symbol: item.symbol,
                icon: item.iconUrl,
                minQuoteAmount: item.minQuoteAmount,
                maxQuoteAmount: item.maxQuoteAmount,
                quoteSymbol: item.quoteSymbol
              }));
            }
            const asset = this.settlementAssets.find(
              item => item.uuid === this.assetUuid
            );
            if (this.assetUuid !== "all" && (!asset || !asset.available)) {
              this.assetUuid = "";
            }
          })
          .catch(err => {
            this.$errHandler(err);
          });
      }
    },
    setName(nameAvailable) {
      this.isShowSetNameDialog = !this.isShowSetNameDialog;
      this.nameAvailable = nameAvailable;
    },
    successAdd() {
      this.isShowQrCodeDialog = false;
      this.page = 1;
      this.loadData();
    },
    dialogClose() {
      this.isShowQrCodeDialog = false;
    },
    setNameSuccess() {
      if (!this.isShowQrCodeDialog) {
        this.isShowQrCodeDialog = true;
      }
    },
    addDialogClose() {
      this.valuationUuid = "";
      this.assetUuid = "";
    },
    editDialogClose() {
      this.isShowEditDialog = false;
    },
    successEdit() {
      this.isShowEditDialog = false;
      this.page = 1;
      this.loadData();
    },
    beforeEnter(id, url) {
      console.log(id, url);
      // var that = this;
      // if (!this.avatarMap[id]) {
      //   let canvas = document.createElement("canvas");
      //   const ctx = canvas.getContext("2d");
      //   let img = new Image();
      //   img.crossOrigin = "Anonymous"; // 解决Canvas.toDataURL 图片跨域问题
      //   img.src = url;
      //   img.onload = function() {
      //     canvas.height = img.height;
      //     canvas.width = img.width;
      //     ctx.drawImage(img, 0, 0, img.width, img.height); // 参数可自定义
      //     that.avatarMap[id] = canvas.toDataURL("image/jpeg", 1); // 获取Base64编码
      //     canvas = null; // 清除canvas元素
      //     img = null; // 清除img元素
      //   };
      //   img.onerror = function() {
      //     axios
      //       .get(url, {
      //         responseType: "arraybuffer"
      //       })
      //       .then(res => {
      //         const bufferUrl = btoa(
      //           new Uint8Array(res.data).reduce(
      //             (data, byte) => data + String.fromCharCode(byte),
      //             ""
      //           )
      //         );
      //         this.avatarMap[id] = "data:image/png;base64," + bufferUrl;
      //       });
      //   };
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.link-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.coin-icon {
  width: 18px;
  height: 18px;
}

.qrcode-svg:hover {
  color: #409eff;
}

.qrcode-svg:focus {
  color: #409eff;
}

.svg-icon:hover {
  color: #409eff;
}

.svg-icon:focus {
  color: #409eff;
}

.empty-view {
  &-title {
    font-weight: 600;
  }

  &-tips {
    color: #999999;
  }
}

.empty-item {
  &-title {
    font-weight: 500;
  }
}

.step-icon {
  color: #2a7dfb;
}

.qrcode {
  position: relative;
}

.qrcode-avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  z-index: 1000;
}

.share-img-header {
  width: 100%;
  margin-top: -12px;
}

.share-img-cryptos {
  width: 80%;
  margin-top: 20px;
}

.share-img-bottom {
  width: 80%;
  margin: 20px 0;
}

.btn-download {
  height: 36px;
  width: 140px;
  margin-top: 20px;
}
</style>