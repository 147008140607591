<template>
  <el-dialog
    title="Claim Your Name"
    top="10vh"
    width="90%"
    style="max-width:480px"
    :close-on-click-modal="canClose"
    append-to-body
    @close="dialogClose"
  >
    <div class="name-dialog">
      <p class="font-bold text-base">
        Claim your free 「mixpay.me」domain and start customizing your payment
        link.
      </p>

      <el-form class="my-8" ref="form" :rules="formRules" :model="form">
        <el-form-item prop="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            minlength="5"
            :placeholder="$t('actions.typeHere')"
            clearable
          >
            <template #prepend>
              <div class="row-align-center">
                <el-icon><Link /></el-icon>
                <div class="ml-1">{{ prefix }}</div>
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>

      <div class="text-#f7a349">Username can only be claimed once.</div>

      <div class="mt-8 space-y-2">
        <p>1. Usernames must be between 5 to 20 characters.</p>
        <p>2. Usernam cannot be all numbers.</p>
      </div>

      <div class="row-center">
        <el-button
          type="primary"
          class="mt-8 w-1/2"
          @click="confirm"
          :loading="isSubmitting"
        >
          Claim Your Name
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { ElMessageBox } from "element-plus";
import { updateUserName } from "@/api/index.js";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device,
      appURL: state => state.appURL
    }),
    prefix() {
      let displayUrl = this.appURL.substr(8, 100) + "/";
      return displayUrl;
    },
    canClose() {
      return this.userInfo.name != "";
    }
  },

  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please enter the Name"));
      } else if (value.length > 20 || value.length < 5) {
        callback(new Error(this.$t("name.nameRequired[0]")));
      } else if (new RegExp(/^[0-9]*$/).test(value)) {
        callback(new Error(this.$t("name.nameRequired[1]")));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: ""
      },
      formRules: {
        name: [{ required: true, trigger: "change", validator: validateName }]
      },
      isSubmitting: false,
      successSetName: false
    };
  },

  methods: {
    dialogClose() {
      this.form.name = "";
      if (
        (!this.userInfo.name || this.userInfo.name === "") &&
        !this.successSetName
      ) {
        this.$emit("dialogClose");
      }
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.name === this.userInfo.name) {
            this.$emit("closeDialog");
            return;
          }
          ElMessageBox.confirm(this.$t("name.modifyConfirm"), "Tips", {
            confirmButtonText: this.$t("actions.confirm"),
            cancelButtonText: this.$t("actions.cancel"),
            type: "warning"
          })
            .then(() => {
              this.isSubmitting = true;
              updateUserName(this.form.name)
                .then(() => {
                  this.successSetName = true;
                  this.$store.dispatch("updateUserInfo");
                })
                .then(() => {
                  this.isSubmitting = false;
                  this.$emit("closeDialog");
                  this.$emit("setNameSuccess");
                })
                .catch(err => {
                  this.isSubmitting = false;
                  this.$errHandler(err, "dialog");
                });
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input {
  height: 50px;
  font-size: 16px;
}

.info-item {
  height: 50px;
  font-size: 15px;
  color: #333333;
  border: 1px solid #dcdfe6;
  border-radius: 12px;
  padding: 0 15px;
  margin-top: 12px;
}
</style>