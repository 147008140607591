import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import store from "@/store/index.js";

import Layout from "../layout/index.vue";

import Dashbord from "../views/dashbord/dashbord.vue";
import MyQRCode from "../views/qrcode/qrcode.vue";
import Billing from "../views/billing/billing.vue";
import Report from "../views/report/payment-report.vue";
import Asset from "../views/asset/assets.vue";
import History from "../views/history/payment-history.vue";
import Setting from "../views/setting/settings.vue";
import Help from "../views/about/help-support.vue";
import ApiKey from "../views/api-key/api-key.vue";
// Wallet for Email User
import WalletIndex from "../views/wallet/wallet.vue";
import WalletSetPincode from "../views/wallet/set-pin-code.vue";
import WalletTransactions from "../views/wallet/transactions.vue";
import WalletWithdraws from "../views/wallet/withdraws.vue";
// Swap for Email User
import Swap from "../views/swap/swap.vue";

import Login from "../views/login/login.vue";
import Invitation from "../views/invitation/invitation.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        component: Dashbord,
        name: "home",
        meta: { title: "Dashboard", needLogin: true },
      },
      {
        path: "/payment-link",
        component: MyQRCode,
        props: true,
        name: "PaymentLink",
        meta: { title: "Payment Link", needLogin: true },
      },
      {
        path: "/payment-link/add",
        component: () => import("@/views/qrcode/add-qrcode"),
        name: "AddPaymentLink",
        meta: { title: "Add Payment Link", needLogin: true },
      },
      {
        path: "/payment-link/edit-qrcode",
        component: () => import("@/views/qrcode/edit-qrcode"),
        name: "EditPaymentLink",
        meta: { title: "Edit Payment Link", needLogin: true },
      },
      {
        path: "/payment-link/notification",
        component: () => import("@/views/qrcode/notification"),
        name: "Notification",
        meta: { title: "Notification", needLogin: true },
        hidden: true,
      },
      {
        path: "/payment-link/received",
        component: () => import("@/views/qrcode/received"),
        name: "Received",
        meta: { title: "Received", needLogin: true },
        hidden: true,
      },
      {
        path: "/billing",
        meta: { title: "Billing", needLogin: true },
        component: Billing,
        name: "Billing",
      },
      {
        path: "/report",
        meta: { title: "Payment Report", needLogin: true },
        component: Report,
        name: "Report",
      },
      {
        path: "/assets",
        component: Asset,
        name: "Asset",
        meta: { title: "Assets", needLogin: true },
      },
      {
        path: "/history",
        component: History,
        name: "History",
        meta: { title: "Payment History", needLogin: true },
      },
      {
        path: "/settings",
        name: "settings",
        component: Setting,
        meta: { title: "Settings", needLogin: true },
      },
      {
        path: "/help",
        name: "help",
        component: Help,
        meta: { title: "Help & Support" },
      },
      {
        path: "/api-key",
        name: "api",
        component: ApiKey,
        meta: { title: "My API Key", needLogin: true },
      },
      {
        path: "/wallet",
        component: WalletIndex,
        meta: {
          hasPin: true,
          userTypeList: ["mail", "metamask"],
          title: "My Wallet",
          needLogin: true,
        },
        name: "My Wallet",
      },
      {
        path: "/wallet/setPincode",
        component: WalletSetPincode,
        meta: {
          hasPin: false,
          userTypeList: ["mail", "metamask"],
          title: "My Wallet",
          needLogin: true,
        },
        name: "Set Pincode",
      },
      {
        path: "/wallet/transactions",
        component: WalletTransactions,
        meta: {
          hasPin: true,
          userTypeList: ["mail", "metamask"],
          title: "My Wallet",
          needLogin: true,
        },
        name: "transactions",
      },
      {
        path: "/wallet/withdraws",
        component: WalletWithdraws,
        meta: {
          hasPin: true,
          userTypeList: ["mail", "metamask"],
          title: "My Wallet",
          needLogin: true,
        },
        name: "withdraws",
      },
      {
        path: "/wallet/swap",
        component: Swap,
        meta: {
          hasPin: true,
          userTypeList: ["mail", "metamask"],
          title: "Swap",
          needLogin: true,
        },
        name: "swap",
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
    meta: { title: "Login", icon: "dashboard" },
  },
  {
    path: "/invitation",
    name: "invitation",
    component: Invitation,
    meta: { title: "Invitation", needLogin: true },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404/404"),
    meta: { title: "404" },
  },
  // { path: "/:pathMatch(.*)", redirect: "/404", hidden: true },
];

const router = createRouter({
  history: process.env.NODE_ENV === 'production' ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "MixPay - Dashboard";

  if (!to.matched.length) {
    return next({
      name: "404",
    });
  }
  if (to.meta && to.meta.needLogin && !store.state.userInfo.token) {
    const obj = {
      path: "/login",
    };
    if (to.name !== "home") {
      obj.query = {
        loginFrom: encodeURIComponent(to.fullPath),
      };
    }
    return next(obj);
  }

  if (to.meta.userTypeList && to.meta.userTypeList.length > 0) {
    if (!to.meta.userTypeList.includes(store.state.userInfo.type)) {
      return next({
        name: "home",
      });
    }
    if (to.meta.hasPin && !store.state.userInfo.hasPin) {
      return next({
        path: "/wallet/setPincode",
        query: {
          from: to.fullPath,
        },
      });
    }
  }

  next();
});

router.afterEach((to) => {
  if (window.gtag) {
    window.gtag("config", "G-9L00RJVMVW", {
      page_title: to.meta.title,
      page_path: to.fullPath,
    });
  }
});

export default router;
