<template>
    <el-dialog
      title="Upgrade required"
      top="10vh"
      width="90%"
      style="max-width:480px;"
      :close-on-click-modal="false"
      :show-close="true"
      @closed="onDialogClose"
    >
      <div class="step pb-6" v-if="step === 0">
        <div class="detail mt-2">
          <div class="detail-item row-justify mt-2 p-4">
            <div class="detail-item-content">
              <p>In order to continue using MixPay, we need to upgrade your account to Mixin Safe. </p>
              <p class="mt-3">You will need to input your 6-digit PIN to confirm the upgrade.</p>
              <p class="mt-3">
                After you upgrade if this message still showing, please send an email to <el-link type="primary" href="mailto:customer@mixpay.me" target="_blank">customer@mixpay.me</el-link>.
              </p>
            </div>
          </div>
        </div>
        <div class="btn-group mt-8 mx-9">
          <el-button type="primary" @click="goStep(1)">
            <span>Continue</span>
          </el-button>
        </div>
      </div>
      <div class="step pb-6" v-else-if="step === 1">
        <div class="back-wrapper">
          <div
            class="back-btn row-align-center cursor-pointer"
            @click="goStep(0)"
          >
            <exp-svg-icon name="back" size="sm" />
            <span class="text-base">Back</span>
          </div>
        </div>
        <pin-codebox
          ref="pincodebox"
          class="mt-4"
          v-model:pin="pin"
          :loading="isSubmitting"
          @submit="onSubmit"
        />
      </div>
    </el-dialog>
  </template>
  
  <script>

  import PinCodebox from "@/components/codebox/input-codebox.vue";
  import { upgradeToSafe } from "@/api";
  import { ElMessage, ElMessageBox } from "element-plus";

  export default {
    components: {
      PinCodebox
    },
  
    data() {
      return {
        step: 0,
        pin: "",
        isSubmitting: false
      };
    },
  
    methods: {
      goStep(step) {
        this.step = step;
      },
      onSubmit() {
        if (this.pin.length < 6) {
          ElMessageBox.alert(
            "Please make sure your PIN contains at least 6 characters.",
            "Reminder",
            {
              confirmButtonText: "Confirm",
              showClose: false,
              callback: () => {
                this.$refs.pincodebox && this.$refs.pincodebox.focus();
              }
            }
          );
          return;
        }
        if (this.isSubmitting) return;
        this.isSubmitting = true;
        upgradeToSafe(
          this.pin
        )
          .then(() => {
            this.isSubmitting = false;
            this.$emit("success");
            ElMessage({
              message: "Upgrade Successfully",
              type: "success"
            });
          })
          .catch(err => {
            this.isSubmitting = false;
            this.$errHandler(err, "dialog", () => {
              this.pin = "";
              this.$refs.pincodebox && this.$refs.pincodebox.focus();
            });
          });
      },
      onDialogClose() {
        this.step = 0;
        this.pin = "";
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .receive {
    .receive-amount {
      .num {
        font-size: 2rem;
      }
  
      .unit {
        font-size: 1rem;
      }
    }
  }
  
  .btn-group {
    .el-button {
      width: 100%;
      height: 50px;
    }
  }
  </style>