<template>
  <el-container>
    <el-header class="header items-center bg-white">
      <app-header />
    </el-header>
    <el-container>
      <el-aside v-if="device === 'desktop'">
        <aside-menu />
      </el-aside>
      <el-drawer
        v-else
        :model-value="isAsideMenuOpen"
        direction="ltr"
        :size="220"
        :show-close="false"
        :with-header="false"
        :append-to-body="true"
        class="px-0"
        @close="onAsideMenuClose"
      >
        <aside-menu @menu-collapse="onAsideMenuClose" />
      </el-drawer>

      <el-main>
        <router-view v-if="isRefresh" />
      </el-main>
    </el-container>
  </el-container>

  <el-dialog v-model="isShowMvmTipsDialog" title="Tips" class="w-[500px]">
    <p class="leading-10 break-words overflow-hidden">
      Please switch to MVM Network before you can use it. If you haven't added
      MVM Network yet, please refer to:
    </p>
    <p class="text-main-color cursor-pointer" @click="onMVMJoinBtnClick">
      Join MVM
    </p>
    <div class="row-center">
      <el-button
        class="mt-10 w-1/2 h-10"
        type="primary"
        @click="onMVMTipsDialogConfirm"
      >
        I Got It
      </el-button>
    </div>
  </el-dialog>

  <el-dialog v-model="isShowTipsDialog" title="Tips" class="w-[500px]">
    <p class="text-base text-dark-dark text-center">
      Do you want to switch your account? Switching accounts requires to sign,
      please click MetaMask Extension to sign.
    </p>
    <div class="col-center">
      <img src="@/assets/images/tips-metamask2.jpeg" class="w-1/3 my-8" />
      <el-button class="w-1/3" type="primary" @click="onTipsDialogConfirm">
        I Got It
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { ElLoading, ElMessageBox } from "element-plus";
import { ethers } from "ethers";

import AppHeader from "./components/AppHeader.vue";
import AsideMenu from "./components/AsideMenu.vue";

import { MetaMaskProvider, MVMChainId, signature } from "@/plugins/web3/index";
import { fetchMvmNonce, postMvmLogin } from "@/api/index";

export default {
  name: "IndexPage",

  components: { AppHeader, AsideMenu },

  computed: {
    ...mapState({
      isAsideMenuOpen: state => state.isAsideMenuOpen,
      device: state => state.device,
      userInfo: state => state.userInfo
    })
  },

  data() {
    return {
      isRefresh: true,
      state: "visible",

      isShowMvmTipsDialog: false,
      isShowTipsDialog: false,
      isPageVisible: false
    };
  },

  watch: {
    $route() {
      if (this.userInfo.type === "mvm") {
        this.onMVMChainChanged();
      }
    }
  },

  mounted() {
    document.addEventListener("visibilitychange", this.handleVisiable);
    this.handleVisiable();

    // 加载用户数据
    if (this.userInfo.token) {
      this.$store
        .dispatch("updateUserInfo")
        .then(() => {
          if (this.userInfo.type === "mvm") {
            this.removeMVMEvent();
            this.addMVMEvent();
            this.onMVMChainChanged();
            this.onMVMAccountsChanged();
          }
        })
        .catch(() => {});
      this.$store.dispatch("updateUserSubAccounts").catch(() => {});
    }
  },
  unmounted() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
    this.removeMVMEvent();
  },

  methods: {
    addMVMEvent() {
      MetaMaskProvider.addEvent("chainChanged", this.onMVMChainChanged);
      MetaMaskProvider.addEvent("accountsChanged", this.onMVMAccountsChanged);
    },

    removeMVMEvent() {
      MetaMaskProvider.removeEvent("chainChanged");
      MetaMaskProvider.removeEvent("accountsChanged");
    },

    async onMVMChainChanged() {
      const chainId = await MetaMaskProvider.getChainId();
      if (chainId === MVMChainId) {
        this.isShowMvmTipsDialog = false;
      } else {
        this.isShowMvmTipsDialog = true;
      }
    },

    async onMVMTipsDialogConfirm() {
      this.isShowMvmTipsDialog = !this.isShowMvmTipsDialog;
      if (!this.isShowMvmTipsDialog) {
        try {
          await MetaMaskProvider.switchToMvmNetwork();
        } catch (e) {
          //
        }
      }
    },

    async onMVMJoinBtnClick() {
      try {
        await MetaMaskProvider.addMvm();
      } catch (e) {
        window.open("https://chainlist.org/?search=mvm");
      }
    },

    async onMVMAccountsChanged() {
      if (!this.isPageVisible) return;
      const accounts = await MetaMaskProvider.getAccounts();
      if (accounts.length > 0) {
        const address = ethers.utils.getAddress(accounts[0]);
        if (address !== this.userInfo.walletAddress) {
          this.isShowTipsDialog = true;
        } else {
          this.isShowTipsDialog = false;
        }
      } else {
        this.$store.commit("clearLocal");
        this.$router.replace("/login");
      }
    },

    async loginByMVM() {
      let loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      try {
        await MetaMaskProvider.switchToMvmNetwork();
        const accounts = await MetaMaskProvider.getAccounts();
        const address = ethers.utils.getAddress(accounts[0]);
        const nonceData = await fetchMvmNonce(address);
        const signatureData = await signature(
          MetaMaskProvider.provider,
          nonceData.data.nonce
        );
        const tokenData = await postMvmLogin(address, signatureData);
        if (tokenData.data && tokenData.data.token) {
          this.$store.commit("setUserInfo", {
            walletAddress: address,
            token: tokenData.data.token
          });
          this.$emit("refresh");
        }
      } catch (e) {
        var msg = e.reason || e.message;
        if (msg === "user rejected signing") {
          msg = "Account verification failed. Please try again later.";
        }
        ElMessageBox.confirm(msg, "", {
          confirmButtonText: "OK",
          type: "warning",
          showCancelButton: false
        }).catch(() => {});
      } finally {
        loading && loading.close();
      }
    },

    onTipsDialogConfirm() {
      this.isShowTipsDialog = false;
      this.loginByMVM();
    },

    handleVisiable() {
      this.isPageVisible = document.visibilityState === "visible";
    },

    onAsideMenuClose() {
      this.$store.commit("toggleAsideMenu", false);
    }
  }
};
</script>

<style lang="scss">
.el-aside {
  display: block;
  height: calc(100vh - 70px);
  background: #ffffff;
  border-right: 1px solid #ececec;
}

.el-main {
  height: calc(100vh - 70px);
}

.el-header {
  border-bottom: 1px solid #ececec;
}
</style>