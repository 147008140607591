const IS_DEV =
  location.host === "mixpay-dashboard.betacvvbu.com" ||
  process.env.NODE_ENV !== "production";

const IS_LOCAL = location.host === "localhost:8080";

export default {
  // API_URL: IS_DEV ? "http://api.mp.test" : "https://api.mixpay.me",
  // APP_URL: IS_DEV ? "http://mp.test" : "https://mixpay.me",

  API_URL: getAPIUrl(),
  APP_URL: getAPPUrl(),

  CLIENT_ID: IS_DEV
    ? "a37c0fcf-1fd6-4375-a7f8-7ec6e2544c7b"
    : "3539c3ce-52c0-4b0b-9573-c035ecb98d48",
  RSA_PUB:
    "-----BEGIN PUBLIC KEY-----\nMFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMCVffqLKD1pcZNMoxXkHajvx+Eb59nF\nJ8WURp3fWfgsJUXQ44oa46kB57FEPSlQU3IgQaQW6bBCptc0hgZIBV0CAwEAAQ==\n-----END PUBLIC KEY-----",
  EXINONE_API_URL: "https://app.eiduwejdk.com/api/v2",
  INFURA_ID: IS_DEV
    ? "23e6e439bbf14bb3b5ecd0bfa6fb29ae"
    : "e54763fb9afb4a829f069766f985524d",
  WALLET_CONNECT_PROJECT_ID: IS_DEV
    ? "dad76efb4b2e29cb3e7f699d6c74a02c"
    : "3803fd55d7d496391e7da503a3c8f822",
};

function getAPIUrl() {
  if (IS_LOCAL) {
    return "http://api.mp.test";
  }

  if (IS_DEV) {
    return "https://mixpay-api.mixpmt.me";
  }

  return "https://api.mixpay.me";
}

function getAPPUrl() {
  if (IS_LOCAL) {
    return "http://app.mp.test";
  }

  if (IS_DEV) {
    return "https://mixpay.mixpmt.me";
  }

  return "https://mixpay.me";
}