<template>
  <el-dialog
    title="Add Receiver"
    top="10vh"
    width="90%"
    style="max-width:480px"
    @close="dialogClose"
  >
    <el-form label-position="top">
      <el-form-item label="Mixin ID">
        <p class="input-hint">{{ $t("notification.addBotTip[0]") }}</p>
        <el-input
          v-model="mixinId"
          type="number"
          :placeholder="$t('notification.addBotTip[5]')"
          clearable
        />
      </el-form-item>
    </el-form>

    <div class="row-center mt-6">
      <el-button
        :class="device === 'mobile' ? 'w-full' : 'w-1/2'"
        type="primary"
        :loading="isAdding"
        @click="addMixinId"
      >
        {{ $t("actions.confirm") }}
      </el-button>
    </div>
    <el-scrollbar height="45vh" class="mt-10" always>
      <h3 class="title">{{ $t("notification.addBotTip[1]") }}</h3>
      <p class="tips-content">{{ $t("notification.addBotTip[2]") }}</p>
      <div class="mb-5 row-center">
        <img class="w-2/3" src="../images/step-1.png" />
      </div>
      <p class="tips-content">{{ $t("notification.addBotTip[3]") }}</p>
      <div class="mb-5 row-center">
        <img class="w-2/3" src="../images/step-3.png" />
      </div>
      <p class="tips-content">{{ $t("notification.addBotTip[4]") }}</p>
      <div class="mb-5 row-center">
        <img class="w-2/3" src="../images/step-2.png" />
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { addNotificationReceiver } from "@/api/index";
import { ElMessage } from "element-plus";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device
    })
  },

  props: {
    linkId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      mixinId: "",
      isAdding: false
    };
  },

  methods: {
    addMixinId() {
      if (this.mixinId.length < 5) {
        ElMessage.error(this.$t("error.invalidMixinId"));
        this.$refs.input && this.$refs.input.focus();
        return;
      }
      this.isAdding = true;
      addNotificationReceiver(this.linkId, this.mixinId)
        .then(() => {
          this.isAdding = false;
          this.mixinId = "";
          this.$emit("addReceiver");
        })
        .catch(err => {
          this.isAdding = false;
          this.$errHandler(err, "dialog");
        });
    },
    dialogClose() {
      this.mixinId = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input {
  height: 50px;
  font-size: 16px;
}
.input-hint {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.title {
  color: color-text-base;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.tips-content {
  color: color-text-base;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
</style>