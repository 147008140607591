<template>
  <div class="px-10 pt-7 pb-20">
    <div class="max-w-[490px]">
      <h3 class="title mb-7">Profile</h3>

      <el-form label-position="top">
        <div class="avatar-item row-align-center">
          <div class="label mr-10">
            <div>{{ $t("account.avatar") }}</div>
          </div>
          <el-upload
            class="avatar-uploader"
            name="avatar"
            :show-file-list="false"
            :on-change="handelAvatarChange"
            :auto-upload="false"
          >
            <img :src="avatarUrl" class="avatar" />
            <el-icon class="upload-icon" size="20px"><UploadFilled /></el-icon>
          </el-upload>
        </div>
        <el-form-item class="mt-9" :label="$t('account.nickname')">
          <span class="input-hint">
            This Nickname will be displayed on the billing page for the payer to
            <el-popover placement="right" :width="400" trigger="hover">
              <template #reference>
                <el-link class="underline" type="primary" :underline="false">
                  view
                </el-link>
              </template>
              <template #default>
                <div>
                  <div class="header-showcase">
                    <div class="title">Showcase</div>
                  </div>
                  <img
                    class="img-showcase"
                    src="@/assets/images/user-showcase.png"
                    alt="MixPay"
                  />
                </div>
              </template>
            </el-popover>
          </span>
          <el-input
            v-model="nickname"
            maxlength="20"
            show-word-limit
            size="large"
            :placeholder="$t('actions.typeHere')"
          />
        </el-form-item>
        <el-button
          class="px-16"
          type="primary"
          plain
          :loading="isSubmitSettingName"
          @click="confirmSetName"
          >{{ $t("actions.saveChange") }}</el-button
        >
      </el-form>

      <div class="label mt-9 mb-2">UUID</div>
      
      <div class="info-item row-justify-center mb-2">
        <div>{{ userInfo.userUuid }}</div>
        <el-icon class="cursor-pointer" @click="copy(userInfo.userUuid)"
          ><DocumentCopy
        /></el-icon>
      </div>
      <div class="input-hint-danger mb-12" v-if="!userInfo.isInWhiteList">
        ⚠️ Your account is not allowed to receive payment right now. Please <el-link type="primary" href="https://mixpay.me/quick-start#whitelist-form" target="_blank">submit the form</el-link> to get whitelisted.
      </div>

      <h3 class="title mb-7 mt-8">{{ $t("receiveSettings.default") }}</h3>

      <div class="label mt-9 mb-2 row-align-center">
        <div>{{ $t("receiveSettings.quote") }}</div>
      </div>
      <span class="input-hint">{{ $t("tips.quoteTip") }}</span>
      <div
        class="info-item row-justify-center cursor-pointer"
        @click="handleValuationDialog"
      >
        <div class="row-align-center mr-2" v-if="valuationUuid">
          <exp-symbol
            class="mr-2"
            :size="device === 'mobile' ? 'md' : 'lg'"
            :uuid="valuationUuid"
          />
          <span class="setting-item-content">
            {{ currentValuation && currentValuation.symbol }}
          </span>
          <exp-fiat-icon
            class="ml-2"
            v-if="currentValuation && !currentValuation.isAsset"
          />
        </div>
        <div
          class="setting-item-placeholder text-placeholder-color mr-2"
          v-else
        >
          {{ $t("actions.chooseHere") }}
        </div>
        <el-icon><ArrowRightBold /></el-icon>
      </div>

      <div class="label mt-9 mb-2 row-align-center">
        <div>{{ $t("receiveSettings.receive") }}</div>
      </div>
      <span class="input-hint">{{ $t("tips.receiveTip") }}</span>
      <div
        class="info-item row-justify-center mb-8 cursor-pointer"
        @click="handleSettleDialog"
      >
        <div class="row-align-center mr-2" v-if="assetUuid">
          <exp-symbol
            class="mr-2"
            :size="device === 'mobile' ? 'md' : 'lg'"
            :uuid="assetUuid"
            isRound
          />
          <exp-coin-name
            :uuid="assetUuid"
            :symbol="currentAsset && currentAsset.symbol"
            class="setting-item-content"
          />
          <exp-fiat-icon
            class="ml-2"
            v-if="currentAsset && !currentAsset.isAsset"
          />
        </div>
        <div
          class="setting-item-placeholder text-placeholder-color mr-2"
          v-else
        >
          {{ $t("actions.chooseHere") }}
        </div>
        <el-icon><ArrowRightBold /></el-icon>
      </div>
      <el-button
        class="px-16"
        type="primary"
        plain
        :loading="isSubmitReceiving"
        @click="confirmReceiveSetting"
      >
        {{ $t("actions.saveChange") }}
      </el-button>

      <div v-if="userInfo.type === 'mixin'">
        <div class="title mb-7 mt-12 mb-2 row-align-center">
          <div>Notification Receivers</div>
          <el-icon
            class="cursor-pointer ml-1"
            size="16px"
            @click="isShowNotificationTipsDialog = true"
          >
            <QuestionFilled />
          </el-icon>
        </div>
        <span class="input-hint">
          Receive notification of your payment after successful addition.
        </span>
        <div
          class="info-item row-justify-center mb-8 cursor-pointer"
          @click="isShowNotificationUsersDialog = true"
        >
          <div class="row-align-center">
            <div
              class="item-remembrancer"
              v-for="(item, index) in remembrancerList"
              :key="index"
            >
              <img :src="item" class="img-remembrancer" />
            </div>
          </div>
          <el-icon><ArrowRightBold /></el-icon>
        </div>
      </div>

      <!-- 用户权限 -->
      <div v-permission="['own']">
        <div class="title mb-7 mt-12 mb-2 row-align-center">
          <div>Users</div>
        </div>
        <div class="input-hint">
          The invitations to your account will be displayed here.
        </div>
        <div
          class="info-item row-justify-center mb-8 cursor-pointer"
          @click="showUserRoleDialog"
        >
          <div class="row-align-center">
            <div
              class="item-remembrancer"
              v-for="(item, index) in roleUserList"
              :key="index"
            >
              <img :src="item" class="img-remembrancer" />
            </div>
          </div>
          <el-icon><ArrowRightBold /></el-icon>
        </div>
      </div>
    </div>

    <user-role-dialog
      v-model:value="isUserRoleDialogShow"
      @dialog-close="showUserRoleDialog"
    />

    <set-name-dialog v-model="isShowSetNameDialog" @closeDialog="setName" />

    <quote-picker-dialog
      v-model="isShowQuotePicker"
      v-model:defaultValue="valuationUuid"
      @closeDialog="handleValuationDialog"
    />

    <settle-picker-dialog
      v-model="isShowSettlePicker"
      :options="computedAssetOptions"
      has-filter
      v-model:defaultValue="assetUuid"
      @closeDialog="handleSettleDialog"
    />

    <notification-tips-dialog v-model="isShowNotificationTipsDialog" />

    <notification-users-dialog v-model="isShowNotificationUsersDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import QuotePickerDialog from "../qrcode/components/quote-picker-dialog.vue";
import SettlePickerDialog from "../qrcode/components/settle-picker-dialog.vue";
import SetNameDialog from "./components/set-name-dialog.vue";
import copyText from "@/utils/copy-text.js";
import { ElMessage, ElNotification } from "element-plus";
import { updateUserDefaultReceive, setUserInfo } from "@/api/index";
import { stringName } from "@/utils/string.js";
import { compressAccurately } from "image-conversion";
import { getBase64 } from "@/utils/string.js";
import NotificationTipsDialog from "./components/notification-tips-dialog.vue";
import NotificationUsersDialog from "./components/notification-users-dialog.vue";
import UserRoleDialog from "./components/user-role-dialog.vue";

export default {
  name: "UserSettings",
  components: {
    SetNameDialog,
    QuotePickerDialog,
    SettlePickerDialog,
    NotificationTipsDialog,
    NotificationUsersDialog,
    UserRoleDialog
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device,
      settings: state => state.settings,
      walletChainId: state => state.walletChainId
    }),
    currentValuation() {
      return this.settings.quoteAssets.find(
        item => item.uuid === this.valuationUuid
      );
    },
    currentAsset() {
      if (this.assetUuid === "all") {
        return {
          icon: require("@/assets/all.svg"),
          symbol: this.$t("selector.all"),
          uuid: "all",
          isAsset: 1,
          size: "lg"
        };
      }
      return this.settings.settlementAssets.find(
        item => item.uuid === this.assetUuid
      );
    },
    computedAssetOptions() {
      return this.settings.quoteAssets.filter(item => item.isSettlement);
    },
    remembrancerList() {
      const list = [];
      if (this.userInfo.avatar) {
        list.push(
          this.userInfo.avatar || require("@/assets/images/avatar-default.svg")
        );
      }
      if (Array.isArray(this.userInfo.remembrancers)) {
        this.userInfo.remembrancers.forEach(item => {
          list.push(
            item.avatarUrl || require("@/assets/images/avatar-default.svg")
          );
        });
      }
      return list;
    },
    roleUserList() {
      const list = [
        this.userInfo.avatar || require("@/assets/images/avatar-default.svg")
      ];
      this.userInfo.collaborators.forEach(item => {
        list.push(
          item.avatarUrl || require("@/assets/images/avatar-default.svg")
        );
      });
      return list;
    }
  },

  data() {
    return {
      nickname: "",
      isDataLoading: true,
      isShowSetNameDialog: false,
      isAssetsPickerShow: false,
      assetUuid: "",
      isValuationPickerShow: false,
      valuationUuid: "",
      isShowQuotePicker: false,
      isShowSettlePicker: false,
      isShowReceiveDialog: false,
      isSubmitReceiving: false,
      isEditReceiveSettings: true,
      defaultValuationUuid: "",
      defaultAssetUuid: "",
      isSubmitSettingName: false,
      avatarUrl: "",
      isShowNotificationTipsDialog: false,
      isShowNotificationUsersDialog: false,

      isUserRoleDialogShow: false
    };
  },

  mounted() {
    this.avatarUrl = this.userInfo.avatar;
    if (this.userInfo.nickname) {
      this.nickname =
        this.userInfo.nickname.length > 20
          ? this.userInfo.nickname.slice(0, 6)
          : this.userInfo.nickname;
    }
    let [p1, p2, p3] = [
      Promise.resolve(),
      Promise.resolve(),
      Promise.resolve()
    ];
    p1 = this.$store.dispatch("updateUserInfo");
    p1.then(() => {
      this.avatarUrl = this.userInfo.avatar;
      if (this.userInfo.nickname) {
        this.nickname =
          this.userInfo.nickname.length > 20
            ? this.userInfo.nickname.slice(0, 6)
            : this.userInfo.nickname;
      }
      if (this.settings.quoteStatus !== "success") {
        p2 = this.$store.dispatch("updateQuoteAssets");
      }
      if (this.settings.settlementStatus !== "success") {
        p3 = this.$store.dispatch("updateSettlementAssets", {});
      }
      return Promise.all([p2, p3]);
    })
      .then(() => {
        const { assetUuid, valuationUuid } = this.userInfo;
        if (
          assetUuid &&
          (assetUuid === "all" ||
            this.settings.settlementAssets.find(
              item => item.uuid === assetUuid
            ))
        ) {
          this.assetUuid = assetUuid;
          this.defaultAssetUuid = assetUuid;
        }
        if (
          valuationUuid &&
          this.settings.quoteAssets.find(item => item.uuid == valuationUuid)
        ) {
          this.valuationUuid = valuationUuid;
          this.defaultValuationUuid = valuationUuid;
        }
        this.isDataLoading = false;
      })
      .catch(err => {
        this.$errHandler(err);
      });
  },

  methods: {
    stringName,

    stringFormat(str) {
      if (str.length <= 16) {
        return str;
      }
      return str.slice(0, 8) + "..." + str.slice(-8);
    },
    copy(text) {
      if (copyText(text)) {
        ElMessage({
          message: "Copied to clipboard",
          type: "success"
        });
      }
    },
    setName() {
      this.isShowSetNameDialog = !this.isShowSetNameDialog;
    },

    handleValuationDialog() {
      this.isShowQuotePicker = !this.isShowQuotePicker;
      if (!this.isShowQuotePicker) {
        if (
          this.valuationUuid &&
          this.assetUuid &&
          (this.defaultValuationUuid != this.valuationUuid ||
            this.defaultAssetUuid != this.assetUuid)
        ) {
          this.isEditReceiveSettings = false;
        }
      }
    },

    handleSettleDialog() {
      this.isShowSettlePicker = !this.isShowSettlePicker;
      if (!this.isShowSettlePicker) {
        if (
          this.valuationUuid &&
          this.assetUuid &&
          (this.defaultValuationUuid != this.valuationUuid ||
            this.defaultAssetUuid != this.assetUuid)
        ) {
          this.isEditReceiveSettings = false;
        }
      }
    },
    confirmReceiveSetting() {
      if (!this.valuationUuid) {
        ElMessage.error(this.$t("tips.selectQuote"));
        this.handleValuationDialog();
        return;
      }
      if (!this.assetUuid) {
        ElMessage.error(this.$t("tips.selectReceive"));
        this.handleSettleDialog();
        return;
      }
      this.isSubmitReceiving = true;
      updateUserDefaultReceive(this.assetUuid, this.valuationUuid)
        .then(() => {
          this.isSubmitReceiving = false;
          this.$store.commit("setUserInfo", {
            assetUuid: this.assetUuid,
            valuationUuid: this.valuationUuid
          });
          this.defaultValuationUuid = this.valuationUuid;
          this.defaultAssetUuid = this.assetUuid;
          this.isEditReceiveSettings = true;
          ElNotification({
            title: "Success",
            message: "Receive Settings Saved.",
            type: "success"
          });
        })
        .catch(err => {
          this.isSubmitReceiving = false;
          this.$errHandler(err, "dialog");
        });
    },
    confirmSetName() {
      if (!this.nickname) {
        ElMessage.error(this.$t("name.enterName"));
        return;
      }
      if (
        this.nickname === this.userInfo.nickname &&
        this.avatarUrl === this.userInfo.avatar
      ) {
        return;
      }
      if (this.nickname.length > 20) {
        ElMessage.error("The nickname must not be greater than 20 characters.");
        return;
      }
      this.isSubmitSettingName = true;
      setUserInfo(this.nickname, this.avatarUrl)
        .then(() => {
          return this.$store.dispatch("updateUserInfo");
        })
        .then(() => {
          this.isSubmitSettingName = false;
          ElNotification({
            title: "Success",
            message: "Profile Saved.",
            type: "success"
          });
          this.$emit("closeDialog");
        })
        .catch(err => {
          this.isSubmitSettingName = false;
          this.$errHandler(err, "dialog");
        });
    },
    handelAvatarChange(file) {
      if (file.size / 1024 > 200) {
        compressAccurately(file.raw, 200).then(res => {
          getBase64(res).then(data => {
            this.avatarUrl = data;
          });
        });
      } else {
        getBase64(file.raw).then(data => {
          this.avatarUrl = data;
        });
      }
    },

    showUserRoleDialog() {
      this.isUserRoleDialogShow = !this.isUserRoleDialogShow;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-avatar {
  height: 100px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
}

.upload-icon {
  position: relative;
  bottom: -40px;
  right: 10px;
  color: color-primary;
}

:deep .el-input__wrapper {
  height: 50px;
}

.el-form-item {
  margin-bottom: 32px;
}

.label {
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  font-weight: 500;
}

.input-hint {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}
.input-hint-danger {
  color: #eb5757;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.info-item {
  height: 50px;
  font-size: 16px;
  font-family: 'Inter';
  color: #333333;
  border: 1px solid #dcdfe6;
  border-radius: 12px;
  padding: 0 15px;
  margin-top: 12px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
}

.card {
  width: 680px;
  padding: 30px;

  @media only screen and (max-width: 1280px) {
    width: 580px;
  }

  @media only screen and (max-width: 960px) {
    width: 90%;
  }

  @media only screen and (max-width: 470px) {
    width: 83%;
  }
}

.avatar {
  border: 1px solid #dcdfe6;
}

.setting-header {
  padding: 0 10px;
}

.setting-item {
  padding: 0 8px;
  border: 1px solid #dcdfe6;
  margin-top: 20px;
}

.setting-item-title, .setting-item-content {
  font-size: 16px;

  @media only screen and (max-width: 1280px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 960px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 470px) {
    font-size: 12px;
  }
}

.setting-item-placeholder {
  display: block;

  @media only screen and (max-width: 470px) {
    display: none;
  }
}

.header-showcase {
  color: #1E1E22;
  padding: 0 20px;

  .title {
    font-size: 20px;
  }
}

.img-showcase {
  width: 280px;
  height: 372px;
  margin: 20px 80px 40px 80px;
}

.item-remembrancer {
  border-radius: 50%;
  background: #E6E6E6;
  width: 28px;
  height: 28px;
  font-size: 12px;

  .img-remembrancer {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  &:not(:first-child) {
    margin-left: -12px;
  }
}
</style>