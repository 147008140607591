import { ethers } from "ethers";

import MetaMaskProvider, { MVMChainId } from "./metamask-provider";

const signature = (provider, message) => {
  const web3Provider = new ethers.providers.Web3Provider(provider, "any");
  return web3Provider.getSigner().signMessage(message);
};

export { MetaMaskProvider, signature, MVMChainId };
