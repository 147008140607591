<template>
    <el-dialog top="5vh" title="Manage Assets" class="w-max">
        <el-scrollbar max-height="650">
            <!-- <div class="content">Let's take <span class="span" @click="goCoWallet">CoWallet(7000103970)</span> as an example</div> -->
            <div class="title">1. Add <span class="span-title" @click="goCoWallet">CoWallet(7000103970)</span> as the contact</div>
            <div class="col-center">
                <img class="step-img w-1/2" src="@/assets/images/account/manage-asset-1.png" />
            </div>
            <div class="title">2. Create a multi-signature group using Mixin Messenger</div>
            <div class="content">
                When creating a group, make sure that the members in the group are the same as those in the multi-signature account created in MixPay
                <span class="span" @click="goCoWallet">CoWallet(7000103970)</span> should also be added to the group.
            </div>
            <div class="col-center">
                <img class="step-img w-1/2" src="@/assets/images/account/manage-asset-2.png" />
                <img class="step-img w-1/2 mt-10" src="@/assets/images/account/manage-asset-3.png" />
            </div>
            <div class="title">3. Set the multi-signature group name</div>
            <div class="content">
                Set the group name to the form "group name ^N", N is the threshold for multiple signatures, which refer to the total number of signatures or keys
                and must be the same as the numbers of multi-signature account created in MixPay.
            </div>
            <div class="col-center">
                <img class="step-img w-1/2" src="@/assets/images/account/manage-asset-4.png" />
            </div>
            <div class="title">4. Open the coWallet robot in a multi-signature group</div>
            <div class="content">Click the "+" in the lower left corner of a group chat to see the <span class="span" @click="goCoWallet">CoWallet(7000103970)</span> icon.</div>
            <div class="col-center">
                <img class="step-img w-1/2" src="@/assets/images/account/manage-asset-5.png" />
            </div>

        </el-scrollbar>

    </el-dialog>
</template>

<script>
import copyText from "@/utils/copy-text.js";
import { ElMessage } from "element-plus";
export default {
    

    methods: {
        goCoWallet() {
            if (copyText('7000103970')) {
                ElMessage({
                    message: 'Copied to clipboard',
                    type: 'success',
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-scrollbar {
    width: 700px;
}
.content {
    color: color-text-base;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}
.title {
    color: color-text-base;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}
.span-title {
    color: color-primary;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
.span {
    font-size: 16px;
    margin-bottom: 20px;
    color: color-primary;
    cursor: pointer;
}
</style>