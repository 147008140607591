<template>
  <div class="sidebar-logo-container" >
    <transition name="sidebarLogoFade">
      <router-link  key="expand" class="sidebar-logo-link row-align-center" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  data() {
    return {
      title: '',
      // logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'
      logo: require('@/assets/images/mixpay-logo.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  height: 70px;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    padding-left: 16px;

    & .sidebar-logo {
      width: 200px;
    }

    & .sidebar-title {
      margin: 0;
      color: color-text-base;
      font-size: 16px;
      margin-left: 10px;
      font-weight: 600;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
