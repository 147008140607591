import { validateWalletOptions } from "@web3-onboard/injected-wallets/dist/validation";
import standardWallets from "@web3-onboard/injected-wallets/dist/wallets";
import {
  ProviderLabel,
  ProviderIdentityFlag,
} from "@web3-onboard/injected-wallets/dist/types";

const isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
const isWallet =
  isMobile &&
  !!(
    window.ethereum ||
    window.BinanceChain ||
    window.tally ||
    window.web3 ||
    window.arbitrum ||
    window.xfi ||
    window.gamestop ||
    window.bitkeep ||
    window.avalanche
  );
const isOkxWallet = !!window.okxwallet;
const isOneKeyWallet = !!window.$onekey;

const okxwallet = {
  label: "OKX Wallet",
  injectedNamespace: "okxwallet",
  checkProviderIdentity: ({ provider }) => {
    return (
      !!provider && (!!provider["isOKExWallet"] || !!provider["isOkxWallet"])
    );
  },
  getIcon: async () => require("@/assets/images/icon-okex.png"),
  getInterface: () => ({
    provider: window.okxwallet,
  }),
  platforms: ["all"],
};
const onekey = {
  label: "OneKey Wallet",
  injectedNamespace: "$onekey",
  checkProviderIdentity: ({ provider }) => {
    return !!provider && !!provider.ethereum && !!provider.ethereum["isOneKey"];
  },
  getIcon: async () => require("@/assets/images/icon-onekey.png"),
  getInterface: () => ({
    provider: window.$onekey && window.$onekey.ethereum,
  }),
  platforms: ["all"],
};
const injectedWallets = [...standardWallets, okxwallet, onekey];

ProviderIdentityFlag["OneKeyWallet"] = "isOneKey";

export default (options) => {
  // node环境不适用
  if (typeof window === "undefined") return () => null;
  // 判断参数是否有效
  if (options) {
    const result = validateWalletOptions(options);
    if (result && result.error) throw result.error;
  }

  return (helpers) => {
    if (isWallet && isOkxWallet) {
      return [
        {
          label: okxwallet.label,
          getIcon: okxwallet.getIcon,
          getInterface: okxwallet.getInterface,
        },
      ];
    }

    if (isWallet && isOneKeyWallet) {
      return [
        {
          label: onekey.label,
          getIcon: onekey.getIcon,
          getInterface: onekey.getInterface,
        },
      ];
    }
    const { device } = helpers;
    const { filter = {} } = options || {};
    const filteredWallets = injectedWallets.filter((wallet) => {
      const { label, platforms } = wallet;
      const walletFilters = filter[label];
      const filteredWallet = walletFilters === false;
      const excludedDevice =
        Array.isArray(walletFilters) &&
        (walletFilters.includes(device.type) ||
          walletFilters.includes(device.os.name));
      const invalidPlatform =
        !platforms.includes("all") &&
        !platforms.includes(device.type) &&
        !platforms.includes(device.os.name);
      const supportedWallet =
        !filteredWallet && !excludedDevice && !invalidPlatform;
      return supportedWallet;
    });
    let removeMetaMask = false;
    const validWallets = filteredWallets.filter(
      ({ injectedNamespace, checkProviderIdentity, label }) => {
        const provider = window[injectedNamespace];
        if (!provider) return;
        let walletExists;
        if (provider.providers && Array.isArray(provider.providers)) {
          walletExists = !!provider.providers.filter((provider) =>
            checkProviderIdentity({ provider, device })
          ).length;
        } else {
          walletExists = checkProviderIdentity({ provider, device });
        }
        if (
          walletExists &&
          provider.isMetaMask &&
          !provider.overrideIsMetaMask &&
          label !== ProviderLabel.MetaMask &&
          label !== "Detected Wallet" &&
          label !== okxwallet.label &&
          label !== onekey.label
        ) {
          removeMetaMask = true;
        }
        return walletExists;
      }
    );
    if (validWallets.length) {
      const moreThanOneWallet = validWallets.length > 1;
      // if more than one wallet, then remove detected wallet
      return validWallets
        .filter((item) => {
          return (
            !(item.label === ProviderLabel.Detected && moreThanOneWallet) ||
            (item.label === ProviderLabel.MetaMask &&
              moreThanOneWallet &&
              removeMetaMask)
          );
        })
        .map(({ label, getIcon, getInterface }) => ({
          label,
          getIcon,
          getInterface,
        }));
    }
    return [];
  };
};
