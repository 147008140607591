<template>
  <div :class="`exp-symbol ${size}`">
    <img :src="icon" class="exp-symbol-main-icon" @error="showFailAssetImage" />
    <img v-if="chainIcon" :src="chainIcon" class="exp-symbol-chain-icon" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "exp-symbol",
  props: {
    uuid: {
      type: String
    },
    size: {
      type: String,
      default: "md",
      validator: val => ["xxl", "xl", "lg", "md", "sm"].indexOf(val) > -1
    },
    isRound: {
      type: Boolean,
      default: false
    },
    iconUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["assetMap", "quoteMap", "userAssetsMap"]),
    icon() {
      const { uuid, assetMap, quoteMap, userAssetsMap } = this;
      if (uuid == "all") {
        return require("@/assets/all.svg");
      }
      if (assetMap[uuid]) {
        return assetMap[uuid]["icon"];
      }
      if (quoteMap[uuid]) {
        return quoteMap[uuid]["icon"];
      }
      if (userAssetsMap[uuid]) {
        return userAssetsMap[uuid]["icon"];
      }
      if (this.iconUrl) {
        return this.iconUrl;
      }
      return require("@/assets/images/fail.png");
    },
    chainIcon() {
      const { uuid, assetMap, quoteMap } = this;
      if (assetMap[uuid]) {
        return assetMap[uuid]["chainIcon"];
      }
      if (quoteMap[uuid]) {
        return quoteMap[uuid]["chainIcon"];
      }
      return null;
    }
  },
  methods: {
    showFailAssetImage(e) {
      e.srcElement.src = require("@/assets/images/fail.png");
    }
  }
};
</script>

<style lang="scss">
.exp-symbol {
  position: relative;

  &.lg {
    width: 24px;
    height: 24px;
  }

  &.sm {
    width: 12px;
    height: 12px;
  }

  &.md {
    width: 18px;
    height: 18px;
  }

  &.xl {
    width: 32px;
    height: 32px;
  }

  &.xxl {
    width: 48px;
    height: 48px;
  }

  .exp-symbol-main-icon {
    display: block;
    width: 100%;
    border-radius: 50%;
  }

  .exp-symbol-chain-icon {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    width: 40%;
    height: 40%;
    border: 1px solid #fff;
  }
}
</style>
