import Config from "@/config";
import { init } from "@web3-onboard/vue";
import injectedModule from "./injected";
import WalletConnectModule from "@web3-onboard/walletconnect";
import CoinbaseModule from "@web3-onboard/coinbase";

export const web3onboard = init({
  wallets: [
    injectedModule(),
    WalletConnectModule({
      version: 2,
      projectId: Config.WALLET_CONNECT_PROJECT_ID,
      requiredChains: [1],
      qrcodeModalOptions: {
        mobileLinks: [
          "metamask",
          "tokenpocket",
          "imtoken",
          "trust",
          "rainbow",
          "mathwallet",
          "huobi",
          "bitkeep",
          "onekey",
        ],
      },
    }),
    CoinbaseModule(),
  ],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      namespace: "evm",
      label: "Ethereum Mainnet",
      rpcUrl: `https://mainnet.infura.io/v3/${Config.INFURA_ID}`,
      blockExplorerUrl: "https://etherscan.io/",
    },
  ],
  appMetadata: {
    name: "MixPay",
    description: "Decentralized Web3 Payment Protocol - MixPay",
    icon: require("@/assets/images/logo.svg"),
    logo: require("@/assets/images/logo.svg"),
    // icon: window.location.origin + "/favicon.ico",
    // logo: window.location.origin + "/favicon.ico",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" },
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
    ],
  },
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          header: "Available Wallets",
          sidebar: {
            heading: "Welcome",
            subheading: "Connect your wallet",
            // paragraph: 'Connecting your wallet to MixPay to bridge your asset from any network into MVM mainnet or withdraw from MVM mainnet to any network.',
          },
        },
      },
    },
  },
  accountCenter: {
    desktop: {
      enabled: false,
    },
    mobile: {
      enabled: false,
    },
  },
});
