<template>
  <el-dialog
    :title="$t('notification.tips')"
    top="10vh"
    class="w-max"
  >
    <template #default>
      <div class="scroller mt-2">
        <el-scrollbar max-height="65vh">
          <div class="space-y-4">
            <p class="tips-content">{{ $t('notification.tipsContent[0]') }}</p>
            <p class="tips-content">{{ $t('notification.tipsContent[1]') }}</p>
            <p class="tips-content">{{ $t('notification.tipsContent[2]') }}</p>
            <p class="tips-content">{{ $t('notification.tipsContent[3]') }}</p>
            <!-- <p><strong>{{ $t('notification.tipsContent[4]') }}</strong></p> -->
          </div>

          <div class="mt-10 space-y-4">
              <h3 class="title">{{ $t('notification.addBot[0]') }}</h3>
              <p class="tips-content">{{ $t('notification.addBot[1]') }}<span class="span-title" @click="copyRobot">7000104220</span></p>
              <img class="w-1/2" src="@/views/qrcode/images/step-0.png" alt="" />
              <p class="tips-content">{{ $t('notification.addBot[2]') }}</p>
              <img class="w-1/2" src="@/views/qrcode/images/step-1.png" alt="" />
          </div>
        </el-scrollbar>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import copyText from "@/utils/copy-text.js";

export default {


  methods: {
    copyRobot() {
      if (copyText('7000104220')) {
          ElMessage({
              message: 'Copied to clipboard',
              type: 'success',
          })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroller {
  // width: 696px;
  width: 546px;
}

.item-list-contacts {
  height: 70px;
}

.el-input {
  width: 500px;
  height: 50px;
}

.list-contacts {
  display: flex;
  flex-wrap: wrap;

  .item-list-contacts {
    position: relative;
    box-sizing: border-box;
    width: 260px;
    border-radius: 8px;
    border: 1px solid border-default-color;
    margin-bottom: 18px;
    padding: 0 12px;

    &-nickname {
      color: color-text-base;
    }

    &-mixinId {
      font-size: 12px;  
    }

    &-right {
      margin-left: 18px;
    }
  }

  .add-contact {
    color: color-primary;
    font-weight: 500;
    border: 1px solid color-primary;
  }

  .add-contact:hover {
    background: #F8FAFF;
    font-weight: 600;
  }

  .status {
    margin-left: 6px;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 8px;
    color: #fff;

    &.Pending {
        background: #ffa527;
    }

    &.Approved {
        background: #0ec9b1;
    }

    &.Rejected {
        background: #ff4466;
    }
    }
}

.title {
  color: color-text-base;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sub-title {
  color: color-text-base;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.tips-content {
  color: color-text-base;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.span-title {
  color: color-primary;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.span {
  color: color-primary;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
</style>