<template>
  <div>
    <el-dialog
      title="Users"
      top="10vh"
      width="90%"
      style="max-width:480px"
      :model-value="value"
      :close-on-click-modal="false"
      @update:model-value="onDialogClose"
      @open="onDailogOpen"
    >
      <div>
        <div class="popup-header row-justify">
          <div class="popup-header-title">
            <el-button type="primary" @click="onAddDialogShow">
              <div class="row-center">
                <el-icon class="el-icon--add"><Plus /></el-icon>
                <span class="ml-2 text-base">Add New User</span>
              </div>
            </el-button>
          </div>
          <div class="popup-header-action hidden sm:block">
            <el-link
              type="primary"
              target="_blank"
              href="https://help.mixpay.me/en/articles/7237076-how-to-add-a-user"
            >
              How to add a User?
            </el-link>
          </div>
        </div>
        <div class="popup-body">
          <div
            v-loading="true"
            style="height:300px"
            v-if="loadStatus === 'pending' || loadStatus === 'loading'"
          ></div>
          <el-scrollbar height="300px" v-else :always="true">
            <div class="scroller">
              <div class="scroller-item row-justify-center px-4 py-4 mt-4">
                <div class="scroller-item-left mr-4">
                  <img :src="userInfo.avatar" class="avatar" />
                </div>
                <div class="scroller-item-mid">
                  <span
                    class="nickname text-lg lg:max-w-[140px] xl:max-w-[240px] 2xl:max-w-[400px]"
                  >
                    {{ userInfo.nickname }}
                  </span>
                </div>
                <div class="scroller-item-right">
                  <span>Owner</span>
                </div>
              </div>
              <div
                class="scroller-item row-justify-center px-4 py-4 mt-4 overflow-auto"
                v-for="(item, i) in list"
                :key="i"
              >
                <div class="scroller-item-left mr-4">
                  <img :src="item.avatarUrl" class="avatar" />
                </div>
                <div class="scroller-item-mid row-align-center">
                  <span
                    class="nickname text-lg lg:max-w-[140px] xl:max-w-[240px] 2xl:max-w-[400px]"
                  >
                    {{ item.nickname }}
                  </span>
                  <span
                    class="badge ml-2 px-2 py-1 rounded-lg text-xs"
                    :style="{
                      'background-color': getRoleStatus(item.isAgree).color
                    }"
                  >
                    {{ getRoleStatus(item.isAgree).text }}
                  </span>
                </div>
                <div class="scroller-item-right row-align-center">
                  <span>Administrator</span>
                  <span
                    class="row-center btn-delete cursor-pointer ml-4"
                    @click="onRoleDelete(item.id)"
                  >
                    <el-icon size="18">
                      <Delete />
                    </el-icon>
                  </span>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="Add New User"
      top="10vh"
      width="90%"
      style="max-width:480px"
      v-model="isAddDialogShow"
      :close-on-click-modal="false"
      @close="onAddDialogClose"
    >
      <div>
        <div class="row-center">
          <el-radio-group v-model="type" size="large" @change="onTabChange">
            <el-radio-button label="email">Email</el-radio-button>
            <el-radio-button label="mixin">Mixin</el-radio-button>
          </el-radio-group>
        </div>

        <div class="form mt-8">
          <div class="form-control" v-if="type === 'email'">
            <div class="form-control-title text-base">
              <strong>Email</strong>
            </div>
            <div class="form-control-content mt-2">
              <el-input
                v-model="email"
                type="text"
                placeholder="Please enter the user email"
              ></el-input>
            </div>
            <div class="form-control-footer">
              <p class="mt-2">
                The other party will receive an email invitation from you.
              </p>
              <p class="mt-2 input-error" v-if="emailErr">{{ emailErr }}</p>
            </div>
          </div>

          <div class="form-control" v-if="type === 'mixin'">
            <div class="form-control-title text-base">
              <strong>Mixin ID</strong>
            </div>
            <div class="form-control-content mt-2">
              <el-input
                v-model="mixinId"
                type="tel"
                placeholder="Please enter the Mixin ID"
              ></el-input>
            </div>
            <div class="form-control-footer">
              <p class="mt-2">
                An invitation message will be sent to other party's Mixin via
                MixPay.
              </p>
              <p class="mt-2 input-error" v-if="mixinIdErr">{{ mixinIdErr }}</p>
            </div>
          </div>
        </div>

        <div class="roles mt-8">
          <div class="roles-header row-justify-center">
            <h3 class="roles-header-title">User Roles</h3>
            <div class="roles-header-action">
              <el-link
                type="primary"
                href="https://help.mixpay.me/en/articles/7237780-about-user-roles"
                target="_blank"
                >About User Roles</el-link
              >
            </div>
          </div>
          <div class="roles-body mt-4">
            <div class="role-list">
              <el-radio-group v-model="roleType" size="large">
                <el-radio label="admin" border>
                  <div>
                    <p class="text-base"><strong>Administrator</strong></p>
                    <p class="text-sm">
                      Full access to all menus, but no changes to users with
                      owner privileges.
                    </p>
                  </div>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="btn-group row-center mt-8 mx-8">
          <el-button type="primary" @click="onSubmit" :loading="isSubmitting">
            <span>Send Invitation</span>
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendRoleInvitation, deleteRoleInvitation } from "@/api";
import { mapState } from "vuex";
import { ElMessageBox } from "element-plus";
import { validateEmail } from "@/utils/email-format";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isAddDialogShow: false,
      type: "email",
      email: "",
      emailErr: "",
      mixinId: "",
      mixinIdErr: "",
      roleType: "admin",
      isSubmitting: false
    };
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      loadStatus: state => state.roleInvitation.loadStatus,
      list: state => state.roleInvitation.list
    })
  },

  methods: {
    getRoleStatus(status) {
      const map = {
        0: {
          status: "waiting",
          text: "Waiting",
          color: "#ffa527"
        },
        1: {
          status: "approved",
          text: "Approved",
          color: "#0ec9b1"
        },
        "-1": {
          status: "rejected",
          text: "Rejected",
          color: "#ff4466"
        },
        "-2": {
          status: "deleted",
          text: "Deleted",
          color: "#ff4466"
        }
      };
      return map[status];
    },
    onDialogClose() {
      this.$emit("dialog-close");
    },
    onDailogOpen() {
      this.$store.dispatch("updateRoleInvitation").catch(err => {
        this.$errHandler(err);
      });
    },
    onAddDialogShow() {
      this.isAddDialogShow = !this.isAddDialogShow;
    },
    onRoleDelete(id) {
      ElMessageBox.confirm("Whether to remove the user from the list?", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showClose: false,
        callback: action => {
          if (action === "confirm") {
            deleteRoleInvitation(id)
              .then(() => {
                const list = this.list.slice();
                const index = list.findIndex(item => item.id === id);
                list.splice(index, 1);
                this.$store.commit("setRoleInvitation", { list: list });
              })
              .catch(err => {
                this.$errHandler(err, "dialog");
              });
          }
        }
      });
    },
    onTabChange(name) {
      if (name === "email") {
        this.mixinId = "";
        this.mixinIdErr = "";
        return;
      }

      if (name === "mixin") {
        this.email = "";
        this.emailErr = "";
        return;
      }
    },
    onEmailInput() {
      this.emailErr = "";
    },
    onMixinIdInput() {
      this.mixinIdErr = "";
    },
    onAddDialogClose() {
      this.mixinId = "";
      this.email = "";
      this.emailErr = "";
      this.mixinIdErr = "";
    },
    onSubmit() {
      if (this.type === "mixin" && this.mixinId.length < 6) {
        this.mixinIdErr = "Please enter a correct Mixin ID.";
        return;
      }
      if (this.type === "email" && !validateEmail(this.email)) {
        this.emailErr = "The email must be a valid email address.";
        return;
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      sendRoleInvitation(
        this.type,
        this.type === "mixin" ? this.mixinId : this.email
      )
        .then(() => {
          this.isSubmitting = false;
          this.isAddDialogShow = false;
          this.onDailogOpen();
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.scroller {
  &-item {
    border: 1px solid #ececec;
    border-radius: 12px;
  }

  &-item-left {
    .avatar {
      width: 40px;
      height: 40px;
    }
  }

  &-item-mid {
    flex: 1 0 0%;

    .badge {
      color: #fff;
    }

    .nickname {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-item-right {
    .btn-delete {
      color: #f46;
    }
  }
}

.role-list {
  .el-radio {
    padding: 10px 20px !important;
    height: auto !important;
    border-color: #ececec !important;
    border-radius: 12px !important;
    white-space: normal;
  }

  :deep {
    .el-radio__inner {
      width: 18px !important;
      height: 18px !important;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      margin-left: 0.5rem;
      color: inherit !important;
    }
  }
}

.form {
  :deep {
    .el-input__wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .input-error {
    color: #f46;
  }
}

.el-radio-group {
  display: flex;
  width: 100%;

  :deep {
    .el-radio-button {
      width: 50%;
    }
    .el-radio-button__inner {
      width: 100%;
    }

    .el-radio {
      width: 100%;
    }
  }
}

.btn-group {
  .el-button {
    width: 100%;
    height: 50px;
  }
}
</style>