import EventEmitter from "events";

function getProvider() {
  let provider = null;
  if (
    window.ethereum &&
    window.ethereum.providers &&
    window.ethereum.providers.length > 1
  ) {
    provider = window.ethereum.providers.find(
      (_provider) => _provider.isMetaMask
    );
  }
  return provider || window.ethereum;
}

export const MVMChainId = "0x120c7";
export const NotFoundMvmErrCode = "4902";

class MetaMaskProvider {
  constructor() {
    this.provider = getProvider();
    this.events = new EventEmitter();

    if (this.provider) {
      this.provider.on("connect", () => {
        this.events.emit("connect");
      });
      this.provider.on("accountsChanged", () => {
        this.events.emit("accountsChanged");
      });
      this.provider.on("chainChanged", () => {
        this.events.emit("chainChanged");
      });
      this.provider.on("disconnect", () => {
        this.events.emit("disconnect");
      });
    }
  }

  addEvent(type, handler) {
    if (typeof handler !== "function") return;
    this.events.addListener(type, handler);
  }

  removeEvent(type, handler) {
    if (typeof handler !== "function") {
      this.events.removeAllListeners(type);
      return;
    }
    this.events.removeListener(type, handler);
  }

  hasMetaMask() {
    return !!this.provider;
  }

  isConnected() {
    return this.provider && this.provider.isConnected();
  }

  getChainId() {
    return this.provider.request({ method: "eth_chainId" });
  }

  getAccounts() {
    return this.provider.request({ method: "eth_requestAccounts" });
  }

  switchChainNetwork(chainId) {
    return this.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  }

  addMVMNetwork() {
    return this.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x120c7",
          chainName: "MVM Mainnet",
          rpcUrls: ["https://geth.mvm.dev/"],
          blockExplorerUrls: ["https://scan.mvm.dev/"],
          nativeCurrency: {
            symbol: "ETH",
            decimals: 18,
          },
        },
      ],
    });
  }

  async switchToMvmNetwork() {
    const chainId = await this.getChainId();
    if (chainId === MVMChainId) return;
    try {
      await this.switchChainNetwork(MVMChainId);
    } catch (switchError) {
      if (switchError.code === NotFoundMvmErrCode) {
        await this.addMVMNetwork();
      } else {
        throw switchError;
      }
    }
  }

  async watchMvmAsset(tokenAddress, tokenSymbol, tokenDecimals, tokenImage) {
    try {
      const wasAdded = await getProvider().request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Token added!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  getBalance(address) {
    return this.provider.request({
      method: "eth_getBalance",
      params: [address, "latest"],
      id: 1,
    });
  }
}

export default new MetaMaskProvider();
