
export function stringName(name, startLength, endLength) {
    if(startLength + endLength > name.length) {
        return name;
    }   
    return name.substring(0, startLength) + '...' + name.substring(name.length - endLength)
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      let fileResult = "";
      reader.readAsDataURL(file);
      reader.onload = () => {
        fileResult = reader.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.onloadend = () => {
        resolve(fileResult);
      };
    });
}

export function formatAssetId(uuid) {
  if(uuid.length != 32) {
    return uuid;
  }
  return uuid.substring(0, 8) + '-' + uuid.substring(8, 12) + '-' + uuid.substring(12, 16) + '-' + uuid.substring(16, 20) + '-' + uuid.substring(20, uuid.length);
}

export function upperFirstConcat(str) {
  var newStr = str.split(" ");    
  for(var i = 0; i<newStr.length; i++){
    newStr[i] = newStr[i].slice(0,1).toUpperCase() + newStr[i].slice(1).toLowerCase();
  } 
  return newStr.join(" ");
}