<template>
  <div class="login-view">
    <el-container>
      <el-col :xs="0" :sm="12">
        <div class="login-left col">
          <div class="login-left-header">
            <img
              class="login-logo"
              src="@/assets/images/login/login-logo-white.png"
              alt="MixPay | Dashboard"
            />
          </div>
          <div class="login-left-container col-center">
            <div class="col-center px-8">
              <h2 class="title">Built as Web3, for Web3</h2>
              <p class="brief">
                0% fees forever | Non-custodial | No KYC <br />
                70+ Tokens | 30+ Blockchains | Real-time settlement
              </p>
              <p class="img-box row-center">
                <img
                  class="img-crypto"
                  src="@/assets/images/login/login-cryptos.png"
                  alt="Binance,Coinbase,MetaMask,BTC,ETH,SHIB,DOGE,USDT"
                />
              </p>
              <p class="img-box row-center">
                <img
                  class="img-checkout-page"
                  src="@/assets/images/login/login-bg.png"
                  alt="Checkout Page"
                />
              </p>
            </div>
          </div>
          <div class="login-left-footer"></div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12">
        <div class="login-right col md:mx-2">
          <div class="login-right-header">
            <img
              class="login-logo md:hidden"
              src="@/assets/images/login/login-logo.png"
              alt="MixPay | Dashboard"
            />
          </div>
          <div class="login-right-container flex-grow">
            <div class="pane-wrapper col-center">
              <div class="pane">
                <div class="pane-header">
                  <h2 class="title">Login to your account</h2>
                </div>

                <div class="pane-body">
                  <!-- <div class="sub-title">CONTINUE WITH</div> -->
                  <div class="btn-group">
                    <div class="btn">
                      <el-button type="primary" @click="loginByMetaMask">
                        <img
                          class="btn-icon"
                          src="@/assets/images/login/icon-metamask.svg"
                          alt="MetaMask"
                        />
                        <span>MetaMask</span>
                      </el-button>
                    </div>

                    <div class="btn">
                      <el-button type="default" plain @click="loginByEmail">
                        <img
                          class="btn-icon"
                          src="@/assets/images/login/icon-email.svg"
                          alt="Email"
                        />
                        <span>Email</span>
                      </el-button>
                    </div>
                  </div>
                  <div class="division flex items-center">
                    <span class="division-line"></span>
                    <span class="division-text">or</span>
                    <span class="division-line"></span>
                  </div>

                  <div class="btn-group flex justify-between">
                    <div class="btn btn-inline">
                      <el-button type="default" plain @click="loginByMixin">
                        <img
                          class="btn-icon"
                          src="@/assets/images/login/icon-mixin.svg"
                          alt="Mixin"
                        />
                        <span>Mixin</span>
                      </el-button>
                    </div>
                    <div class="btn btn-inline">
                      <el-button type="default" plain @click="loginByEthWallet">
                        <img
                          src="@/assets/images/login/icon-wallet.svg"
                          alt="Wallet"
                          class="btn-icon"
                        />
                        <span>Wallet</span>
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="pane-footer">
                  <div class="row-center">
                    <el-tooltip placement="bottom" effect="light">
                      <template #content>
                        <p>
                          If you don't have an account, your first login will
                          <br />
                          automatically create a MixPay L2 decentralized wallet
                          for you.
                        </p>
                      </template>
                      <span class="row-center cursor-pointer">
                        <exp-svg-icon
                          class="icon"
                          name="question-circle-fill"
                          size="sm"
                        />
                        <span class="text">Don't have an account?</span>
                      </span>
                    </el-tooltip>
                    <a
                      class="link"
                      href="https://help.mixpay.me/en/collections/3923309-login-faq"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="login-right-footer">
            <div class="flex lg:justify-between">
              <div class="copyright hidden md:block">
                © MIXPMT LTD. 2023. All rights reserved.
              </div>
              <ul class="navbar flex">
                <li class="navbar-item">
                  <a
                    class="navbar-link"
                    target="_blank"
                    href="https://mixpay.me/user-agreement"
                  >
                    User Agreement
                  </a>
                </li>
                <li class="navbar-item">
                  <a
                    class="navbar-link"
                    target="_blank"
                    href="https://mixpay.me/privacy-policy"
                  >
                    Prviacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-container>

    <email-login-dialog v-model="isEmailLoginDialogShow" @jump="jump" />

    <more-methods-dialog v-model="isMoreDialogShow" @jump="jump" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getAuth, fetchMetaMaskNonce, postMetaMaskLogin } from "@/api/index.js";
import { ethers } from "ethers";
import { ElLoading, ElMessageBox } from "element-plus";
import EmailLoginDialog from "./email-login-dialog.vue";
import { web3onboard } from "@/plugins/web3onboard";
import { useOnboard } from "@web3-onboard/vue";
import MoreMethodsDialog from "./more-methods-dialog.vue";
import { MetaMaskProvider, signature } from "@/plugins/web3/index.js";
export default {
  name: "LoginView",

  components: {
    EmailLoginDialog,
    MoreMethodsDialog
  },

  data() {
    return {
      isEmailLoginDialogShow: false,

      isMoreDialogShow: false
    };
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device
    })
  },

  created() {
    if (this.$route.query.token) {
      // 授权回调
      this.$store.commit("setUserInfo", { token: this.$route.query.token });
      this.jump();
    } else if (this.userInfo.token) {
      // 已登录
      this.jump();
    }
  },

  methods: {
    jump() {
      if (this.$route.query.loginFrom) {
        this.$router
          .replace(decodeURIComponent(this.$route.query.loginFrom))
          .catch(() => {});
      } else {
        this.$router.replace("/").catch(() => {});
      }
    },

    async loginByMetaMask() {
      if (!MetaMaskProvider.hasMetaMask()) {
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          return ElMessageBox.confirm(
            "Please login with a desktop browser.",
            "Tips",
            {
              cancelButtonText: this.$t("actions.confirm"),
              type: "warning"
            }
          ).catch(() => {});
        } else {
          return ElMessageBox.confirm(
            "Please install MetaMask extension first",
            "Tips",
            {
              confirmButtonText: "Install MetaMask",
              cancelButtonText: this.$t("actions.cancel"),
              type: "warning"
            }
          )
            .then(() => {
              window.open("https://metamask.io", "_blank");
            })
            .catch(() => {});
        }
      }

      let metaMaskLoading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      try {
        const accounts = await MetaMaskProvider.getAccounts();
        const address = ethers.utils.getAddress(accounts[0]);
        const nonceData = await fetchMetaMaskNonce(address);
        const signatureData = await signature(
          MetaMaskProvider.provider,
          nonceData.data.nonce
        );
        const tokenData = await postMetaMaskLogin(address, signatureData);
        if (tokenData.data && tokenData.data.token) {
          this.$store.commit("setWalletAccount", address);
          this.$store.commit("setUserInfo", {
            walletAddress: address,
            token: tokenData.data.token
          });
          this.jump();
        }
      } catch (e) {
        var msg = e.reason || e.message;
        if (msg === "user rejected signing") {
          msg = "Account verification failed. Please try again later.";
        }
        ElMessageBox.confirm(msg, "", {
          confirmButtonText: "OK",
          type: "warning",
          showCancelButton: false
        }).catch(() => {});
      } finally {
        metaMaskLoading && metaMaskLoading.close();
      }
    },

    loginByEmail() {
      this.isEmailLoginDialogShow = true;
    },

    loginByMixin() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      getAuth(encodeURIComponent(location.href))
        .then(data => {
          if (data.data && data.data.url) {
            window.location.replace(data.data.url);
          }
        })
        .catch(err => {
          loading.close();
          this.$message({
            showClose: true,
            message: err.message,
            type: "error"
          });
        });
    },
    async loginByEthWallet() {
      let loading = null;
      try {
        const { connectedWallet } = useOnboard();
        if (!connectedWallet.value) {
          const c = await web3onboard.connectWallet();
          if (c.length <= 0) {
            return;
          }
        }
        loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        const address = ethers.utils.getAddress(
          connectedWallet.value.accounts[0].address
        );
        const nonceData = await fetchMetaMaskNonce(address);
        const signatureData = await signature(
          connectedWallet.value.provider,
          nonceData.data.nonce
        );
        const tokenData = await postMetaMaskLogin(address, signatureData);
        if (tokenData.data && tokenData.data.token) {
          this.$store.commit("setWalletAccount", address);
          this.$store.commit("setUserInfo", {
            walletAddress: address,
            token: tokenData.data.token
          });
          this.jump();
        }
      } catch (e) {
        const { disconnectConnectedWallet } = useOnboard();
        disconnectConnectedWallet();
        localStorage.removeItem("connectedWallet");
        const message = e.reason ? e.reason : e.message;
        this.$message({
          showClose: true,
          message: message,
          type: "error"
        });
      } finally {
        loading && loading.close();
      }
    },

    loginByMore() {
      this.isMoreDialogShow = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-left {
  justify-content: space-between;
  min-height: 100vh;
  background: linear-gradient(217deg, #5610ff 8.88%, #164aff 87.94%);

  .login-left-header {
    flex-shrink: 0;

    .login-logo {
      margin-top: 44px;
      margin-left: 44px;
      width: 214px;
    }
  }

  .login-left-footer {
    flex-shrink: 0;
    height: 68px;
  }

  .login-left-container {
    color: #fff;
    flex: 1 0 0;

    .title {
      font-size: 24px;
      line-height: 1.5;
    }

    .brief {
      margin-top: 14px;
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      color: #aaa6ff;
    }

    .img-box {
      .img-crypto {
        width: 151px;
        margin-top: 16px;
      }

      .img-checkout-page {
        margin-top: 8px;
        width: 434px;
        max-width: 100%;
      }
    }
  }
}

.login-right {
  justify-content: space-between;
  margin: 0 20px;
  min-height: 100vh;

  @media only screen and (min-width: 768px) {
    margin: 0 80px;
  }

  .login-right-container {
    flex: 1 0 0;
  }

  .login-right-header {
    width: 100%;

    .login-logo {
      margin-top: 44px;
      width: 214px;
    }
  }

  .pane {
    width: 100%;
    max-width: 336px;

    &-wrapper {
      width: 100%;
      height: 100%;
    }
    &-header {
      .title {
        margin-bottom: 32px;
        font-size: 24px;
        text-align: center;
      }
    }

    &-body {
      .sub-title {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 1.5;
        color: #86909c;
      }
      .btn {
        margin-bottom: 16px;
        box-sizing: border-box;
        height: 44px;
        font-size: 16px;
        border-radius: 6px;

        &.btn-inline {
          margin-right: 12px;
          flex-grow: 1;
          width: calc(33.33% - 8px);

          &:last-child {
            margin-right: 0;
          }
        }

        .el-button {
          width: 100%;
          height: 44px;
          font-size: 16px;
          border-radius: 6px;
        }

        .el-button--primary {
          background-color: #164aff;
          border-color: #164aff;
          box-shadow: 0px 2px 4px rgba(#164aff, 0.05);

          &:hover {
            background-color: #4080ff;
            border-color: #4080ff;
            box-shadow: 0px 2px 4px rgba(#4080ff, 0.05);
            transform: scale(1);
          }

          &:active {
            background-color: #0e42d2;
            border-color: #0e42d2;
            box-shadow: 0px 2px 4px rgba(#0e42d2, 0.05);
          }
        }

        .el-button--default {
          background-color: #fff;
          border-color: #f2f3f5;
          box-shadow: 0px 2px 4px rgba(#000, 0.05);
          color: inherit;

          &:hover {
            border-color: #e5e6eb;
            background-color: #f2f3f5;
            transform: scale(1);
          }

          &:active {
            border-color: #e5e6eb;
            background-color: #e5e6eb;
          }
        }

        .btn-icon {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }
      }

      .division {
        margin: 16px 0;
        font-size: 14px;
        line-height: 16px;
        height: 16px;

        .division-text {
          margin: 0 12px;
          color: #c9cdd4;
        }

        .division-line {
          flex: 1 0 0;
          border-top: 1px solid #eeeeee;
        }
      }

      .btn-more-dot {
        margin-right: 3px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #272636;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-footer {
      margin-top: 32px;
      font-size: 12px;
      color: #1d2129;
      .link {
        margin-left: 16px;
        color: #164aff;

        &:hover {
          text-decoration: underline;
        }
      }

      .icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .login-right-footer {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #f0f0f0;
    font-size: 10px;
    line-height: 20px;

    .copyright {
      color: #86909c;
    }

    .navbar {
      .navbar-item {
        margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }
      }

      .navbar-link {
        color: #164aff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>