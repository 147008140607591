import store from "../store";

const handler = function (el, binding) {
  const roles = Array.isArray(binding.value) ? binding.value : [];
  if (!roles.includes(store.state.userInfo.role)) {
    el.setAttribute("style", "display:none!important");
  } else {
    el.removeAttribute("style");
  }
};

export default {
  mounted: handler,
  updated: handler,
};
