<template>
  <el-dialog
    title="Select a Token"
    top="10vh"
    width="90%"
    style="max-width:480px;"
    :close-on-click-modal="false"
    @closed="onDialogClosed"
  >
    <div>
      <div class="filter-control">
        <el-input
          rounded
          type="text"
          placeholder="Search"
          v-model="filterStr"
        ></el-input>
      </div>
      <!-- <div class="common-control mt-4 pb-4">
        <div class="common-control-title text-base">Common tokens</div>
        <div class="common-control-content row">
          <div
            class="common-item row-center cursor-pointer rounded-full px-2 py-1 mt-2 mr-2"
            v-for="item in commonList"
            :key="item.uuid"
            @click="onChoose(item.uuid)"
          >
            <exp-symbol :uuid="item.uuid" :iconUrl="item.icon" size="lg" />
            <span class="symbol ml-2">{{ item.symbol }}</span>
          </div>
        </div>
      </div> -->
      <div class="scroller mt-2">
        <ul class="scroller-list text-base" v-if="filterOptions.length">
          <li
            v-for="item in filterOptions"
            :key="item.uuid"
            class="py-3 scroller-item row-align-center cursor-pointer"
            @click="onChoose(item.uuid)"
          >
            <exp-symbol :uuid="item.uuid" :iconUrl="item.icon" size="lg" />
            <span class="symbol ml-4">{{ item.symbol }}</span>
            <span class="balance">{{ item.amount }}</span>
          </li>
        </ul>
        <div class="py-20 row-center" v-else>
          <span class="text-sm">Not Found</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "SwapAssetPicker",

  props: {
    options: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filterStr: "",
      commonList: [
        {
          uuid: "43d61dcd-e413-450d-80b8-101d5e903357",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/43d61dcd-e413-450d-80b8-101d5e903357.png",
          symbol: "ETH"
        },
        {
          uuid: "25dabac5-056a-48ff-b9f9-f67395dc407c",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/25dabac5-056a-48ff-b9f9-f67395dc407c.png",
          symbol: "TRX"
        },
        {
          uuid: "1949e683-6a08-49e2-b087-d6b72398588f",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/1949e683-6a08-49e2-b087-d6b72398588f.png",
          symbol: "BNB(BEP20)"
        },
        {
          uuid: "4d8c508b-91c5-375b-92b0-ee702ed2dac5",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/4d8c508b-91c5-375b-92b0-ee702ed2dac5.png",
          symbol: "USDT(ERC20)"
        },
        {
          uuid: "b91e18ff-a9ae-3dc7-8679-e935d9a4b34b",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/b91e18ff-a9ae-3dc7-8679-e935d9a4b34b.png",
          symbol: "USDT(TRC20)"
        },
        {
          uuid: "94213408-4ee7-3150-a9c4-9c5cce421c78",
          icon:
            "https://d3m2vh9f00s059.cloudfront.net/fiats/94213408-4ee7-3150-a9c4-9c5cce421c78.png",
          symbol: "USDT(BEP20)"
        }
      ]
    };
  },

  computed: {
    filterOptions() {
      const key = String(this.filterStr).toLowerCase();
      return this.options.filter(item => {
        return (
          String(item.symbol)
            .toLowerCase()
            .indexOf(key) > -1
        );
      });
    }
  },

  methods: {
    onChoose(uuid) {
      this.$emit("choose", uuid);
    },
    onDialogClosed() {
      this.filterStr = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-control {
  :deep {
    .el-input__wrapper {
      padding-left: 20px;
      padding-right: 20px;
      height: 50px;
      border-radius: 25px;
    }
  }
}
.scroller {
  height: 400px;
  overflow: hidden auto;

  &-item {
    border-bottom: 1px solid #ececec;

    .symbol {
      flex: 1 0 0%;
    }
  }
}

.common-control {
  border-bottom: 1px solid #ececec;

  &-content {
    flex-wrap: wrap;
  }
  .common-item {
    border: 1px solid #ececec;
  }
}
</style>