<template>
  <div v-loading="loading">
    <body-header />
    <div class="max-w-[680px] m-10 p-10 bg-[#f6f9ff] rounded-xl">
      <!-- <h2 class="row-center">API-Key?</h2> -->
      <p class="text-font-color leading-6">For developers interested in building applications using our <el-link href="https://mixpay.me/developers" type="primary" target="_blank">API Service</el-link>, please create an Api-Key Token which you can then use with all your api requests.</p>
      <div class="mt-12">
        <el-button class="w-1/4" type="primary" @click="createApiKey">{{ userInfo.hasPrivateKey ? 'Recreation' : 'Create API Key' }}</el-button>
      </div>
    </div>
    <p class="mt-12 ml-10 text-hint">Neither the server nor the browser saves sensitive information. If you forget, you can generate a new one.</p>

    <el-dialog v-model="isShowDialog" title="API Key" class="w-max">
      <div class="dialog-body py-4">
        <div class="api-key">{{ apiKey }}</div>
        <div class="dialog-tips row-align-center mt-3 mb-8">
          <exp-svg-icon name="alert" size="xs"/>
          <p class="ml-3">Neither the server nor the browser saves sensitive information. Please keep it safe.</p>
        </div>
        <div class="row-center gap-x-6">
          <el-button class="w-1/3" @click="closeDialog">{{ $t("actions.cancel") }}</el-button>
          <el-button class="w-1/3" type="primary" @click="copyApiKey">Copy</el-button>
        </div>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { ElMessageBox, ElMessage } from "element-plus";
import { getApiKey } from "@/api/index"; 
import copyText from "@/utils/copy-text.js";

export default {

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  data() {
    return {
      loading: false,
      isShowDialog: false,
      apiKey: 'iodsdmsnfdoufhouhfndslnfosdlho',

      publicKey: '',
      privateKey: '',
    }
  },
  
  mounted() {
     this.$store.dispatch("updateUserInfo");
  },

  methods: {
    createApiKey() {
      ElMessageBox.confirm(this.userInfo.hasPrivateKey ? 'Is it confirmed to generate API Key?</br>The old API Key is automatically invalidated after recreation.' : 'Is it confirmed to generate API Key?', "Tips", {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('actions.confirm'),
        cancelButtonText: this.$t('actions.cancel'),
      }).then(() => {
        this.getKey();
      });
    },
    getKey() {
      this.loading = true;
      getApiKey().then(data => {
        this.loading = false;
        if(data && data.data) {
          this.apiKey = JSON.stringify(data.data);
          this.isShowDialog = true;
        }
      }).catch(err => {
        this.loading = false;
        this.$errHandler(err, "dialog");
      })
    },
    closeDialog() {
      this.isShowDialog = false;
    },
    copyApiKey() {
      if (copyText(this.apiKey)) {
        ElMessage({
          message: 'Copied to clipboard',
          type: 'success',
        })
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.text-hint {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}
.dialog-body {
  width: 490px;
}
.api-key {
  font-size: 16px;
  font-weight: 500;
  color: #1E1E22;
  line-height: 20px;
  background: #f6f9ff;
  padding: 16px;
  border-radius: 12px;
  word-break: break-all;
}
.dialog-tips {
  color: #E6A23C;
  font-size: 14px;
  line-height: 20px;
}
</style>