<template>
  <div>
    <el-dialog
      title="Withdraw"
      v-model="isWithdrawShow"
      top="10vh"
      width="90%"
      style="max-width:480px"
      :close-on-click-modal="false"
      append-to-body
      @close="onDialogClose"
    >
      <div class="pb-6">
        <div class="step" v-if="step == 0">
          <div class="control-group">
            <div class="control-item">
              <div class="control-item-content">
                <div
                  class="input-item dropdown-link row-align-center cursor-pointer"
                  @click="onAssetDropdownChange"
                >
                  <exp-symbol
                    :uuid="uuid"
                    :iconUrl="selectAsset.icon"
                    size="xl"
                  />
                  <div class="ml-2 text">
                    <p class="name">{{ selectAsset.symbol }}</p>
                    <p class="balance mt-1">
                      Balance: {{ selectAsset.amount }}
                    </p>
                  </div>
                  <div class="row-center">
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </div>
                </div>
                <div class="dropdown-menu" v-show="isAssetDropdownShow">
                  <div class="dropdown-menu-scroller">
                    <div
                      class="dropdown-item row-align-center cursor-pointer"
                      v-for="item in list"
                      :key="item.uuid"
                      @click="onChooseAsset(item)"
                    >
                      <exp-symbol
                        :uuid="item.uuid"
                        :iconUrl="item.icon"
                        size="xl"
                      />
                      <div class="ml-2 text">
                        <p class="name">{{ item.symbol }}</p>
                        <p class="balance">Balance: {{ item.amount }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="control-item mt-4">
              <div class="control-item-title">Address</div>
              <div class="control-item-content">
                <div
                  class="input-item dropdown-link row-align-center cursor-pointer"
                  @click="showAddressDialog"
                >
                  <div class="text">
                    <template v-if="!selectAddress">
                      <div class="text-light mt-4">Select Address</div>
                    </template>
                    <template v-else>
                      <div class="label">{{ selectAddress.label }}</div>
                      <div class="address">
                        {{ selectAddress.destination }} {{ selectAddress.tag }}
                      </div>
                    </template>
                  </div>
                  <div class="row-center">
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="control-item mt-4">
              <div class="control-item-title">Amount</div>
              <div class="control-item-content mt-2">
                <el-input
                  v-model="amount"
                  type="number"
                  placeholder="0.0"
                  @input="onAmountChange"
                >
                  <template #append>
                    <div
                      class="btn-max row-center cursor-pointer"
                      @click="onMaxClick"
                    >
                      MAX
                    </div>
                  </template>
                </el-input>
              </div>
              <ul class="control-item-footer">
                <li
                  class="list-item mt-2"
                  v-if="selectAddress && selectAddress.fee >= 0"
                >
                  Gas Fee:
                  <strong>
                    {{ selectAddress.fee }}
                    {{ selectAddress.feeAssetSymbol }}
                  </strong>
                </li>
                <li class="list-item mt-2" v-if="feeAsset">
                  <p class="row-align-center">
                    Gas Fee Balance:
                    <strong class="ml-1">
                      {{ feeAsset.amount }} {{ feeAsset.symbol }}
                    </strong>
                    <!-- <el-link type="primary" class="ml-2" @click="goSwap">
                      Swap
                    </el-link> -->
                  </p>
                </li>
                <li
                  class="list-item mt-2"
                  v-if="selectAddress && selectAddress.dust > 0"
                >
                  Minimum withdrawal:
                  <strong>
                    {{ selectAddress.dust }}
                    {{ selectAddress.assetSymbol }}</strong
                  >
                </li>
                <li
                  class="list-item mt-2"
                  v-if="selectAddress && selectAddress.reserve > 0"
                >
                  Minimum reserve:
                  <strong>
                    {{ selectAddress.reserve }}
                    {{ selectAddress.assetSymbol }}
                  </strong>
                </li>
                <li class="mt-2 input-error" v-if="errMsg">{{ errMsg }}</li>
              </ul>
            </div>
          </div>
          <div class="btn-group mt-12 mx-8">
            <el-button type="primary" @click="goStep(1)">Continue</el-button>
          </div>
          <div class="withdraw-fqa mt-8">
            <p class="text-center">
              <el-link
                type="primary"
                href="https://help.mixpay.me/en/articles/7190520-how-to-withdraw-from-mixpay-dashboard-wallet"
                target="_blank"
              >
                How to withdraw?
              </el-link>
            </p>
          </div>
        </div>
        <div class="step" v-if="step == 1">
          <div class="back-wrapper">
            <div
              class="back-btn row-align-center cursor-pointer"
              @click="goStep(0)"
            >
              <exp-svg-icon name="back" size="sm" />
              <span class="text-base">Back</span>
            </div>
          </div>
          <div
            class="detail px-4 py-4 mt-4 text-sm"
            v-if="selectAddress && selectAsset"
          >
            <div class="detail-item row-justify-center">
              <div class="detail-item-title">Amount</div>
              <div class="detail-item-content">
                {{ amount }} {{ selectAsset.symbol }}
              </div>
            </div>
            <div class="detail-item row-justify-center mt-2">
              <div class="detail-item-title">Address</div>
              <div class="detail-item-content">
                {{ selectAddress.destination }}
              </div>
            </div>
            <div class="detail-item row-justify-center mt-2">
              <div class="detail-item-title">Network</div>
              <div class="detail-item-content">
                {{ selectAddress.network }}
              </div>
            </div>
            <div class="detail-item row-justify-center mt-2">
              <div class="detail-item-title">Fee</div>
              <div class="detail-item-content">
                {{ selectAddress.fee }} {{ selectAddress.feeAssetSymbol }}
              </div>
            </div>
          </div>
          <ol class="tips mt-4">
            <li class="list-item">
              Ensure that the address is correct and on the same network.
            </li>
            <li class="list-item">Transactions cannot be canceled.</li>
          </ol>
          <div class="btn-group mt-12 mx-8">
            <el-button type="primary" @click="goStep(2)">Continue</el-button>
          </div>
        </div>
        <div class="step" v-if="step == 2">
          <div class="back-wrapper">
            <div
              class="back-btn row-align-center cursor-pointer"
              @click="goStep(1)"
            >
              <exp-svg-icon name="back" size="sm" />
              <span class="text-base">Back</span>
            </div>
          </div>
          <h2 class="text-center mt-4">Security Verification</h2>
          <div class="codebox-wrapper mt-8 mx-8">
            <div class="codebox-title">Enter the 6-digit PIN</div>
            <div class="codebox-control mt-4">
              <code-box ref="codebox" mask v-model:value="pin" auto-focus />
            </div>
          </div>
          <div class="btn-group mt-12 mx-8">
            <el-button type="primary" @click="onSubmit" :loading="isSubmitting">
              Confirm
            </el-button>
          </div>
          <div class="tips-wrapper">
            <p class="mt-8 mx-8">
              <a
                href="https://help.mixpay.me/en/articles/7185569-i-forgot-the-pin-what-should-i-do"
                target="_blank"
                class="text-main-color"
              >
                Lost your 6-digit PIN?
              </a>
            </p>
          </div>
        </div>
      </div>
    </el-dialog>

    <address-list
      v-model="isAddressDialogShow"
      :addressId="addressId"
      :uuid="uuid"
      @choose="chooseAddress"
      @add-address="addNewAddress"
      @delete-address="deleteAddress"
    />

    <delete-address-dialog
      v-model="isDeleteAddressDialogShow"
      :uuid="uuid"
      :addressId="deleteAddressId"
      @close-dialog="deleteAddress"
    />

    <add-address-dialog
      v-model="isAddAddressDialogShow"
      :uuid="uuid"
      @close-dialog="addNewAddress"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fixDecimal } from "@/utils/num-format";
import CodeBox from "../codebox";
import AddressList from "./address-list.vue";
import AddAddressDialog from "./add-withdraw-address-dialog.vue";
import DeleteAddressDialog from "./delete-address-dialog.vue";
import { withdrawToAddress } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import Big from "big.js";

Big.NE = -9;

export default {
  components: {
    CodeBox,
    AddressList,
    AddAddressDialog,
    DeleteAddressDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },

  data() {
    const addressMapStr = localStorage.getItem("wallet_address_map");
    let selectAddressMap = {};
    if (addressMapStr) {
      try {
        selectAddressMap = JSON.parse(addressMapStr);
      } catch (e) {
        selectAddressMap = {};
      }
    }

    return {
      amount: "",
      addressId: "",
      pin: "",
      errMsg: "",
      isAssetDropdownShow: false,
      isAddressDropdownShow: false,
      step: 0,
      isSubmitting: false,

      isWithdrawShow: false,
      isAddressAddShow: false,

      isAddressDialogShow: false,
      isAddAddressDialogShow: false,

      isDeleteAddressDialogShow: false,
      deleteAddressId: '',

      selectAddressMap: selectAddressMap
    };
  },

  watch: {
    value() {
      this.isWithdrawShow = this.value;
    },
    uuid: {
      handler() {
        const { uuid } = this;
        if (!uuid) return;
        this.$store
          .dispatch("updateEmailAddressMap", uuid)
          .then(() => {
            // this.autoSelect();
          })
          .catch(() => {});
      },
      immediate: true
    }
  },

  computed: {
    ...mapState({
      list: state => state.emailAssets.list,
      emailAssetAddressMap: state => state.emailAssetAddressMap
    }),
    selectAsset() {
      return this.list.find(item => item.uuid === this.uuid) || {};
    },
    addressMap() {
      const { uuid, emailAssetAddressMap } = this;
      return emailAssetAddressMap[uuid];
    },
    selectAddress() {
      const { addressId, addressMap } = this;
      if (!addressMap || !addressMap.list) {
        return null;
      }
      return (
        addressMap.list.find(item => Number(item.id) === Number(addressId)) ||
        null
      );
    },
    feeAsset() {
      const { selectAddress, list } = this;
      if (!selectAddress) return null;
      return list.find(item => item.uuid === selectAddress.feeAssetId) || null;
    }
  },

  methods: {
    onDialogClose() {
      this.addressId = "";
      this.errMsg = "";
      this.amount = "";
      this.pin = "";
      this.step = 0;
      this.$emit("update:value", false);
    },
    autoSelect() {
      const { uuid, addressMap } = this;
      if (!uuid || !addressMap || !Array.isArray(addressMap.list)) return;
      const id = this.selectAddressMap[uuid];
      if (addressMap.list.find(item => Number(item.id) === Number(id))) {
        this.addressId = id;
      }
    },
    onAssetDropdownChange() {
      this.isAddressDropdownShow = false;
      this.isAssetDropdownShow = !this.isAssetDropdownShow;
    },
    onChooseAsset(item) {
      if (this.uuid !== item.uuid) {
        this.$emit("update:uuid", item.uuid);
        this.addressId = "";
        this.amount = "";
      }
      this.isAssetDropdownShow = false;
    },
    onAmountChange() {
      this.$nextTick(() => {
        this.amount = fixDecimal(this.amount, 8);
        this.errMsg = "";
      });
    },
    onMaxClick() {
      const { selectAddress, selectAsset } = this;

      if (!selectAsset) return;

      let fee = Big("0");
      let reserve = Big("0");
      let output = Big("0");
      let balance = Big(selectAsset.amount > 0 ? selectAsset.amount : "0");
      if (
        selectAddress &&
        selectAddress.assetId === selectAddress.feeAssetId &&
        selectAddress.fee > 0
      ) {
        fee = Big(selectAddress.fee);
      }
      if (selectAddress && selectAddress.reserve > 0) {
        reserve = Big(selectAddress.reserve);
      }

      output = balance.minus(fee).minus(reserve);

      if (output.lt("0")) {
        output = Big("0");
      }

      this.amount = output.toString();
    },
    goStep(step) {
      if (step === 0) {
        //
      }
      if (step === 1) {
        this.pin = "";
        this.errMsg = "";
        if (!this.selectAddress) {
          this.errMsg = "The address is invalid";
          return;
        }
        if (this.amount <= 0) {
          this.errMsg = "The amount is invalid.";
          return;
        }
        const amount = Big(this.amount);
        const balance = Big(
          this.selectAsset.amount > 0 ? this.selectAsset.amount : "0"
        );
        const {
          dust,
          reserve,
          assetId,
          assetSymbol,
          feeAssetId,
          fee
        } = this.selectAddress;
        const feeAsst = this.list.find(item => item.uuid === feeAssetId);
        if (amount.lt(dust > 0 ? dust : "0")) {
          this.errMsg = `The amount can no be less than ${dust} ${assetSymbol}`;
          return;
        }
        if (reserve > 0 && balance.minus(amount).lt(reserve)) {
          this.errMsg = `The minimum reserve is ${reserve} ${assetSymbol}`;
          return;
        }
        if (
          balance
            .minus(assetId === feeAssetId && fee > 0 ? fee : "0")
            .lt(amount)
        ) {
          this.errMsg = "Balance Insufficient";
          return;
        }
        if (fee > 0 && +fee > (feeAsst ? +feeAsst.amount : 0)) {
          ElMessageBox.confirm(
            "Insufficient balance for gas fee. You can go to Swap to exchange the required gas fee.",
            "Reminder",
            {
              confirmButtonText: "Go Swap",
              cancelButtonText: "I Got It",
              showClose: false,
              callback: action => {
                if (action === "confirm") {
                  this.$router.replace("/wallet/swap");
                }
              }
            }
          );
          return;
        }
      }

      if (step == 2) {
        //
      }
      this.step = step;
    },
    onSubmit() {
      if (this.pin.length < 6) {
        ElMessageBox.alert(
          "Please make sure your password contains at least 6 characters.",
          "Reminder",
          {
            confirmButtonText: "Confirm",
            showClose: false,
            callback: () => {
              this.$refs.codebox && this.$refs.codebox.focus();
            }
          }
        );
        return;
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      withdrawToAddress(this.addressId, this.amount, this.pin)
        .then(() => {
          ElMessage({
            message: "Withdraw Successfully",
            type: "success"
          });
          this.isSubmitting = false;
          this.$emit("update:value", false);
          this.$store.dispatch("updateEmailUserAssets").catch(() => {});
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog", () => {
            this.pin = "";
            this.$refs.codebox && this.$refs.codebox.focus();
          });
        });
    },
    showAddressDialog() {
      this.isAddressDialogShow = !this.isAddressDialogShow;
    },
    chooseAddress(addressId) {
      this.addressId = addressId;
      this.selectAddressMap[this.uuid] = addressId;
      localStorage.setItem(
        "wallet_address_map",
        JSON.stringify(this.selectAddressMap)
      );
      this.isAddressDialogShow = false;
    },
    addNewAddress() {
      this.isAddAddressDialogShow = !this.isAddAddressDialogShow;
    },
    deleteAddress(addressId) {
      this.deleteAddressId = String(addressId);
      this.isDeleteAddressDialogShow = !this.isDeleteAddressDialogShow;
    },
    goSwap() {
      let pay = "4d8c508b-91c5-375b-92b0-ee702ed2dac5"; // USDT
      let receive = "43d61dcd-e413-450d-80b8-101d5e903357"; // ETH
      if (this.selectAddress) {
        if (
          this.selectAddress.feeAssetId ===
          "4d8c508b-91c5-375b-92b0-ee702ed2dac5"
        ) {
          [pay, receive] = [receive, pay];
        } else {
          receive = this.selectAddress.feeAssetId;
        }
      }
      localStorage.setItem("swap_assets", `${pay},${receive}`);
      this.$router.push("/wallet/swap");
    }
  }
};
</script>

<style lang="scss" scoped>
.control-item {
  &-title {
    font-size: 1rem;
  }

  &-content {
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    &.no-border {
      border-bottom: none;
    }

    .el-input {
      --el-input-border-radius: 12px;
      --el-input-height: 50px;
    }
  }

  &-footer {
    padding-left: 20px;

    .list-item {
      list-style: disc;
    }

    .input-error {
      margin-left: -20px;
      color: #ff4466;
    }
  }

  .input-item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 50px;

    .text {
      flex: 1 0 0%;
    }

    .name,
    .label {
      font-size: 1rem;
    }

    .text-light {
      font-size: 1rem;
      color: #828282;
    }

    .text-strong {
      font-size: 1rem;
    }
  }

  .el-input :deep {
    .el-input__wrapper {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .el-input-group__append {
      box-shadow: none;
      background: transparent;
    }

    .el-input__inner {
      height: 50px;
      font-size: 1.25rem;
      line-height: 1;
    }

    .btn-max {
      color: #409eff;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 100;
    width: 100%;

    .dropdown-menu-scroller {
      padding: 20px;
      max-height: 300px;
      border-radius: 16px;
      overflow: hidden scroll;
      background: #fff;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 50%;
      top: -6px;
      width: 10px;
      height: 10px;
      border: 1px solid #e4e7ed;
      border-right-color: transparent;
      border-bottom-color: transparent;
      transform: rotate(45deg);
      background: #fff;
    }

    .dropdown-item {
      padding: 10px 0;
      border-bottom: 1px solid #e6e6e6;

      &.btn-add {
        border-bottom: 0;
      }
    }
  }
}

.btn-group {
  .el-button {
    width: 100%;
    height: 50px;
  }
}

.detail {
  border-radius: 16px;
  background: #f1f1f1;

  .detail-item-content {
    max-width: 60%;
    word-break: break-all;
    text-align: right;
  }
}

.codebox-wrapper {
  :deep .codebox-box {
    flex: 0 1 40px;
    width: 40px;
  }
}

.tips {
  padding-left: 20px;
  text-align: left;

  .list-item {
    list-style: disc;
  }
}
</style>