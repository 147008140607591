<template>
  <el-dialog
    :title="title"
    top="10vh"
    width="90%"
    style="max-width:480px"
    :close-on-click-modal="false"
    append-to-body
    @close="onDialogClose"
  >
    <div v-if="step === 0" class="step pb-6">
      <h2 class="mx-4 my-4">
        Do you want to remove that address from your address book?
      </h2>
      <div class="btn-group mt-12">
        <el-button type="primary" @click="goStep(1)">Continue</el-button>
      </div>
    </div>
    <div v-if="step === 1" class="step pb-6">
      <div class="back-wrapper">
        <div
          class="back-btn row-align-center cursor-pointer"
          @click="goStep(0)"
        >
          <exp-svg-icon name="back" size="sm" />
          <span>Back</span>
        </div>
      </div>
      <div class="control-group mt-6 mx-8">
        <div class="control-item codebox-control">
          <div class="control-item-title mb-2">Enter the 6-digit PIN</div>
          <div class="control-item-content">
            <code-box
              ref="codebox"
              mask
              auto-focus
              v-model:value="pin"
            ></code-box>
          </div>
        </div>
      </div>
      <div class="btn-group mt-12 mx-8">
        <el-button type="primary" @click="onSubmit" :loading="isSubmitting">
          Confirm
        </el-button>
      </div>
      <div class="tips-wrapper">
        <p class="mt-8 mx-8">
          <a
            href="https://help.mixpay.me/en/articles/7185569-i-forgot-the-pin-what-should-i-do"
            target="_blank"
            class="text-main-color"
          >
            Lost your 6-digit PIN?
          </a>
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import CodeBox from "../codebox";
import { noSci } from "@/utils/num-format";
import { deleteAddress } from "@/api/index";
import { ElMessageBox } from "element-plus";
export default {
  name: "DeleteWithdrawAddressDialog",

  components: {
    CodeBox
  },

  filters: {
    noSci
  },

  props: {
    addressId: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      pin: "",
      step: 0,
      isSubmitting: false
    };
  },

  computed: {
    ...mapState({
      emailAssets: state => state.emailAssets
    }),
    title() {
      const { uuid, emailAssets } = this;
      const asset = emailAssets.list.find(item => item.uuid === uuid);
      return asset ? `Delete ${asset.symbol} Address` : "Delete Address";
    }
  },

  methods: {
    onDialogClose() {
      this.pin = "";
      this.step = 0;
      this.isSubmitting = false;
    },
    goStep(step) {
      if (step === 0) {
        this.pin = "";
        this.step = 0;
      }
      if (step === 1) {
        this.step = 1;
        this.$refs.codebox && this.$refs.codebox.focus();
      }
    },
    onSubmit() {
      if (this.pin.length < 6) {
        ElMessageBox.alert(
          "Please make sure your password contains at least 6 characters.",
          "Reminder",
          {
            confirmButtonText: "Confirm",
            showClose: false,
            callback: () => {
              this.$refs.codebox && this.$refs.codebox.focus();
            }
          }
        );
        return;
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      deleteAddress(this.addressId, this.pin)
        .then(() => {
          this.isSubmitting = false;
          this.$emit("close-dialog");
          this.$store
            .dispatch("updateEmailAddressMap", this.uuid)
            .catch(() => {});
        })
        .catch(err => {
          this.isSubmitting = false;
          ElMessageBox.alert(err.message, "Tips", {
            confirmButtonText: "Confirm",
            showClose: false,
            callback: () => {
              this.pin = "";
              this.$refs.codebox && this.$refs.codebox.focus();
            }
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  .icon {
    width: 72px;
    height: 72px;
  }

  .el-input {
    height: 50px;

    & :deep {
      .el-input__wrapper {
        padding: 1px 20px;
      }
    }
  }

  .control-item-title {
    font-size: 1rem;
  }

  .el-button {
    width: 100%;
    height: 50px;
  }

  .back-wrapper {
    font-size: 1.25rem;
  }

  .error-msg {
    color: #ff4466;
  }

  .codebox-control {
    :deep .codebox-box {
      flex: 0 1 40px;
      width: 40px;
    }
  }
}
</style>