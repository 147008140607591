<template>
  <el-dialog
    :title="$t('link.editPaymentLink')"
    top="10vh"
    width="90%"
    style="max-width:480px"
    @open="dialogOpen"
    @close="dialogClose"
    destroy-on-close
  >
    <el-form class="el-form" ref="loginForm" :model="form" label-position="top">
      <div class="upload-avatar row-center">
        <el-upload
          class="avatar-uploader"
          name="avatar"
          :show-file-list="false"
          :on-change="handelAvatarChange"
          :auto-upload="false"
        >
          <img :src="avatarUrl" class="avatar" />
          <el-icon class="upload-icon" size="20px"><UploadFilled /></el-icon>
        </el-upload>
      </div>
      <el-form-item :label="$t('receiveSettings.qrcodeName')">
        <el-input
          v-model="qrname"
          maxlength="20"
          show-word-limit
          size="large"
          :placeholder="$t('actions.typeHere')"
        />
      </el-form-item>
      <el-form-item
        class="note-input"
        :label="$t('receiveSettings.noteOptional')"
        :class="device === 'mobile' ? 'mt-3' : 'mt-10'"
      >
        <el-input
          v-model="remark"
          size="large"
          :placeholder="$t('actions.typeHere')"
          maxlength="50"
        />
      </el-form-item>
    </el-form>
    <div class="col-center">
      <el-button
        class="w-1/2"
        type="primary"
        @click="editLink"
        :loading="isSubmitting"
      >
        Confirm
      </el-button>
      <el-link class="mt-4" type="danger" @click="deleteLink">Delete</el-link>
    </div>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
import { deleteUserReceive, postReceiveProfile } from "@/api/index.js";
import { compressAccurately } from "image-conversion";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { getBase64 } from "@/utils/string.js";

export default {
  components: {},

  props: {
    linkData: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device
    })
  },

  data() {
    return {
      id: "",
      qrname: "",
      avatarUrl: "",
      remark: "",
      isSubmitting: false,
      avatarLoading: false
    };
  },

  methods: {
    dialogOpen() {
      if (this.linkData) {
        this.id = this.linkData.id;
        this.avatarUrl = this.linkData.avatar;
        this.qrname = this.linkData.name;
        this.remark = this.linkData.note;
      }
    },

    dialogClose() {
      this.qrname = "";
      this.$emit("editDialogClose");
    },

    editLink() {
      if (!this.qrname) {
        ElMessage.error(this.$t("receiveSettings.qrcodeNameTip"));
        return;
      }
      if (
        this.qrname === this.linkData.name &&
        this.avatarUrl === this.linkData.avatar &&
        this.remark === this.linkData.note
      ) {
        this.$emit("editDialogClose");
        return;
      }
      this.isSubmitting = true;
      postReceiveProfile(
        this.id,
        this.avatarUrl === this.linkData.avatar ? null : this.avatarUrl,
        this.qrname,
        this.remark ? this.remark : ""
      )
        .then(() => {
          this.isSubmitting = false;
          this.$emit("successEdit");
          ElNotification({
            title: "Success",
            message: "Edit Payment Link succeeded.",
            type: "success"
          });
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    },
    handelAvatarChange(file) {
      if (file.size / 1024 > 200) {
        compressAccurately(file.raw, 200).then(res => {
          getBase64(res).then(data => {
            this.avatarUrl = data;
          });
        });
      } else {
        getBase64(file.raw).then(data => {
          this.avatarUrl = data;
        });
      }
    },
    deleteLink() {
      ElMessageBox.confirm(this.$t("myQrcode.deleteConfirm"), "Tips", {
        confirmButtonText: this.$t("actions.confirm"),
        cancelButtonText: this.$t("actions.cancel")
      })
        .then(() => {
          deleteUserReceive(this.id)
            .then(() => {
              this.$emit("successEdit");
            })
            .catch(err => {
              this.$errHandler(err, "dialog");
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-avatar {
  height: 100px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
}

.upload-icon {
  position: relative;
  bottom: -40px;
  right: 10px;
  color: color-primary;
}

.el-form-item {
  margin: 0 0 40px;
}

.el-button {
  height: 50px;
}

.el-input {
  height: 50px;
  font-size: 16px;
}

.input-symbol {
  width: 50%;
  margin-bottom: 40px;

  &-label {
    font-size: 16px;
    color: #1e1e22;
    font-weight: 500;
  }

  &-wrapper {
    height: 50px;
    font-size: 16px;
    border: 1px solid #dcdfe6;
    border-radius: 10px;
    margin-top: 8px;
    padding: 0 12px;
  }

  &-placeholder {
    font-size: 16px;
    font-weight: 400;
  }
}

.link-header {
  margin-bottom: 8px;

  &-label {
    font-size: 16px;
    color: #1e1e22;
    font-weight: 500;
  }
}
</style>