<template>
  <svg-supplier />
  <router-view v-if="isRouterAlive" @refresh="reload" />
</template>

<script>
import { mapState } from "vuex";
import svgSupplier from "@/components/common/svg-supplier";
import ResizeMixin from "@/layout/mixin/ResizeHandler";

export default {
  components: {
    svgSupplier
  },

  mixins: [ResizeMixin],

  computed: mapState({
    settings: state => state.settings
  }),

  provide() {
    return {
      reload: this.reload
    };
  },

  data() {
    return {
      isRouterAlive: true
    };
  },

  created() {
    // 加载配置数据
    const loadBase = () => {
      const promises = [];
      if (this.settings.loadStatus !== "success") {
        promises.push(this.$store.dispatch("updateSettings"));
      }
      if (this.settings.quoteStatus) {
        promises.push(this.$store.dispatch("updateQuoteAssets"));
      }
      Promise.all(promises).catch(() => {
        setTimeout(loadBase, 10000);
      });
    };
    loadBase();
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/element-ui.scss";
@font-face {
  font-family: "custom din";
  font-style: normal;
  font-weight: 600;
  src: url("@/assets/fonts/din-medium.ttf");
}

@font-face {
  font-family: "custom harmony";
  font-style: normal;
  font-weight: 400;
  src: url("@/assets/fonts/harmony-sans-regular.ttf");
}

@font-face {
  font-family: "custom harmony";
  font-style: normal;
  font-weight: 600;
  src: url("@/assets/fonts/harmony-sans-medium.ttf");
}

@font-face {
  font-family: "custom harmony";
  font-style: normal;
  font-weight: bold;
  src: url("@/assets/fonts/harmony-sans-bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("@/assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("@/assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("@/assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  src: url("@/assets/fonts/Inter-Bold.ttf");
}

/* * 重置样式 */
html,
body,
#app {
  height: 100%;
}

html,
body {
  width: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  position: relative;
  font-family: "Inter", font-family-normal;
  font-size: font-body-normal;
  color: color-text-body;
  background: #fbfdfe;
}

a {
  color: inherit;
  background: transparent;
  text-decoration: none;
}

input {
  padding: 0;
}

img {
  outline: none;
  vertical-align: center;
}

/* * 布局用样式 */
.row {
  display: flex;
}

.row-align-center {
  display: flex;
  align-items: center;
}

.row-justify {
  display: flex;
  justify-content: space-between;
}

.row-justify-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.col-align-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* * 特殊样式 */
.footer-fixed-margin {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}

.footer-fixed-padding {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
