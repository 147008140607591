<template>
  <el-dialog
    top="10vh"
    width="90%"
    style="max-width:720px"
    title="Settlement Currency"
    append-to-body
    @close="dialogClose"
    @open="dialogOpen"
  >
    <div>
      <el-input
        v-model="filterKey"
        size="large"
        placeholder="Search"
        :prefix-icon="Search"
        :suffix-icon="Search"
      />

      <el-scrollbar max-height="400px">
        <div
          class="scroller mt-6"
          v-if="filteredOptions && filteredOptions.length > 0"
        >
          <el-row :gutter="20">
            <el-col
              v-for="(item, index) in filteredOptions"
              :key="item.uuid"
              :xs="12"
              :sm="8"
            >
              <div
                class="item row-align-center cursor-pointer"
                :class="[
                  item.uuid == defaultValue ? 'item-active' : '',
                  item.available ? '' : 'disabled',
                  index === 0 ? 'item-all' : '',
                  index % 3 != 2 ? 'item-right' : ''
                ]"
                :key="item.uuid"
                @click="$_onChoose(item)"
              >
                <exp-symbol class="item-icon" :uuid="item.uuid" />
                <exp-coin-name
                  :uuid="item.uuid"
                  :symbol="item.symbol"
                  class="item-name"
                />
                <span class="item-fiat" v-if="!item.isAsset">Fiat</span>

                <span class="tag" v-if="item.uuid === defaultValue">
                  <el-icon><Select /></el-icon>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="scroller" v-else>
          <empty-view class="mt-4 w-full" />
        </div>
      </el-scrollbar>
    </div>

    <el-dialog
      title="Why can't I choose?"
      top="10vh"
      width="90%"
      style="max-width:720px"
      append-to-body
      v-model="isShowLimitDialog"
    >
      <div class="limit-dialog">
        <p class="text-base leading-6">
          1. MixPay will trade in real-time through the exchange during
          settlement. Due to the depth of each currency, MixPay has set a limit
          protection to prevent losses caused by huge slippage when the
          transaction amount is too large during settlement.
        </p>
        <p class="text-base leading-6 my-2">2.Currency limits.</p>
        <el-table
          :data="limitAssets"
          max-height="340"
          table-layout="auto"
          class="table-assets shadow-sm"
          header-row-class-name="table-header"
          scrollbar-always-on
        >
          <el-table-column label="Currency">
            <template #default="scope">
              <span>
                {{ scope.row.symbol + (networkMap[scope.row.uuid] || "") }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="`Min.(${limitSymbol})`"
            prop="minQuoteAmount"
          >
          </el-table-column>

          <el-table-column
            :label="`Max.(${limitSymbol})`"
            prop="maxQuoteAmount"
          >
          </el-table-column>
        </el-table>
        <div class="row-center">
          <el-button
            type="primary"
            class="mt-10 w-1/2"
            @click="isShowLimitDialog = !isShowLimitDialog"
          >
            I Got It
          </el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    defaultValue: {
      type: [Number, String],
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    filterFn: {
      type: Function,
      default: function(item, key) {
        if (!this.hasFilter) return true;
        const filterKey = String(key).toLowerCase();
        const text = String(item.symbol).toLowerCase();
        return text.indexOf(filterKey) > -1;
      }
    }
  },

  computed: mapState({
    device: state => state.device,
    settlementAssets: state => state.settings.settlementAssets,
    multisigAccount: state => state.multisigAccount,

    computedOptions() {
      let list = [
        {
          icon: require("@/assets/all.svg"),
          symbol: this.$t("selector.all"),
          uuid: "all",
          isAsset: 1,
          size: "lg",
          available: true
        }
      ];
      if (this.options.length) {
        list = list.concat(this.options);
      } else {
        list = list.concat(this.settlementAssets);
      }
      list.sort((itemA, itemB) => {
        return +itemB.available - +itemA.available;
      });
      return list;
    },
    filteredOptions() {
      if (this.isMultisig) {
        return this.computedOptions
          .filter(item => item.isAsset)
          .filter(item => {
            const symbol = String(item.symbol).toLowerCase();
            const key = String(this.filterKey).toLowerCase();
            return symbol.indexOf(key) > -1;
          });
      } else {
        return this.computedOptions.filter(item => {
          const symbol = String(item.symbol).toLowerCase();
          const key = String(this.filterKey).toLowerCase();
          return symbol.indexOf(key) > -1;
        });
      }
    },
    limitAssets() {
      if (this.options.length) {
        return this.options;
      }
      return this.settlementAssets;
    },
    limitSymbol() {
      return this.limitAssets[0] && this.limitAssets[0].quoteSymbol;
    }
  }),

  data() {
    return {
      filterKey: "",
      isMultisig: false,
      isShowLimitDialog: false,
      networkMap: {
        "4d8c508b-91c5-375b-92b0-ee702ed2dac5": "(ERC20)",
        "b91e18ff-a9ae-3dc7-8679-e935d9a4b34b": "(TRC20)"
      }
    };
  },

  methods: {
    $_onChoose(item) {
      // if (item.uuid === this.defaultValue) {
      //   this.$emit("closeDialog");
      //   return;
      // }
      if (!item.available) {
        this.showSettlementDialog();
        return;
      }
      this.$emit("update:defaultValue", item.uuid);
      this.$emit("closeDialog");
    },
    dialogClose() {
      this.filterKey = "";
    },
    showSettlementDialog() {
      this.isShowLimitDialog = true;
    },
    dialogOpen() {
      this.isMultisig =
        this.multisigAccount && this.multisigAccount.type === "multisig";
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input {
  height: 50px;
  font-size: 16px;
}

.scroller {
  display: flex;
  flex-wrap: wrap;

  .item {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 12px;
    border: 1.5px solid #dcdfe6;

    &-active {
      background: #fff;
      border: 1px solid var(--el-color-primary);
    }

    &.disabled {
      filter: grayscale(1);
    }

    // &-all {
    //   width: 432px;
    // }

    // &-right {
    //   margin-right: 32px;
    // }

    .item-icon {
      flex-shrink: 0;
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }

    .item-name {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }

    .item-fiat {
      font-size: 11px;
      font-weight: 500;
      color: #ffa527;
      background: rgba(255, 165, 39, 0.07);
      border-radius: 2px;
      border: 1px solid rgba(255, 165, 39, 0.06);
      padding: 1px 5px;
    }

    .exp-fiat-icon {
      margin: 0;
    }

    .tag {
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
      background: linear-gradient(
        45deg,
        transparent,
        transparent 50%,
        var(--el-color-primary) 50%,
        var(--el-color-primary)
      );
      color: #fff;
      border-radius: 0 10px 0 0;

      .el-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 12px;
      }
    }
  }
}

.limit-dialog {
  .bot {
    color: var(--el-color-primary);
  }
}
</style>