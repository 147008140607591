<template>
  <el-dialog
    title="Order Confirm"
    top="10vh"
    width="90%"
    style="max-width:480px;"
    :close-on-click-modal="false"
    @closed="onDialogClose"
  >
    <div class="step pb-6" v-if="step === 0">
      <div class="receive text-center">
        <p class="receive-title text-xl">Estimated to receive</p>
        <p class="receive-amount mt-2">
          <strong class="num">{{ receiveAmount }}</strong>
          <span class="unit"
            >&nbsp;{{ receiveAsset && receiveAsset.symbol }}</span
          >
        </p>
      </div>
      <div class="detail mt-12">
        <div class="detail-item row-justify mt-6">
          <div class="detail-item-title">Payment Amount</div>
          <div class="detail-item-content">
            {{ payAmount }} {{ payAsset && payAsset.symbol }}
          </div>
        </div>
        <div class="detail-item row-justify mt-6">
          <div class="detail-item-title">Estimated Price</div>
          <div class="detail-item-content text-right">
            <div>
              1 {{ receiveAsset && receiveAsset.symbol }} ≈ {{ forwardPrice }}
              {{ payAsset && payAsset.symbol }}
            </div>
            <div class="mt-2">
              1 {{ payAsset && payAsset.symbol }} ≈ {{ price }}
              {{ receiveAsset && receiveAsset.symbol }}
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group mt-12 mx-9">
        <el-button type="primary" @click="goStep(1)">
          <span>Confirm</span>
        </el-button>
      </div>
    </div>
    <div class="step pb-6" v-else-if="step === 1">
      <div class="back-wrapper">
        <div
          class="back-btn row-align-center cursor-pointer"
          @click="goStep(0)"
        >
          <exp-svg-icon name="back" size="sm" />
          <span class="text-base">Back</span>
        </div>
      </div>
      <pin-codebox
        ref="pincodebox"
        class="mt-4"
        v-model:pin="pin"
        :loading="isSubmitting"
        @submit="onSubmit"
      />
    </div>
  </el-dialog>
</template>

<script>
import PinCodebox from "@/components/codebox/input-codebox.vue";
import { postConvert } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    PinCodebox
  },

  props: {
    payAmount: {
      type: String,
      default: "0"
    },
    receiveAmount: {
      type: String,
      default: "0"
    },
    payAsset: {
      type: Object,
      default: () => ({})
    },
    receiveAsset: {
      type: Object,
      default: () => ({})
    },
    price: String,
    forwardPrice: String
  },

  data() {
    return {
      step: 0,
      pin: "",
      isSubmitting: false
    };
  },

  methods: {
    goStep(step) {
      this.step = step;
    },
    onSubmit() {
      if (this.pin.length < 6) {
        ElMessageBox.alert(
          "Please make sure your password contains at least 6 characters.",
          "Reminder",
          {
            confirmButtonText: "Confirm",
            showClose: false,
            callback: () => {
              this.$refs.pincodebox && this.$refs.pincodebox.focus();
            }
          }
        );
        return;
      }
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      postConvert(
        this.payAsset.uuid,
        this.receiveAsset.uuid,
        this.payAmount,
        this.pin
      )
        .then(() => {
          this.isSubmitting = false;
          this.$emit("success");
          ElMessage({
            message: "Transfer Successfully",
            type: "success"
          });
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog", () => {
            this.pin = "";
            this.$refs.pincodebox && this.$refs.pincodebox.focus();
          });
        });
    },
    onDialogClose() {
      this.step = 0;
      this.pin = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.receive {
  .receive-amount {
    .num {
      font-size: 2rem;
    }

    .unit {
      font-size: 1rem;
    }
  }
}

.btn-group {
  .el-button {
    width: 100%;
    height: 50px;
  }
}
</style>