<template>
  <el-dialog
    :title="$t('history.depositTitle')"
    top="10vh"
    style="max-width:480px"
    width="90%"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <el-scrollbar
      v-loading="isLoading"
      element-loading-text="Loading..."
      max-height="680px"
    >
      <div class="status col-center">
        <exp-svg-icon
          :name="statusIcon()"
          :size="device === 'mobile' ? 'lg' : 'xl'"
          class="status-icon"
        />
        <h4 class="status-title">
          {{ upperFirstConcat(billing.orderStatus) }}
        </h4>
        <p class="col-center">
          <span class="strong-num" :class="billing.orderStatus">
            {{ noSci(billing.quoteAmount) }}
            <span class="unit">{{ billing.quoteSymbol }}</span>
          </span>
          <span class="num" v-if="symbol !== priceSymbol && amount > 0">
            {{ amount }} {{ symbol }}
          </span>
        </p>
      </div>
      <div class="division"></div>
      <div class="detail">
        <div v-if="billing.name && billing.email">
          <h3 class="info-title">Customer Information</h3>
          <div class="detail-item row-justify-center">
            <div class="detail-item-title">Name</div>
            <div class="detail-item-content">{{ billing.name || "-" }}</div>
          </div>
          <div class="detail-item row-justify-center">
            <div class="row-align-center">
              <div class="detail-item-title">Email</div>
              <el-tag class="ml-2" type="success">Issued</el-tag>
            </div>
            <div class="detail-item-content">{{ billing.email || "-" }}</div>
          </div>
        </div>
        <h3 class="info-title">Payment Information</h3>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">From</div>
          <div class="detail-item-content" v-if="isReceived">
            {{ payer }} <br />
            <span v-if="payerId">(ID: {{ payerId }})</span>
          </div>
          <div class="detail-item-content" v-else>-</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.settlementTo") }}</div>
          <div class="detail-item-content" v-if="isReceived">
            {{ $t(`history.${settlementMethod}`) }}
          </div>
          <div class="detail-item-content" v-else>
            {{ settlementType }}
          </div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.transactionID") }}</div>
          <div
            class="row-center"
            v-if="isReceived"
            @click="openExplorer(transferSnapshotId)"
          >
            <div class="detail-item-content link">
              {{ transferSnapshotId || "-" }}
            </div>
          </div>
          <div class="row-center" v-else>
            <div class="detail-item-content">{{ "-" }}</div>
          </div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.payeeNote") }}</div>
          <div class="detail-item-content" v-if="isReceived">
            {{ remark || "-" }}
          </div>
          <div class="detail-item-content" v-else>
            {{ billing.remark || "-" }}
          </div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">{{ $t("history.payerNote") }}</div>
          <div class="detail-item-content" v-if="isReceived">
            {{ note || "-" }}
          </div>
          <div class="detail-item-content" v-else>-</div>
        </div>
        <div class="detail-item row-justify-center">
          <div class="detail-item-title">Time Created</div>
          <div class="detail-item-content">{{ billing.createdAt }}</div>
        </div>
        <div class="detail-item row-justify-center" v-if="isReceived">
          <div class="detail-item-title">Payment Time</div>
          <div class="detail-item-content">{{ createdAt }}</div>
        </div>
        <div class="detail-item row-justify-center" v-else>
          <div class="detail-item-title">Time Expired</div>
          <div class="detail-item-content">{{ billing.expiredAt }}</div>
        </div>
      </div>
      <el-button
        type="primary"
        class="mt-2 w-1/2 ml-[125px]"
        @click="billingClick"
        :loading="isSending"
        v-if="
          billing.orderStatus != 'pending' ||
            (billing.orderStatus === 'pending' &&
              billing.email &&
              !billing.hasSendEmail)
        "
        >{{
          billing.orderStatus === "pending" ? "Send Email" : "Duplicate Billing"
        }}</el-button
      >
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { upperFirstConcat } from "@/utils/string";
import { noSci } from "@/utils/num-format.js";
import { getReceiveDetail, sendMail } from "@/api/index.js";
import { formatTime } from "@/utils/date-format.js";
import { ElMessageBox, ElNotification } from "element-plus";

export default {
  props: {
    billing: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device
    }),
    isReceived() {
      return this.billing.orderStatus === "received";
    },
    settlementType() {
      if (this.userInfo.type === "mvm") {
        return "MVM";
      }
      if (["mixin", "robot", "multisig"].includes(this.userInfo.type)) {
        return "Mixin Wallet";
      }
      return "L2 Wallet";
    }
  },

  data() {
    return {
      isLoading: false,
      amount: "0",
      symbol: "",
      price: "",
      priceSymbol: "",
      snapshotId: "",
      status: "",
      createdAt: "0",
      payer: "",
      payerId: "",
      note: "",
      remark: "",
      transferSnapshotId: "",
      transferStatus: "none",
      settlementMethod: "",
      transactionHash: "",
      isSending: false
    };
  },

  methods: {
    upperFirstConcat,
    noSci,
    dialogOpen() {
      if (this.billing.orderStatus === "received") {
        this.isLoading = true;
        getReceiveDetail(this.billing.traceId)
          .then(({ data: d }) => {
            this.isLoading = false;
            if (d) {
              this.amount = d.settlementAmount;
              this.symbol = d.settlementSymbol;
              this.price = d.quoteAmount;
              this.priceSymbol = d.quoteSymbol;
              this.status = d.status;
              this.createdAt = formatTime(
                d.createdAt * 1000,
                "YYYY-MM-DD HH:mm"
              );
              this.payer =
                String(d.payerName).length > 12
                  ? d.payerName.substring(0, 12) + "..."
                  : d.payerName;
              this.payerId = d.payerMixinNumber;
              this.note = d.note;
              this.remark = d.remark;
              this.transferSnapshotId = d.transferSnapshotId;
              this.settlementMethod = d.settlementMethod;
            }
          })
          .catch(err => {
            this.isLoading = false;
            this.$errHandler(err, "dialog");
          });
      }
    },
    dialogClose() {},
    statusIcon() {
      if (this.billing.orderStatus === "pending") {
        return "status-processing";
      }
      if (this.billing.orderStatus === "received") {
        return "status-settled";
      }
      return "status-expired";
    },
    openExplorer(snapshotId) {
      if (!snapshotId) return;
      window.open(
        `https://mixin.one/snapshots/${snapshotId}`,
        "_blank",
        "noopener"
      );
    },
    billingClick() {
      if (this.billing.orderStatus === "pending") {
        ElMessageBox.confirm(
          "We will send the billing details and payment instructions to your customer via email.",
          "Send Email",
          {
            confirmButtonText: "Send",
            showCancelButton: false
          }
        ).then(() => {
          this.isSending = true;
          sendMail(this.billing.id)
            .then(() => {
              this.isSending = false;
              this.$emit("successSend");
              ElNotification({
                title: "Success",
                message: "Send Email succeeded.",
                type: "success"
              });
            })
            .catch(err => {
              this.isSending = false;
              this.$errHandler(err, "dialog");
            });
        });
      } else {
        this.$emit("createBilling");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.status {
  .status-title {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
    color: color-text-base;
  }

  .strong-num {
    display: flex;
    align-items: flex-start;
    font-size: 32px;
    font-weight: bold;

    &.pending {
      color: #f7a349;
    }

    &.received {
      color: #0ec9b1;
    }

    &.expired {
      color: color-text-base;
    }

    .unit {
      margin-top: 12px;
      margin-left: 2px;
      font-size: 18px;
    }
  }

  .num {
    margin-top: 10px;
  }
}

.division {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-position: left center;
  background-size: 20px 2px;
  background-image: linear-gradient(
    90deg,
    rgba(#5162ad, 0.16),
    rgba(#5162ad, 0.16) 12px,
    transparent 12px,
    transparent 20px
  );
}

.info-title {
  margin-bottom: 20px;
}

.detail-item {
  font-size: 16px;
  margin-bottom: 20px;

  @media only screen and (max-width: 470px) {
    font-size: 12px;
  }

  &-title {
    max-width: 300px;
    color: color-text-caption;
  }

  &-content {
    max-width: 350px;
    font-weight: 600;
    word-break: break-all;
    text-align: right;

    &.link {
      color: var(--el-color-primary);
    }
  }
}
</style>