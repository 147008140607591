<template>
  <el-dialog top="5vh" width="80%" title="About Multisig Account">
    <el-scrollbar max-height="600" always>
      <div class="content">
        Multi-signature is a safe, reliable, and effective practical technology
        that can effectively improve the security of custody assets and enhance
        customer trust. Mixin mainnet supports multi-signature based on ed25519,
        and t/M can be arbitrarily set to participate in multi-signature, where
        t is threshold, "M" is members, and "M" supports up to 255 people to
        participate in multi-signature. For example, 2/3 multi-signature means
        that there are 3 people who jointly manage the assets, and any two
        people need to sign the transaction to use the assets.
      </div>
      <div class="content">
        Different from the Bitcoin multi-signature address starting with 3,
        Mixin's multi-signature is based on UTXO but there is no distinctive
        address for multi-signature. A transfer is sent to multiple people. The
        transfer needs to specify a set of payees and a threshold. The asset is
        co-owned by all parties participating in the multi-signature, and the
        same user can participate in different multi-signature groups at the
        same time. To facilitate the explanation below, the multi-signature
        account is still used to refer to the encrypted assets jointly managed
        by multiple people.
      </div>
      <div class="title">Asset Management</div>
      <div class="content">
        Co-management of assets is one of the main use-cases of multi-signature.
        Each use of funds requires a valid signature by multiple people. No one
        can move the assets by himself, and this mechanism can also avoid
        single-point-of-failures causing permanent loss of assets.
      </div>
      <div class="content">
        The multi-signature bot 7000102367 developed by the Mixin team is very
        simple, all you need is to set up a chat group and you are good to go,
        it contains only the front-end and it's open-source
      </div>
      <el-link
        class="url"
        type="primary"
        href="https://github.com/MixinNetwork/multisig-bot"
        target="_blank"
      >
        https://github.com/MixinNetwork/multisig-bot.
      </el-link>
      <div class="content">
        The multi-signature assistant bot 7000102968 developed by the Laomao
        team is a multi-currency multi-signature wallet with a simple and
        friendly interface.
      </div>
      <div class="content">
        The multi-signature wallet called "CoWallet"(id: 7000103970) developed
        by Pando Team is a multi-currency multi-signature wallet with a simple
        and friendly interface. It's also open-source
      </div>
      <el-link
        class="url"
        type="primary"
        href="https://github.com/fox-one/cowallet"
        target="_blank"
        >https://github.com/fox-one/cowallet.</el-link
      >
      <div class="title">Application</div>
      <div class="sub-title">E-Commerce</div>
      <div class="content">
        2/3 multi-signature is commonly used in the e-commerce industry. Buyers,
        sellers, and platforms are the multi-signature participants. Buyers
        first transfer funds to the multi-signature account, and then the buyer
        and seller can confirm the subsequent transaction confirmation and
        refund process. When disputes arise, the platform will do 2/3
        arbitration. The e-commerce platform effectively guarantees the
        integrity of the two parties by implementing multi-signature
        transactions, reducing transaction risks and improving efficiency.
      </div>
      <div class="sub-title">E-government</div>
      <div class="content">
        Multi-signature technology can also be applied to the e-government
        system of level-by-level approval, where multi-signature transfers are
        associated with the approval process and electronic contracts. Process
        participants sign the transaction level-by-level until the electronic
        signature finally becomes effective and the certificate is stored
        on-chain. For example, set up a 4/4 financial expenditure approval
        process, in which the participants are marketing people, department
        leaders, finance staff, and cashiers. Marketing people initiate and
        participate in multi-signatures, after the department leaders, finance
        staff, and cashiers review and sign in turn, automated lending can be
        realized, and the entire process is automatically recorded on-chain.
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<style lang="scss" scoped>
.el-dialog {
  max-width: 600px;
  width: 80%;
}

.content {
  color: color-text-base;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.title {
  color: color-text-base;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.sub-title {
  color: color-text-base;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.url {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
</style>