<template>
  <el-dialog
    title="Customer Information"
    top="10vh"
    style="max-width:480px"
    width="90%"
    @open="dialogOpen"
    @close="dialogClose"
    destroy-on-close
  >
    <template #default>
      <el-form
        class="el-form"
        ref="infoForm"
        :rules="infoRules"
        :model="infoForm"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <el-form-item label="Name" prop="name">
          <el-input
            v-model="infoForm.name"
            size="large"
            maxlength="20"
            placeholder="This name will be used for your records only"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="infoForm.email"
            size="large"
            type="email"
            @input="quoteAmountChange"
            placeholder="Please enter customer's email address"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="row-center mt-10">
        <el-button class="w-1/2" type="primary" @click="confirmInfo">
          Confirm
        </el-button>
      </div>

      <el-dialog
        title="Billing Confirmation"
        v-model="isShowConfirmDialog"
        top="10vh"
        style="max-width:480px"
        width="90%"
        append-to-body
      >
        <div class="confirm-body">
          <h3 class="info-title">Customer Information</h3>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Name</div>
            <div class="info-item-content">{{ infoForm.name }}</div>
          </div>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Email</div>
            <div class="info-item-content">{{ infoForm.email }}</div>
          </div>
          <h3 class="info-title">Payment Information</h3>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Amount</div>
            <div class="info-item-content row-align-center">
              <exp-symbol
                :uuid="billing.quoteAssetId"
                class="mr-1"
                v-if="billing.quoteAssetId"
              />
              <span class="coin-name text-slate-700">
                {{ noSci(billing.quoteAmount) }} {{ billing.quoteSymbol }}
              </span>
            </div>
          </div>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Settlement Currency</div>
            <div
              class="info-item-content row-align-center"
              v-if="billing.settlementAssetId"
            >
              <exp-symbol :uuid="billing.settlementAssetId" class="mr-1" />
              <exp-coin-name
                :uuid="billing.settlementAssetId"
                :symbol="billing.settlementSymbol"
                class="coin-name text-slate-700"
              />
            </div>
            <div class="info-item-content row-align-center" v-else>
              <img class="coin-icon mr-1 w-[18px]" src="@/assets/all.svg" />
              <span>{{ $t("selector.all") }} </span>
            </div>
          </div>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Validity Period</div>
            <div class="info-item-content">{{ billing.expiredAt }}</div>
          </div>
          <div class="info-item row-justify-center">
            <div class="info-item-title">Note</div>
            <div class="info-item-content">{{ billing.remark || "--" }}</div>
          </div>

          <div class="row-align-center mt-2">
            <el-button
              type="info"
              class="flex-1"
              @click="isShowConfirmDialog = false"
            >
              Cancel
            </el-button>
            <el-button
              type="primary"
              class="flex-1"
              @click="sendEmail"
              :loading="isSubmitting"
            >
              Send Email
            </el-button>
          </div>

          <div class="mt-4 text-center">
            We will send the billing details and payment instructions to your
            customer via email.
          </div>
        </div>
      </el-dialog>
    </template>
  </el-dialog>
</template>

<script>
import { noSci } from "@/utils/num-format.js";
import { sendMail } from "@/api/index.js";

export default {
  props: {
    billing: {
      type: Object
    }
  },

  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please enter the Name"));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (!value) {
        callback(new Error("Please enter the Email"));
      } else if (!verify.test(value)) {
        callback(new Error("Please enter the true Email"));
      } else {
        callback();
      }
    };
    return {
      infoRules: {
        name: [{ required: true, trigger: "change", validator: validateName }],
        email: [{ required: true, trigger: "change", validator: validateEmail }]
      },
      infoForm: {
        name: "",
        email: ""
      },
      isShowConfirmDialog: false,
      isSubmitting: false
    };
  },

  methods: {
    noSci,
    dialogOpen() {},
    dialogClose() {},
    confirmInfo() {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          this.isShowConfirmDialog = true;
        }
      });
    },
    sendEmail() {
      this.isSubmitting = true;
      sendMail(this.billing.id, this.infoForm.name, this.infoForm.email)
        .then(() => {
          this.isSubmitting = false;
          this.$emit("successSend");
          // ElNotification({
          //   title: 'Success',
          //   message: 'Send Email succeeded.',
          //   type: 'success',
          // })
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$errHandler(err, "dialog");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin: 0 0 30px;
}

.el-button {
  height: 50px;
}

.el-input {
  height: 50px;
  font-size: 16px;
}

.confirm-body {
  .info-title {
    margin-bottom: 30px;
  }

  .info-item {
    font-size: 16px;
    margin-bottom: 24px;

    &-title {
      max-width: 300px;
      color: color-text-caption;
    }

    &-content {
      max-width: 350px;
      font-weight: 600;
      word-break: break-all;
      text-align: right;
      color: color-text-base;
    }
  }
}
</style>