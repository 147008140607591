<template>
  <div>
    <div class="pt-4 pb-20">
      <div class="body-top px-10 pb-4">

        <!-- 新用户还未签署最新协议 -->
        <div class="danger-message" v-if="userInfo.isInWhiteList && !userInfo.isReadNewlyAgreement">
          Dear MixPay user<br /><br />
          ⚠️ Important update to <a class="el-link el-link--primary is-underline" :href="'https://mixpay.me/user-agreement-update-2024?payee_id=' + userInfo.userUuid" target="_blank">MixPay user agreement</a>
        </div>

        <!-- 老用户 -->
        <div class="danger-message" v-if="!userInfo.isSafeRegistered && userInfo.isInWhiteList">
          Dear MixPay user<br /><br />
          You need to upgrade your Mixin account to using Mixin Safe to continue using MixPay. <br /><br />

          Please upgrade your Mixin Messenger App to the latest version, and follow the migration instruction.<br /><br />

          If you encounter any problems, please leave a message for the Mixin robot at 7000.<br /><br />

          After you upgrade to using Mixin Safe, you can refresh this page, if this message still showing, please send an email to <el-link type="primary" href="mailto:bd@mixpay.me" target="_blank">bd@mixpay.me</el-link>.
        </div>

        <!-- 新用户还未开通白名单，不允许收款的情况下 -->
        <div class="danger-message" v-if="!userInfo.isInWhiteList">
          Dear MixPay user:<br /><br />
          ⚠️ Your account is not allowed to receive payment right now. Please <el-link type="primary" href="https://mixpay.me/quick-start#whitelist-form" target="_blank">submit the form</el-link> to get whitelisted.<br/><br/>
          MixPay Team.<br />
        </div>

        <h3 class="title mb-3">Dashbord</h3>
        <div class="text-darl-dark text-lg font-medium mb-4">Today</div>

        <el-row :gutter="10">
          <el-col :xs="24" :sm="8">
            <div
              class="row-align-center text-sm leading-4 text-gray-gray4 font-medium transform translate-y-1.5 mb-2.5"
            >
              <div>Total Amount</div>
              <el-tooltip
                effect="dark"
                content="Today 00:00 - 23:59 Total Received"
                placement="bottom"
              >
                <exp-svg-icon
                  name="question"
                  size="xxs"
                  class="ml-1 cursor-pointer"
                />
              </el-tooltip>
            </div>
            <h1 class="total-usd font-bold text-dark-dark">
              $ {{ userTodayStatistic.transactionValue }}
            </h1>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div
              class="row-align-center text-sm leading-4 text-gray-gray4 font-medium transform translate-y-1.5 mb-2.5"
            >
              <div>Total Completed</div>
              <el-tooltip
                effect="dark"
                content="Number of successful payments today"
                placement="bottom"
              >
                <exp-svg-icon
                  name="question"
                  size="xxs"
                  class="ml-1 cursor-pointer"
                />
              </el-tooltip>
            </div>
            <h1 class="total-usd font-bold text-dark-dark">
              {{ userTodayStatistic.transactionCount }}
            </h1>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div
              class="row-align-center text-sm leading-4 text-gray-gray4 font-medium transform translate-y-1.5 mb-2.5"
            >
              <div>Active Users</div>
              <el-tooltip
                effect="dark"
                content="Total amount of users with successful payment today"
                placement="bottom"
              >
                <exp-svg-icon
                  name="question"
                  size="xxs"
                  class="ml-1 cursor-pointer"
                />
              </el-tooltip>
            </div>
            <h1 class="total-usd font-bold text-dark-dark">
              {{ userTodayStatistic.activeUserCount }}
            </h1>
          </el-col>
        </el-row>
      </div>

      <div class="flex gap-28 px-10 pt-7" v-if="userInfo.type === 'mvm'">
        <div class="w-2/5">
          <div class="flex-col mb-2">
            <div
              class="text-sm leading-4 text-gray-gray4 font-medium transform translate-y-1.5 mb-2.5"
            >
              Total Balance In Wallet
            </div>
            <div class="total-usd font-bold text-dark-dark">
              $ {{ totalUSD }}
            </div>
          </div>
          <el-skeleton :rows="7" animated v-if="balanceLoaing" />
          <el-table
            :data="userAssetsList"
            size="large"
            max-height="340"
            table-layout="auto"
            class="table-assets shadow-sm"
            header-row-class-name="table-header"
            scrollbar-always-on
            v-else
          >
            <el-table-column label="TOKEN">
              <template #default="scope">
                <div class="row-align-center">
                  <exp-symbol
                    class="item-left flex-shrink-0"
                    :uuid="scope.row.uuid"
                    :iconUrl="scope.row.icon"
                    size="xl"
                    :isRound="true"
                  />
                  <span
                    class="symbol text-sm text-dark-dark leading-6 font-bold ml-4"
                  >
                    {{
                      (assetMap[scope.row.uuid] &&
                        assetMap[scope.row.uuid].symbol) ||
                        scope.row.symbol
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="scope">
                <el-popover
                  placement="top"
                  v-if="scope.row.isCanAdd"
                  effect="dark"
                >
                  <template #reference>
                    <div
                      class="row-align-center cursor-pointer w-min"
                      @click="
                        addAssetToWallet(scope.row.uuid, scope.row.symbol)
                      "
                    >
                      <img
                        class="w-[56px]"
                        src="@/assets/images/add-metamask.svg"
                      />
                    </div>
                  </template>
                  <span
                    class="cursor-pointer"
                    @click="addAssetToWallet(scope.row.uuid, scope.row.symbol)"
                  >
                    Add to Metamask
                  </span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="BALANCE" align="right">
              <template #default="scope">
                <div class="item-right col-align-end">
                  <span class="amount text-dark-dark text-base">
                    {{ scope.row.balance }}
                  </span>
                  <span class="value text-gray-gray4 text-sm">
                    ≈ $
                    {{
                      toFixed(Math.floor(scope.row.balanceUsd * 100) / 100, 2)
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="w-3/5">
          <div
            class="font-inter text-dark-dark text-xl leading-8 -tracking-tighest"
          >
            About Wallet Assets
          </div>
          <div
            class="font-inter text-gray-gray5 font-normal text-sm leading-6 -tracking-tighest mt-2"
          >
            <p>
              1. The assets settled by MixPay will be deposited into the
              <el-link
                type="primary"
                href="https://scan.mvm.dev/"
                target="_blank"
              >
                MVM Network </el-link
              >.
            </p>
            <p>
              2. Transferring from
              <el-link
                type="primary"
                href="https://scan.mvm.dev/"
                target="_blank"
                >MVM Network</el-link
              >
              to other Networks requires use of
              <el-link
                type="primary"
                href="https://bridge.mvm.app/"
                target="_blank"
              >
                MVM Bridge </el-link
              >.
            </p>
          </div>
          <el-steps
            direction="vertical"
            :active="4"
            class="mt-6 h-min py-0"
            :space="80"
          >
            <el-step>
              <template #title>
                Open
                <el-link
                  class="step-url"
                  type="primary"
                  href="https://bridge.mvm.app/"
                  target="_blank"
                >
                  MVM Bridge
                </el-link>
              </template>
            </el-step>
            <el-step title="Connect Wallet" />
            <el-step title="Select Withdrawal Asset" />
            <el-step title="Confirm Withdrawal" />
          </el-steps>
        </div>
      </div>

      <div
        :class="device === 'mobile' ? '' : 'row-justify-center'"
        class="mt-6 px-10"
      >
        <h3 class="title">Recent Transactions</h3>
        <div
          class="row-align-center"
          :class="device === 'mobile' ? 'mt-3' : ''"
          v-if="
            dashbordFlows && dashbordFlows.list && dashbordFlows.list.length > 0
          "
        >
          <el-button
            class="mr-6"
            type="success"
            icon="Refresh"
            :loading="isRefreshing"
            @click="refrshTrans"
          >
            Refresh
          </el-button>
          <el-button type="primary" icon="View" @click="viewTrans">
            View All
          </el-button>
        </div>
      </div>
      <div class="px-10">
        <empty-view
          v-if="
            dashbordFlows &&
              dashbordFlows.list &&
              dashbordFlows.list.length <= 0
          "
          class="mt-4"
        />
        <el-table
          :data="dashbordFlows.list"
          size="large"
          class="mt-4 text-[15px] shadow-sm"
          v-loading="transLoading"
          element-loading-text="Loading..."
          table-layout="auto"
          v-else
        >
          <el-table-column prop="payerName" label="Payer" min-width="100" />
          <el-table-column label="Quote" min-width="200">
            <template #default="scope">
              <div class="row-align-center">
                <exp-symbol
                  class="coin-icon"
                  :uuid="scope.row.quoteAssetId"
                  :iconUrl="scope.row.quoteIcon"
                />
                <span class="mx-2">{{ scope.row.quoteAmount }}</span>
                <span>{{ scope.row.quoteSymbol }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Receiving" min-width="200">
            <template #default="scope">
              <div class="row-align-center" v-if="scope.row.settlementAmount">
                <exp-symbol
                  class="coin-icon"
                  :uuid="scope.row.settlementAssetId"
                  :iconUrl="scope.row.settlementIcon"
                />
                <span class="mx-2">{{ scope.row.settlementAmount }}</span>
                <span>{{ scope.row.settlementSymbol }}</span>
              </div>
              <div class="row-align-center" v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="Status" min-width="80">
            <template #default="scope">
              <div class="row-align-center">
                <span
                  :class="
                    getSettlementStatus(scope.row.transferStatus) &&
                    getSettlementStatus(scope.row.transferStatus).status ===
                      'success'
                      ? 'text-[#62d37e]'
                      : 'text-[#fda527]'
                  "
                >
                  {{
                    getSettlementStatus(scope.row.transferStatus) &&
                      getSettlementStatus(scope.row.transferStatus).text
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Date"
            prop="date"
            width="180"
          ></el-table-column>
          <el-table-column label="Operations" width="160">
            <template #default="scope">
              <el-link type="primary" @click="goDetail(scope.row.traceId)">
                Details
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <receive-detail-dailog
      v-model="isShowReceiveDetail"
      :traceId="receiveTraceId"
    />

    <upgrade-to-safe-dialog
      v-model="isShowUpgradeSafe"
      @success="onUpgradeSuccess"
    />

  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ReceiveDetailDailog from "../history/components/receive-detail-dialog.vue";
import Big from "big.js";
import { toFixed, noSci, fixDecimal } from "@/utils/num-format.js";
import { MetaMaskProvider } from "@/plugins/web3/index.js";
import { getAssetContract } from "@/api/mixin";
import UpgradeToSafeDialog from "./components/upgrade-to-safe-dialog.vue";

export default {
  name: "DashboardPage",
  components: {
    ReceiveDetailDailog,
    UpgradeToSafeDialog
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      device: state => state.device,
      dashbordFlows: state => state.dashbordFlows,
      mvmAssets: state => state.mvmAssets,
      settings: state => state.settings,
      userTodayStatistic: state => state.userTodayStatistic
    }),
    ...mapGetters(["assetMap", "mvmAssetsMap", "assetsPricesMap"]),
    totalUSD() {
      const total = this.userAssetsList.reduce((sum, item) => {
        if (item.balanceUsd > 0) {
          return sum.plus(Big(item.balanceUsd));
        }
        return sum;
      }, Big("0"));
      return toFixed(Math.floor(total * 100) / 100, 2);
    },
    userAssetsList() {
      const list = [];
      this.mvmAssets.list.forEach(item => {
        list.push({
          uuid: item.uuid,
          symbol: item.symbol,
          name: item.name,
          balance: fixDecimal(item.balance, 8),
          isCanAdd: item.isCanAdd,
          balanceUsd: Big(item.balance)
            .times(
              this.assetsPricesMap[item.uuid]
                ? this.assetsPricesMap[item.uuid].priceUsd
                : 0
            )
            .round(8, 1)
            .toString()
        });
      });
      if (Array.isArray(this.settings.settlementAssets)) {
        this.settings.settlementAssets.forEach(item => {
          if (!this.mvmAssetsMap[item.uuid]) {
            list.push({
              uuid: item.uuid,
              symbol: item.symbol,
              name: item.name,
              balance: 0,
              balanceUsd: 0,
              isCanAdd: true
            });
          }
        });
      }
      var temp = list.splice(0, 1);
      list.sort((itemA, itemB) => {
        return +itemB.balanceUsd - +itemA.balanceUsd;
      });
      temp = temp.concat(list);
      return temp;
    }
  },

  data() {
    return {
      list: [],
      receiveTraceId: "",
      isShowReceiveDetail: false,
      isShowUpgradeSafe: false,
      transLoading: false,
      isRefreshing: false,
      balanceLoaing: false
    };
  },

  mounted() {
    if (!this.userInfo.userUuid && this.userInfo.token) {
      setTimeout(() => {
        this.$store.dispatch("updateUserInfo").catch(() => {});
      }, 500);
    }

    this.loadData();
  },

  methods: {
    toFixed,
    noSci,
    loadData() {
      if (this.dashbordFlows.loadStatus === "pending") {
        this.transLoading = true;
      }
      if (this.userInfo.type === "mvm") {
        if (this.mvmAssets.loadStatus === "pending") {
          this.balanceLoaing = true;
        }
        Promise.all([
          this.$store.dispatch("updateMvmAssets", this.userInfo.walletAddress),
          this.$store.dispatch("getAssetPrice"),
          this.$store.dispatch("updateSettlementAssets", {})
        ])
          .then(() => {
            this.balanceLoaing = false;
            this.getTrans();
          })
          .catch(() => {
            this.balanceLoaing = false;
            this.getTrans();
          });
      } else {
        this.getTrans();
      }

      // 如果是子账号，且未升级 safe，则显示升级 safe 弹窗
      if ( 
        ! this.userInfo.isSafeRegistered
        && this.userInfo.isInWhiteList
        && (this.userInfo.type === "mail" || this.userInfo.type === "metamask")
      ) {
        this.isShowUpgradeSafe = true;
      }
    },

    // 升级 safe 成功
    onUpgradeSuccess() {
      this.isShowUpgradeSafe = false;
      window.location.reload();
    },

    getTrans() {
      Promise.all([
        this.$store.dispatch("updateDashbordFlows"),
        this.$store.dispatch("updateTodayStatistic")
      ])
        .then(() => {
          this.transLoading = false;
        })
        .catch(err => {
          this.transLoading = false;
          this.$errHandler(err);
        });
    },

    getTitle(type = "withdraw", payerName = "") {
      let title = "";
      switch (type) {
        case "deposit":
          title = payerName;
          break;
        case "snapshot_refund":
          title = this.$t("status.refund");
          break;
        default:
          title = this.$t("status.withdraw");
          break;
      }
      return title;
    },

    getSettlementStatus(status) {
      let obj;
      switch (status) {
        case "done":
        case "none":
          obj = {
            status: "success",
            text: this.$t("history.settlementSuccess")
          };
          break;
        case "pending":
        case "unpaid":
          obj = {
            status: "pending",
            text: this.$t("history.settlementProcessing")
          };
          break;
        default:
          obj = {
            status: "",
            text: ""
          };
      }
      return obj;
    },

    goDetail(traceId) {
      this.receiveTraceId = traceId;
      this.isShowReceiveDetail = true;
    },

    refrshTrans() {
      this.isRefreshing = true;
      this.$store
        .dispatch("updateDashbordFlows")
        .then(() => {
          this.isRefreshing = false;
        })
        .catch(() => {
          this.isRefreshing = false;
        });
    },

    viewTrans() {
      this.$router.push("history");
    },

    addAssetToWallet(uuid, symbol) {
      getAssetContract(uuid)
        .then(data => {
          if (data && data.data && data.data.asset_contract) {
            MetaMaskProvider.watchMvmAsset(
              data.data.asset_contract,
              symbol,
              8,
              ""
            );
          }
        })
        .catch(err => {
          this.$errHandler(err, "dialog");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.body-top {
  border-bottom: 1px solid #ececec;
}

.danger-message {
  margin: 20px 0 40px;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  background: #fff7e8;
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
}

.total-usd {
  font-size: 32px;
  line-height: 52px;
}

.table-header {
  color: #999999;
  font-weight: normal;
  font-size: 12px;
}

.step-url {
  font-size: 16px;
  font-weight: 500;
}

:deep {
  .table-assets {
    .table-header {
      color: #999999;

      .cell {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .el-step.is-vertical .el-step__line {
    width: 1px;
    background-color: color-primary;
  }

  .el-step__title.is-finish {
    color: color-text-base;
    font-weight: 500;
  }

  .el-step__icon.is-text {
    border: 1px solid color-primary;
  }
}
</style>
