import axios from "axios";
import CustomErr from "./custom-err";
import config from "@/config";

function responseError(err) {
  const e = Object.create(err);
  e.stack = err.stack || new Error().stack;
  e.name = "Response Error";
  e.isWrapped = true;
  if (e.response && e.response.data) {
    e.preCode = e.code;
    e.preMsg = e.message;
    e.code = e.response.data.code;
    e.message = e.response.data.message;
  }
  return e;
}

const service = axios.create({
  baseURL: config.API_URL + "/v1",
  timeout: 20000,
});

service.interceptors.request.use(
  (req) => {
    return Promise.resolve(req);
  },
  (err) => {
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data);
  },
  (err) => {
    if (err.code == "ECONNABORTED") {
      return Promise.reject(
        new CustomErr(
          responseError(new Error("Request timeout. Please try again."))
        )
      );
    }
    // if (err.message == 'Network Error') {
    //   return Promise.reject(new CustomErr(responseError(new Error('Server error. Please try again later.'))));
    // }
    return Promise.reject(
      new CustomErr(responseError(err), { isResponse: true })
    );
  }
);

export default service;
