<template>
  <el-dialog
    top="10vh"
    width="90%"
    style="max-width:720px"
    title="Quote Currency"
    append-to-body
    @close="dialogClose"
  >
    <div>
      <el-input
        v-model="filterKey"
        size="large"
        placeholder="Please Input"
        :prefix-icon="Search"
        @input="filterKeyChange"
      />

      <el-scrollbar max-height="400px">
        <div
          class="scroller mt-6"
          v-if="computedAssets && computedAssets.length > 0"
        >
          <el-row :gutter="20">
            <el-col
              v-for="item in computedAssets"
              :key="item.uuid"
              :xs="12"
              :sm="8"
            >
              <div
                class="item row-align-center cursor-pointer"
                :class="[item.uuid == defaultValue ? 'item-active' : '']"
                @click="$_onChoose(item)"
              >
                <exp-symbol class="item-icon" :uuid="item.uuid" />
                <span class="item-name">{{ item.symbol }}</span>
                <span class="item-fiat" v-if="!item.isAsset">Fiat</span>

                <span class="tag" v-if="item.uuid === defaultValue">
                  <el-icon>
                    <Select />
                  </el-icon>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="scroller" v-else>
          <empty-view class="mt-4 w-full" />
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    defaultValue: {
      type: [Number, String],
      default: ""
    }
  },

  computed: mapState({
    quoteAssets: state => state.settings.quoteAssets,
    device: state => state.device,

    computedAssets() {
      return this.quoteAssets.filter(item => {
        const symbol = String(item.symbol).toLowerCase();
        const key = String(this.filterKey).toLowerCase();
        return symbol.indexOf(key) > -1;
      });
    }
  }),

  data() {
    return {
      filterKey: ""
    };
  },

  methods: {
    $_onChoose(item) {
      if (item.uuid === this.defaultValue) {
        this.$emit("closeDialog");
        return;
      }
      this.$emit("update:defaultValue", item.uuid);
      this.$emit("closeDialog");
    },
    filterKeyChange() {
      if (!this.filterKeyChange === " ") {
        this.filterKeyChange = "";
      }
    },
    dialogClose() {
      this.filterKey = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input {
  height: 50px;
  font-size: 16px;
}

.scroller {
  display: flex;

  .item {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 14px;
    border-radius: 12px;
    border: 1.5px solid #dcdfe6;

    &-active {
      background: #fff;
      border: 1.5px solid var(--el-color-primary);
    }

    .item-icon {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }

    .item-name {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }

    .item-fiat {
      font-size: 11px;
      font-weight: 500;
      color: #ffa527;
      background: rgba(255, 165, 39, 0.07);
      border-radius: 1px;
      border: 1px solid rgba(255, 165, 39, 0.06);
      padding: 1px 5px;
    }

    .exp-fiat-icon {
      margin: 0;
    }

    .tag {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      background: linear-gradient(
        45deg,
        transparent,
        transparent 50%,
        var(--el-color-primary) 50%,
        var(--el-color-primary)
      );
      color: #fff;
      border-radius: 0 10px 0 0;

      .el-icon {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 16px;
      }
    }
  }
}
</style>